import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "components/Status";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomButton } from "components/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { Loader } from "components/Loader";
import { themes } from "services/constants";

import {
  setDepartmentId,
  setDepartmentLeadId,
  setDepartmentName,
} from "services/Redux/userManagament";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
export const CardView = ({
  departmentList,
  isLoading,
  filterChanges,
  setFilterChanges,
  setEditDepartmentDetails,
  setOpenEditScreen,
  setDepartmentApi,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { token, domain } = useSelector((state) => state.tokenReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const [Page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(20);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserDetails, setDeleteUserDetails] = useState(null);

  useEffect(() => {
    if (filterChanges) {
      setFilterChanges(false);
      setLeavePage(0);
    }
  }, [filterChanges, setFilterChanges]);

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 20));
    setLeavePage(0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setDeleteUserDetails(null);
    setOpenDialog(false);
  };

  const deleteDepartment = async () => {
    try {
      const response = await fetch(`${domain}delete-department/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          department_id: deleteUserDetails.department_id,
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setDepartmentApi(true);
        showToast("error", res.message);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      {departmentList?.length > 0 ? (
        <Mui.Grid container spacing={0} sx={{ justifyContent: "center" }}>
          {departmentList
            .slice(Page * rowsPerPage, Page * rowsPerPage + rowsPerPage)
            .map((department, index) => (
              <Mui.Grid
                item
                key={department.id}
                sx={{ paddingLeft: 3 }}
                spacing={5}
              >
                <Mui.Card
                  className=" min-h-full max-w-fit mt-5 w-96 flex space-x-1 shadow-lg "
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    marginLeft: 3,
                    marginTop: 5,
                  }}
                >
                  <Mui.CardContent>
                    <Mui.Grid container>
                      <Mui.Grid xs={11}>
                        <Mui.Tooltip title={department.department_name}>
                          <Mui.Typography
                            sx={{
                              color: themes.headLine,
                              fontSize: { xs: 15, sm: 17, md: 20, lg: 28 },
                              fontWeight: "bold",
                              textAlign: "left",
                            }}
                          >
                            {department.department_name.length > 10
                              ? department.department_name.substr(0, 10) + "..."
                              : department.department_name}
                          </Mui.Typography>
                        </Mui.Tooltip>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        {department.department_name === "General" ||
                        department.department_name === "All" ? null : (
                          <Mui.IconButton
                            size="small"
                            onClick={(e) => {
                              dispatch(
                                setDepartmentId(department.department_id)
                              );
                              dispatch(
                                setDepartmentLeadId(
                                  department.department_lead_id
                                )
                              );
                              dispatch(
                                setDepartmentName(department.department_name)
                              );
                              handleMenuOpen(e, index);
                              setEditDepartmentDetails(department);
                              setDeleteUserDetails(department);
                            }}
                          >
                            <MoreVertIcon size="small" />
                          </Mui.IconButton>
                        )}
                        {department.department_name === "General" ? null : (
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.Stack>
                              <Mui.MenuItem
                                onClick={() => setOpenEditScreen(true)}
                              >
                                Edit
                              </Mui.MenuItem>
                              <Mui.MenuItem
                                onClick={() => {
                                  handleMenuClose();
                                  setOpenDialog(true);
                                }}
                              >
                                Delete
                              </Mui.MenuItem>
                            </Mui.Stack>
                          </Mui.Menu>
                        )}
                      </Mui.Grid>
                    </Mui.Grid>
                    <div
                      onDoubleClick={() => {
                        dispatch(setDepartmentId(department.department_id));
                        localStorage.setItem(
                          "approveLeaveDepartmentId",
                          department.department_id
                            ? department.department_id
                            : 99999
                        );
                        dispatch(
                          setDepartmentLeadId(department.department_lead_id)
                        );
                        localStorage.setItem(
                          "approveLeaveDepartmentLeadId",
                          department.department_lead_id
                            ? department.department_lead_id
                            : 99999
                        );
                        dispatch(setDepartmentName(department.department_name));
                        if (location.pathname === "/admin/departments") {
                          dispatch(setGlobalSearchValue(""));
                          history.push("/admin/department/view/members");
                        } else {
                          dispatch(setGlobalSearchValue(""));
                          history.push("/admin/department/status");
                        }
                      }}
                    >
                      <Mui.Grid container>
                        <Mui.Grid xs={8.3}>
                          <Mui.Typography
                            fontSize={17}
                            sx={{ paddingTop: 1, fontWeight: "bold" }}
                          >
                            <Mui.Typography>Total Members</Mui.Typography>
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={3.7} sx={{ paddingTop: 0.7 }}>
                          <Mui.Typography>
                            {department?.members?.length}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Grid container sx={{ paddingBottom: 2 }}>
                        <Mui.Grid xs={8.3}>
                          <Mui.Typography
                            fontSize={17}
                            sx={{ paddingTop: 1, fontWeight: "bold" }}
                          >
                            <Mui.Typography>Department Head</Mui.Typography>
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={3.7} sx={{ paddingTop: 0.7 }}>
                          <Mui.Tooltip title={department?.department_lead_name}>
                            <Mui.Typography>
                              {department?.department_lead_name?.length > 10
                                ? department?.department_lead_name.substr(
                                    0,
                                    10
                                  ) + "..."
                                : department?.department_lead_name}
                            </Mui.Typography>
                          </Mui.Tooltip>
                        </Mui.Grid>
                      </Mui.Grid>
                      {/* <Mui.Grid container>
                        <Mui.Grid xs={11}>
                          <Mui.Button
                            type="submit"
                            variant="contained"
                            size="high"
                            sx={{
                              color: themes.whiteColor,
                              backgroundcolor: themes.blackColor,
                            }}
                          >
                            {department?.members?.length} /{" "}
                            {department?.members?.length}
                          </Mui.Button>
                        </Mui.Grid>
                        <Mui.Grid xs={1}></Mui.Grid>
                      </Mui.Grid> */}
                      <Mui.Grid container>
                        <Mui.Grid
                          xs={12}
                          sx={{
                            paddingLeft: { xs: 20, sm: 20, md: 25, lg: 30 },
                          }}
                        ></Mui.Grid>
                      </Mui.Grid>
                    </div>
                  </Mui.CardContent>
                </Mui.Card>
              </Mui.Grid>
            ))}
        </Mui.Grid>
      ) : isLoading ? null : (
        <Mui.Container maxWidth="xl" sx={{ marginTop: 2 }}>
          <Mui.Alert severity="info">No data available</Mui.Alert>
        </Mui.Container>
      )}
      {isLoading && (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      )}
      {departmentList.length > 20 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={departmentList.length}
          rowsPerPage={rowsPerPage}
          page={Page}
          onPageChange={handleChangeLeavePage}
          onRowsPerPageChange={handleChangeLeaveRowsPerPage}
        />
      )}

      <GlobalDialogBox
        open={openDialog}
        handleCloseDialog={handleClose}
        title=" Delete Confirmation"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>
            {" "}
            Are you sure you want to delete this department?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={() => {
              setOpenDialog(false);
              handleClose();
            }}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => {
              setOpenDialog(false);
              deleteDepartment();
            }}
            actionName="Yes"
            typeName="button"
          />
        </Mui.DialogActions>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
