import React, { useEffect } from "react";
import * as Mui from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Maintenance from "../../assets/img/brand/upgrate.png";
export const UpgrateNotification = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(function () {
      history.push("/auth/login");
      window.location.reload();
    }, 30000);
  },[history]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <img
          src={Maintenance}
          alt=""
          style={{ height: "100%", width: "100%" }}
        />
      </Mui.Grid>
    </React.Fragment>
  );
};
