import React, { useContext, useState } from "react";
import { TaskContext } from "../TaskContext";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomButton";
import { useFetch } from "services/hooks/useFetch";

export const Materials = ({ selectedTaskid, setActiveTap }) => {
  const { domain } = useContext(TaskContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const { result: material } = useFetch(
    `${domain}material_task/?taskid=${selectedTaskid}`
  );

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 1,
          }}
        >
          <CustomButton
            actionFuntion={() => setActiveTap("ShowMaterialScreen")}
            actionName="Add Material"
            typeName="submit"
          />
        </Mui.Grid>
      </Mui.Grid>
      <Mui.TableContainer sx={{ mt: 2, width: "100%", padding: 1 }}>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left">
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Type
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Unit
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Inv Quantity
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Rate
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Amount
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {material
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <Mui.TableRow key={data.id} hover={true}>
                  <>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {data.type}
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {data.unit}
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {data.inv_quantity}
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {data.rate}
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {data.amount}
                    </Mui.TableCell>
                  </>
                </Mui.TableRow>
              ))}
          </Mui.TableBody>
        </Mui.Table>

        {material.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={material.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.TableContainer>
    </React.Fragment>
  );
};
