import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { CustomTextBox } from "components/CustomTextBox";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { AddCircleOutline, SwapHoriz } from "@mui/icons-material";
// Delete;
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { PaymentMode } from "pages/PayRoll/PayRollHeaders";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "components/Status";
let rowId = 0;
export const EditSalaryGroup = ({
  companyId,
  setCompanyFilter,
  companyFilter,
  editSalaryGroupDetails,
  setEditSalary,
  setUpdateEditDetails,
  updateEditDetails,
  reCallApi,
  employeeDetails,
  reCallEmployeeApi,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: salaryGroupData } = useFetch(
    companyId !== 0 ? `${domain}salary-group/?company=${companyId}` : null
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState([]);
  const [swapSalaryGroup, setSwapSalaryGroup] = useState(false);
  const [personDetails, setPersonDetails] = useState(null);
  const [salaryGroupName, setSalaryGroupName] = useState(
    editSalaryGroupDetails.name
  );
  const [bankName, setBankName] = useState(
    editSalaryGroupDetails.source_bank_detail_name
  );
  const [paymentModeDetails, setPaymentModeDetails] = useState(
    editSalaryGroupDetails.mode_of_payment
  );

  const [userDetails, setUserDetails] = useState(
    editSalaryGroupDetails?.users_detail?.map((data) => {
      return {
        ...data,
        id: data?.id,
      };
    })
  );
  const { result: bankDetailsData, setLoad: reCallSourceBankApi } = useFetch(
    companyId !== 0 ? `${domain}source-bank/?company=${companyId}` : null
  );

  useEffect(() => {
    const updateEditDetailsSortData = () => {
      setValue("salry_group_name", editSalaryGroupDetails.name);
      setValue("banck_name", editSalaryGroupDetails.source_bank_detail_name);
      setValue("bank_id", editSalaryGroupDetails.source_bank_detail);
      setValue("payment_mode", editSalaryGroupDetails.mode_of_payment);
      let arrayofData = editSalaryGroupDetails.users_detail.sort(
        (a, b) => a?.id - b?.id
      );
      rowId =
        arrayofData?.length !== 0
          ? arrayofData[arrayofData?.length - 1]?.id
          : rowId;
    };
    if (updateEditDetails) {
      setUpdateEditDetails(false);
      updateEditDetailsSortData();
    }
  }, [
    editSalaryGroupDetails.mode_of_payment,
    editSalaryGroupDetails.name,
    editSalaryGroupDetails.source_bank_detail,
    editSalaryGroupDetails.source_bank_detail_name,
    editSalaryGroupDetails.users_detail,
    setUpdateEditDetails,
    setValue,
    updateEditDetails,
  ]);

  useEffect(() => {
    if (companyFilter) {
      reCallEmployeeApi(true);
      reCallSourceBankApi(true);
      setCompanyFilter(false);
    }
  }, [companyFilter, reCallEmployeeApi, reCallSourceBankApi, setCompanyFilter]);

  const updateSalaryGroupName = (event) => {
    if (event.target.value.length < 32) {
      setValue("salry_group_name", event.target.value);
      setSalaryGroupName(event.target.value);
    }
  };

  const handleBankName = (event, data) => {
    if (data) {
      setValue("banck_name", data?.label);
      setBankName(data?.label);
      setValue("bank_id", data.id);
    }
  };

  const handlePaymentMode = (event, data) => {
    if (data) {
      setValue("payment_mode", data?.label);
      setPaymentModeDetails(data?.label);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editUser = isEdit
    ? () => null
    : () => {
        if (!isEdit) {
          let shouldSetIsEdit = false;
          for (const item of userDetails) {
            if (item.username === "") {
              shouldSetIsEdit = true;
              break;
            }
          }
          if (shouldSetIsEdit) {
            setIsEdit(true);
            return;
          }
          let newRow = {
            rowId: rowId + 1,
            username: "",
            employee_id: "",
            id: "",
          };
          rowId = rowId + 1;
          setUserDetails([...userDetails, newRow]);
        }
      };

  const handleMembers = (details) => (event, data) => {
    if (data) {
      let userDetailsWithNewMember = userDetails?.map((element) => {
        if (element?.id === details?.id) {
          return {
            ...element,
            employee_id: data?.employee_id,
            username: data?.username,
            id: data?.id,
          };
        }
        return element;
      });
      setUserDetails(userDetailsWithNewMember);
      setIsEdit(false);
    }
  };

  const updateSalaryGroup = async (data) => {
    let userArray = [];
    userDetails?.forEach((element) => {
      if (element?.username !== "") {
        userArray.push(element?.id);
      }
    });
    try {
      const response = await fetch(
        `${domain}salary-group/${editSalaryGroupDetails.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: data.salry_group_name,
            company: companyId,
            users: userArray,
            payment_cycle: "Weekly", //optional for now
            mode_of_payment: data.payment_mode,
            source_bank_detail: data.bank_id,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setEditSalary(false);
        reset();
        reCallApi(true);
        showToast("success", "Salary group updated successfully");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  // const removeUser = async (members) => {
  //   let removedData = userDetails?.filter((data) => {
  //     return data.id !== members.id;
  //   });
  //   setUserDetails(removedData);
  //   setIsEdit(false);
  // };

  useEffect(() => {
    const filterEmployeeData = () => {
      const employeeDetailsData = employeeDetails?.map((obj) => ({
        ...obj,
        label: obj.username,
      }));
      let empDetails = [];
      employeeDetailsData?.forEach((element) => {
        let findEmp = userDetails?.filter((data) => {
          return data.username === element.label;
        });
        if (findEmp?.length === 0) {
          empDetails = [...empDetails, element];
        }
      });
      if (empDetails?.length === 0) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
        setFilterEmployee(empDetails);
      }
    };

    if (userDetails?.length !== 0) {
      filterEmployeeData();
    }
  }, [employeeDetails, userDetails]);

  const swapToNewGroup = () => {};

  const swapSalaryGroupName = async (data) => {
    try {
      const response = await fetch(`${domain}move/salary-group-users`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          salary_group_id: data.salary_group_name_id,
          user_id: personDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        let filterData = userDetails.filter((data) => {
          return data.id !== personDetails.id;
        });
        showToast(
          "success",
          `${personDetails.username} was successfully swapped to ${data.salary_group_name}`
        );
        setUserDetails(filterData);
        setSwapSalaryGroup(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(updateSalaryGroup)}>
          <Mui.Grid container sx={{ padding: 5 }}>
            <Mui.Grid xs={5.8}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 0.5,
                }}
              >
                Salary Group Name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={salaryGroupName}
                rules={{
                  ...register("salry_group_name", {
                    required: "Salary group name is required",
                    validate: {
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateSalaryGroupName}
                placeHolder="Enter Salary Group Name"
                errorMessage={
                  errors?.salry_group_name?.message
                    ? errors?.salry_group_name?.message
                    : ""
                }
                textBoxError={errors.salry_group_name ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={0.4}></Mui.Grid>
            <Mui.Grid xs={5.8}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 0.5,
                }}
              >
                Payment Bank
              </Mui.Typography>
              <CustomAutoComplete
                dataList={bankDetailsData.map((obj) => ({
                  ...obj,
                  label: obj.bank_name,
                }))}
                selectedValue={bankName}
                rules={{
                  ...register("banck_name", {
                    required: "Source bank is required",
                  }),
                }}
                updateDataFunction={handleBankName}
                placeHolder="Select payment bank"
                errorMessage={
                  errors?.banck_name?.message ? errors?.banck_name?.message : ""
                }
                textBoxError={errors?.banck_name ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={5.8}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Payment Mode
                </Mui.Typography>
                <CustomAutoComplete
                  dataList={PaymentMode}
                  selectedValue={paymentModeDetails}
                  rules={{
                    ...register("payment_mode", {
                      required: "Payment mode is required",
                    }),
                  }}
                  updateDataFunction={handlePaymentMode}
                  placeHolder="Select payment mode"
                  errorMessage={
                    errors?.payment_mode?.message
                      ? errors?.payment_mode?.message
                      : ""
                  }
                  textBoxError={errors?.payment_mode ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Typography sx={{ paddingTop: 2 }}>Users List</Mui.Typography>
            <Mui.TableContainer
              component={Mui.Paper}
              sx={{ mt: 2, width: "100%", maxHeight: 500 }}
            >
              <Mui.Table>
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.primary,
                    position: "sticky",
                    top: -1,
                    zIndex: 10,
                  }}
                >
                  <Mui.TableRow maxWidth="xl" align="left">
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Employee name
                    </Mui.TableCell>

                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Employee id
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Action
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {userDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((members) => (
                      <React.Fragment>
                        <Mui.TableRow maxWidth="xl" align="left">
                          <Mui.TableCell>
                            <CustomAutoComplete
                              dataList={filterEmployee}
                              selectedValue={members?.username}
                              updateDataFunction={handleMembers(members)}
                              placeHolder="Please select the user"
                              fullWith
                            />
                          </Mui.TableCell>
                          <Mui.TableCell>{members?.employee_id}</Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.IconButton
                              onClick={() => {
                                setPersonDetails(members);
                                setSwapSalaryGroup(true);
                              }}
                              disabled={
                                salaryGroupData.length === 1 ? true : false
                              }
                            >
                              <Mui.Tooltip
                                title={
                                  salaryGroupData.length === 1
                                    ? "There is no available salary group"
                                    : false
                                }
                              >
                                <SwapHoriz />
                              </Mui.Tooltip>
                            </Mui.IconButton>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </React.Fragment>
                    ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignSelf: "flex-end",
              }}
            >
              {userDetails?.length > 10 && (
                <Mui.TablePagination
                  className="custom-pagination"
                  component="div"
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={userDetails?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Mui.Grid>
            <Mui.IconButton onClick={() => editUser()}>
              <AddCircleOutline />
            </Mui.IconButton>
          </Mui.Grid>

          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 3,
              paddingBottom: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => {
                reCallApi(true);
                setEditSalary(false);
              }}
              actionName="Cancel"
              typeName="button"
            />
            &nbsp; &nbsp;
            <CustomButton actionName="Update" typeName="submit" />
          </Mui.Grid>
      </form>
      <GlobalDialogBox
        handleCloseDialog={() => {
          setSwapSalaryGroup(false);
        }}
        open={swapSalaryGroup}
        title="Swap salary group"
      >
        <form onSubmit={handleSubmit(swapSalaryGroupName)}>
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Stack>
                <Mui.Grid container>
                  <Mui.Typography sx={{ paddingBottom: 1 }}>
                    Current Salary Group
                  </Mui.Typography>
                  <Mui.TextField
                    defaultValue={editSalaryGroupDetails.name}
                    size="small"
                    sx={{ width: "100%", paddingBottom: 2 }}
                  />
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingBottom: 1 }}>
                  <Mui.Typography>Select Salary Group </Mui.Typography>
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={salaryGroupData?.map((obj) => ({
                      ...obj,
                      label: obj.name,
                    }))}
                    sx={{ width: "100%", paddingBottom: 2 }}
                    ListboxProps={{ style: { maxHeight: "100px" } }}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.name
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return editSalaryGroupDetails.name !== option.name ? (
                        <li {...props}>{option.name}</li>
                      ) : null;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setValue("salary_group_name_id", value.id);
                        setValue("salary_group_name", value.name);
                      } else {
                        setValue("salary_group_name_id", null);
                        setValue("salary_group_name", null);
                      }
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        {...register("salary_group_name", {
                          required: "Salary group name is required",
                        })}
                        sx={{
                          background: themes.whiteColor,
                          maxHeight: 120,
                        }}
                        placeholder="Select salary group name"
                        size="small"
                        error={Boolean(errors.salary_group_name)}
                        helperText={errors.salary_group_name?.message || ""}
                      />
                    )}
                  />
                  {/* <CustomAutoComplete
                    dataList={salaryGroupData?.map((obj) => ({
                      ...obj,
                      label: obj.name,
                    }))}
                    rules={{
                      ...register("salary_group_name", {
                        required: "Salary group name is required",
                      }),
                    }}
                    updateDataFunction={handleSalaryGroupName}
                    placeHolder="Please select the salary group name"
                    errorMessage={
                      errors?.salary_group_name?.message
                        ? errors?.salary_group_name?.message
                        : ""
                    }
                    textBoxError={errors.salary_group_name ? true : false}
                  /> */}
                </Mui.Grid>
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingRight: 1,
                    paddingTop: 4,
                  }}
                >
                  <CustomButton
                    actionFuntion={() => setSwapSalaryGroup(false)}
                    actionName="Cancel"
                    typeName="button"
                  />
                  &nbsp;&nbsp;
                  <CustomButton
                    actionFuntion={() => swapToNewGroup()}
                    actionName="Swap"
                    typeName="submit"
                  />
                </Mui.Grid>
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
