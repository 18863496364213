import * as Mui from "@mui/material";
import React from "react";
import "./loder.css";
import comLogo from "../../assets/img/brand/company_logo.png";
export const Loader = ({ info }) => {
  return (
    <React.Fragment>
      <Mui.Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
      >
        <Mui.Grid xs={12} sm={10} md={8} lg={6} xl={4}>
          <div className="spin">
            <img
              src={comLogo}
              alt=""
              style={{ height: "70px", width: "70px" }}
            />
          </div>
        </Mui.Grid>
        {/* <Mui.Grid>
          <Mui.Typography
            style={{
              color: themes.primary,
              fontWeight: "bold",
              marginTop: 18,
              fontSize: 28,
            }}
          >
            {info}
          </Mui.Typography>
        </Mui.Grid> */}
      </Mui.Grid>
    </React.Fragment>
  );
};
