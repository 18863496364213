import React from "react";
import { MyProjectContextProvider } from "./MyProjectContext";
import { ProjectHead } from "./ProjectHead";

export const Project = () => {
  return (
    <React.Fragment>
      <MyProjectContextProvider>
        <ProjectHead />
      </MyProjectContextProvider>
    </React.Fragment>
  );
};
