import React, { useState, useContext, useEffect } from "react";
import { taskPriority } from "services/constants";
import { useSelector } from "react-redux";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { TaskContext } from "../TaskContext";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { useForm } from "react-hook-form";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { projectError } from "services/constants/ErrorMessages";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";

export const CreateChildTask = ({
  openCreateChildTask,
  setOpenCreateChildTask,
  selectedTask,
  setFetchDetails,
  workflowStatus,
  setDoAnyAct,
}) => {
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };
  const { allUsers, editProject } = useContext(TaskContext);
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [priority, setPriority] = useState("Medium");
  const [taskName, setTaskName] = useState(null);
  const [taskError, setTaskError] = useState("");
  const [priProjMembers, setPriProjMembers] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const isDueDate =
    selectedTask?.sprint?.end_date !== null
      ? new Date(formatDate(selectedTask?.sprint?.end_date))
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
  const [dueDate, setDueDate] = useState(isDueDate);
  const { register, formState, handleSubmit, reset, getValues } = useForm({
    mode: "onBlur",
  });
  const { errors } = formState;
  const workflow_status_id = workflowStatus.filter(
    (wfs) =>
      wfs.name === "ToDo" &&
      selectedTask.work_flow_status?.workflow === wfs.workflow
  );

  const filterCommonValues = (arr1, arr2) => {
    const valuesInArr2 = arr2.map((item) => item["username"]);
    return arr1.filter((item) => valuesInArr2.includes(item["username"]));
  };

  useEffect(() => {
    if (editProject?.projectType === "Private") {
      const filteredMembers = filterCommonValues(
        allUsers,
        editProject?.members
      );
      setPriProjMembers(filteredMembers);
    }
  }, [editProject?.members, allUsers, editProject?.projectType]);

  const handleCloseDialogBox = () => {
    setOpenCreateChildTask(false);
    setTaskError("");
    setTaskName(null);
    setUserName(null);
    setPriority("Medium");
    setUserId(null);
    setStartDate(new Date().toISOString().substring(0, 10));
    const isDueDate =
      selectedTask?.sprint?.end_date !== null
        ? new Date(formatDate(selectedTask?.sprint?.end_date))
            .toISOString()
            .substring(0, 10)
        : new Date().toISOString().substring(0, 10);
    setDueDate(isDueDate);
    reset();
  };

  const onSubmit = async (data) => {
    if (taskError === "") {
      try {
        setDoAnyAct(true);
        const response = await fetch(`${domain}create_child_task/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            parent_task_id: selectedTask.id,
            task_name: data.taskname,
            task_owner_id: userId,
            priority: priority,
            start_date: data.startdate,
            due_date: data.enddate,
            current_assignee: userId,
            status: "ToDo",
            work_flow_status_id: workflow_status_id[0]?.workflow,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setFetchDetails(true);
          handleCloseDialogBox();
          showToast("success", "Successfully child task added.");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const isOnlySpaces = (str) => /^\s*$/.test(str);

  return (
    <GlobalDialogBox
      handleCloseDialog={handleCloseDialogBox}
      open={openCreateChildTask}
      title="Add Child Task"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container>
          <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Taskname</Mui.Typography>
            <Mui.TextField
              type="text"
              size="small"
              value={taskName}
              placeholder={projectPlaceholder.taskName}
              {...register("taskname", {
                required: "Please enter task name",
                validate: (value) =>
                  !isOnlySpaces(value) || "Task name cannot be only spaces",
              })}
              InputProps={{
                inputProps: { maxLength: 512 },
              }}
              onChange={(e) => {
                setTaskName(e.target.value);
                if (e.target.value === "") {
                  setTaskError(projectError.taskNameError);
                } else if (isOnlySpaces(e.target.value)) {
                  setTaskError("Task name cannot be only spaces");
                } else {
                  setTaskError("");
                }
              }}
              error={errors.taskname}
              helperText={errors.taskname ? errors.taskname.message : ""}
              fullWidth
            />
          </Mui.Grid>
          <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Assignee</Mui.Typography>
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              fullWidth
              value={userName}
              options={
                editProject?.projectType === "Private"
                  ? priProjMembers
                  : allUsers
              }
              ListboxProps={{ style: { maxHeight: "215px" } }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.username
                    ?.toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Mui.Avatar
                      sx={{ width: 25, height: 25 }}
                      alt={option.username.charAt(0).toUpperCase()}
                      src={option.image_url}
                    />{" "}
                    &nbsp;{option.username}
                  </li>
                );
              }}
              onChange={(event, value) => {
                if (value) {
                  setUserName(value.username);
                  setUserId(value.id);
                } else {
                  setUserName(null);
                  setUserId(null);
                }
              }}
              sx={{ minWidth: "100%" }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  {...register("assignee", {
                    required: false,
                  })}
                  error={errors.assignee}
                  helperText={errors.assignee ? errors.assignee.message : ""}
                  value={userName}
                  size="small"
                  sx={{ background: themes.whiteColor }}
                  name="username"
                  placeholder={projectPlaceholder.selectUser}
                />
              )}
            />
          </Mui.Grid>
          <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Start Date</Mui.Typography>
            <Mui.TextField
              id="from-date"
              type="date"
              size="small"
              value={startDate}
              sx={{ background: themes.whiteColor }}
              {...register("startdate", {
                required: false,
              })}
              fullWidth
              disableOpenPicker
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              inputProps={{
                max: "9999-12-31",
              }}
            />
          </Mui.Grid>
          <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Due Date</Mui.Typography>
            <Mui.TextField
              sx={{ background: themes.whiteColor }}
              type="date"
              size="small"
              value={dueDate}
              {...register("enddate", {
                required: false,
                validate: {
                  noDigitsOrSpecial: (fieldValue) => {
                    if (
                      new Date(fieldValue) < new Date(getValues("startdate"))
                    ) {
                      return "Due date is lesser then start date";
                    } else {
                      return true;
                    }
                  },
                },
              })}
              onChange={(event) => {
                setDueDate(event.target.value);
              }}
              fullWidth
              error={errors.enddate ? true : false}
              helperText={errors.enddate ? errors.enddate.message : ""}
              inputProps={{
                max: "9999-12-31",
              }}
            />
          </Mui.Grid>
          <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
            <Mui.Typography>Priority</Mui.Typography>
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              fullWidth
              value={priority}
              options={taskPriority}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.priority
                    ?.toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.priority}</li>;
              }}
              onChange={(event, value) => {
                if (value) {
                  setPriority(value.priority);
                } else {
                  setPriority(null);
                }
              }}
              sx={{ minWidth: "100%" }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  {...register("priority", {
                    required: false,
                  })}
                  value={priority}
                  size="small"
                  sx={{ background: themes.whiteColor }}
                  name="priority"
                  placeholder={projectPlaceholder.selectPriority}
                  fullWidth
                />
              )}
            />
          </Mui.Grid>
          <Mui.Grid xs={7.5}></Mui.Grid>
          <Mui.Grid
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          ></Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            actionFuntion={() => handleCloseDialogBox(true)}
            actionName="Cancel"
            typeName="button"
          />
          &nbsp;
          <CustomButton actionName="Add" typeName="submit" />
        </Mui.Grid>
      </form>
    </GlobalDialogBox>
  );
};
