import React, { useCallback, useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { Loader } from "components/Loader";
import { showToast } from "components/Status";
import { useSelector } from "react-redux";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { MoreVert, Info } from "@mui/icons-material";
import { CustomButton } from "components/CustomButton";
import { useForm } from "react-hook-form";
import sessionHandling from "services/utils/notificationUtils";

export const EmploymentType = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [fetchEmploymentTypeData, setFetchEmploymentTypeData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [employmentData, setEmploymentData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState([]);
  const [etype, setEtype] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [masterEmployementList, setMasterEmployementList] = useState([]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const checkExistingEmploymentType = useCallback(
    async (e_type) => {
      try {
        const response = await fetch(
          `${domain}check/employment-type?employment_name=${e_type}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            return "Employment type is already exists";
          } else {
            return true;
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  const addEmploymentType = useCallback(
    async (employmentType, description) => {
      try {
        const response = await fetch(`${domain}employment-type/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            employment_type: employmentType,
            description: description,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", "Employment type added successfully.");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setFetchEmploymentTypeData(true);
      }
    },
    [domain, setFetchEmploymentTypeData, token]
  );

  const updateEmploymentType = useCallback(
    async (employmentType, description) => {
      try {
        const response = await fetch(`${domain}employment-type/${edit?.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            employment_type: employmentType,
            description: description,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", "Employment type updated successfully.");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setFetchEmploymentTypeData(true);
      }
    },
    [domain, edit?.id, setFetchEmploymentTypeData, token]
  );

  const removeEmploymentType = useCallback(async () => {
    try {
      const response = await fetch(`${domain}employment-type/${edit?.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: "true",
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Employment type deleted successfully");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setFetchEmploymentTypeData(true);
      setOpenDialog(false);
    }
  }, [domain, edit?.id, setFetchEmploymentTypeData, token]);

  const fetchEmploymentDatas = useCallback(async () => {
    setLoader(true);
    try {
      const response = await fetch(`${domain}employment-type/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setEmploymentData([]);
        setEmploymentData(res);
        setMasterEmployementList(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
    setLoader(false);
  }, [domain, token]);

  useEffect(() => {
    setFetchEmploymentTypeData(true);
  }, [setFetchEmploymentTypeData]);

  useEffect(() => {
    if (fetchEmploymentTypeData === true) {
      fetchEmploymentDatas();
      setFetchEmploymentTypeData(false);
    }
  }, [
    fetchEmploymentDatas,
    fetchEmploymentTypeData,
    setFetchEmploymentTypeData,
  ]);
  useEffect(() => {
    if (etype !== "") {
      setOpenDialog(true);
    }
  }, [etype]);

  useEffect(() => {
    const searchFields = ["employment_type", "description"];
    const filteredResults = masterEmployementList.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setEmploymentData(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterEmployementList]);

  const moveToAddDesignation = () => {
    setEtype("Add");
  };

  return loader === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <EtypeDialog
        type={etype}
        setEtype={setEtype}
        edit={edit}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        addEmploymentType={addEmploymentType}
        updateEmploymentType={updateEmploymentType}
        removeEmploymentType={removeEmploymentType}
        checkExistingEmploymentType={checkExistingEmploymentType}
      />
      <Mui.TableContainer
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
          width: "100%",
          minHeight: "95%",
          paddingTop: 2,
          borderRadius: 4,
          border: `1px solid ${themes.buttonBorder}`,
          justifyContent: "center",
        }}
      >
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingBottom: 2,
          }}
        >
          <CustomButton
            actionFuntion={moveToAddDesignation}
            actionName="Add Employment Type"
            typeName="button"
          />
        </Mui.Grid>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left">
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Employment Type
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Description
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Actions
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {employmentData?.length === 0 ? (
              <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                No data found
              </Mui.Alert>
            ) : (
              employmentData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <Mui.TableRow key={item?.id} maxWidth="xl" align="left">
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.employment_type ? item?.employment_type : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.description ? item?.description : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.IconButton
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setEdit([]);
                          setEdit(item);
                        }}
                      >
                        <MoreVert />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                            setEtype("Edit");
                          }}
                        >
                          Edit
                        </Mui.MenuItem>
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                            setEtype("Delete");
                          }}
                        >
                          Delete
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {employmentData?.length > 10 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={employmentData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Mui.TableContainer>
    </React.Fragment>
  );
};

export default function EtypeDialog({
  type,
  setEtype,
  edit,
  openDialog,
  setOpenDialog,
  addEmploymentType,
  updateEmploymentType,
  removeEmploymentType,
  checkExistingEmploymentType,
}) {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleClose = useCallback(() => {
    setOpenDialog(false);
    setEtype("");
    setValue("e_type", "");
    setValue("description", "");
    reset();
  }, [reset, setEtype, setOpenDialog, setValue]);
  useEffect(() => {
    if (type === "Add") {
      setValue("e_type", "");
      setValue("description", "");
    } else if (type === "Edit") {
      setValue("e_type", edit?.employment_type);
      setValue("description", edit?.description);
    } else if (type === "Delete") {
      setValue("e_type", "");
      setValue("description", "");
      reset();
    }
  }, [edit?.description, edit?.employment_type, reset, setValue, type]);

  const onSubmit = (data) => {
    if (data) {
      if (type === "Add") {
        addEmploymentType(data?.e_type, data?.description);
        handleClose();
      } else if (type === "Edit") {
        updateEmploymentType(data?.e_type, data?.description);
        handleClose();
      } else if (type === "Delete") {
        removeEmploymentType();
        handleClose();
      }
    }
  };
  const checkSpecialChar = (e) => {
    if (!/[a-zA-Z ]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const checkSpecialCharWithDigits = (e) => {
    if (!/[a-zA-Z\d ]/.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <GlobalDialogBox
        key={type}
        handleCloseDialog={handleClose}
        open={openDialog}
        title={
          type === "Delete"
            ? "Delete Employment Type"
            : type === "Add"
            ? "Add Employment Type"
            : "Edit Employment Type"
        }
      >
        {type === "" ? null : type === "Delete" ? (
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ fontSize: 22, paddingBottom: 2 }}>
                Do you want to remove the employment type?
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={12}>
              <Mui.Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={handleClose}
                  actionName="No"
                  typeName="submit"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={handleSubmit(onSubmit)}
                  actionName="Yes"
                  typeName="submit"
                />
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        ) : (
          <>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-start" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Employment Type</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="Employment type is refers to the different type of workers a company may contract with or hire for employment.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={9}>
                <Mui.TextField
                  placeholder="Enter the employment type"
                  {...register("e_type", {
                    required: "Employment Type is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      employmentType: async (fieldValue) => {
                        if (fieldValue.trim().length < 3) {
                          return "Minimum 3 characters are required";
                        } else if (type === "Add") {
                          return checkExistingEmploymentType(fieldValue.trim());
                        } else if (edit?.employment_type !== fieldValue) {
                          return checkExistingEmploymentType(fieldValue.trim());
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  onKeyPress={checkSpecialChar}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 32 },
                  }}
                  error={Boolean(errors.e_type)}
                  helperText={errors.e_type?.message || ""}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-start" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Description</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="Here, you  can add more Information  about the flexibility and operation it offers for that type of employee..            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={9}>
                <Mui.TextField
                  multiline={true}
                  rows={3}
                  placeholder="Enter the description"
                  {...register("description", {
                    required: "Description is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      employmentTypeDescription: async (fieldValue) => {
                        if (fieldValue.trim().length < 3) {
                          return "Minimum 3 characters are required";
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 128 },
                  }}
                  onKeyPress={checkSpecialCharWithDigits}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message || ""}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={1}></Mui.Grid>
            </Mui.Grid>
            <Mui.Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={handleClose}
                actionName="Cancel"
                typeName="submit"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={handleSubmit(onSubmit)}
                actionName={type === "Add" ? "Add" : "Update"}
                typeName="submit"
              />
            </Mui.Stack>
          </>
        )}
      </GlobalDialogBox>
    </form>
  );
}
