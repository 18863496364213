import * as React from "react";
import PropTypes from "prop-types";
import { themes } from "services/constants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme, minWidth }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    minWidth: minWidth ? minWidth : 550,
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, minHeight: "10vh" }} {...other}>
      {children}
    </DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const DialogWithoutCloseOption = ({
  handleCloseDialog,
  open,
  title,
  minWidth,
  children,
}) => {
  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        minWidth={minWidth}
      >
        <FuncDialogTitle
          //   id="customized-dialog-title"
          align="center"
          sx={{
            minWidth: minWidth ? minWidth : 550,
            color: themes.headLine,
            fontWeight: 1000,
            fontSize: "23px",
            background: themes.primary,
            // height:'30px'
          }}
          onClose={handleCloseDialog}
          title={title}
        >
          {title}
        </FuncDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </BootstrapDialog>
    </div>
  );
};
