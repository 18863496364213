import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { PayrunPeriodDateRange } from "./PayrunPeriodRange";
import { elementType } from "prop-types";
export const AttendanceTimeLine = ({
  viewPayrunDetails,
  setDownloadReportDetails,
  downloadReportDetails,
  summaryName,
  companyName,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const { result: attendanceDetails, setLoad: reCallPayScheduleApi } = useFetch(
    `${domain}pay-schedule/attendance-report?pay_schedule_id=${viewPayrunDetails.id}`
  );

  useEffect(() => {
    const downloadReports = () => {
      let dataPreparation = [];
      attendanceDetails?.attendance_data?.forEach((element) => {
        let overAllData = {};
        element.attendance.forEach((attendance_data) => {
          overAllData = {
            ...overAllData,
            [attendance_data.date]: attendance_data.legend,
          };
        });
        overAllData = {
          ...overAllData,
          Present: element.status_counts.Present,
        };
        overAllData = {
          user_name: element.user,
          ...overAllData,
          Present: element.status_counts.Present,
          Leave: element.status_counts.Leave,
          Absent: element.status_counts.Absent,
          halfDay: 0,
          Permission: element.status_counts.Permission,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const company_Name = ["Company Name"];
      const pay_ScheduleName = ["PaySchedule Name"];
      const paySchedulePeriod = ["PaySchedule Period"];
      const TableHead = ["Employee Name"];
      attendanceDetails?.headers?.forEach((element) => {
        TableHead.push(element.day_number);
      });
      TableHead.push("P");
      TableHead.push("L");
      TableHead.push("A");
      TableHead.push("HL");
      TableHead.push("PR");
      company_Name.push(companyName);
      pay_ScheduleName.push(viewPayrunDetails.name);
      paySchedulePeriod.push(
        viewPayrunDetails.start_date + "  to  " + viewPayrunDetails.end_date
      );
      const items = dataPreparation;
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter(
        (field) => field !== "break_details"
      );
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(paySchedulePeriod.join(","));
      csv.unshift(pay_ScheduleName.join(","));
      csv.unshift(company_Name.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "AttendanceTimeline.csv";
      document.body.appendChild(element);
      element.click();
    };
    if (downloadReportDetails && summaryName === "Attendance Timeline") {
      downloadReports();
      setDownloadReportDetails(false);
    }
  }, [attendanceDetails?.attendance_data, attendanceDetails?.headers, companyName, downloadReportDetails, setDownloadReportDetails, summaryName, viewPayrunDetails.end_date, viewPayrunDetails.name, viewPayrunDetails.start_date]);
  return (
    <React.Fragment>
      <PayrunPeriodDateRange
        attendanceDetails={attendanceDetails}
        payScheduleId={viewPayrunDetails.id}
        reCallPayScheduleApi={reCallPayScheduleApi}
      />
    </React.Fragment>
  );
};
