import * as React from "react";
import { useState } from "react";
import LoginImage from "../../../assets/img/brand/loginHalf.png";
import LoginLogo from "../../../assets/img/brand/organflow 2.0 logo.png";
import { useForm } from "react-hook-form";
import { Grid, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { InputAdornment } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { showToast } from "components/Status";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { userManagementError } from "services/constants/ErrorMessages";
import "./Login.css";

export const Login = () => {
  const domainUrl = useSelector((state) => state.tokenReducer.domain);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const emailResponse = await fetch(
      `${domainUrl}email_verfication/?email=${data.email}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }
    );
    const emailReturnResponse = await emailResponse.json();
    if (
      emailResponse.status === 200 &&
      emailReturnResponse.user_status === "Active"
    ) {
      const userId = emailReturnResponse.user_id;
      const userResponse = await fetch(`${domainUrl}custom-token-auth/`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          username: emailReturnResponse.emailuser.username,
          password: data.password,
        }),
      });
      const userReturnResponse = await userResponse.json();
      if (userReturnResponse?.error === "Invalid Credentials") {
        showToast("error", userManagementError.invalidCredentialsError);
        setIsLoading(false);
      }
      if (userResponse.status === 200) {
        const userData = {
          id: userId,
          first_name: emailReturnResponse.emailuser.first_name,
          username: emailReturnResponse.emailuser.username,
          last_name: emailReturnResponse.emailuser.last_name,
          email: emailReturnResponse.emailuser.email,
          role: emailReturnResponse.role,
          phonenumber: emailReturnResponse.role.phonenumber,
          approver: emailReturnResponse.approver,
          teamlead: emailReturnResponse.teamlead,
          firmName: emailReturnResponse.firm_name,
          approver_id: emailReturnResponse.approver_id,
          image_url: emailReturnResponse.image_url,
          department_id: emailReturnResponse.department_id,
          department_name: emailReturnResponse.department_name,
        };
        localStorage.setItem("currentuser", JSON.stringify(userData));
        localStorage.setItem("token", userReturnResponse.token);
        localStorage.setItem("sessionToken", userReturnResponse.session_token);
        await getProductAccess(userReturnResponse.token, userId);
        if (emailReturnResponse.role === "Admin") {
          getCompanyDetails(userReturnResponse.token);
        } else {
          localStorage.setItem("currentScreen", "My Zone");
          localStorage.setItem("currentSubMenu", "Calendar");
          if (emailReturnResponse.role === "Team Lead") {
            window.location.href = "/admin/teamzone/attendance";
          } else {
            window.location.href = "/admin/myzone/status";
          }
        }
      }
    } else if (
      emailReturnResponse.exists === false ||
      emailResponse.status === 400
    ) {
      showToast("error", userManagementError.loginCredentialsError);
      setIsLoading(false);
    } else if (emailReturnResponse.user_status === "Inactive") {
      showToast("error", userManagementError.emailReturnResponseError);
      setIsLoading(false);
    } else if (emailResponse.status === 200) {
      showToast("error", emailReturnResponse.error);
      setIsLoading(false);
    }
    setIsLoading(false);
    reset();
  };

  const getProductAccess = async (token, userId) => {
    try {
      const response = await fetch(`${domainUrl}products/?user_id=${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("productAccess", JSON.stringify(data));
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      showToast(error.message);
    }
  };

  const getCompanyDetails = async (token) => {
    try {
      const response = await fetch(`${domainUrl}company/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (data.length === 0) {
          localStorage.setItem("currentScreen", "Administration");
          localStorage.setItem("currentSubMenu", "Users");
          window.location.href = "/admin/user";
        } else {
          localStorage.setItem("currentScreen", "My Zone");
          localStorage.setItem("currentSubMenu", "Calendar");
          window.location.href = "/admin/teamzone/attendance";
        }
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      showToast(error.message);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Grid
        container
        style={{
          overflowY: "hidden",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Grid item xs={6}>
          <Paper style={{ height: "100%" }}>
            <img
              src={LoginImage}
              alt="Login"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <a href="https://stringconsultants.com/">
              <div
                style={{
                  position: "absolute",
                  left: "25%",
                  bottom: "0.2%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                © 2024 String Consultants. All Rights Reserved
              </div>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={6}>
          <Paper style={{ height: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                justifyContent="flex-end"
                sx={{ paddingTop: 2, paddingRight: 2 }}
              >
                <img
                  src={LoginLogo}
                  alt="LoginLogo"
                  style={{ height: "22%", width: "22%" }}
                />
              </Grid>
              <Grid
                container
                spacing={2}
                direction="column"
                dispay="flex"
                alignItems="center"
              >
                <Grid
                  item
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ minHeight: "14vh" }}
                ></Grid>
                <Grid item>
                  {" "}
                  <Typography
                    sx={{
                      fontWeight: 800,
                      fontSize: "38px",
                      paddingLeft: 6,
                    }}
                  >
                    Welcome to Organflow
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <Typography
                    sx={{ fontSize: 20, paddingRight: 43, fontWeight: 600 }}
                  >
                    Email
                  </Typography>
                  <TextField
                    sx={{
                      minWidth: 400,
                    }}
                    size="medium"
                    type="text"
                    placeholder="Please Enter E-mail"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Badge color="secondary" badgeContent={0}>
                            <MailIcon color="primary" />
                          </Badge>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Typography
                      sx={{ fontSize: 20, paddingRight: 38, fontWeight: 600 }}
                    >
                      Password{" "}
                    </Typography>
                    <TextField
                      sx={{
                        minWidth: 400,
                      }}
                      size="medium"
                      placeholder="Please Enter Password"
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: "Password is required",
                      })}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Badge color="primary" badgeContent={0}>
                              <LockOutlinedIcon color="primary" />
                            </Badge>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Badge color="primary" badgeContent={0}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </Badge>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container sx={{ marginTop: 1 }}>
                    <Grid>
                      <a className="text-dark" href="/auth/forgot-password">
                        <Typography
                          fontSize={15}
                          style={{ fontWeight: "bold", paddingLeft: 270 }}
                        >
                          Forgot Password?
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: 5,
                      minWidth: 407,
                      marginLeft: 1,
                      marginRight: "auto",
                    }}
                  >
                    <Grid justifyContent="center">
                      <Button
                        variant="contained"
                        color="success"
                        type="submit"
                        size="large"
                        sx={{
                          minWidth: 407,
                          borderRadius: 3,
                        }}
                      >
                        {isLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Login"
                        )}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container xs={12} sx={{ paddingTop: 4, marginTop: 3 }}>
                    <Grid
                      sx={{
                        borderRight: "3px solid green",
                      }}
                    ></Grid>
                    <Grid item xs={6}>
                      <a href="https://organflow.app/about/">
                        <Typography
                          sx={{
                            fontWeight: 600,
                            borderRight: "3px solid green",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          About us
                        </Typography>
                      </a>
                    </Grid>
                    <Grid item xs={5.9}>
                      <a href="https://organflow.app/privacy-policy/">
                        <Typography
                          sx={{
                            fontWeight: 600,
                            borderRight: "3px solid green",
                            textAlign: "center",
                          }}
                        >
                          Privacy Policy
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
