import React, { useState, useEffect, useCallback } from "react";
import { NavigateNext, KeyboardArrowDown } from "@mui/icons-material";
import { themes } from "services/constants";
import { showToast } from "components/Status";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import { Loader } from "components/Loader";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";

export const MemberDetails = ({ sprintDetails }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [loading, setLoading] = useState(true);
  const [taskOwnerId, setTaskOwnerId] = useState(0);
  const [Users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [expandTaskOwner, setExpandTaskOwner] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchTaskData = useCallback(async () => {
    try {
      const response = await fetch(
        `${domain}task_summary/?sprint_id=${sprintDetails?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setUsers(res);
        setLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  }, [domain, token, sprintDetails?.id]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { result: taskDetails, setLoad: setGetUserTaskDetails } = useFetch(
    `${domain}sprint_task_details/?sprint_id=${sprintDetails?.id}&user_id=${taskOwnerId}`
  );

  return (
    <React.Fragment>
      {Users.length !== 0 ? (
        <Mui.TableContainer
          sx={{
            mt: 2,
            paddingRight: 5,
            paddingLeft: 5,
          }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow maxWidth="xl" align="left">
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                ></Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Assigned
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Completed
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Onhold
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Time Spent
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {Users.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((user) => (
                <>
                  <Mui.TableRow key={user.id} hover={true}>
                    <Mui.TableCell align="left">
                      <Mui.IconButton
                        size="small"
                        sx={{ marginRight: 2 }}
                        onClick={() => {
                          setTaskOwnerId(
                            user.owner_username === expandTaskOwner
                              ? 0
                              : user.user_id
                          );
                          setExpandTaskOwner(
                            user.owner_username === expandTaskOwner
                              ? 0
                              : user.owner_username
                          );
                          setGetUserTaskDetails(
                            user.owner_username === expandTaskOwner
                              ? false
                              : true
                          );
                        }}
                      >
                        {expandTaskOwner === user.owner_username ? (
                          <KeyboardArrowDown
                            size="small"
                            sx={{ stroke: 2, color: themes.blackColor }}
                          />
                        ) : (
                          <NavigateNext
                            size="small"
                            sx={{ stroke: 2, color: themes.blackColor }}
                          />
                        )}
                      </Mui.IconButton>
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 100,
                      }}
                    >
                      <Mui.Stack direction="row">
                        <Mui.Avatar
                          sx={{ width: 25, height: 25 }}
                          alt={user.owner_username?.charAt(0).toUpperCase()}
                          src={user.image_url}
                        />
                        <Mui.Typography
                          sx={{
                            paddingLeft: 1,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: 100,
                          }}
                        >
                          {user.owner_username ? user.owner_username : "N/A"}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {user.total_task}
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {user.task_completed || user.task_completed === 0
                        ? user.task_completed
                        : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {user.task_onhold ? user.task_onhold : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      align="left"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 100,
                      }}
                    >
                      {user?.total_task_time_for_project
                        ? user?.total_task_time_for_project
                        : "N/A"}
                    </Mui.TableCell>
                  </Mui.TableRow>
                  {expandTaskOwner === user.owner_username && (
                    <Mui.TableRow>
                      <Mui.TableCell colSpan={7}>
                        <Mui.TableContainer
                          sx={{
                            paddingRight: 5,
                            paddingLeft: 5,
                          }}
                        >
                          {taskDetails?.sprint_tasks?.length !== 0 ? (
                            <Mui.Table className="border">
                              <Mui.TableHead
                                sx={{
                                  backgroundColor: themes.primary,
                                }}
                              >
                                <Mui.TableRow>
                                  <Mui.TableCell
                                    sx={{
                                      color: themes.blackColor,
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    Task ID
                                  </Mui.TableCell>
                                  <Mui.TableCell
                                    sx={{
                                      color: themes.blackColor,
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      width: "30%",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    Task Name
                                  </Mui.TableCell>
                                  <Mui.TableCell
                                    sx={{
                                      color: themes.blackColor,
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    Time
                                  </Mui.TableCell>
                                  <Mui.TableCell
                                    sx={{
                                      color: themes.blackColor,
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    Start Date
                                  </Mui.TableCell>
                                  <Mui.TableCell
                                    sx={{
                                      color: themes.blackColor,
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    End Date{" "}
                                  </Mui.TableCell>
                                  <Mui.TableCell
                                    sx={{
                                      color: themes.blackColor,
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    Priority
                                  </Mui.TableCell>
                                  <Mui.TableCell
                                    sx={{
                                      color: themes.blackColor,
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    Status
                                  </Mui.TableCell>
                                </Mui.TableRow>
                              </Mui.TableHead>
                              <Mui.TableBody>
                                {taskDetails?.sprint_tasks?.map(
                                  (task, index) => (
                                    <Mui.TableRow key={index} hover={true}>
                                      <Mui.TableCell
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          maxWidth: 100,
                                        }}
                                      >
                                        {task.task_code
                                          ? task.task_code
                                          : "N/A"}
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          maxWidth: 100,
                                          width: "30%",
                                        }}
                                      >
                                        <Mui.Tooltip title={task.taskname}>
                                          <Mui.Typography
                                            sx={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {task.taskname}
                                          </Mui.Typography>
                                        </Mui.Tooltip>
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {task.total_time_spent}
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {task.start_date}
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {task.due_date ? task.due_date : "N/A"}
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {task.priority.name}
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {task.work_flow_status?.name}
                                      </Mui.TableCell>
                                    </Mui.TableRow>
                                  )
                                )}
                              </Mui.TableBody>
                            </Mui.Table>
                          ) : (
                            <Mui.Stack
                              sx={{ padding: 0.5 }}
                              spacing={2}
                              fullWidth
                            >
                              <Mui.Alert severity="info">
                                There is a no available task
                              </Mui.Alert>
                            </Mui.Stack>
                          )}
                        </Mui.TableContainer>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  )}
                </>
              ))}
            </Mui.TableBody>
          </Mui.Table>
          {Users.length > 10 && (
            <Mui.TablePagination
              sx={{ marginRight: 5 }}
              className="custom-pagination"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={Users.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      ) : loading === false ? (
        <div>
          <Mui.Alert severity="info" sx={{ mt: 2, mr: 5, ml: 5 }}>
            No data available
          </Mui.Alert>
        </div>
      ) : (
        <Loader info="Loading..." />
      )}
    </React.Fragment>
  );
};
