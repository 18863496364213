import React, { useContext, useEffect, useState } from "react";
import { MyProjectContext } from "./MyProjectContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  setEditProject,
  setProjectStatus,
} from "services/Redux/leaveManagement";
import { Loader } from "components/Loader";
import * as Mui from "@mui/material";
import { themes, filterProject } from "services/constants";
import { useDispatch, useSelector } from "react-redux";
import { MoreVert, Public } from "@mui/icons-material";

export const ListView = ({
  handleMoveToEditScreen,
  updateMenuDetails,
  dropDown,
}) => {
  const {
    projects,
    setGetClientDetails,
    currentTap,
    loading,
    setProjects,
    masterDataForSearch,
  } = useContext(MyProjectContext);
  const { userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editProject, setEditProjectDetails] = useState(null);
  const role = userDetails.role === "User" ? true : false;
  const dispatch = useDispatch();

  useEffect(() => {
    const filteredResults = masterDataForSearch.filter((item) =>
      filterProject.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setProjects(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch, setProjects]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentTap) {
      setPage(0);
    }
  }, [currentTap]);

  const handleMoveToTaskScreen = (item) => {
    let status = [];
    item?.workflow?.statuses.forEach((element) => {
      status.push(element.name);
    });
    dispatch(setProjectStatus(status));
    localStorage.setItem("currentEditProject", JSON.stringify(item));
    dispatch(setEditProject(item));
    history.push("/admin/task");
  };

  const updateProjectOption = async (option) => {
    handleMenuClose();
    await handleMoveToEditScreen(option, editProject);
    if (option !== "View" && option !== "Clone") {
      setGetClientDetails(true);
    }
  };

  return (
    <React.Fragment>
      {projects.length !== 0 ? (
        <>
          <Mui.Grid container sx={{ paddingRight: 1 }}>
            <Mui.TableContainer component={Mui.Paper} style={{ paddingTop: 2 }}>
              <Mui.Table
                fixedHeader={false}
                style={{
                  tableLayout: "auto",
                  borderBottom: "1.5px solid transparent",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Mui.TableBody>
                  {projects
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((project, index) => (
                      <Mui.TableRow
                        key={project.id}
                        onDoubleClick={() => handleMoveToTaskScreen(project)}
                        maxWidth="xl"
                        align="left"
                        hover={true}
                        sx={{
                          cursor: "pointer",
                          "& .MuiTableCell-root": {
                            padding: "10px 15px",
                          },
                        }}
                      >
                        <Mui.TableCell
                          sx={{
                            width: "15%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.Typography
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontSize: 15,
                            }}
                          >
                            {project.projectname}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            width: "10%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.Typography sx={{ fontSize: 15 }}>
                            {project.project_code}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            width: "10%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.Typography
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontSize: 15,
                            }}
                          >
                            {project.client?.clientname
                              ? project.client?.clientname
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            width: "15%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.Typography
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontSize: 15,
                            }}
                          >
                            {project.project_lead_name
                              ? project.project_lead_name
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            width: "10%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.Typography
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontSize: 15,
                            }}
                          >
                            {project.date ? project.date : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            width: "10%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          {project.projectType === "Private" ? (
                            <Mui.AvatarGroup
                              max={3}
                              sx={{
                                "& .MuiAvatar-root": {
                                  width: 25,
                                  height: 25,
                                  fontSize: 15,
                                },
                                justifyContent: "left",
                              }}
                            >
                              {project.members.map((member) => (
                                <Mui.Avatar
                                  key={member.id}
                                  sx={{ width: 25, height: 25 }}
                                  alt={member.username}
                                  src={member.image_url}
                                >
                                  {member.image_url ? (
                                    <img
                                      src={member.image_url}
                                      alt={member?.username
                                        ?.charAt(0)
                                        .toUpperCase()}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    member.username?.charAt(0).toUpperCase()
                                  )}
                                </Mui.Avatar>
                              ))}
                            </Mui.AvatarGroup>
                          ) : (
                            <Mui.Stack direction="row" sx={{ fontSize: 15 }}>
                              <Public />
                              &nbsp;{project.projectType}
                            </Mui.Stack>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            width: "10%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.Button
                            size="small"
                            sx={{
                              backgroundColor: themes.taskBtnBck,
                              borderRadius: "8px",
                              justifyContent: "center",
                              alignItems: "center",
                              color: themes.backgroundWhite,
                              fontSize: 15,
                              cursor: "default",
                              "&:hover": {
                                backgroundColor: themes.taskBtnBck,
                                cursor: "default",
                              },
                            }}
                          >
                            {project.completed_tasks}/{project.total_tasks}
                          </Mui.Button>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            width: "10%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.Typography
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontSize: 15,
                            }}
                          >
                            {project.status.status}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            width: "10%",
                            fontSize: 14,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                          }}
                        >
                          <Mui.IconButton
                            onClick={(event) => {
                              updateMenuDetails(project);
                              setEditProjectDetails(project);
                              handleMenuOpen(event);
                            }}
                          >
                            <MoreVert
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {role ? (
                              <>
                                <Mui.Stack>
                                  <Mui.MenuItem
                                    key={"Dashboard"}
                                    onClick={() =>
                                      updateProjectOption("Dashboard")
                                    }
                                  >
                                    {"Dashboard"}
                                  </Mui.MenuItem>
                                </Mui.Stack>
                              </>
                            ) : (
                              <>
                                {dropDown.map((option) => (
                                  <Mui.Stack>
                                    <Mui.MenuItem
                                      key={option}
                                      onClick={() =>
                                        updateProjectOption(option)
                                      }
                                    >
                                      {option}
                                    </Mui.MenuItem>
                                  </Mui.Stack>
                                ))}
                              </>
                            )}
                          </Mui.Menu>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
          </Mui.Grid>
          {projects.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={projects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      ) : loading ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : (
        <div style={{ paddingTop: 8 }}>
          <Mui.Alert severity="info">No data available</Mui.Alert>
        </div>
      )}
    </React.Fragment>
  );
};
