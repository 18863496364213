import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { themes } from "services/constants";
import { MoreVert } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomButton } from "components/CustomButton";
import { Loader } from "components/Loader";
import { EditShiftCategory } from "./EditShiftCategory";
import { setGlobalSearchValue } from "services/Redux/userToken";
import dayjs from "dayjs";
import sessionHandling from "services/utils/notificationUtils";
import { useDispatch } from "react-redux";
export const ShiftCategory = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [shiftCategoryList, setShitCategoryList] = useState([]);
  const [masterShiftCategoryList, setMasterShiftCategoryList] = useState([]);
  const [showShiftCategaryDetails, setShowShiftCategaryDetails] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, isLoading] = useState(false);
  const [editShiftCategoryData, setEditShiftCategoryData] = useState([]);
  const [showEditShiftCategory, setShowEditShiftCategory] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getShiftCategoryApi, setGetShiftCategoryApi] = useState(true);
  const [editShiftCategory, setEditShitCategory] = useState([]);

  useEffect(() => {
    const getShiftCategory = async () => {
      isLoading(true);
      try {
        const response = await fetch(`${domain}list_shift_categories/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setMasterShiftCategoryList(res);
          isLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        isLoading(false);
      }
    };
    if (getShiftCategoryApi) {
      getShiftCategory();
      setGetShiftCategoryApi(false);
    }
  }, [domain, getShiftCategoryApi, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const searchFields = ["name", "number_of_shifts"];
    const filteredResults = masterShiftCategoryList.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setShitCategoryList(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterShiftCategoryList]);

  const convertShiftTimeISOFormat = (item) => {
    let editableData = item;
    let updateData = editableData?.shifts?.map((data) => {
      return {
        ...data,
        break_time: dayjs()
          .set("hour", data?.break_time?.split(":")[0])
          .set("minute", data?.break_time?.split(":")[1])
          .set("second", data?.break_time?.split(":")[2]),
        start_time: dayjs()
          .set("hour", data?.start_time?.split(":")[0])
          .set("minute", data?.start_time?.split(":")[1])
          .set("second", data?.start_time?.split(":")[2]),
        min_start_time: dayjs()
          .set("hour", data?.min_start_time?.split(":")[0])
          .set("minute", data?.min_start_time?.split(":")[1])
          .set("second", data?.min_start_time?.split(":")[2]),
        max_start_time: dayjs()
          .set("hour", data?.max_start_time.split(":")[0])
          .set("minute", data?.max_start_time.split(":")[1])
          .set("second", data?.max_start_time.split(":")[2]),
        end_time: dayjs()
          .set("hour", data?.end_time.split(":")[0])
          .set("minute", data?.end_time.split(":")[1])
          .set("second", data?.end_time.split(":")[2]),
        min_end_time: dayjs()
          .set("hour", data?.min_end_time.split(":")[0])
          .set("minute", data?.min_end_time.split(":")[1])
          .set("second", data?.min_end_time.split(":")[2]),
        max_end_time: dayjs()
          .set("hour", data?.max_end_time.split(":")[0])
          .set("minute", data?.max_end_time.split(":")[1])
          .set("second", data?.max_end_time.split(":")[2]),
      };
    });
    editableData.shifts = updateData;
    setEditShiftCategoryData(editableData);
  };

  const navigateToAddShiftCategory = () => {
    dispatch(setGlobalSearchValue(""));
    history.push("/admin/add/shift/category");
  };

  return (
    <React.Fragment>
      {loading === true ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : showEditShiftCategory ? (
        <React.Fragment>
          <EditShiftCategory
            editShiftCategoryData={editShiftCategoryData}
            setShowEditShiftCategory={setShowEditShiftCategory}
            setGetShiftCategoryApi={setGetShiftCategoryApi}
          />
        </React.Fragment>
      ) : (
        <>
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid xs={9} sm={8} md={10} lg={10}></Mui.Grid>
            <Mui.Grid
              xs={5}
              sm={4}
              md={2}
              lg={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 2,
              }}
            >
              <CustomButton
                actionFuntion={navigateToAddShiftCategory}
                actionName="Add Shift Category"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>

          <Mui.TableContainer
            sx={{
              padding: 2,
              width: "100%",
              minHeight: "95%",
              paddingTop: 2,
            }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow maxWidth="xl" align="left">
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                    }}
                  >
                    Shift Category Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Number of Shifts
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>

              <Mui.TableBody>
                {shiftCategoryList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <>
                      <Mui.TableRow maxWidth="xl" align="left">
                        <Mui.TableCell
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                          }}
                        >
                          {item?.id === showShiftCategaryDetails ? (
                            <Mui.IconButton
                              onClick={() => setShowShiftCategaryDetails(0)}
                            >
                              <KeyboardArrowDownIcon />
                            </Mui.IconButton>
                          ) : (
                            <Mui.IconButton
                              onClick={() =>
                                setShowShiftCategaryDetails(item?.id)
                              }
                              style={{
                                padding: "0px",
                              }}
                            >
                              <ChevronRightIcon />
                            </Mui.IconButton>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                          }}
                        >
                          <Mui.Typography>
                            {item?.name ? item?.name : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                          }}
                        >
                          <Mui.Typography>
                            {item?.number_of_shifts
                              ? item?.number_of_shifts
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={(event) => {
                              setEditShitCategory(item);
                              setAnchorEl(event.currentTarget);
                            }}
                          >
                            <MoreVert />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => {
                              handleMenuClose();
                            }}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                              onClick={(e) => {
                                setShowShiftCategaryDetails(0);
                                setShowEditShiftCategory(true);
                                handleMenuClose();
                                setAnchorEl(null);
                                convertShiftTimeISOFormat(editShiftCategory);
                              }}
                            >
                              Edit
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      </Mui.TableRow>
                      {showShiftCategaryDetails === item?.id && (
                        <Mui.TableRow>
                          <Mui.TableCell colSpan={7}>
                            <Mui.TableContainer
                              sx={{
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              {item?.shifts?.length !== 0 ? (
                                <Mui.Table className="border">
                                  <Mui.TableHead
                                    sx={{
                                      backgroundColor: themes.primary,
                                    }}
                                  >
                                    <Mui.TableRow>
                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Shift Name
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Start Time
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Min Start Time
                                      </Mui.TableCell>

                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Max Start Time
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        End Time
                                      </Mui.TableCell>
                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Min End Time
                                      </Mui.TableCell>

                                      <Mui.TableCell
                                        sx={{
                                          color: themes.blackColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Max End Time
                                      </Mui.TableCell>
                                    </Mui.TableRow>
                                  </Mui.TableHead>
                                  <Mui.TableBody>
                                    {item?.shifts?.map((shift, index) => (
                                      <Mui.TableRow key={index}>
                                        <Mui.TableCell>
                                          {shift.name}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {shift.start_time}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {shift.min_start_time}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {shift.max_start_time}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {shift.end_time}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {shift.min_end_time}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {shift.max_end_time}
                                        </Mui.TableCell>
                                      </Mui.TableRow>
                                    ))}
                                  </Mui.TableBody>
                                </Mui.Table>
                              ) : (
                                <Mui.Stack
                                  sx={{ padding: 0.5 }}
                                  spacing={2}
                                  fullWidth
                                >
                                  <Mui.Alert severity="info">
                                    There is a no available shifts
                                  </Mui.Alert>
                                </Mui.Stack>
                              )}
                            </Mui.TableContainer>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      )}
                    </>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
            {shiftCategoryList.length === 0 ? (
              <Mui.Alert sx={{ marginTop: 3 }} severity="info">
                No data available
              </Mui.Alert>
            ) : null}
            {shiftCategoryList?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[10, 20, 50, 100]}
                count={shiftCategoryList?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.TableContainer>
        </>
      )}
    </React.Fragment>
  );
};
