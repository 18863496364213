import React, { useEffect, createContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showToast } from "components/Status";
import {
  setReloadTask,
  setEditProject,
} from "services/Redux/projectManagement";
import { individualTaskList } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";

export const TaskContext = createContext({});

export const TaskContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const editProject = useSelector((state) => state.leaveReducer.editproject);
  const reloadTask = useSelector((state) => state.projectReducer.reloadTask);
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [openTaskDetails, setOpenTaskDetails] = useState(false);
  const [taskFullView, setTaskFullView] = useState(false);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [workflowStatus, setWorkflowStatus] = useState([]);
  const [projectSprints, setProjectSprints] = useState(
    editProject ? editProject?.sprints : null
  );
  const curSprint = editProject
    ? editProject?.sprints?.filter((spr) => spr.status === "Current")
    : [];
  const [sprintId, setSprintId] = useState(
    curSprint.length === 0
      ? editProject?.sprints[editProject.sprints.length - 1]?.id
      : curSprint[0].id
  );
  const [editProjectId, setEditProjectId] = useState(
    editProject ? editProject.id : null
  );
  const [taskStartTime, setTaskStartTime] = useState(new Date());
  const [isActive, setIsActive] = useState(false);
  const [getProjectDetails, setGetProjectDetails] = useState(true);
  const [projectDetails, setProjectDetails] = useState([]);
  const [fetchTask, setFetchTask] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [projectMasterTaskData, setProjectMasterTaskData] = useState([]);
  const [projectTasks, setProjectTasks] = useState([]);
  const [currentPlayTaskId, setCurrentPlayTaskId] = useState(0);
  const [stikyTableSize, setStikyTableSize] = useState("99%");
  const [editTask, setEditTask] = useState(null);
  const [taskLabels, setTaskLabels] = useState([]);
  const [labelsApi, setLabelsApi] = useState(false);
  const [labelbasedProjectId, setLabelBasedProjectId] = useState(
    editProject ? editProject.id : null
  );
  const [sprintDetails, setSprintDetails] = useState(
    curSprint.length === 0
      ? editProject?.sprints[editProject.sprints.length - 1]
      : curSprint[0]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isTaskLoading, setIsTaskLoading] = useState(true);
  const [userTasklist, setUserTasklist] = useState([]);
  const [fetchMytask, setFetchMytask] = useState(editProject ? false : true);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  useEffect(() => {
    const fetchTaskLabels = async () => {
      try {
        const response = await fetch(
          `${domain}project_task_label/?project=${labelbasedProjectId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const labels = res.map((obj) => ({
            ...obj,
            label: obj.label_name,
          }));
          let addLabel = {
            id: 99999,
            label_name: "Add label",
            label: "Add label",
          };
          setTaskLabels([...labels, addLabel]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (labelsApi) {
      fetchTaskLabels();
      setLabelsApi(false);
    }
  }, [domain, editProjectId, labelbasedProjectId, labelsApi, token]);

  useEffect(() => {
    const fetchWorkflowStatus = async () => {
      try {
        const response = await fetch(`${domain}workflow-statuses/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const statusLabel = res.map((obj) => ({
            ...obj,
            label: obj.name,
          }));
          setWorkflowStatus(statusLabel);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchWorkflowStatus();
  }, [domain, token]);

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        setIsTaskLoading(true);
        const url = editProjectId
          ? `${domain}task/?project=${editProjectId}&sprint=${sprintId}`
          : `${domain}task/?current_assignee=${userDetails.id}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const count = res.filter((task) => task.status.status === "ToDo");
          const runningTaskId = res.filter(
            (task) =>
              task.is_running && userDetails.id === task.current_assignee.id
          );
          setCurrentPlayTaskId(runningTaskId[0]?.id);
          setTaskStartTime(runningTaskId[0]?.start_time);
          setIsActive(runningTaskId[0] ? true : false);
          setStikyTableSize(count.length > 6 ? "98%" : "100%");
          setProjectMasterTaskData(res);
          setProjectTasks(res);
          setMasterDataForSearch(res);
          setIsLoading(false);
          if (reloadTask) {
            setGetProjectDetails(true);
            dispatch(setReloadTask(false));
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsTaskLoading(false);
      }
    };
    if (fetchTask || reloadTask) {
      fetchTaskData();
      setFetchTask(false);
    }
  }, [
    dispatch,
    domain,
    editProject,
    editProjectId,
    fetchTask,
    reloadTask,
    sprintId,
    token,
    userDetails.id,
  ]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await fetch(`${domain}user_with_avatar/ `, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const AllUsersData = res.map((obj) => ({
            ...obj,
            label: obj.username,
          }));
          setAllUsers(AllUsersData);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchAllUsers();
  }, [domain, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${domain}project/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const projects = res.map((obj) => ({
            ...obj,
            label: obj.projectname,
          }));
          let projectsDetails = [...projects];
          setProjectDetails(projectsDetails);
          if (editProjectId) {
            let currentProject = projectsDetails.filter(
              (data) => data.id === editProjectId
            );
            setProjectSprints(currentProject[0].sprints);
            const curSprint = currentProject[0]?.sprints?.filter(
              (spr) => spr.status === "Current"
            );
            if (curSprint.length === 0) {
              setSprintId(
                currentProject[0].sprints[currentProject[0].sprints.length - 1]
                  .id
              );
              setSprintDetails(
                currentProject[0]?.sprints[
                  currentProject[0]?.sprints?.length - 1
                ]
              );
            } else {
              setSprintId(curSprint[0].id);
              setSprintDetails(curSprint[0]);
            }
            dispatch(setEditProject(currentProject[0]));
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (getProjectDetails) {
      fetchData();
      setGetProjectDetails(false);
    }
  }, [
    dispatch,
    domain,
    editProjectId,
    getProjectDetails,
    projectDetails,
    token,
  ]);

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${domain}task/?current_assignee=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const filtered_data = res.filter(
            (task) => task.work_flow_status.name !== "Completed"
          );
          setUserTasklist(filtered_data);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    if (fetchMytask) {
      fetchTaskData();
      setFetchMytask(false);
    }
  }, [domain, fetchMytask, token, userDetails.id]);

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  useEffect(() => {
    const filteredResults = masterDataForSearch.filter((item) =>
      individualTaskList.some((key) =>
        getNestedValue(item, key)
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setProjectMasterTaskData(filteredResults);
  }, [globalSearchValue, masterDataForSearch, setProjectMasterTaskData]);

  return (
    <TaskContext.Provider
      value={{
        token,
        domain,
        projectMasterTaskData,
        projectTasks,
        setProjectTasks,
        editProject,
        allUsers,
        setFetchTask,
        currentPlayTaskId,
        setCurrentPlayTaskId,
        projectDetails,
        setGetProjectDetails,
        setEditProjectId,
        setSprintId,
        sprintId,
        projectSprints,
        setProjectSprints,
        isTaskLoading,
        editProjectId,
        stikyTableSize,
        setStikyTableSize,
        userDetails,
        taskStartTime,
        setTaskStartTime,
        isActive,
        setIsActive,
        sprintDetails,
        setSprintDetails,
        editTask,
        setEditTask,
        isLoading,
        taskFullView,
        setTaskFullView,
        showTaskDetails,
        setShowTaskDetails,
        workflowStatus,
        openTaskDetails,
        setOpenTaskDetails,
        taskLabels,
        setLabelsApi,
        setLabelBasedProjectId,
        userTasklist,
        setUserTasklist,
        fetchMytask,
        setFetchMytask,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
