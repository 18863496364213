import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import { EarningDetails } from "./SalaryComponent/EarningDetails";
import { useForm } from "react-hook-form";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { onlyNumberRegex } from "services/constants/patterns";
import { CustomButton } from "components/CustomButton";
import { salaryType } from "services/constants";
import { CustomTextBox } from "components/CustomTextBox";
import { DeductionDetails } from "./SalaryComponent/DeductionDetails";
import { useFetch } from "services/hooks/useFetch";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";

export const SalaryDetails = ({
  setPageNo,
  existingUserDetails,
  setExistingUserDetails,
  openDialogBox,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [salaryTypeName, setSalaryTypeName] = useState(
    existingUserDetails?.salary_type_name
      ? existingUserDetails?.salary_type_name
      : "Daily"
  );
  const [amount, setAmount] = useState(
    existingUserDetails?.amount ? existingUserDetails?.amount : 0
  );
  const [salaryComponentDetails, setSalaryComponentDetails] = useState([]);
  const [updateEarning,setupdateEarning] =useState(true);
  const [monthlySalary, setMonthlySalary] = useState(
    existingUserDetails?.monthlySalary ? existingUserDetails?.monthlySalary : 0
  );
  const [otherSalaryComponents, setOtherSalaryComponents] = useState(
    existingUserDetails?.earnings ? existingUserDetails?.earnings : []
  );
  const [userDeductionDetails, setUserDeductionDetails] = useState(
    existingUserDetails?.deductions ? existingUserDetails?.deductions : []
  );
  const [updateDeductionDetails, setUpdateDeductionDetails] = useState(false);
  const [specialAllowance, setSpecialAllowance] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [salaryGroupName, setSalaryGroupName] = useState(
    existingUserDetails?.salaryGroupName
      ? existingUserDetails?.salaryGroupName
      : ""
  );
  const { result: salaryGroupData } = useFetch(
    existingUserDetails.company_id !== 0
      ? `${domain}salary-group/?company=${existingUserDetails.company_id}`
      : null
  );

  useEffect(() => {
    setValue(
      "salary_type",
      existingUserDetails?.salary_type_name
        ? existingUserDetails?.salary_type_name
        : "Daily"
    );
    setValue(
      "salary_amount",
      existingUserDetails?.amount ? existingUserDetails?.amount : 0
    );
    setValue(
      "salary_group_id",
      existingUserDetails?.salary_group
        ? existingUserDetails?.salary_group
        : null
    );
    setValue(
      "salary_group_name",
      existingUserDetails?.salaryGroupName
        ? existingUserDetails?.salaryGroupName
        : ""
    );
  }, [
    existingUserDetails?.amount,
    existingUserDetails?.salaryGroupName,
    existingUserDetails?.salary_group,
    existingUserDetails?.salary_type_name,
    setValue,
  ]);

  const onSubmit = async (data) => {
    if (buttonDisabled) return;
    setButtonDisabled(true);
    if (specialAllowance >= 0) {
      try {
        const response = await fetch(`${domain}employee-onboard`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            user_name: existingUserDetails.user_name,
            email: existingUserDetails.email_id,
            company_id: existingUserDetails.company_id,
            portal_access: existingUserDetails.portal_access,
            employee_id: existingUserDetails.employee_id,
            date_joined: existingUserDetails.date_of_joined
              ? existingUserDetails.date_of_joined
                  ?.split("-")
                  .reverse()
                  .join("-")
              : null,
            leave_group_id: existingUserDetails?.leave_group_id,
            department_id: existingUserDetails.department_id,
            designation_id: existingUserDetails.designation_id,
            employment_type_id: existingUserDetails.employment_type_id,
            parent_name: existingUserDetails.parent_name,
            parent_mobile_number: existingUserDetails.parent_mobile_number,
            bankname: existingUserDetails.bank_name,
            account_holder_name: existingUserDetails.account_holder_name,
            accountno: existingUserDetails.account_number,
            ifsc: existingUserDetails.ifsc,
            branch_name: existingUserDetails.branch_name,
            pan: existingUserDetails.pan_number,
            aadhar: existingUserDetails.aadhar_number,
            address: existingUserDetails.address,
            permanent_address: existingUserDetails.permanent_address,
            pf: existingUserDetails.pf_number,
            uan: existingUserDetails.uan_number,
            esi: existingUserDetails.esi_number,
            dob: existingUserDetails.date_of_birth
              ? existingUserDetails.date_of_birth.split("-").reverse().join("-")
              : null,
            emergency_contacts: existingUserDetails.emergency_contacts,
            transport_route_no: existingUserDetails?.transport_route_no,
            transport_type: existingUserDetails?.transport_type,
            block_name: existingUserDetails?.block_name,
            room_no: existingUserDetails?.room_no,
            phone_number: existingUserDetails.phone_number,
            //salaryDetails
            salary_group: data.salary_group_id,
            earnings: otherSalaryComponents.filter((data) => {
              return data.name !== "";
            }),
            deductions: userDeductionDetails.filter((data) => {
              return data.name !== "";
            }),
            salary_type:
              data.salary_type === "Daily"
                ? 1
                : data.salary_type === "Weekly"
                ? 2
                : data.salary_type === "Monthly"
                ? 3
                : 1,
            amount: data.salary_amount,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          openDialogBox();
          reset();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        setButtonDisabled(false);
        showToast("error", error.message);
      }
    } else {
      showToast("error", "Special Allowance should be greater then 0");
      setButtonDisabled(false);
    }
  };

  const updateUserEnterData = () => {
    if (specialAllowance >= 0) {
      setExistingUserDetails({
        ...existingUserDetails,
        salary_group: getValues("salary_group_id"),
        earnings: otherSalaryComponents.filter((data) => {
          return data.name !== "";
        }),
        deductions: userDeductionDetails.filter((data) => {
          return data.name !== "";
        }),
        salary_type:
          getValues("salary_type") === "Daily"
            ? 1
            : getValues("salary_type") === "Weekly"
            ? 2
            : getValues("salary_type") === "Monthly"
            ? 3
            : 1,
        amount: getValues("salary_amount"),
        salaryGroupName: getValues("salary_group_name"),
        salary_type_name: getValues("salary_type"),
        monthlySalary: monthlySalary,
      });
      setPageNo(4);
    } else {
      showToast("error", "Special Allowance should be greater then 0");
    }
  };

  const handleSalaryGroup = (event, data) => {
    if (data) {
      setValue("salary_type", data?.label);
      setSalaryTypeName(data?.label);
      setAmount(0);
    }
  };

  const handleAmount = (event) => {
    if (onlyNumberRegex.test(event.target.value)) {
      let salary = 0;
      setAmount(event.target.value);
      setValue("salary_amount", event.target.value);
      if (salaryTypeName === "Daily") {
        salary = parseInt(event.target.value) * 30;
        setMonthlySalary(salary);
      } else if (salaryTypeName === "Weekly") {
        salary = parseInt(event.target.value) * 4;
        setMonthlySalary(parseInt(event.target.value) * 4);
      } else {
        salary = parseInt(event.target.value);
        setMonthlySalary(parseInt(event.target.value));
      }
      if (otherSalaryComponents.length !== 0) {
        let updateData = otherSalaryComponents?.map((element) => {
          return {
            ...element,
            amount:
              element.name === "Special Allowance" ? (salary ? salary : 0) : 0,
          };
        });
        setOtherSalaryComponents(updateData);
      }
    }
  };

  const handleSalaryGroupName = (event, data) => {
    if (data) {
      setSalaryGroupName(data.label);
      setValue("salary_group_id", data.id);
      setValue("salary_group_name", data.label);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 10 }}>
        <Mui.Grid container>
          <Mui.Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
            Cost To Company
          </Mui.Typography>
          <Mui.Grid container>
            <Mui.Grid xs={5.8}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 0.5,
                }}
              >
                Salary Type
              </Mui.Typography>
              <CustomAutoComplete
                dataList={salaryType}
                selectedValue={salaryTypeName}
                rules={{
                  ...register("salary_type", {
                    required: "SalaryType is required",
                  }),
                }}
                updateDataFunction={handleSalaryGroup}
                placeHolder="Please select the salaryType"
                errorMessage={
                  errors?.salary_type?.message
                    ? errors?.salary_type?.message
                    : ""
                }
                textBoxError={errors?.salary_type ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={0.4}></Mui.Grid>
            <Mui.Grid xs={5.8}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 0.5,
                }}
              >
                {salaryTypeName}
              </Mui.Typography>
              <CustomTextBox
                selectedValue={amount}
                rules={{
                  ...register("salary_amount", {
                    required: "Amount is required",
                    validate: {
                      checkAmount: (fieldValue) => {
                        if (amount > 0) {
                          return true;
                        } else {
                          return "Salary amount is greater then zero";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={handleAmount}
                placeHolder="Enter the amount"
                errorMessage={
                  errors?.salary_amount?.message
                    ? errors?.salary_amount?.message
                    : ""
                }
                textBoxError={errors.salary_amount ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
            Salary Group
          </Mui.Typography>
          <Mui.Grid container>
            <Mui.Grid xs={5.8}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 0.5,
                }}
              >
                Salary group name
              </Mui.Typography>
              <CustomAutoComplete
                dataList={salaryGroupData.map((obj) => ({
                  ...obj,
                  label: obj.name,
                }))}
                selectedValue={salaryGroupName}
                rules={{
                  ...register("salary_group_name", {
                    required: "Salary group name is required",
                  }),
                }}
                updateDataFunction={handleSalaryGroupName}
                placeHolder="Please select the salary group"
                errorMessage={
                  errors?.salary_group_name?.message
                    ? errors?.salary_group_name?.message
                    : ""
                }
                textBoxError={errors?.salary_group_name ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={6.2}></Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 1 }}>
          <Mui.Stack direction="row">
            <Mui.Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
              Salary Components
            </Mui.Typography>{" "}
            &nbsp;
            <Mui.Typography
              sx={{ fontSize: 22, color: themes.generalSubHeader }}
            >
              (Monthly)
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <EarningDetails
          otherSalaryComponents={otherSalaryComponents}
          salaryComponentDetails={salaryComponentDetails}
          setOtherSalaryComponents={setOtherSalaryComponents}
          existingUserDetails={existingUserDetails}
          setSalaryComponentDetails={setSalaryComponentDetails}
          monthlySalary={monthlySalary}
          setUpdateDeductionDetails={setUpdateDeductionDetails}
          setSpecialAllowance={setSpecialAllowance}
          specialAllowance={specialAllowance}
          updateEarning={updateEarning}
          setupdateEarning={setupdateEarning}
        />
        <Mui.Grid container sx={{ paddingTop: 1 }}>
          <Mui.Typography sx={{ fontSize: 18 }}>Deductions</Mui.Typography>
        </Mui.Grid>
        <DeductionDetails
          otherSalaryComponents={otherSalaryComponents}
          existingUserDetails={existingUserDetails}
          userDeductionDetails={userDeductionDetails}
          setUserDeductionDetails={setUserDeductionDetails}
          setUpdateDeductionDetails={setUpdateDeductionDetails}
          updateDeductionDetails={updateDeductionDetails}
        />
        <Mui.Grid
          container
          sx={{
            paddingTop: {
              xs: 0,
              sm: 0,
              md: 10,
              lg: 10,
            },
            paddingBottom: 2,
          }}
        >
          <Mui.Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "flex-start",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => updateUserEnterData()}
              actionName="Previous"
              typeName="submit"
            />
          </Mui.Grid>

          <Mui.Grid
            xs={8}
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "flex-end",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionName="Create"
              typeName="submit"
              disableAction={buttonDisabled}
            />
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
