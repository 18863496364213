import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { leaveSuccess } from "services/constants/SuccessMessages";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
export const AddLeave = ({ onLeaveAdded, showForm, setShowForm }) => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  const [holi_startDate, setHoliStartDate] = useState();
  const [isAdding, setIsAdding] = useState(false);
  const minDate = new Date("2020-01-01");

  const handleHideForm = () => {
    setShowForm(false);
    setIsAdding(false);
  };

  useEffect(() => {
    if (showForm) {
      setHoliStartDate("");
      reset();
    }
  }, [reset, showForm]);

  const onSubmit = async (data) => {
    if (!isAdding) {
      setIsAdding(true);
      try {
        const response = await fetch(`${domain}companyleave/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            date: holi_startDate,
            leave_type: data.holidayname,
            createdby: userDetails.firstname,
            createdat: new Date().toISOString(),
            modifiedby: userDetails.firstname,
            modifiedat: new Date().toISOString(),
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", leaveSuccess.holidaySuccess);
          showToast("success", res.message);
          setShowForm(false);
          onLeaveAdded(res);
          setTimeout(function () {
            setIsAdding(false);
          }, 1000);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        if (error.message.includes("already exists")) {
          showToast(
            "error",
            `Holiday already exists on the same date: ${holi_startDate
              .split("-")
              .reverse()
              .join("-")}`
          );
          setIsAdding(false);
        } else {
          setIsAdding(false);
          showToast("error", error.message);
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = /^[A-Za-z\s]$/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };
  const handlePreventCopyPaste = (event) => {
    event.preventDefault();
  };

  const validateDate = (value) => {
    const selectedDate = new Date(value);
    if (isNaN(selectedDate.getTime())) {
      return "Invalid date";
    }
    if (selectedDate < minDate) {
      return "Date must be 01/01/2020 or later";
    }
    return true;
  };

  return (
    <>
      <div>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={showForm}
          PaperProps={{
            sx: {
              borderRadius: 5,
            },
          }}
        >
          <FuncDialogTitle
            id="customized-dialog-title"
            align="center"
            sx={{
              minWidth: 580,
              color: themes.blackColor,
              fontWeight: 1000,
              fontSize: "23px",
              background: themes.primary,
            }}
            onClose={handleHideForm}
            title={"Add Holiday"}
          >
            {"Add Holiday"}
          </FuncDialogTitle>
          <Mui.DialogContent dividers>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Mui.Grid container spacing={2}>
                <Mui.Grid item xs={12}>
                  <Mui.TextField
                    id="from-date"
                    label="Date"
                    type="date"
                    size="small"
                    value={holi_startDate}
                    {...register("fromDate", {
                      required: "Date is required.",
                      validate: validateDate,
                    })}
                    onChange={(event) => setHoliStartDate(event.target.value)}
                    error={Boolean(errors.fromDate)}
                    helperText={errors.fromDate?.message || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    disableOpenPicker
                  />
                </Mui.Grid>
                <Mui.Grid item xs={12}>
                  <Mui.TextField
                    label="Holiday Name"
                    placeholder="Please enter holiday name"
                    {...register("holidayname", {
                      required: "Holiday name is required",
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3 characters",
                      },
                      maxLength: {
                        value: 30,
                        message: "Maximum length is 30 characters",
                      },
                    })}
                    onKeyPress={handleKeyDown}
                    error={Boolean(errors.holidayname)}
                    helperText={errors.holidayname?.message || ""}
                    onPaste={handlePreventCopyPaste}
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Mui.Grid>
                <Mui.Grid container sx={{ mt: 2 }}>
                  <Mui.Grid xs={5}></Mui.Grid>
                  <Mui.Grid
                    xs={7}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <CustomButton
                      actionFuntion={handleHideForm}
                      actionName="Cancel"
                      typeName="button"
                      disabled={isSubmitting || isAdding}
                    />
                    &nbsp;&nbsp;
                    <CustomButton actionName="Add" typeName="submit" />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </form>
          </Mui.DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  borderRadius: 4,
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: themes.blackColor }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
