import React, { useContext, useState, useEffect, useCallback } from "react";
import * as Mui from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { themes } from "services/constants";
import { useDrag } from "react-dnd";
import { showToast } from "components/Status";
import { TaskContext } from "./TaskContext";
import { TaskDetails } from "./TaskDetails";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { projectSuccess } from "services/constants/SuccessMessages";
import { ChangeAssignee } from "./ChangeAssignee";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import {
  AvTimer,
  KeyboardArrowRight,
  MoreVert,
  PlayArrow,
  Add,
  PauseCircle,
  AccountTree,
  StopCircle,
} from "@mui/icons-material";
import { getContrastingTextColor } from "services/utils/colorChecker";

const useStyles = makeStyles({
  tableCell: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});

const DraggableCard = ({ task, status, setActiveTap }) => {
  const classes = useStyles();
  const {
    domain,
    token,
    setFetchTask,
    setCurrentPlayTaskId,
    currentPlayTaskId,
    userDetails,
    allUsers,
    taskStartTime,
    setTaskStartTime,
    isActive,
    setIsActive,
    setEditTask,
    editTask,
    workflowStatus,
    openTaskDetails,
    setOpenTaskDetails,
    taskLabels,
    setLabelsApi,
    setLabelBasedProjectId,
    editProject,
  } = useContext(TaskContext);
  const [durationTime, setDuration] = useState(null);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(0);
  const [taskid, setTaskId] = useState(0);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [newLabel, setNewLabel] = useState("");
  const [newLabelColor, setNewLabelColor] = useState(themes.primaryIndicator);
  const [workflowStatusDetails, setWorkflowStatusDetails] = useState(null);
  const [taskSprintDetails, setTaskSprintDetails] = useState(null);
  const [moveMenuAnchorEl, setMoveMenuAnchorEl] = useState(null);
  const [labelMenuAnchorEl, setLavekMenuAnchorEl] = useState(null);
  const [expandedSubMenuAnchorEl, setExpandedSubMenuAnchorEl] = useState(null);
  const [selectedTaskid, setSelectedTaskid] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAssigneeDialogBox, setOpenAssigneeDialogBox] = useState(false);
  const [changeAssigneeStatus, setChangeAssigneeStatus] = useState("");
  const [priProjMembers, setPriProjMembers] = useState([]);
  const [, ref] = useDrag({
    type: "CARD",
    item: { task },
  });

  const priorityColor = (priority) => {
    switch (priority) {
      case "Low":
        return themes.outTap;
      case "Medium":
        return themes.activeTap;
      case "High":
        return themes.onleaveTap;
      default:
        break;
    }
  };

  const filterCommonValues = (arr1, arr2) => {
    const valuesInArr2 = arr2.map((item) => item["username"]);
    return arr1.filter((item) => valuesInArr2.includes(item["username"]));
  };

  useEffect(() => {
    setLabelsApi(true);
  }, [setLabelsApi]);

  useEffect(() => {
    if (editProject?.projectType === "Private") {
      const filteredMembers = filterCommonValues(
        allUsers,
        editProject?.members
      );
      setPriProjMembers(filteredMembers);
    }
  }, [editProject?.members, allUsers, editProject?.projectType]);

  const ticks = useCallback(() => {
    let momenttime = 0;
    const startTime = moment(taskStartTime, "HH:mm:ss a");
    let notesTime = moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A");
    const endTime = moment(notesTime, "HH:mm:ss a");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    const sec = parseInt(duration.asSeconds()) % 60;
    const time = `${hours}:${minutes}:${sec}`;
    momenttime = moment(time, "HH:mm:ss").format("HH:mm:ss");
    setDuration(momenttime);
  }, [taskStartTime]);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        ticks();
      }, 1000);
    } else if (!isActive && taskStartTime !== null) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, taskStartTime, ticks]);

  const handleWorkingTime = async (taskid, action, task) => {
    try {
      const response = await fetch(`${domain}toggle_timer/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          action: action,
          user_id: userDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        if (action === "start") {
          setIsActive(true);
          setTaskStartTime(
            moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A")
          );
          if (task?.work_flow_status?.name === "ToDo") {
            await moveToInProgressWithPay(taskid, task);
          }
          setCurrentPlayTaskId(taskid);
        } else {
          setIsActive(false);
          setCurrentPlayTaskId(0);
        }
        setFetchTask(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const moveToInProgressWithPay = async (taskid, task) => {
    try {
      const response = await fetch(`${domain}edit_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          status: "InProgress",
          workflow_status_id: task.work_flow_status.workflow,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const checkDoubleClick = (task, column) => {
    setTaskId(task.id);
  };

  const updateAssignee = async () => {
    try {
      const response = await fetch(`${domain}edit_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          new_assignee: userId,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setFetchTask(true);
        showToast("success", res.message);
        setTaskId(0);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleClick = (task) => {
    handleMenuClose();
    setOpenTaskDetails(true);
    setSelectedTask(task);
    setSelectedTaskid(task.id);
    setEditTask(task);
    setLabelBasedProjectId(task.project.id);
  };

  const handleMenuClose = () => {
    setAnchorMenu(null);
    setWorkflowStatusDetails(null);
    setEditTask(null);
  };

  const updateProjectWorkflowStatus = (task) => {
    if (task.work_flow_status.workflow !== "Completed") {
      setWorkflowStatusDetails(
        workflowStatus.filter(
          (status) => status.workflow === task.work_flow_status.workflow
        )
      );
      getProjectUnCompletedSprint(task);
    } else {
      setWorkflowStatusDetails(null);
    }
  };

  const getProjectUnCompletedSprint = async (task) => {
    try {
      const response = await fetch(
        `${domain}sprints/?project=${task.project.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setTaskSprintDetails(res.filter((data) => data.is_completed === false));
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateTaskStatus = async (Status) => {
    if (Status !== "Completed") {
      setOpenAssigneeDialogBox(true);
      setChangeAssigneeStatus(Status);
    } else {
      try {
        const response = await fetch(`${domain}edit_task/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            task_id: editTask.id,
            status: Status,
            workflow_status_id: editTask.work_flow_status.workflow,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setFetchTask(true);
          showToast("success", res.message);
          setWorkflowStatusDetails(null);
          setEditTask(null);
          setExpandedSubMenuAnchorEl(null);
          handleMenuClose();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const handleCloseAssignee = () => {
    setExpandedSubMenuAnchorEl(null);
    setOpenAssigneeDialogBox(false);
  };

  const updateMoveSprint = async (sprint) => {
    const selectedStatus = [
      {
        task_id: editTask.id,
      },
    ];
    try {
      const response = await fetch(`${domain}move_sprint_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          selected_tasks: selectedStatus,
          new_sprint_id: sprint.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setFetchTask(true);
        showToast("success", res.message);
        setWorkflowStatusDetails(null);
        setEditTask(null);
        handleMenuClose();
        setMoveMenuAnchorEl(null);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateTaskLabel = async (labelId, action, taskid) => {
    try {
      const response = await fetch(`${domain}update_task_label/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          label_id: labelId,
          action: action,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setFetchTask(true);
        showToast("success", res.message);
        setLavekMenuAnchorEl(null);
        setAnchorMenu(null);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
    setNewLabelColor(themes.primaryIndicator);
    setNewLabel("");
  };

  const showLabels = (labels, taskid) => {
    return labels.length > 0 ? (
      <Mui.AvatarGroup
        max={4}
        sx={{
          "& .MuiAvatar-root": {
            width: 30,
            height: 20,
            fontSize: 10,
            padding: "1px 5px",
            margin: "0 1px",
            marginLeft: "0px",
            borderRadius: 2,
          },
        }}
      >
        {labels.map((lab) => (
          <Mui.Tooltip title={lab.label_name}>
            <Mui.Avatar
              key={taskid}
              sx={{
                height: 20,
                textTransform: "capitalize",
                backgroundColor: lab.color_code
                  ? lab.color_code
                  : themes.primaryIndicator,
                "&:hover": {
                  backgroundColor: lab.color_code
                    ? lab.color_code
                    : themes.primaryIndicator,
                },
                color: getContrastingTextColor(
                  lab.color_code ? lab.color_code : themes.primaryIndicator
                ),
              }}
            >
              {String(lab.label_name).substring(0, 6)}
            </Mui.Avatar>
          </Mui.Tooltip>
        ))}
      </Mui.AvatarGroup>
    ) : null;
  };

  const createNewLabel = async (task) => {
    if (newLabel !== "") {
      try {
        const response = await fetch(`${domain}project_task_label/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            project: task.project.id,
            label_name: newLabel,
            color_code: newLabelColor,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", projectSuccess.createLabel);
          handleCloseDialogBox();
          setLavekMenuAnchorEl(null);
          setAnchorMenu(null);
          setLabelsApi(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  return (
    <React.Fragment>
      <Mui.TableRow
        key={task.id}
        ref={ref}
        style={{ cursor: "grab" }}
        maxWidth="xl"
        align="left"
        sx={{
          "& .MuiTableCell-root": {
            height: "15px",
            padding: "2px 15px",
          },
          backgroundColor: selectedTaskid === task.id ? "yellow" : null,
        }}
        hover={true}
      >
        <Mui.TableCell
          align="left"
          className={classes.tableCell}
          sx={{
            width: "30%",
            borderRight: 1,
            borderColor: themes.taskBorder,
            fontSize: 14,
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 100,
          }}
        >
          <Mui.Stack
            direction="row"
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            <Mui.Grid
              container
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <Mui.Grid
                xs={11.5}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                onClick={() => handleClick(task)}
              >
                <Mui.Stack
                  direction="row"
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                >
                  <Mui.Tooltip title={task.taskname}>
                    <Mui.Typography
                      sx={{
                        fontSize: 14,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        overflow: "hidden",
                      }}
                    >
                      <Mui.Stack
                        direction="row"
                        style={{ paddingTop: "0px", paddingBottom: "0px" }}
                      >
                        <Mui.Typography
                          sx={{
                            fontSize: 14,
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            color: themes.blackColor,
                            fontWeight: "700",
                            marginRight: 0.5,
                          }}
                        >
                          {task.task_code ? task.task_code : "N/A"}
                        </Mui.Typography>
                        <Mui.Typography
                          sx={{
                            fontSize: 14,
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {" " + task.taskname}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.Typography>
                  </Mui.Tooltip>
                  &nbsp;
                  {task?.task_type === "Child Task" ? (
                    <Mui.Tooltip title={task?.task_type}>
                      <AccountTree
                        style={{ fontSize: 14 }}
                        sx={{
                          width: 20,
                          height: 20,
                          mt: "3px",
                          textTransform: "capitalize",
                          color: themes.redColor,
                        }}
                      />
                    </Mui.Tooltip>
                  ) : null}
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid
                xs={0.5}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              >
                <Mui.IconButton
                  style={{ padding: "0px" }}
                  onClick={(e) => {
                    updateProjectWorkflowStatus(task);
                    setEditTask(task);
                    setAnchorMenu(e.currentTarget);
                    setLabelBasedProjectId(task.project.id);
                  }}
                >
                  <MoreVert />
                </Mui.IconButton>

                <Mui.Menu
                  anchorEl={anchorMenu}
                  open={Boolean(anchorMenu)}
                  onClose={handleMenuClose}
                >
                  <Mui.MenuItem
                    sx={{
                      backgroundColor: themes.primaryIndicator,
                      color: themes.headLine,
                      borderBottom: `2px solid ${themes.whiteColor}`,
                      "&:hover": {
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                  >
                    Labels
                    <Mui.IconButton
                      size="small"
                      sx={{ color: themes.headLine }}
                      onClick={(event) => {
                        setLavekMenuAnchorEl(event.currentTarget);
                      }}
                    >
                      <KeyboardArrowRight />
                    </Mui.IconButton>
                    <Mui.Popover
                      anchorEl={labelMenuAnchorEl}
                      open={Boolean(labelMenuAnchorEl)}
                      onClose={() => {
                        setLavekMenuAnchorEl(null);
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      PaperProps={{
                        style: {
                          maxHeight: "400px",
                          width: "120px",
                          padding: "5px",
                        },
                      }}
                    >
                      {taskLabels?.map((label) =>
                        label.label_name !== "Add label" ? (
                          <Mui.MenuItem
                            sx={{
                              backgroundColor: label.color_code
                                ? label.color_code
                                : themes.primaryIndicator,
                              color: getContrastingTextColor(
                                label?.color_code
                                  ? label?.color_code
                                  : themes.primaryIndicator
                              ),
                              borderBottom: `2px solid ${themes.whiteColor}`,
                              "&:hover": {
                                backgroundColor: label.color_code
                                  ? label.color_code
                                  : themes.primaryIndicator,
                              },
                            }}
                            onClick={() =>
                              updateTaskLabel(label?.id, "add", editTask.id)
                            }
                          >
                            {label?.label_name}
                          </Mui.MenuItem>
                        ) : (
                          <Mui.MenuItem
                            sx={{
                              justifyContent: "center",
                              backgroundColor: themes.primaryIndicator,
                              borderBottom: `2px solid ${themes.whiteColor}`,
                              "&:hover": {
                                backgroundColor: themes.primaryIndicator,
                              },
                            }}
                          >
                            <Mui.IconButton
                              size="small"
                              sx={{ backgroundColor: themes.iconColor }}
                              onClick={() => {
                                setLavekMenuAnchorEl(null);
                                setAnchorMenu(null);
                                setOpenDialog(true);
                              }}
                            >
                              <Add size="small" sx={{ fontSize: 15 }} />
                            </Mui.IconButton>
                          </Mui.MenuItem>
                        )
                      )}
                    </Mui.Popover>
                  </Mui.MenuItem>
                  <Mui.MenuItem
                    sx={{
                      backgroundColor: themes.primaryIndicator,
                      color: themes.headLine,
                      borderBottom: `2px solid ${themes.whiteColor}`,
                      "&:hover": {
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                    onClick={() => handleClick(editTask)}
                  >
                    Details
                  </Mui.MenuItem>
                  <Mui.Tooltip
                    title={
                      taskSprintDetails?.filter(
                        (status) => status?.name !== editTask?.sprint?.name
                      ).length === 0
                        ? "Please add new sprint!"
                        : ""
                    }
                  >
                    <Mui.MenuItem
                      sx={{
                        backgroundColor: themes.primaryIndicator,
                        color: themes.headLine,
                        borderBottom: `2px solid ${themes.whiteColor}`,
                        "&:hover": {
                          backgroundColor: themes.primaryIndicator,
                        },
                      }}
                      onClick={(event) => {
                        if (
                          taskSprintDetails?.filter(
                            (status) => status?.name !== editTask?.sprint?.name
                          ).length !== 0
                        ) {
                          setMoveMenuAnchorEl(event.currentTarget);
                        }
                      }}
                      disabled={
                        task.is_running ||
                        task?.work_flow_status?.name === "Completed"
                          ? true
                          : false
                      }
                    >
                      Move
                      {taskSprintDetails?.filter(
                        (status) => status?.name !== editTask?.sprint?.name
                      ).length !== 0 && (
                        <Mui.IconButton
                          size="small"
                          sx={{ color: themes.headLine }}
                          onClick={(event) => {
                            setMoveMenuAnchorEl(event.currentTarget);
                          }}
                        >
                          <KeyboardArrowRight />
                        </Mui.IconButton>
                      )}
                    </Mui.MenuItem>
                  </Mui.Tooltip>
                  <Mui.Popover
                    anchorEl={moveMenuAnchorEl}
                    open={Boolean(moveMenuAnchorEl)}
                    onClose={() => {
                      setMoveMenuAnchorEl(null);
                    }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        width: "120px",
                        padding: "5px",
                      },
                    }}
                  >
                    {taskSprintDetails?.map((status) =>
                      status?.name !== editTask?.sprint?.name ? (
                        <Mui.MenuItem
                          sx={{
                            backgroundColor: themes.primaryIndicator,
                            color: themes.headLine,
                            borderBottom: `2px solid ${themes.whiteColor}`,
                            "&:hover": {
                              backgroundColor: themes.primaryIndicator,
                            },
                          }}
                          onClick={() => updateMoveSprint(status)}
                        >
                          {status.name}
                        </Mui.MenuItem>
                      ) : null
                    )}
                  </Mui.Popover>

                  <Mui.MenuItem
                    sx={{
                      backgroundColor: themes.primaryIndicator,
                      color: themes.headLine,
                      borderBottom: `2px solid ${themes.whiteColor}`,
                      "&:hover": {
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                    onClick={(event) => {
                      setExpandedSubMenuAnchorEl(event.currentTarget);
                    }}
                    disabled={task.is_running ? true : false}
                  >
                    Status
                    <Mui.IconButton
                      size="small"
                      sx={{ color: themes.headLine }}
                      onClick={(event) => {
                        setExpandedSubMenuAnchorEl(event.currentTarget);
                      }}
                    >
                      <KeyboardArrowRight />
                    </Mui.IconButton>
                  </Mui.MenuItem>
                  <Mui.Popover
                    anchorEl={expandedSubMenuAnchorEl}
                    open={Boolean(expandedSubMenuAnchorEl)}
                    onClose={() => {
                      setExpandedSubMenuAnchorEl(null);
                    }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        width: "120px",
                        padding: "5px",
                      },
                    }}
                  >
                    {workflowStatusDetails?.map((status) => (
                      <Mui.MenuItem
                        sx={{
                          backgroundColor: themes.primaryIndicator,
                          color: themes.headLine,
                          borderBottom: `2px solid ${themes.whiteColor}`,
                          "&:hover": {
                            backgroundColor: themes.primaryIndicator,
                          },
                        }}
                        onClick={() => updateTaskStatus(status.name)}
                      >
                        {status.name}
                      </Mui.MenuItem>
                    ))}
                  </Mui.Popover>
                  <Mui.MenuItem
                    sx={{
                      backgroundColor: themes.primaryIndicator,
                      borderBottom: `2px solid ${themes.whiteColor}`,
                      color: themes.headLine,
                      "&:hover": {
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                  >
                    Invoice
                  </Mui.MenuItem>
                  <Mui.MenuItem
                    sx={{
                      backgroundColor: themes.primaryIndicator,
                      color: themes.headLine,
                      borderBottom: `2px solid ${themes.whiteColor}`,
                      "&:hover": {
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                    onClick={() => setActiveTap("ShowMaterialScreen")}
                  >
                    Material
                  </Mui.MenuItem>
                </Mui.Menu>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Stack>
        </Mui.TableCell>
        <Mui.TableCell
          className={classes.tableCell}
          sx={{
            width: "13%",
            borderRight: 1,
            borderColor: themes.taskBorder,
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 100,
          }}
        >
          <Mui.Paper
            elevation={0}
            sx={{ p: "0px 0px", display: "flex", alignItems: "center" }}
          >
            {task.labels.length > 0 ? showLabels(task.labels, task.id) : null}
          </Mui.Paper>
        </Mui.TableCell>
        <Mui.TableCell
          align="left"
          className={classes.tableCell}
          sx={{
            width: "13%",
            borderRight: 1,
            borderColor: themes.taskBorder,
            fontSize: 14,
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 100,
          }}
          onDoubleClick={() =>
            !task.is_running ? checkDoubleClick(task, "ChangeUser") : null
          }
        >
          <Mui.Stack
            direction="row"
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            {taskid !== task.id ? (
              <>
                <Mui.Avatar
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: themes.primaryIndicator,
                  }}
                  alt={task.current_assignee?.username.charAt(0).toUpperCase()}
                  src={task.current_assignee?.image_url}
                />
                <Mui.Typography
                  sx={{
                    paddingLeft: 1,
                    fontSize: 14,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {task.current_assignee?.username}
                </Mui.Typography>
              </>
            ) : (
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                fullWidth
                value={userName}
                options={
                  editProject?.projectType === "Private"
                    ? priProjMembers
                    : allUsers
                }
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.username
                      ?.toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Mui.Avatar
                        sx={{ width: 20, height: 20 }}
                        alt={option.username.charAt(0).toUpperCase()}
                        src={option.image_url}
                      />{" "}
                      &nbsp;{option.username}
                    </li>
                  );
                }}
                onChange={(event, value) => {
                  if (value) {
                    setUserName(value.username);
                    setUserId(value.id);
                  } else {
                    setUserName(null);
                    setUserId(null);
                  }
                }}
                sx={{
                  minWidth: "100%",
                  fontSize: 14,
                }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    value={userName}
                    size="small"
                    sx={{ background: themes.whiteColor, fontSize: 14 }}
                    name="username"
                    placeholder={projectPlaceholder.selectUser}
                    onBlur={(event) => {
                      if (
                        event.target.value !==
                          task?.current_assignee?.username &&
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        updateAssignee();
                      } else {
                        setTaskId(0);
                        setUserName(null);
                      }
                    }}
                    autoFocus
                  />
                )}
              />
            )}
          </Mui.Stack>
        </Mui.TableCell>
        <Mui.TableCell
          align="left"
          className={classes.tableCell}
          sx={{
            width: "11%",
            borderRight: 1,
            borderColor: themes.taskBorder,
            fontSize: 14,
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 100,
          }}
        >
          <Mui.Paper
            elevation={0}
            component="form"
            sx={{ p: "0px 0px", display: "flex", alignItems: "center" }}
          >
            <Mui.IconButton style={{ padding: "0px" }}>
              <AvTimer />
            </Mui.IconButton>
            <Mui.InputBase
              sx={{ ml: 1, flex: 1, fontSize: 14 }}
              value={
                currentPlayTaskId === task.id ? durationTime : task.total_time
              }
              disabled={currentPlayTaskId === task.id ? false : true}
            />
            <Mui.IconButton
              color="primary"
              size="small"
              sx={{
                padding: "2px",
                borderRadius: 3,
                backgroundColor:
                  currentPlayTaskId === task.id
                    ? themes.onleaveTap
                    : themes.primaryIndicator,
                color: themes.backgroundWhite,
                ":hover": {
                  backgroundColor:
                    currentPlayTaskId === task.id
                      ? themes.onleaveTap
                      : themes.primaryIndicator,
                },
              }}
              onClick={() => {
                if (isActive) {
                  handleWorkingTime(task.id, "pause", task);
                } else {
                  setDuration(null);
                  handleWorkingTime(task.id, "start", task);
                }
              }}
              disabled={
                userDetails.id === task.current_assignee?.id &&
                status !== "Completed" &&
                !task.sprint?.is_completed &&
                task.sprint?.name !== "Backlog"
                  ? false
                  : true
              }
            >
              {currentPlayTaskId === task.id ? (
                <PauseCircle size="small" sx={{ fontSize: 14 }} />
              ) : !task.is_running ? (
                <PlayArrow size="small" sx={{ fontSize: 14 }} />
              ) : (
                <Mui.Tooltip title="In Running">
                  <StopCircle
                    size="small"
                    sx={{ fontSize: 18, color: themes.greenColor }}
                  />
                </Mui.Tooltip>
              )}
            </Mui.IconButton>
          </Mui.Paper>
        </Mui.TableCell>
        <Mui.TableCell
          className={classes.tableCell}
          align="left"
          sx={{
            width: "13%",
            borderRight: 1,
            borderColor: themes.taskBorder,
            fontSize: 14,
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 100,
          }}
        >
          <Mui.Stack direction="row">
            <Mui.Avatar
              sx={{
                width: 20,
                height: 20,
                backgroundColor: themes.primaryIndicator,
              }}
              alt={task?.task_owner?.username.charAt(0).toUpperCase()}
              src={task?.task_owner?.image_url}
            />
            <Mui.Typography
              sx={{
                paddingLeft: 1,
                fontSize: 14,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {task?.task_owner?.username}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.TableCell>
        <Mui.TableCell
          align="left"
          className={classes.tableCell}
          sx={{
            width: "10%",
            borderRight: 1,
            borderColor: themes.taskBorder,
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 100,
          }}
        >
          <Mui.Typography
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              fontSize: 14,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {task?.due_date ? task?.due_date : "N/A"}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell
          align="left"
          className={classes.tableCell}
          sx={{
            width: "10%",
            borderRight: 1,
            borderColor: themes.taskBorder,
            color: priorityColor(task.priority?.name),
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 100,
          }}
        >
          <Mui.Typography
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              fontSize: 14,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {task.priority?.name}
          </Mui.Typography>
        </Mui.TableCell>
      </Mui.TableRow>
      {openTaskDetails && selectedTaskid !== null ? (
        <TaskDetails
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          selectedTaskid={selectedTaskid}
          setSelectedTaskid={setSelectedTaskid}
          setActiveTap={setActiveTap}
          currentStatus={status}
        />
      ) : null}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        minWidth={400}
        title="Add Label"
      >
        <Mui.Stack direction="row" sx={{ paddingTop: 2 }}>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
              <Mui.Typography>Label Name</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={0.4} sx={{ paddingTop: 1 }}>
              <Mui.Typography>:</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={7.6}>
              <Mui.TextField
                placeholder="Enter label name"
                onChange={(event) => setNewLabel(event.target.value)}
                InputProps={{
                  inputProps: { maxLength: 32 },
                }}
                size="small"
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Stack>
        <br />
        <Mui.Stack direction="row">
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
              <Mui.Typography>Label Color</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={0.4} sx={{ paddingTop: 1 }}>
              <Mui.Typography>:</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={7.6}>
              <Mui.TextField
                type="color"
                value={newLabelColor}
                onChange={(event) => setNewLabelColor(event.target.value)}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Stack>
        <Mui.Stack direction="row" sx={{ paddingTop: 2 }}>
          <Mui.Grid container>
            <Mui.Grid xs={6}></Mui.Grid>
            <Mui.Grid
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  setNewLabelColor(themes.primaryIndicator);
                  setNewLabel("");
                }}
                actionName="Cancel"
                typeName="button"
              />
              <CustomButton
                actionFuntion={() => createNewLabel(task)}
                actionName="Add"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Stack>
      </GlobalDialogBox>
      {openAssigneeDialogBox ? (
        <ChangeAssignee
          open={openAssigneeDialogBox}
          status={changeAssigneeStatus}
          handleClose={handleCloseAssignee}
          editTask={editTask}
          option={"Task"}
        />
      ) : null}
    </React.Fragment>
  );
};

export default DraggableCard;
