import { createSlice } from "@reduxjs/toolkit";

export const leaveManagementReducer = createSlice({
  name: "token",
  initialState: {
    currentScreen: "Status",
    reLoadApi: false,
    editproject: JSON.parse(localStorage.getItem("currentEditProject"))
      ? JSON.parse(localStorage.getItem("currentEditProject"))
      : null,
    reloadHRMSApi: false,
    allStatus: false,
    activeStatus: true,
    onLeaveStatus: true,
    onBreakStatus: true,
    projectStatus: ["ToDo", "InProgress", "Review", "Testing", "Completed"],
  },
  reducers: {
    setCurrentLeaveManagementScreen: (state, action) => {
      state.currentScreen = action.payload;
    },
    setReloadApi: (state, action) => {
      state.reLoadApi = action.payload;
    },
    setEditProject: (state, action) => {
      if (action.payload !== null) {
        state.editproject = action.payload;
      }
    },
    setProjectStatus: (state, action) => {
      state.projectStatus = action.payload;
    },
    setReloadHRMSApi: (state, action) => {
      state.reloadHRMSApi = action.payload;
    },
    setAllStatus: (state, action) => {
      state.allStatus = action.payload;
    },
    setActiveStatus: (state, action) => {
      state.activeStatus = action.payload;
    },
    setLeaveStatus: (state, action) => {
      state.onLeaveStatus = action.payload;
    },
    setOnBreakStatus: (state, action) => {
      state.onBreakStatus = action.payload;
    },
  },
});

export const {
  setCurrentLeaveManagementScreen,
  setLeaveManagementTapIndicator,
  setReloadApi,
  setEditProject,
  setProjectStatus,
  setReloadHRMSApi,
  setAllStatus,
  setActiveStatus,
  setLeaveStatus,
  setOnBreakStatus,
} = leaveManagementReducer.actions;

export default leaveManagementReducer.reducer;
