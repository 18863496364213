import React, { useState, useEffect } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { MoreVert } from "@mui/icons-material";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomTextBox } from "components/CustomTextBox";
import { useForm } from "react-hook-form";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { CustomButton } from "components/CustomButton";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
import { Loader } from "components/Loader";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
const keys = ["name"];
export const Expense = ({
  companyId,
  reloadData,
  setReloadData,
  selectedComponent,
  currentScreen,
  companyChange,
  setCompanyChange,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editExpenseDetails, setEditExpenseDetails] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [expenseDetails, setExpenseDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [deleteExpense, setDeleteExpense] = useState(false);

  const {
    result: expenseData,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}expenses/?company=${companyId}` : null
  );

  useEffect(() => {
    if (reloadData && selectedComponent === "Expense") {
      reCallApi(true);
      setReloadData(false);
    }
  });

  useEffect(() => {
    if (
      companyChange === true &&
      currentScreen === "Salary Component" &&
      selectedComponent === "Expense"
    ) {
      reCallApi(true);
      setCompanyChange(false);
    }
  }, [
    companyChange,
    currentScreen,
    reCallApi,
    selectedComponent,
    setCompanyChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const updateComponentName = (event) => {
    if (event.target.value.length <= 64) {
      setComponentName(event.target.value);
      setValue("component_name", event.target.value);
    }
  };

  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        `${domain}expenses/${editExpenseDetails.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: data.component_name,
            company: companyId,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        handleCloseDialogAndDetails();
        reCallApi(true);
        showToast("success", res.message);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleCloseDialogAndDetails = () => {
    setOpenDialog(false);
    setTimeout(function () {
      setComponentName("");
      reset();
    }, 100);
  };

  useEffect(() => {
    if (updateData && expenseData?.length !== 0 && expenseData) {
      setExpenseDetails(expenseData);
      setUpdateData(false);
    }
  }, [expenseData, updateData]);

  useEffect(() => {
    if (expenseData?.length !== 0 || expenseData?.length === 0) {
      const filteredResults = expenseData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setExpenseDetails(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, expenseData]);

  const handleDeleteExpense = async () => {
    try {
      const response = await fetch(
        `${domain}expenses/${editExpenseDetails.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            isdeleted: true,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setDeleteExpense(false);
        showToast("success", res.message);
        reCallApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1 }}>
        {expenseDetails?.length === 0 ? (
          <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
            No data found
          </Mui.Alert>
        ) : (
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {expenseDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((expense, index) => (
                    <Mui.TableRow>
                      <Mui.TableCell>{expense.name}</Mui.TableCell>
                      <Mui.TableCell>
                        <Mui.IconButton
                          onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                            setEditExpenseDetails(expense);
                            setComponentName(expense.name);
                            setValue("component_name", expense.name);
                          }}
                        >
                          <MoreVert
                            sx={{
                              color: themes.blackColor,
                              strokeWidth: 2,
                            }}
                          />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                            onClick={() => {
                              handleMenuClose();
                              setOpenDialog(true);
                            }}
                          >
                            Edit
                          </Mui.MenuItem>
                          <Mui.MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                              setDeleteExpense(true);
                            }}
                          >
                            Delete
                          </Mui.MenuItem>
                        </Mui.Menu>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        )}
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
          setTimeout(function () {
            setComponentName("");
            reset();
          }, 100);
        }}
        open={openDialog}
        title="Edit Expense"
        minWidth={550}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Component Name</Mui.Typography>
            <CustomTextBox
              selectedValue={componentName}
              rules={{
                ...register("component_name", {
                  required: "Component name is required",
                  minLength: {
                    value: 3,
                    message: "Minium length is 3 characters",
                  },
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    uniqueNameCheck: async (value) => {
                      if (editExpenseDetails.name !== value) {
                        const response = await fetch(
                          `${domain}expense/unique`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `token ${token}`,
                            },
                            body: JSON.stringify({
                              company: companyId,
                              name: value.trim(),
                            }),
                          }
                        );
                        const res = await response.json();
                        if (res.exists) {
                          return "Expense name already exists";
                        }
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateComponentName}
              placeHolder="Enter the component name"
              errorMessage={
                errors?.component_name?.message
                  ? errors?.component_name?.message
                  : ""
              }
              textBoxError={errors.component_name ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
                setTimeout(function () {
                  setComponentName("");
                  reset();
                }, 100);
              }}
              actionName="Cancel"
              typeName="button"
            />{" "}
            &nbsp;
            <CustomButton
              actionFuntion={handleSubmit(onSubmit)}
              actionName="Update"
              typeName="submit"
            />
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={() => setDeleteExpense(false)}
        open={deleteExpense}
        title="Delete Expense"
        minWidth={600}
      >
        <Mui.Grid container>
          <Mui.Typography>
            Do you want to delete the {editExpenseDetails?.name} ?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            actionFuntion={() => setDeleteExpense(false)}
            actionName="Cancel"
            typeName="button"
          />{" "}
          &nbsp;
          <CustomButton
            actionFuntion={() => handleDeleteExpense()}
            actionName="Delete"
            typeName="button"
          />
        </Mui.Grid>
      </GlobalDialogBox>
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {expenseDetails.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={expenseDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
