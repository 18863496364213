import React from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import deviceAsset from "assets/img/brand/device.png";

export const Device = () => {
  return (
    <React.Fragment>
      <Mui.Grid sx={{ marginLeft: 3 }}>
        <Mui.Card
          style={{
            display: "flex",
            alignSelf: "center",
            paddingLeft: "10px",
            alignItems: "center",
            justifyContent: "center",
            width: "98%",
            height: 60,
            borderRadius: "20px",
            color: themes.headLine,
          }}
        >
          <Mui.Typography
            sx={{
              fontSize: 24,
              fontWeight: 800,
              color: themes.blueColor,
            }}
          >
            One or more biometric device is offline
          </Mui.Typography>
        </Mui.Card>
      </Mui.Grid>
      <Mui.Grid containter sx={{ paddingTop: 12 }}>
        <Mui.Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={deviceAsset}
            alt=""
            style={{ height: "45%", width: "45%" }}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
