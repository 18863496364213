import React from "react";
import { Grid, Paper } from "@mui/material";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
export const SettingsMenu = ({ setCurrentScreen, currentScreen }) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid conatiner spacing={2} sx={{ marginTop: 5 }}>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Leave Period"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Leave Period" ? themes.formBackground : null,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("settingScreens", "Leave Period");
              setCurrentScreen("Leave Period");
            }}
          >
            Leave Period
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Leave Type"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Leave Type" ? themes.formBackground : null,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("settingScreens", "Leave Type");
              setCurrentScreen("Leave Type");
            }}
          >
            Leave Type
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Leave Group"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Leave Group" ? themes.formBackground : null,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("settingScreens", "Leave Group");
              setCurrentScreen("Leave Group");
            }}
          >
            Leave Group
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Site Settings"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Site Settings"
                  ? themes.formBackground
                  : null,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("settingScreens", "Site Settings");
              setCurrentScreen("Site Settings");
            }}
          >
            Site Settings
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
