import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { attendanceFilterList, attendanceFilterCard } from "services/constants";
import { showToast } from "components/Status";
import ListIcon from "@mui/icons-material/List";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { ListView } from "./ListView";
import { CardView } from "./CardView";
import { AddDepartment } from "./AddDepartment";
import {
  setDepartmentId,
  setDepartmentName,
  setAttendanceScreenView,
} from "services/Redux/userManagament";
import { useDispatch } from "react-redux";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";

export const Attendance = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { token, domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const { attendanceScreenView } = useSelector((state) => state.userReducer);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentApi, setDepartmentApi] = useState(true);
  const [allDepartmentDetails, setAllDepartmentDetails] = useState({});
  const [userDataViewMethod, setUserDataViewMethod] =
    useState(attendanceScreenView);
  const [loader, setLoader] = useState(false);
  const [listViewPage, setListViewPage] = useState(0);
  const [Page, setLeavePage] = useState(0);

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchTeam = async () => {
      setLoader(true);
      try {
        const response = await fetch(
          `${domain}department/details?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setMasterDataForSearch(res.departments);
          setDepartmentList(res.departments);
          setAllDepartmentDetails(res.all);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
      setLoader(false);
    };
    if (departmentApi) {
      fetchTeam();
      setDepartmentApi(false);
    }
  }, [departmentApi, domain, token, userDetails.id]);

  useEffect(() => {
    let filterKeys =
      userDataViewMethod === "CardView"
        ? attendanceFilterCard
        : attendanceFilterList;
    const filteredResults = masterDataForSearch.filter((item) =>
      filterKeys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setDepartmentList(filteredResults);
    setListViewPage(0);
    setLeavePage(0);
  }, [globalSearchValue, masterDataForSearch, userDataViewMethod]);

  return (
    <React.Fragment>
      {open ? (
        <AddDepartment
          open={open}
          setOpen={setOpen}
          setDepartmentApi={setDepartmentApi}
        />
      ) : null}
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Grid xs={11.5}>
          {userDataViewMethod === "PlanShift" ? (
            <Mui.IconButton
              size="small"
              onClick={() => {
                dispatch(setAttendanceScreenView("CardView"));
                setUserDataViewMethod("CardView");
              }}
            >
              <ArrowCircleLeftIcon sx={{ fontSize: 30 }} />
            </Mui.IconButton>
          ) : null}
        </Mui.Grid>

        <Mui.Grid xs={0.5}>
          {userDataViewMethod !== "PlanShift" ? (
            <Mui.IconButton
              aria-label="close"
              sx={{
                marginTop: -1,
              }}
              onClick={() => {
                dispatch(
                  setAttendanceScreenView(
                    userDataViewMethod === "ListView" ? "CardView" : "ListView"
                  )
                );
                setUserDataViewMethod(
                  userDataViewMethod === "ListView" ? "CardView" : "ListView"
                );
              }}
            >
              <Mui.Tooltip
                key={userDataViewMethod}
                title={
                  userDataViewMethod === "CardView" ? "ListView" : "CardView"
                }
                placement="bottom"
              >
                {userDataViewMethod === "CardView" ? (
                  <ListIcon
                    style={{
                      fontSize: 40,
                      color: themes.bgIconColor,
                      fontWeight: "bold",
                    }}
                  />
                ) : (
                  <CreditCardIcon
                    style={{
                      fontSize: 40,
                      color: themes.bgIconColor,
                      fontWeight: "bold",
                    }}
                  />
                )}
              </Mui.Tooltip>
            </Mui.IconButton>
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingLeft: 7, paddingRight: 7 }}>
        <Mui.Card
          elevation={5}
          style={{
            width: "100%",
            height: 68,
            fontSize: 18,
            fontWeight: "bold",
            borderRadius: 20,
            color: themes.headLine,
            backgroundColor: "white",
          }}
        >
          <Mui.Grid container>
            <Mui.Grid
              item
              xs={1.7}
              sx={{ borderRight: `2px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Typography
                sx={{
                  fontSize: 18,
                  color: themes.primaryIndicator,
                  textAlign: "center",
                  paddingTop: 2,
                }}
              >
                All Department
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={1.7}
              sx={{ borderRight: `2px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Stack>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    textAlign: "center",
                    paddingTop: 1,
                  }}
                >
                  Total no of staffs
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {allDepartmentDetails?.total_staff_count}
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={1.7}
              sx={{ borderRight: `2px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Stack>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    textAlign: "center",
                    paddingTop: 1,
                  }}
                >
                  No of staffs planned
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    textAlign: "center",
                  }}
                >
                  {allDepartmentDetails?.staff_planned_count}
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={1.7}
              sx={{ borderRight: `2px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Stack>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    textAlign: "center",
                    paddingTop: 1,
                  }}
                >
                  No of staffs present
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    textAlign: "center",
                  }}
                >
                  {allDepartmentDetails?.staff_present_count}
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={1.7}
              sx={{ borderRight: `2px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Stack>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    textAlign: "center",
                    paddingTop: 1,
                  }}
                >
                  No of staffs overtime
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    textAlign: "center",
                  }}
                >
                  {allDepartmentDetails?.staff_overtime_count}
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={1.7}
              sx={{ borderRight: `2px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Stack>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    textAlign: "center",
                    paddingTop: 1,
                  }}
                >
                  No of staffs missing
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    textAlign: "center",
                  }}
                >
                  {allDepartmentDetails?.staff_missing_count
                    ? allDepartmentDetails?.staff_missing_count
                    : "0"}
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid item xs={1.7}>
              <Mui.Stack>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    textAlign: "center",
                    paddingTop: 1,
                  }}
                >
                  No of staffs absent
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    textAlign: "center",
                  }}
                >
                  {allDepartmentDetails?.staff_absent_count}
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Card>
      </Mui.Grid>
      {loader ? (
        <Loader info="Loading..." />
      ) : userDataViewMethod === "ListView" ? (
        <ListView
          departmentList={departmentList}
          setDepartmentList={setDepartmentList}
          listViewPage={listViewPage}
          setListViewPage={setListViewPage}
        />
      ) : (
        <CardView
          departmentList={departmentList}
          setLeavePage={setLeavePage}
          Page={Page}
        />
      )}
    </React.Fragment>
  );
};
