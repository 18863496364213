import React, { useState } from "react";
import * as Mui from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomButton";
const payScheduleKeyPair = [
  {
    key: "name",
    name: "Payrun name",
  },
  {
    key: `${"start_date"}-${"end_date"}`,
    name: "Payrun period",
  },
  {
    key: "run_date",
    name: "Pay date",
  },
  {
    key: "action",
    name: "Action",
  },
];

export const ApproveOverTime = () => {
  const [overTimeDetails, setOverTimeDetails] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [listViewPage, setListViewPage] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  return (
    <React.Fragment>
      {overTimeDetails?.length === 0 ? (
        <>
          <Mui.Grid container xs={12} sx={{ paddingTop: 1 }}></Mui.Grid>
          <Mui.Alert severity="info" sx={{ margin: 2 }}>
            No data available
          </Mui.Alert>
        </>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 2, width: "100%", paddingRight: 1 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                {payScheduleKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {overTimeDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((department) => (
                  <Mui.TableRow>
                    {payScheduleKeyPair.map((header) =>
                      header.name !== "Action" &&
                      header.name !== "Payrun period" ? (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          {department[header.key]
                            ? department[header.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ) : header.name === "Action" ? (
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              //   setEditPayScheduleDetails(department);
                            }}
                          >
                            <MoreVertIcon
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                            //   onClick={() => setEditPaySchedule(true)}
                            >
                              Edit
                            </Mui.MenuItem>
                            <Mui.MenuItem onClick={() => setOpenDialog(true)}>
                              Delete
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      ) : (
                        <Mui.TableCell>
                          {department.start_date}-{department.end_date}
                        </Mui.TableCell>
                      )
                    )}
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {overTimeDetails.length > 20 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[20, 50, 100]}
              count={overTimeDetails.length}
              rowsPerPage={rowsPerPage}
              page={listViewPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      )}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
