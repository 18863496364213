import React, { useEffect, createContext, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import sessionHandling from "services/utils/notificationUtils";

export const SprintDashboardContext = createContext({});

export const SprintDashboardContextProvider = ({ children }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const history = useHistory();
  const editProjectScreenData = useSelector(
    (state) => state.projectReducer.editProjectScreenData
  );
  const [reloadSprDash, setReloadSprDash] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [sprintDetails, setSprintDetails] = useState(null);
  const [memberCount, setMemberCount] = useState(0);

  const getMembersCount = useCallback(
    async (sprintDet) => {
      try {
        const response = await fetch(
          `${domain}sprint_member_count/?sprint_id=${sprintDet?.id}&project_id=${sprintDet?.project}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setMemberCount(res.user_count);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setDataLoading(false);
      }
    },
    [domain, token]
  );

  const fetchSprintData = useCallback(
    async (spr_data) => {
      try {
        const url = `${domain}get-sprint-details/?sprint_id=${spr_data?.id}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setSprintDetails(res[0]);
          getMembersCount(res[0]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setReloadSprDash(false);
        setDataLoading(false);
      }
    },
    [domain, token, getMembersCount]
  );

  useEffect(() => {
    const location_isValid = localStorage.getItem("sprDashboard");
    const sprintDet = JSON.parse(localStorage.getItem("sprDashData"))
      ? JSON.parse(localStorage.getItem("sprDashData"))
      : null;
    if (location_isValid === "false" || reloadSprDash) {
      fetchSprintData(sprintDet);
    } else {
      setSprintDetails(sprintDet);
      getMembersCount(sprintDet);
      localStorage.setItem("sprDashboard", false);
    }
  }, [
    history,
    editProjectScreenData,
    fetchSprintData,
    reloadSprDash,
    getMembersCount,
  ]);

  return (
    <SprintDashboardContext.Provider
      value={{
        token,
        domain,
        sprintDetails,
        dataLoading,
        setReloadSprDash,
        reloadSprDash,
        memberCount,
      }}
    >
      {children}
    </SprintDashboardContext.Provider>
  );
};
