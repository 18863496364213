import React from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import TimelineItem from "@mui/lab/TimelineItem";
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
export const PageSideBar = ({ pageNo }) => {
  return (
    <React.Fragment>
      <Timeline
        position="left"
      >
        <TimelineItem>
          <TimelineSeparator sx={{ height: 150 }}>
            <TimelineDot
              sx={{
                backgroundColor:
                  pageNo >= 1 ? themes.primaryIndicator : themes.iconColor,
                border: `3px solid ${themes.colorShadow}`,
              }}
            >
              <Mui.Stack
                sx={{
                  display: "flex",
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                  textAlignLast: "center",
                }}
              >
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    paddingTop: 0.5,
                    color: themes.blackColor,
                  }}
                >
                  1
                </Mui.Typography>
              </Mui.Stack>
            </TimelineDot>
            <TimelineConnector sx={{ width: 4 }} />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ color: themes.colorShadow, fontWeight: "bold" }}>
              Step 1
            </Typography>
            <Typography sx={{ fontSize: 16 }} component="span">
              Basic Details
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator sx={{ height: 150 }}>
            <TimelineDot
              sx={{
                backgroundColor:
                  pageNo >= 2 ? themes.primaryIndicator : themes.iconColor,
                border: `3px solid ${themes.colorShadow}`,
              }}
            >
              <Mui.Stack
                sx={{
                  // display: "flex",
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                  textAlignLast: "center",
                }}
              >
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    paddingTop: 0.5,
                    color: themes.blackColor,
                  }}
                >
                  2
                </Mui.Typography>
              </Mui.Stack>
            </TimelineDot>
            <TimelineConnector sx={{ width: 4 }} />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ color: themes.colorShadow, fontWeight: "bold" }}>
              Step 2
            </Typography>
            <Typography sx={{ fontSize: 16 }} component="span">
              Personal Details
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator sx={{ height: 150 }}>
            <TimelineDot
              sx={{
                backgroundColor:
                  pageNo >= 3 ? themes.primaryIndicator : themes.iconColor,
                border: `3px solid ${themes.colorShadow}`,
              }}
            >
              <Mui.Stack
                sx={{
                  // display: "flex",
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                  textAlignLast: "center",
                }}
              >
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    paddingTop: 0.5,
                    color: themes.blackColor,
                  }}
                >
                  3
                </Mui.Typography>
              </Mui.Stack>
            </TimelineDot>
            <TimelineConnector sx={{ width: 4 }} />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ color: themes.colorShadow, fontWeight: "bold" }}>
              Step 3
            </Typography>
            <Typography sx={{ fontSize: 16 }} component="span">
              Contact Details
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator sx={{ height: 150 }}>
            <TimelineDot
              sx={{
                backgroundColor:
                  pageNo >= 4 ? themes.primaryIndicator : themes.iconColor,
                border: `3px solid ${themes.colorShadow}`,
              }}
            >
              <Mui.Stack
                sx={{
                  display: "flex",
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                  textAlignLast: "center",
                }}
              >
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    paddingTop: 0.5,
                    color: themes.blackColor,
                  }}
                >
                  4
                </Mui.Typography>
              </Mui.Stack>
            </TimelineDot>
            <TimelineConnector sx={{ width: 4 }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography sx={{ color: themes.colorShadow, fontWeight: "bold" }}>
              Step 4
            </Typography>
            <Typography sx={{ fontSize: 16 }} component="span">
              Bank Details
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor:
                  pageNo >= 5 ? themes.primaryIndicator : themes.iconColor,
                border: `3px solid ${themes.colorShadow}`,
              }}
            >
              <Mui.Stack
                sx={{
                  display: "flex",
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                  textAlignLast: "center",
                }}
              >
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    paddingTop: 0.5,
                    color: themes.blackColor,
                  }}
                >
                  5
                </Mui.Typography>
              </Mui.Stack>
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography sx={{ color: themes.colorShadow, fontWeight: "bold" }}>
              Step 5
            </Typography>
            <Typography sx={{ fontSize: 16 }} component="span">
              Salary Details
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </React.Fragment>
  );
};
