import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { CustomButton } from "components/CustomButton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Close, Check } from "@mui/icons-material";
import { themes } from "services/constants";
import { projectSuccess } from "services/constants/SuccessMessages";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/BackArrow";
import { useDispatch } from "react-redux";

const formatDate = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

export const EditProject = () => {
  const dispatch = useDispatch();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const editProjectScreenData = useSelector(
    (state) => state.projectReducer.editProjectScreenData
  );
  const [members, setMembers] = useState([]);
  const [projectLead, setProjectLead] = useState("");
  const [projectLeadId, setProjectLeadId] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [autocompleteVisible, setAutocompleteVisible] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [clientName, setClientName] = useState("");
  const [allClients, setAllClient] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [allCompanies, setAllCompanies] = useState([]);
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    multiple: true,
    options:
      projectLead === ""
        ? members
        : members?.filter((data) => {
            return data.username !== projectLead;
          }),
    getOptionLabel: (option) => option.username,
    value: selectedMembers,
    onChange: (event, newValue) => {
      setSelectedMembers(newValue);
    },
  });

  const history = useHistory();

  useEffect(() => {
    const location_isValid = localStorage.getItem("editProject");
    if (location_isValid === "false") {
      dispatch(setGlobalSearchValue(""));
      history.push("/admin/project-management");
    } else {
      localStorage.setItem("editProject", false);
    }
  }, [dispatch, history]);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("projectname", editProjectScreenData?.projectname);
    setValue("projectcode", editProjectScreenData?.project_code);
    setValue("description", editProjectScreenData?.description);
    setValue("startdate", editProjectScreenData?.date);
    setValue(
      "enddate",
      editProjectScreenData?.target_date
        ? editProjectScreenData?.target_date
        : new Date().toISOString().substring(0, 10)
    );
    setStartDate(
      editProjectScreenData?.date
        ? formatDate(editProjectScreenData?.date)
        : new Date().toISOString().substring(0, 10)
    );
    setDueDate(
      editProjectScreenData?.target_date
        ? formatDate(editProjectScreenData?.target_date)
        : new Date().toISOString().substring(0, 10)
    );
    setSelectedMembers(editProjectScreenData?.members);
    setValue("clientname", editProjectScreenData?.client?.clientname);
    setClientName(editProjectScreenData?.client?.clientname);
    setClientId(editProjectScreenData?.client?.id);
    setCompanyId(editProjectScreenData?.company?.id);
    setCompanyName(editProjectScreenData?.company?.companyname);
    setValue("company", editProjectScreenData?.company?.companyname);
    setValue(
      "estimateAmount",
      editProjectScreenData?.estimation ? editProjectScreenData?.estimation : 0
    );
    setProjectLead(editProjectScreenData?.project_lead_name);
    setProjectLeadId(editProjectScreenData?.projectlead);
    setValue("projectLead", editProjectScreenData?.projectlead);
    setAutocompleteVisible(
      editProjectScreenData?.projectType === "Private" ? true : false
    );
  }, [editProjectScreenData, setValue]);

  useEffect(() => {
    const getMembers = async () => {
      const response = await fetch(`${domain}selected_members/`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        const memberList = res.map((obj) => ({
          ...obj,
          label: obj.username,
          username: obj.username,
        }));
        setMembers(memberList);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    };
    getMembers();
  }, [domain, token]);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(`${domain}client/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const allClients = res.map((obj) => ({
            ...obj,
            label: obj.clientname,
          }));
          setAllClient(allClients);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchClientDetails();
  }, [domain, token]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(`${domain}company/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const companyDetails = res.map((obj) => ({
            ...obj,
            label: obj.companyname,
          }));
          setAllCompanies(companyDetails);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchCompanyData();
  }, [domain, token]);

  const handleKeyDown = (e) => {
    const allowedKeys = /^[A-Za-z\s]$/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    try {
      const payloadData = {
        project_id: editProjectScreenData.id,
        projectName: data.projectname,
        projectLead: projectLeadId,
        companyName: companyName,
        clientId: clientId,
        clientName: clientName,
        estimationAmount: data.estimateAmount,
        selected_members: selectedMembers,
        companyId: companyId,
        start_date: startDate,
        dueDate: dueDate,
        description: data.description,
      };

      if (
        editProjectScreenData?.project_code?.toUpperCase() !==
        data?.projectcode?.toUpperCase()
      ) {
        payloadData.project_code = data.projectcode.toUpperCase();
      }

      const response = await fetch(`${domain}edit_project/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify(payloadData),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        localStorage.setItem("editProject", false);
        dispatch(setGlobalSearchValue(""));
        history.push("/admin/project-management");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2 }}>
        <Mui.Grid xs={12}>
          <Mui.Stack direction="row">
            <BackArrow
              actionFunction={() => {
                localStorage.setItem("editProject", false);
                dispatch(setGlobalSearchValue(""));
                history.push("/admin/project-management");
              }}
            />
            &nbsp;
            <Mui.Typography
              sx={{ color: themes.outTap, fontSize: 30, fontWeight: "bold" }}
            >
              Edit Project
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{ paddingLeft: 30, paddingRight: 30, paddingTop: 5 }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Paper sx={{ borderRadius: 3 }}>
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Project Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.TextField
                  fullWidth
                  {...register("projectname", {
                    required: "Project name is required",
                    maxLength: {
                      value: 30,
                      message: "Maximum length is 30 characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Minium length is 3 characters",
                    },
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        if (!/[A-Za-z]/.test(fieldValue)) {
                          return "Project name must contain at least one alphabet character.";
                        }
                        if (/^\d+$/.test(fieldValue)) {
                          return "Project name should not contain only digits.";
                        }
                        if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                          return "Project name should only contain alphabets, spaces, -,numbers and & symbol.";
                        }
                        return true;
                      },
                      projectAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          fieldValue?.toUpperCase() !==
                            editProjectScreenData?.projectname?.toUpperCase()
                        ) {
                          try {
                            const response = await fetch(
                              `${domain}check_project_name/?project_name=${fieldValue}`,
                              {
                                method: "GET",
                                headers: {
                                  "content-type": "application/json",
                                  Authorization: `token ${token}`,
                                },
                              }
                            );
                            const res = await response.json();
                            if (response.ok) {
                              if (res?.exists) {
                                return "Project name already taken.Choose a unique name";
                              } else {
                                return true;
                              }
                            } else if (response.status === 409) {
                              sessionHandling();
                            } else {
                              throw new Error(res.error);
                            }
                          } catch (error) {
                            showToast("error", error.message);
                          }
                        }
                      },
                    },
                  })}
                  name="projectname"
                  InputProps={{
                    inputProps: { maxLength: 30 },
                  }}
                  error={errors.projectname ? true : false}
                  sx={{ background: themes.whiteColor }}
                  placeholder={projectPlaceholder.projectName}
                  helperText={
                    errors.projectname ? errors.projectname.message : ""
                  }
                  onKeyPress={handleKeyDown}
                  onBlur={() => trigger("projectname")}
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Project Code
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.TextField
                  fullWidth
                  {...register("projectcode", {
                    required: "Project code is required",
                    minLength: {
                      value: 3,
                      message: "Minium length is 3 characters",
                    },
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        if (!/^[0-9a-zA-Z]+$/.test(fieldValue)) {
                          return "Project code must be alphanumeric characters.";
                        }
                        return true;
                      },
                      proCodeAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          fieldValue?.toUpperCase() !==
                            editProjectScreenData?.project_code?.toUpperCase()
                        ) {
                          try {
                            const response = await fetch(
                              `${domain}validate/project_code/?project_code=${fieldValue}`,
                              {
                                method: "GET",
                                headers: {
                                  "content-type": "application/json",
                                  Authorization: `token ${token}`,
                                },
                              }
                            );
                            const res = await response.json();
                            if (response.ok) {
                              if (res?.exists) {
                                return "Product code already exists";
                              } else {
                                return true;
                              }
                            } else if (response.status === 409) {
                              sessionHandling();
                            } else {
                              throw new Error(res.error);
                            }
                          } catch (error) {
                            showToast("error", error.message);
                          }
                        }
                      },
                    },
                  })}
                  name="projectcode"
                  error={errors.projectcode ? true : false}
                  sx={{ background: themes.whiteColor }}
                  placeholder={projectPlaceholder.projectCode}
                  helperText={
                    errors.projectcode ? errors.projectcode.message : ""
                  }
                  InputProps={{
                    inputProps: { maxLength: 3 },
                  }}
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Lead
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={projectLead}
                  options={members}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.username
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.username}</li>;
                  }}
                  ListboxProps={{
                    style: {
                      maxHeight: "150px",
                    },
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setProjectLead(value.username);
                      setProjectLeadId(value.user_id);
                      setValue("projectLead", value.username);
                      trigger("projectLead");
                    } else {
                      setProjectLead("");
                      setProjectLeadId(null);
                      setValue("projectLead", null);
                    }
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      {...register("projectLead", {
                        required: "Project lead is required",
                      })}
                      value={projectLead}
                      sx={{ background: themes.whiteColor }}
                      placeholder={projectPlaceholder.projectLead}
                      size="medium"
                      error={errors.projectLead ? true : false}
                    />
                  )}
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Description
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.TextField
                  {...register("description", {
                    required: false,
                  })}
                  multiline
                  minRows={3}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Start Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.TextField
                  id="to-date"
                  type="date"
                  size="small"
                  {...register("startdate", {
                    required: false,
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        if (parseInt(startDate?.split("-")[0]) > 2999) {
                          return "Please check the date, its too future date";
                        } else if (parseInt(startDate?.split("-")[0]) < 2020) {
                          return "Start date cannot be before 01/01/2020 or later";
                        }
                        return true;
                      },
                    },
                  })}
                  value={startDate}
                  sx={{ background: themes.whiteColor }}
                  onChange={(event) => setStartDate(event.target.value)}
                  error={errors.startdate ? true : false}
                  helperText={errors.startdate ? errors.startdate.message : ""}
                  fullWidth
                  disableOpenPicker
                  inputProps={{
                    max: "9999-12-31",
                  }}
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Due Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.TextField
                  id="to-date"
                  type="date"
                  size="small"
                  {...register("enddate", {
                    required: false,
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        if (new Date(dueDate) < new Date(startDate)) {
                          return "Due date is greater then start date";
                        }
                        if (parseInt(dueDate.split("-")[0]) > 2999) {
                          return "Please check the date, its too future date";
                        } else if (parseInt(dueDate.split("-")[0]) < 2020) {
                          return "Due date cannot be before 01/01/2020 or later";
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  value={dueDate}
                  sx={{ background: themes.whiteColor }}
                  onChange={(event) => setDueDate(event.target.value)}
                  error={errors.enddate ? true : false}
                  helperText={errors.enddate ? errors.enddate.message : ""}
                  fullWidth
                  disableOpenPicker
                  inputProps={{
                    max: "9999-12-31",
                  }}
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Visibility
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.FormControlLabel
                  sx={{
                    marginTop: 2,
                  }}
                  control={
                    <Mui.Switch
                      onChange={() => {
                        setAutocompleteVisible(!autocompleteVisible);
                        setSelectedMembers([]);
                      }}
                      sx={{
                        width: 70,
                        height: 50,

                        "& .MuiSwitch-thumb": {
                          width: 32,
                          height: 32,
                          color: autocompleteVisible ? themes.primary : "none",
                        },
                        "& .MuiSwitch-track": {
                          borderRadius: 100 / 2,
                        },
                      }}
                      checked={autocompleteVisible}
                    />
                  }
                  label={
                    autocompleteVisible ? (
                      <Mui.Typography sx={{ fontWeight: "bold" }}>
                        Visible To Members
                      </Mui.Typography>
                    ) : (
                      <Mui.Typography sx={{ fontWeight: "bold" }}>
                        Access to All
                      </Mui.Typography>
                    )
                  }
                />
                {autocompleteVisible && (
                  <>
                    <Mui.Grid container direction="row">
                      <Mui.Typography
                        sx={{
                          fontFamily: "Popins",
                          fontWeight: "bold",
                          fontSize: 23,
                        }}
                      >
                        Add Members (Optional)
                      </Mui.Typography>
                    </Mui.Grid>
                    <div {...getRootProps()}>
                      <InputWrapper
                        sx={{
                          width: "100%",
                          minHeight: 50,
                          mt: 1,
                          mb: 1,
                          borderRadius: "15px",
                          padding: 1.5,
                        }}
                        ref={setAnchorEl}
                        className={
                          autocompleteVisible || focused ? "focused" : ""
                        }
                        autocompleteVisible={autocompleteVisible}
                      >
                        {selectedMembers.map((option, index) => (
                          <StyledTag
                            label={option.username}
                            {...getTagProps({ index })}
                          />
                        ))}
                        <input {...getInputProps()} />
                      </InputWrapper>
                    </div>
                    {groupedOptions.length > 0 ? (
                      <Listbox
                        {...getListboxProps()}
                        autocompleteVisible={autocompleteVisible}
                      >
                        {groupedOptions.map((option, index) => (
                          <li {...getOptionProps({ option, index })}>
                            <span>{option.username}</span>
                            <Check fontSize="small" />
                          </li>
                        ))}
                      </Listbox>
                    ) : null}
                  </>
                )}
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Client
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={clientName}
                  options={allClients}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.clientname}</li>;
                  }}
                  ListboxProps={{
                    style: {
                      maxHeight: "150px",
                    },
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setClientId(value.id);
                      setValue("clientid", value.id);
                      setClientName(value.clientname);
                      setValue("clientname", value.clientname);
                    } else {
                      setClientName("");
                      setValue("clientname", "");
                      setClientId(0);
                      setValue("clientid", 0);
                    }
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      {...register("clientname", {
                        required: false,
                      })}
                      value={clientName}
                      sx={{ background: themes.whiteColor }}
                      placeholder={projectPlaceholder.selectClientName}
                      size="medium"
                      error={errors.clientname ? true : false}
                      helperText={errors.clientname?.message}
                    />
                  )}
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Company
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={companyName}
                  options={allCompanies}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.companyname
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.companyname}</li>;
                  }}
                  ListboxProps={{
                    style: {
                      maxHeight: "150px",
                    },
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setCompanyId(value.id);
                      setCompanyName(value.companyname);
                      setValue("company", value.companyname);
                      trigger("company");
                    } else {
                      setCompanyId(null);
                      setCompanyName("");
                      setValue("company", "");
                    }
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      {...register("company", {
                        required: "Company is required",
                      })}
                      value={companyName}
                      sx={{ background: themes.whiteColor }}
                      placeholder={projectPlaceholder.selectCompany}
                      size="medium"
                      error={errors.company ? true : false}
                      helperText={errors.company?.message}
                    />
                  )}
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={4}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "small" }}>
                  Estimation Amount
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={6} lg={8} sx={{ paddingBottom: 2 }}>
                <Mui.TextField
                  {...register("estimateAmount", {
                    required: "Atleast one digits required",
                  })}
                  InputProps={{
                    inputProps: { maxLength: 10 },
                  }}
                  error={errors.estimateAmount ? true : false}
                  helperText={errors.estimateAmount?.message}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={12} sm={6} md={8} lg={10}></Mui.Grid>
              <Mui.Grid xs={12} sm={6} md={4} lg={2}>
                <CustomButton actionName="Update" typeName="submit" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Paper>
        </form>
      </Mui.Grid>
    </React.Fragment>
  );
};

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <Close onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = Mui.styled(Tag)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    height: 34px;
    margin: 2px;
    line-height: 22px;
    border-radius: 25px;
    background-color: ${
      theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
    };
    border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
    border-radius: 2px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;
    border-radius: 25px;
  
    &:focus {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
      background-color: ${
        theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
      };
    }
  
    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
    & svg {
      font-size: 12px;
      cursor: pointer;
      padding: 4px;
    }
  `
);

const InputWrapper = Mui.styled("div")(
  ({ theme, autocompleteVisible }) => `
    width: 100%; 
     border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
     background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
     border-radius: 4px;
     padding: 1px;
     display: flex;
     flex-wrap: wrap;
     
     &:hover {
       border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
     }
  
     ${
       autocompleteVisible
         ? `
         &.focused {
           border-color: ${
             theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"
           };
           box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
         }
       `
         : ""
     }
  
     & input {
       background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
       color: ${
         theme.palette.mode === "dark"
           ? "rgba(255,255,255,0.65)"
           : "rgba(0,0,0,.85)"
       };
      
      
       flex-grow: 1;
       border: 0;
       margin: 0;
       outline: 0;
     }
  `
);

const Listbox = Mui.styled("ul")(
  ({ theme, autocompleteVisible }) => `
     width: 300px;
     margin: 2px 0 0;
     padding: 0;
     position: absolute;
     list-style: none;
     background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
     overflow: auto;
     max-height: 150px;
     border-radius: 4px;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
     z-index: 1;
    
     ${
       autocompleteVisible
         ? `
       background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
     `
         : ""
     }
    
     & li {
       padding: 5px 12px;
       display: flex;
    
       & span {
         flex-grow: 1;
       }
    
       & svg {
         color: transparent;
       }
     }
    
     & li[aria-selected='true'] {
       background-color: ${
         theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"
       };
       font-weight: 600;
    
       & svg {
         color: #1890ff;
       }
     }
    
     & li.${autocompleteClasses.focused} {
       background-color: ${
         theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
       };
       cursor: pointer;
    
       & svg {
         color: currentColor;
       }
     }
  `
);
