import { useState } from "react";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import * as Icons from "@mui/icons-material";
import * as Mui from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import Person4Icon from "@mui/icons-material/Person4";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";
export const AddDepartment = ({ open, setOpen, setDepartmentApi }) => {
  const { token, domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [shiftCategoryName, setShiftCategoryName] = useState("");
  const [departmentLeadName, setDepartmentLeadName] = useState("");
  const [departmentProxyName, setDepartmentProxyName] = useState("");
  const [departmentProxyList, setDepartmentProxyList] = useState([]);
  const { result: departmentLeadList } = useFetch(
    `${domain}departmentleadfilter/`
  );
  const { result: shiftCategoryList } = useFetch(`${domain}shift_category/`);
  const {
    setValue,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm();

  const handleCloseDepartment = () => {
    reset();
    setOpen(false);
    setShiftCategoryName("");
    setDepartmentLeadName("");
    setDepartmentProxyName("");
  };

  const AddDepartmentOnSubmit = async (data) => {
    try {
      const response = await fetch(`${domain}create_department/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          department_lead_id: data.departmentLeadId,
          shift_category: shiftCategoryName,
          departmentname: data.department,
          proxy_id: data.departmentProxy,
          approved_by: userDetails.username,
          selectedMembers: null,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Department created successfully");
        handleCloseDepartment();
        setDepartmentApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const checkSpecialChar = (e) => {
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const getDepartmentProxyList = (leadName) => {
    if (leadName !== "") {
      const proxy = departmentLeadList.filter((data) => {
        return data.user.auth_user.username !== leadName;
      });
      setDepartmentProxyList(proxy);
    } else {
      setDepartmentProxyList([]);
    }
  };

  return (
    <Mui.Dialog open={open}>
      <Mui.Container
        style={{
          height: 60,
          backgroundColor: "#3FCFC7",
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Mui.DialogTitle
          style={{
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          New Department
        </Mui.DialogTitle>
        <Icons.Close
          onClick={handleCloseDepartment}
          style={{
            alignSelf: "center",
            color: "#ffffff",
          }}
        />
      </Mui.Container>

      <Mui.DialogContent>
        <form
          onSubmit={handleSubmit(AddDepartmentOnSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            maxWidth: "600px",
            margin: "auto",
          }}
        >
          <Mui.Container
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Mui.TextField
              size="medium"
              placeholder="Enter department name"
              sx={{
                borderWidth: "10px solid black",
              }}
              {...register("department", {
                required: "department is required",
                maxLength: {
                  value: 30,
                  message: "Maximum length is 30 characters",
                },
                minLength: {
                  value: 3,
                  message: "Minimum length is 3 characters",
                },
                validate: {
                  noDigitsOrSpecial: (fieldValue) => {
                    if (!/[A-Za-z]/.test(fieldValue)) {
                      return "Department Name must contain at least one alphabet character.";
                    }
                    if (/^\d+$/.test(fieldValue)) {
                      return "Department Name should not contain only digits.";
                    }
                    if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                      return "DepartmentClient Name should only contain alphabets, spaces, -,numbers and & symbol.";
                    }
                    return true;
                  },
                },
              })}
              error={Boolean(errors.department)}
              helperText={errors.department?.message || ""}
              onKeyPress={checkSpecialChar}
              InputProps={{
                startAdornment: (
                  <Mui.InputAdornment>
                    <Mui.IconButton>
                      <Icons.AddBusiness
                        style={{
                          fontSize: 25,
                          color: themes.blackColor,
                          fontWeight: "bold",
                        }}
                      />
                    </Mui.IconButton>
                  </Mui.InputAdornment>
                ),
              }}
              fullWidth
            />

            <Mui.Stack spacing={2} width="500px">
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={shiftCategoryName}
                options={shiftCategoryList}
                sx={{ width: "100%" }}
                ListboxProps={{ style: { maxHeight: "150px" } }}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.name
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.name}</li>;
                }}
                onChange={(event, value) => {
                  if (value) {
                    setShiftCategoryName(value.name);
                    setValue("shiftCategoryId", value.id);
                  } else {
                    setValue("shiftCategoryId", null);
                    setShiftCategoryName("");
                  }
                }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    value={shiftCategoryName}
                    {...register("shiftCategory", {
                      required: "Shift category is required",
                    })}
                    sx={{
                      background: themes.whiteColor,
                      overflow: "auto",
                      maxHeight: 120,
                      paddingLeft: 3,
                    }}
                    placeholder="Select shift category"
                    size="medium"
                    error={Boolean(errors.shiftCategory)}
                    helperText={errors.shiftCategory?.message || ""}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <GroupsIcon
                            style={{
                              margin: "0 8px",
                              color: "#a6a6a6",
                            }}
                          />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                    fullWidth
                  />
                )}
              />
            </Mui.Stack>
          </Mui.Container>
          <Mui.Container
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Mui.Stack spacing={2} width="500px">
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={departmentLeadName}
                options={departmentLeadList}
                sx={{ width: "100%" }}
                ListboxProps={{ style: { maxHeight: "150px" } }}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.user.auth_user.username
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.user.auth_user.username}</li>;
                }}
                onChange={(event, value) => {
                  if (value) {
                    setDepartmentLeadName(value.user.auth_user.username);
                    setValue("departmentLeadId", value.id);
                    getDepartmentProxyList(value.user.auth_user.username);
                  } else {
                    setValue("departmentLeadId", null);
                    setDepartmentLeadName("");
                    getDepartmentProxyList("");
                  }
                }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    value={departmentLeadName}
                    {...register("departmentLead", {
                      required: "Department lead is required",
                    })}
                    sx={{
                      background: themes.whiteColor,
                      overflow: "auto",
                      maxHeight: 120,
                    }}
                    placeholder="Select department lead"
                    size="medium"
                    error={Boolean(errors.departmentLead)}
                    helperText={errors.departmentLead?.message || ""}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Person4Icon
                            style={{
                              margin: "0 8px",
                              color: "#a6a6a6",
                            }}
                          />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                    fullWidth
                  />
                )}
              />
            </Mui.Stack>
            <Mui.Stack spacing={2} width="500px">
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={departmentProxyName}
                options={departmentProxyList}
                sx={{ width: "100%" }}
                ListboxProps={{ style: { maxHeight: "150px" } }}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.user.auth_user.username
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.user.auth_user.username}</li>;
                }}
                onChange={(event, value) => {
                  if (value) {
                    setDepartmentProxyName(value.user.auth_user.username);
                    setValue("departmentProxy", value.id);
                  } else {
                    setValue("departmentProxy", null);
                    setDepartmentProxyName("");
                  }
                }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    value={departmentProxyName}
                    {...register("proxyName", {
                      required: "Proxy name is required",
                    })}
                    sx={{
                      background: themes.whiteColor,
                      overflow: "auto",
                      maxHeight: 120,
                      paddingLeft: 3,
                    }}
                    placeholder="Select proxy name"
                    size="medium"
                    error={Boolean(errors.proxyName)}
                    helperText={errors.proxyName?.message || ""}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Person4Icon
                            style={{
                              margin: "0 8px",
                              color: "#a6a6a6",
                            }}
                          />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                    fullWidth
                  />
                )}
              />
            </Mui.Stack>
          </Mui.Container>
          <Mui.Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              alignSelf: "flex-end",
              marginRight: 20.0,
              width: 150,
              backgroundColor: "#3FCFC7",
            }}
          >
            Create
          </Mui.Button>
        </form>
      </Mui.DialogContent>
    </Mui.Dialog>
  );
};
