import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { KeyboardArrowDown, ChevronRight } from "@mui/icons-material";
import { themes } from "services/constants";

export const PunchDetails = ({
  punchDetailsData,
  startDate,
  endDate,
  departmentName,
  shiftCategoryName,
  shiftName,
  setRemoveExpand,
  removeExpand,
  setPunchExports,
  punchExports,
  loading,
  page,
  setLeavePage,
  setLeaveRowsPerPage,
  rowsPerPage,
}) => {
  const [personExpandableUsername, setPersonExpandableUsername] = useState("");

  useEffect(() => {
    if (removeExpand) {
      setPersonExpandableUsername("");
      setRemoveExpand(false);
    }
  }, [removeExpand, setRemoveExpand]);

  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  useEffect(() => {
    const punchExportsDetails = () => {
      let dataPreparation = [];
      punchDetailsData.forEach((element) => {
        element?.punches?.forEach((punchEntries) => {
          let overAllData = {
            date: punchEntries.date,
            username: element.username,
            shift_name: punchEntries.shift_name,
            location: punchEntries.location,
            punch_time: punchEntries.punch_time,
            device_name: punchEntries.device_name,
          };
          dataPreparation = [...dataPreparation, overAllData];
        });
      });
      const department = ["Department Name"];
      const shiftCategory = ["ShiftCategory Name"];
      const shift = ["Shift Name"];
      const date = ["Date"];
      department.push(departmentName);
      shiftCategory.push(shiftCategoryName);
      shift.push(shiftName);
      date.push(`${startDate} to ${endDate}`);
      const items = dataPreparation;
      const TableHead = ["Date"];
      TableHead.push("UserName");
      TableHead.push("ShiftName");
      TableHead.push("Device Name");
      TableHead.push("PunchIn");
      TableHead.push("Location");
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(date.join(","));
      csv.unshift(shift.join(","));
      csv.unshift(shiftCategory.join(","));
      csv.unshift(department.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "Punch.csv";
      document.body.appendChild(element);
      element.click();
    };
    if (punchExports) {
      punchExportsDetails();
      setPunchExports(false);
    }
  }, [
    departmentName,
    endDate,
    punchDetailsData,
    punchExports,
    setPunchExports,
    shiftCategoryName,
    shiftName,
    startDate,
  ]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 6 }}>
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 2, width: "100%", paddingRight: 2 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Action
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Punch count
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {punchDetailsData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((element) => (
                  <>
                    <Mui.TableRow>
                      <Mui.TableCell>
                        {element?.username === personExpandableUsername ? (
                          <Mui.IconButton
                            onClick={() => setPersonExpandableUsername("")}
                          >
                            <KeyboardArrowDown />
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() =>
                              setPersonExpandableUsername(element?.username)
                            }
                            style={{
                              padding: "0px",
                            }}
                          >
                            <ChevronRight />
                          </Mui.IconButton>
                        )}
                      </Mui.TableCell>
                      <Mui.TableCell>{element.username}</Mui.TableCell>
                      <Mui.TableCell>{element.punch_in_count}</Mui.TableCell>
                    </Mui.TableRow>
                    {personExpandableUsername === element?.username && (
                      <Mui.TableRow sx={{ justifyItems: "center" }}>
                        <Mui.TableCell colSpan={12}>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%",
                            }}
                            className="w-96 border rounded"
                          >
                            {element?.punches?.length !== 0 ? (
                              <Mui.Table className="border">
                                <Mui.TableHead
                                  sx={{
                                    backgroundColor: themes.primary,
                                  }}
                                >
                                  <Mui.TableRow>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Date
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Shift
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Punch in
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Device name
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Location
                                    </Mui.TableCell>
                                  </Mui.TableRow>
                                </Mui.TableHead>
                                <Mui.TableBody>
                                  {element?.punches?.map(
                                    (shiftDetails, index) => (
                                      <>
                                        <Mui.TableRow key={index}>
                                          <Mui.TableCell>
                                            {shiftDetails?.date}
                                          </Mui.TableCell>
                                          <Mui.TableCell>
                                            {shiftDetails?.shift_name}
                                          </Mui.TableCell>
                                          <Mui.TableCell>
                                            {shiftDetails?.punch_time}
                                          </Mui.TableCell>
                                          <Mui.TableCell>
                                            {shiftDetails?.device_name}
                                          </Mui.TableCell>
                                          <Mui.TableCell>
                                            {shiftDetails?.location}
                                          </Mui.TableCell>
                                        </Mui.TableRow>
                                      </>
                                    )
                                  )}
                                </Mui.TableBody>
                              </Mui.Table>
                            ) : (
                              <Mui.Stack
                                sx={{ padding: 0.5 }}
                                spacing={2}
                                fullWidthy
                              >
                                <Mui.Alert severity="info">
                                  There is a no available shifts
                                </Mui.Alert>
                              </Mui.Stack>
                            )}
                          </Mui.TableContainer>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {punchDetailsData.length === 0 ? (
            <Mui.Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
              No data available
            </Mui.Alert>
          ) : null}
          {punchDetailsData.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={punchDetailsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      </Mui.Grid>
    </React.Fragment>
  );
};
