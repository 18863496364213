import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ChangeDepartment } from "./ChangeDepartment";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { BackArrow } from "components/BackArrow";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import sessionHandling from "services/utils/notificationUtils";
import { useDispatch } from "react-redux";
export const ViewMembers = () => {
  const dispatch = useDispatch();
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId, departmentLeadId } = useSelector(
    (state) => state.userReducer
  );
  const location = useLocation();
  const [reloadApi, setReloadApi] = useState(true);
  const [departmentMembers, setDepartmentMembers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [changeDepartment, setChangeDepartment] = useState(false);
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(10);
  const history = useHistory();
  const [members, setMembers] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  useEffect(() => {
    const getDepartment = async () => {
      try {
        const response = await fetch(`${domain}department/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const allDepartments = res.map((obj) => ({
            ...obj,
            label: obj.department,
          }));
          setDepartmentList(allDepartments);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    getDepartment();
  }, [token, domain]);

  useEffect(() => {
    const fetchDepartmentMembers = async () => {
      try {
        const response = await fetch(
          `${domain}get_department_members/${departmentLeadId}/${departmentId}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentMembers(res);
          setMasterDataForSearch(res.members_data);
          setMembers(res.members_data);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (reloadApi) {
      fetchDepartmentMembers();
      setReloadApi(false);
    }
  }, [departmentId, departmentLeadId, domain, reloadApi, token]);

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setMembers(FilterDockets());
    setLeavePage(0);
  }, [globalSearchValue, masterDataForSearch]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2 }}>
        <Mui.Grid xs={12} sm={12} md={0.4} lg={0.4}>
          <BackArrow
            actionFunction={() => {
              if (location.pathname === "/admin/attendance/view/members") {
                dispatch(setGlobalSearchValue(""));
                history.push("/admin/teamzone/attendance");
              } else {
                dispatch(setGlobalSearchValue(""));
                history.push("/admin/departments");
              }
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          xs={12}
          sm={12}
          md={2.3}
          lg={2.3}
          sx={{
            textAlign: "center",
            borderRight: `5px solid ${themes.blackColor}`,
          }}
        >
          <Mui.Stack>
            <Mui.Typography
              sx={{
                color: themes.primaryIndicator,
                fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
              }}
            >
              Name
            </Mui.Typography>
            <Mui.Typography
              sx={{
                color: themes.blackColor,
                fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
              }}
            >
              {departmentMembers.department_name}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid
          xs={12}
          sm={12}
          md={2.3}
          lg={2.3}
          sx={{
            textAlign: "center",
            borderRight: `5px solid ${themes.blackColor}`,
          }}
        >
          <Mui.Typography
            sx={{
              color: themes.primaryIndicator,
              fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
            }}
          >
            Lead
          </Mui.Typography>
          <Mui.Typography
            sx={{
              color: themes.blackColor,
              fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
            }}
          >
            {departmentMembers.department_lead_name}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          xs={12}
          sm={12}
          md={2.3}
          lg={2.3}
          sx={{
            textAlign: "center",
            borderRight: `5px solid ${themes.blackColor}`,
          }}
        >
          <Mui.Typography
            sx={{
              color: themes.primaryIndicator,
              fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
            }}
          >
            Proxy
          </Mui.Typography>
          <Mui.Typography
            sx={{
              color: themes.blackColor,
              fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
            }}
          >
            {departmentMembers.proxy_name}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          xs={12}
          sm={12}
          md={2.3}
          lg={2.3}
          sx={{
            textAlign: "center",
            borderRight: `5px solid ${themes.blackColor}`,
          }}
        >
          <Mui.Typography
            sx={{
              color: themes.primaryIndicator,
              fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
            }}
          >
            Shift Category
          </Mui.Typography>
          <Mui.Typography
            sx={{
              color: themes.blackColor,
              fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
            }}
          >
            {departmentMembers.shift_category_name}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          xs={12}
          sm={12}
          md={2.3}
          lg={2.3}
          sx={{
            textAlign: "center",
          }}
        >
          <Mui.Typography
            sx={{
              color: themes.primaryIndicator,
              fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
            }}
          >
            Total Members
          </Mui.Typography>
          <Mui.Typography
            sx={{
              color: themes.blackColor,
              fontSize: { xs: 18, sm: 18, md: 20, lg: 23 },
            }}
          >
            {departmentMembers.total_members}
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingTop: 2 }}>
        <Mui.Grid xs={5.5} sm={5.5} md={2} lg={1}>
          {/* We need this code to naviage the screens */}
          {/* <Mui.Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: themes.primary,
              color: themes.whiteColor,
              padding: 1,
              borderRadius: 5,
              textTransform: "capitalize",
            }}
            onClick={() => history.push("/admin/teamzone/plan-shift")}
          >
            Plan Shift
          </Mui.Button> */}
        </Mui.Grid>
        <Mui.Grid xs={1} sm={1} md={8} lg={10}></Mui.Grid>
        <Mui.Grid xs={5.5} sm={5.5} md={2} lg={1}></Mui.Grid>
      </Mui.Grid>
      <Mui.TableContainer component={Mui.Paper} sx={{ mt: 2, width: "100%" }}>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Name
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                User code
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Designation
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Employment Type
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Phone Number
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                System Access
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Status
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Action
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {members
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((member) => (
                <React.Fragment>
                  <Mui.TableRow>
                    <Mui.TableCell>
                      <Mui.Stack direction="row">
                        <Mui.Avatar
                          sx={{ width: 25, height: 25 }}
                          alt={member.department_member_name.charAt(0)}
                          src={member.image_url}
                        />
                        <Mui.Typography
                          sx={{
                            paddingLeft: 1,
                          }}
                        >
                          {member.department_member_name}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {member.user_code ? member.user_code : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {member.designation ? member.designation : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {member.employment_type ? member.employment_type : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>{member.phonenumber}</Mui.TableCell>
                    <Mui.TableCell>
                      {member.system_access ? member.system_access : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>{member.status}</Mui.TableCell>
                    <Mui.TableCell>
                      <Mui.IconButton
                        onClick={(event) => {
                          setEditUser(member);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                      >
                        <Mui.MenuItem
                          onClick={() => {
                            setChangeDepartment(true);
                            setAnchorEl(null);
                          }}
                        >
                          Change Department
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                </React.Fragment>
              ))}
          </Mui.TableBody>
        </Mui.Table>
        {members?.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={members?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.TableContainer>
      {changeDepartment ? (
        <ChangeDepartment
          changeDepartment={changeDepartment}
          setChangeDepartment={setChangeDepartment}
          editUser={editUser}
          departmentList={departmentList}
          departmentName={departmentMembers.department_name}
          setAnchorEl={setAnchorEl}
          setReloadApi={setReloadApi}
        />
      ) : null}
    </React.Fragment>
  );
};
