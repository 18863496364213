import React, { useState } from "react";
import * as Mui from "@mui/material";
import Papa from "papaparse";
import { CustomButton } from "components/CustomButton";
import { BackArrow } from "components/BackArrow";
import { useDispatch } from "react-redux";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const BulkImport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
//   const [importedData, setImportData] = useState([]);
  const [header, setHeader] = useState([]);
  const DownloadData = () => {
    const TableHead = ["user_name"];
    TableHead.push("employee_id");
    TableHead.push("phone_number");
    TableHead.push("date_joined");
    let items = [
      {
        user_name: "Organflow",
        employee_id:'ORGAN1234567',
        phone_number: "8300833133",
        date_joined: "1973-05-23",
      },
    ];
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter((field) => field);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "UserSampleData.csv";
    document.body.appendChild(element);
    element.click();
  };
 
  const prepareJson=(data,header)=>{
    console.log(data,header);
    //    let json=[];
    //    data.forEach(element => {
    //       let array = {
    //         user_name: element[0],
    //         employee_id: element[1],
    //         phone_number:element[2],
    //       };
    //    });
  }


  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 3,
        }}
      >
        <Mui.Grid xs={0.5}>
          <BackArrow
            actionFunction={() => {
              dispatch(setGlobalSearchValue(""));
              history.push("/admin/teamzone/employee");
            }}
          />
        </Mui.Grid>
        <Mui.Grid
          xs={11.5}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 2,
          }}
        >
          <CustomButton
            actionFuntion={DownloadData}
            actionName="Sample CSV"
            typeName="button"
          />
          &nbsp;
          <Mui.Button
            variant="contained"
            component="label"
            size="large"
            sx={{
              background: themes.primaryButton,
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: themes.primaryButton,
              },
              color: themes.headLine,
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: { xs: 0, sm: 0, md: 12, lg: 14 },
                textTransform: "capitalize",
              }}
            >
              Import
            </Mui.Typography>
            <Mui.TextField
              style={{ display: "none" }}
              accept="image/*"
              type="file"
              onChange={(e) => {
                const files = e.target.files;
                if (files) {
                  console.log(files[0]);
                  Papa.parse(files[0], {
                    complete: function (results) {
                      console.log("Finished:", results.data);
                      let header = results.data[0];
                      setHeader(header);
                      prepareJson(results.slice(1), header);
                    },
                  });
                }
                // resizeFile(e.target.files[0]);
                // setProfile(e.target.files[0]);
                // setupdateProfile(true);
              }}
            />
          </Mui.Button>
        </Mui.Grid>
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ width: "100%", maxHeight: 840 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow maxWidth="xl" align="left">
                {header.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {/* {employeeDetails
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <Mui.TableRow key={data.id}>
                    <>
                      <Mui.TableCell align="left">
                        {data?.user_name ? data?.user_name : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.employee_id ? data?.employee_id : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.user_code ? data?.user_code : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.email ? data?.email : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.status ? data?.status : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        <Mui.IconButton
                          onClick={(e) => {
                            setEditDetails(data);
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVert
                            sx={{ color: themes.blackColor, strokeWidth: 2 }}
                          />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                            onClick={() => editDetailsConfigurations()}
                          >
                            Edit
                          </Mui.MenuItem>
                          {editDetails?.status === "Active" ? (
                            <Mui.MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenDeactivatePopup(true);
                              }}
                            >
                              Inactive
                            </Mui.MenuItem>
                          ) : editDetails?.status === "Expired" ||
                            editDetails?.status === "Invited" ? null : (
                            <Mui.MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenActivatePopup(true);
                              }}
                            >
                              Activate
                            </Mui.MenuItem>
                          )}
                        </Mui.Menu>
                      </Mui.TableCell>
                    </>
                  </Mui.TableRow>
                ))} */}
            </Mui.TableBody>
          </Mui.Table>
          {/* {employeeDetails.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={employeeDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )} */}
        </Mui.TableContainer>
      </Mui.Grid>
    </React.Fragment>
  );
};
