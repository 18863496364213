export const userManagementError = {
  departmentError: "Department name already exists",
  imagesizeError: "Image size is too large",
  activeError: "Status updated successfully.",
  deactivateError:
    "To deactivate this department lead, please remove or move all assigned employees first.",
  sendmailError:
    "There was a problem sending the email. Please try again later.",
  invalidCredentialsError: "Invalid username or password. Please try again.",
  loginCredentialsError: "Invalid username or password. Please try again.",
  emailReturnResponseError:
    "Your account has been deactivated.Please contact the admin",
  memberDeletedError: "Please select at least one employee.",
  selectEmployeeError:
    "An error occurred while updating your session. Please try logging in again.",
};

export const leaveManagementError = {
  leaveSummaryError: "Failed to fetch leave summary, Please refresh the page.",
  networkResponseError: "Network response was not okay.",
  deletedleaveError: "The leave deleted successfully",
  dataFetchError: "Error fetching data. Please refresh the page.",
  checkInError: "Check-in time to be earlier than check-out time.",
  breakError: "Breakout time to be earlier than break start time",
  breakInError: "Please enter break start time.",
  breakOutError: "Please enter break end time",
  editBreakError: "Breakout time to be earlier than break start time",
};

export const projectError = {
  networkResponseError: "Network response was not ok",
  enterTaskName: "Please enter task name",
  enterError: "Please enter comments",
  invalidStartTime: "Please enter the start time",
  invalidEndTime: "Please enter the end time",
  startTime: "Start time is lesser then end time",
  endTime: "End time is before starting time",
  taskMaterial: "Task materials removed successfully",
  fetchError: `Error fetching :`,
  labelName: "Label name is required",
  taskNameError: "Please enter task name",
  sprintnameError:
    "Only alphanumeric characters within 32 characters are allowed",
  projectnameError: "Please select project",
};

export const minimum10Digits = "Minimum 10 digits are required";
export const minimum3Characters = "Minimum 3 characters with no spaces";
