import React, { useCallback, useContext, useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { sprintTaskDetails } from "services/constants";
import { MoreHoriz } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { SprintDashboardContext } from "../DashboardContext/sprintindex";
import sessionHandling from "services/utils/notificationUtils";

export const SprintTasks = ({ unCompletedSprint }) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { sprintDetails, setReloadSprDash } = useContext(
    SprintDashboardContext
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [moveTask, setMoveTask] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElindiviual, setAnchorElindiviual] = useState(null);
  const [remainingSprints, setRemaingSprints] = useState([]);
  const [sprintTask, setSprintTask] = useState([]);
  const [loading, setLoading] = useState(true);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  const fetchTaskData = useCallback(async () => {
    try {
      const response = await fetch(
        `${domain}task/?project=${sprintDetails?.project}&sprint=${sprintDetails?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setSprintTask(res);
        setMasterDataForSearch(res);
        setLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  }, [domain, token, sprintDetails]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  useEffect(() => {
    const filterData = unCompletedSprint?.filter((spirnt) => {
      return spirnt.name !== sprintDetails?.name;
    });
    setRemaingSprints(filterData);
    setLoading(false);
  }, [sprintDetails, unCompletedSprint]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addTasktoMoveSprint = (task) => {
    let checkTask = moveTask.filter((moveTaskdata) => {
      return moveTaskdata.task_id === task.id;
    });
    if (checkTask.length === 0) {
      let data = {
        task_id: task.id,
      };
      const moveTaskData = [...moveTask, data];
      setMoveTask(moveTaskData);
    } else {
      let remainingTask = moveTask.filter((moveTaskdata) => {
        return moveTaskdata.task_id !== task.id;
      });
      setMoveTask(remainingTask);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const moveSprint = async (sprint) => {
    try {
      const response = await fetch(`${domain}move_sprint_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          new_sprint_id: sprint.id,
          selected_tasks: moveTask,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        handleMenuClose();
        handleIndiviualMenuClose();
        setReloadSprDash(true);
        showToast("success", `Task successfully moved to another sprint.`);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleIndiviualMenuClose = () => {
    setAnchorElindiviual(false);
    setMoveTask([]);
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };
  useEffect(() => {
    const filteredResults = masterDataForSearch.filter((item) =>
      sprintTaskDetails.some((key) =>
        getNestedValue(item, key)
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setSprintTask(filteredResults);
  }, [globalSearchValue, masterDataForSearch, setSprintTask]);

  return (
    <React.Fragment>
      {sprintTask.length !== 0 ? (
        <Mui.TableContainer
          sx={{
            mt: 2,
            paddingRight: 5,
            paddingLeft: 5,
          }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow maxWidth="xl" align="left">
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                ></Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Task ID
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    width: "30%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Task Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Assignee
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Time Spent
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  End Date
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Priority
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Status
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  <Mui.IconButton
                    onClick={(event) => {
                      if (moveTask.length !== 0) {
                        setAnchorEl(event.currentTarget);
                      }
                    }}
                    disabled={remainingSprints?.length === 0 ? true : false}
                  >
                    <MoreHoriz />
                  </Mui.IconButton>
                  <Mui.Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    elevation={1}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                    sx={{ marginLeft: 11, marginTop: -3 }}
                  >
                    {remainingSprints?.map((option) =>
                      sprintDetails?.name !== option?.name ? (
                        <Mui.Stack>
                          <Mui.MenuItem
                            key={option}
                            onClick={() => moveSprint(option)}
                          >
                            {option.name}
                          </Mui.MenuItem>
                        </Mui.Stack>
                      ) : null
                    )}
                  </Mui.Menu>
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {sprintTask
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <Mui.TableRow key={data.id} hover={true}>
                    <>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        <Mui.Checkbox
                          onChange={() => addTasktoMoveSprint(data)}
                          disabled={
                            data?.work_flow_status?.name === "Completed"
                              ? true
                              : remainingSprints?.length === 0
                              ? true
                              : false
                          }
                        />
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        {data.task_code ? data.task_code : "N/A"}
                      </Mui.TableCell>
                      <Mui.Tooltip title={data.taskname}>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            width: "30%",
                            maxWidth: 100,
                            textOverflow: "ellipsis",
                          }}
                        >
                          {data.taskname}
                        </Mui.TableCell>
                      </Mui.Tooltip>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        <Mui.Stack direction="row">
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt={data?.current_assignee?.username
                              .charAt(0)
                              .toUpperCase()}
                            src={data.current_assignee?.image_url}
                          />
                          <Mui.Typography
                            sx={{
                              paddingLeft: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data.current_assignee?.username
                              ? data.current_assignee?.username
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Stack>
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.total_time ? data.total_time : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.due_date ? data.due_date : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.priority?.name ? data.priority?.name : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell
                        align="left"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data?.work_flow_status?.name
                          ? data?.work_flow_status?.name
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        <Mui.IconButton
                          onClick={(event) => {
                            if (moveTask.length === 0) {
                              setMoveTask([
                                {
                                  task_id: data.id,
                                },
                              ]);
                              setAnchorElindiviual(event.currentTarget);
                            }
                          }}
                          disabled={
                            data?.work_flow_status?.name === "Completed"
                              ? true
                              : remainingSprints?.length === 0
                              ? true
                              : false
                          }
                        >
                          <MoreHoriz />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorElindiviual}
                          open={Boolean(anchorElindiviual)}
                          onClose={handleIndiviualMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                          sx={{ marginLeft: 11, marginTop: -3 }}
                        >
                          {remainingSprints?.length !== 0
                            ? remainingSprints?.map((option) =>
                                sprintDetails?.name !== option?.name ? (
                                  <Mui.Stack>
                                    <Mui.MenuItem
                                      key={option}
                                      onClick={() => moveSprint(option)}
                                    >
                                      {option.name}
                                    </Mui.MenuItem>
                                  </Mui.Stack>
                                ) : null
                              )
                            : null}
                        </Mui.Menu>
                      </Mui.TableCell>
                    </>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>

          {sprintTask.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={sprintTask.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      ) : loading === false ? (
        <div>
          <Mui.Alert severity="info" sx={{ mt: 2, mr: 5, ml: 5 }}>
            No data available
          </Mui.Alert>
        </div>
      ) : (
        <Loader info="Loading..." />
      )}
    </React.Fragment>
  );
};
