import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { Daily } from "./Daily";
import { Weekly } from "./Weekly";
import { themes } from "services/constants";
import { setCurrentScreen } from "services/Redux/userManagament";
import { setGlobalSearchValue } from "services/Redux/userToken";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector, useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import { setPlanShiftScreen } from "services/Redux/hrms";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachWeekOfInterval,
  getWeek,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import moment from "moment";
import { Loader } from "components/Loader";
import { defaultAllSelection } from "services/constants";
import { BackArrow } from "components/BackArrow";
import { showToast } from "components/Status";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";

export const PlanShift = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { planShiftScreen } = useSelector((state) => state.hrmsReducer);
  const { domain, token, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const [dataVisualMethod, setDataVisualMethod] = useState(planShiftScreen);
  const [isEdit, setIsEdit] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [shiftData, setShiftData] = useState([]);
  const [shiftCatagoryName, setShiftCatagoryName] = useState("");
  const [shiftName, setShiftName] = useState([]);
  const [shiftId, setshiftId] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [listOfDate, setListOfDate] = useState([]);
  const [weekNumber, setWeekNumber] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [weeklyselectedDate, setWeeklySelectedDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(new Date(), "yyyy-MM");
  const [loading, isLoading] = useState(false);
  const [fetchWeekly, setFetchWeekly] = useState(false);
  const [fetchDaily, setFetchDaily] = useState(false);
  const [presentScreen, setPresentScreen] = useState(false);
  const [weeklyShiftList, setWeeklyShiftList] = useState([]);
  const [dailyShiftList, setDailyShiftList] = useState([]);
  const [shiftChanged, setShiftChanged] = useState([]);
  const [filterChanges, setFilterChanges] = useState(false);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const minDate = new Date(2020, 0, 1);
  const { result: departmentData } = useFetch(
    `${domain}get-department/?user_id=${userDetails.id}`
  );

  useEffect(() => {
    if (presentScreen !== true && departmentData?.length !== 0) {
      dispatch(setDepartmentName(departmentData[0]?.department_name));
      dispatch(setDepartmentId(departmentData[0]?.id));
      dataVisualMethod === "Weekly"
        ? setFetchWeekly(true)
        : setFetchDaily(true);
    } else {
      dispatch(setDepartmentName(departmentName));
      dispatch(setDepartmentId(departmentId));
    }
  }, [
    dataVisualMethod,
    departmentData,
    departmentId,
    departmentName,
    dispatch,
    presentScreen,
  ]);
  const [storeCurrentWeek, setStoreCurrentWeek] = useState("");
  const getCurrentWeek = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days =
      Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)) +
      startDate.getDay();
    const weekNumber = Math.ceil(days / 7);
    const weekValue = `${currentDate.getFullYear()}-W${weekNumber
      .toString()
      .padStart(2, "0")}`;
    setStoreCurrentWeek(weekNumber);
    return weekValue;
  };
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek);

  const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startDate);
      day.setDate(startDate.getDate() + i);
      days.push(day);
    }
    return days;
  };
  const handleWeeklyDateOnChange = (date) => {
    setPresentScreen(true);
    setIsEdit(false);
    setWeeklySelectedDate(date);
  };
  const handleDailyDateOnChange = (event) => {
    setPresentScreen(true);
    setIsEdit(false);
    setSelectedWeek(event.target.value);
  };
  useEffect(() => {
    if (
      selectedWeek &&
      departmentId !== 99999 &&
      dataVisualMethod === "Daily"
    ) {
      const startDate = moment(selectedWeek).startOf("isoWeek").toDate();
      const endDate = moment(selectedWeek).endOf("isoWeek").toDate();
      const dates = getWeekDays(startDate);
      const formatDates = dates?.map((value) =>
        moment(value).format("YYYY-MM-DD")
      );
      setListOfDate([]);
      setStartDate("");
      setEndDate("");
      setListOfDate(formatDates);
      setStartDate(moment(startDate).format("YYYY-MM-DD"));
      setEndDate(moment(endDate).format("YYYY-MM-DD"));
      if (startDate !== "" && endDate !== "") {
        setFetchDaily(true);
      }
    }
  }, [dataVisualMethod, departmentId, selectedWeek]);

  useEffect(() => {
    const getWeekNumbersOfMonth = () => {
      const start = startOfMonth(weeklyselectedDate);
      const end = endOfMonth(weeklyselectedDate);
      const weeks = eachWeekOfInterval({ start, end }, { weekStartsOn: 1 });
      const maxWeeks = 52;
      const currentMonth = weeklyselectedDate.getMonth();
      const nextYear = weeklyselectedDate.getFullYear() + 1;
      const currentYear = weeklyselectedDate.getFullYear();
      return weeks
        .map((week) => {
          const weekNumber = getWeek(week, { weekStartsOn: 1 });
          const weekStart = startOfWeek(week, { weekStartsOn: 1 });
          const weekEnd = endOfWeek(week, { weekStartsOn: 1 });
          if (
            (currentMonth === 11 && weekNumber === 1) ||
            (currentMonth !== 11 &&
              weekNumber === 1 &&
              weeks[0]?.getFullYear() === nextYear) ||
            weekNumber > maxWeeks
          ) {
            return null;
          }
          return {
            weekNumber: weekNumber,
            start: format(weekStart, "MMM d"),
            end: format(weekEnd, "MMM d"),
            startDate: weekStart,
            endDate: weekEnd,
            currentYear: currentYear,
          };
        })
        .filter((week) => week !== null);
    };
    if (weekNumber) {
      const getWeekNumber = getWeekNumbersOfMonth();
      setSelectedYear("");
      setWeekNumber([]);
      setSelectedYear(weeklyselectedDate.getFullYear());
      setWeekNumber(getWeekNumber);
    }
  }, [weekNumber, weeklyselectedDate]);

  useEffect(() => {
    const formatDate = () => {
      if (weeklyselectedDate) {
        const formattedDate =
          weeklyselectedDate?.getFullYear() +
          "-" +
          ("0" + (weeklyselectedDate?.getMonth() + 1)).slice(-2);
        setFormattedDate(formattedDate);
      }
    };
    formatDate();
    if (
      formattedDate &&
      departmentId !== 99999 &&
      dataVisualMethod === "Weekly"
    ) {
      setFetchWeekly(true);
    }
  }, [dataVisualMethod, departmentId, formattedDate, weeklyselectedDate]);

  const handleEdit = (index) => {
    setIsEdit(true);
    setEditingIndex(index);
  };

  const handleCancel = () => {
    setShiftChanged([]);
    setShiftName("");
    setshiftId("");
    setIsEdit(false);
    setEditingIndex(null);
  };

  const handleShiftChange = (index, name, id) => {
    const newShiftName = [...shiftName];
    newShiftName[index] = name;
    const newShiftid = [...shiftId];
    newShiftid[index] = id;
    setShiftName(newShiftName);
    setshiftId(newShiftid);
    const newShiftChanged = [...shiftChanged];
    newShiftChanged[index] = true;
    setShiftChanged(newShiftChanged);
  };

  const updateWeeklyShift = async (userId, year, week_assignments) => {
    try {
      const response = await fetch(`${domain}shift_assignments/weekly/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          shift_assignments: [
            {
              user: userId,
              year: year,
              week_assignments: week_assignments,
            },
          ],
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Weekly shift assigned successfully");
        setFetchWeekly(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      handleCancel();
    }
  };

  const updateDailyShift = async (userId, daily_assignments) => {
    try {
      const response = await fetch(`${domain}shift_assignments/daily/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          shift_assignments: [
            {
              user: userId,
              daily_assignments: daily_assignments,
            },
          ],
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Daily shift assigned successfully");
        setFetchDaily(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      handleCancel();
    }
  };

  useEffect(() => {
    const fetchShiftData = async () => {
      try {
        const response = await fetch(
          `${domain}department_filter/?id=${departmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const shifts = res?.reduce((key, value) => {
            return [
              ...key,
              ...value.department_shift_categories.reduce(
                (keys, values) => [...keys, ...values.shifts],
                []
              ),
            ];
          }, []);
          if (dataVisualMethod === "Weekly") {
            const weekShift = shifts?.filter((value) => value?.id !== 99999);
            setShiftData([]);
            setShiftData(weekShift);
          } else {
            setShiftData([]);
            let shiftDataWithLabel = [];
            shiftDataWithLabel = shifts?.map((obj) => ({
              ...obj,
              label: obj.name,
            }));
            const allShifts = {
              id: defaultAllSelection,
              label: "Weekoff",
              name: "Weekoff",
            };
            setShiftName(allShifts?.label);
            setShiftData([allShifts, ...shiftDataWithLabel]);
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (shiftData?.length === 0 && departmentName !== "All") {
      fetchShiftData();
    }
    if (dataVisualMethod !== "Weekly") {
      fetchShiftData();
    }
  }, [
    dataVisualMethod,
    departmentId,
    departmentName,
    domain,
    shiftData?.length,
    token,
  ]);

  useEffect(() => {
    const fetchWeeklyShiftList = async () => {
      try {
        isLoading(true);
        const response = await fetch(
          `${domain}weekly_user_shift_assignments/?department_id=${departmentId}&month=${formattedDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setWeeklyShiftList([]);
          setShiftCatagoryName("");
          setMasterDataForSearch([]);
          setWeeklyShiftList(res?.data);
          setShiftCatagoryName(res?.shift_category);
          setMasterDataForSearch(res?.data);
          isLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        isLoading(false);
      }
    };
    if (fetchWeekly === true && departmentId !== "" && formattedDate) {
      fetchWeeklyShiftList();
      setFetchWeekly(false);
    }
  }, [departmentId, domain, fetchWeekly, formattedDate, token]);

  useEffect(() => {
    const fetchDailyShiftList = async () => {
      isLoading(true);
      try {
        const response = await fetch(
          `${domain}daily_user_shift_assignments/?department_id=${departmentId}&from_date=${startDate}&to_date=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDailyShiftList([]);
          setShiftCatagoryName("");
          setMasterDataForSearch([]);
          setDailyShiftList(res?.data);
          setShiftCatagoryName(res?.shift_category);
          setMasterDataForSearch(res?.data);
          isLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        isLoading(false);
      }
    };
    if (fetchDaily === true && departmentId !== "") {
      fetchDailyShiftList();
      setFetchDaily(false);
    }
  }, [departmentId, domain, endDate, fetchDaily, startDate, token]);

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setDailyShiftList(FilterDockets());
    setFilterChanges(true);
  }, [globalSearchValue, masterDataForSearch]);

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setWeeklyShiftList(FilterDockets());
    setFilterChanges(true);
  }, [globalSearchValue, masterDataForSearch]);

  return departmentName === "All" ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      {/* <Mui.Grid container lg={12} md={12} sx={{ mt: 3 }}>
        <Mui.Grid item xs={6} sm={6} md={10} lg={10}></Mui.Grid>
        <Mui.Grid item xs={6} sm={6} md={2} lg={2}>
          <Mui.Button
            variant={"contained"}
            sx={{
              background: themes.primaryButton,
              textTransform: "capitalize",
              border: "none",
              "&:hover": {
                border: "none",
                backgroundColor: themes.primaryButton,
              },
              color: themes.headLine,
            }}
            onClick={() =>
              history.push("/admin/teamzone/plan-shift/shift-group")
            }
          >
            Shift Group
          </Mui.Button>
        </Mui.Grid>
      </Mui.Grid> */}
      <Mui.Grid container lg={12} md={12} sx={{ mt: 3 }}>
        <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            value={
              departmentData?.find(
                (option) => option?.department_name === departmentName
              ) || {
                department_name: departmentName !== "All" ? departmentName : "",
              }
            }
            options={departmentData?.filter(
              (value) => value.department_name !== "All"
            )}
            getOptionLabel={(option) =>
              option?.department_name ? option?.department_name : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.department_name === value.department_name
            }
            filterOptions={(options, state) => {
              return options.filter((option) =>
                option.department_name
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.department_name}</li>;
            }}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            onChange={async (event, value) => {
              setPresentScreen(true);
              setIsEdit(false);
              setDepartmentId("");
              setDepartmentName("");
              setShiftData([]);
              setShiftName("");
              setshiftId("");
              if (value) {
                dispatch(setDepartmentName(value.department_name));
                dispatch(setDepartmentId(value.id));
                localStorage.setItem("approveLeaveDepartmentId", value.id);
                localStorage.setItem(
                  "approveLeaveDepartmentName",
                  value.department_name
                );
                dataVisualMethod === "Weekly"
                  ? setFetchWeekly(true)
                  : setFetchDaily(true);
              }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                sx={{ background: "white", padding: 0.5 }}
                placeholder="Select department name"
                label="Select Department"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
          <Mui.ButtonGroup size="large" aria-label="Large button group">
            <Mui.Button
              variant={dataVisualMethod === "Weekly" ? "contained" : "outlined"}
              sx={{
                background:
                  dataVisualMethod === "Weekly"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor:
                    dataVisualMethod === "Weekly"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
              onClick={() => {
                handleCancel();
                setPresentScreen(true);
                dispatch(setDepartmentName(departmentName));
                dispatch(setDepartmentId(departmentId));
                setDataVisualMethod("Weekly");
                setFetchWeekly(true);
                dispatch(setPlanShiftScreen("Weekly"));
                localStorage.setItem("planShiftScreen", "Weekly");
              }}
            >
              Weekly
            </Mui.Button>
            <Mui.Button
              variant={dataVisualMethod === "Daily" ? "contained" : "outlined"}
              sx={{
                background:
                  dataVisualMethod === "Daily"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor:
                    dataVisualMethod === "Daily"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
              onClick={() => {
                handleCancel();
                setPresentScreen(true);
                setSelectedWeek(getCurrentWeek);
                dispatch(setDepartmentName(departmentName));
                dispatch(setDepartmentId(departmentId));
                setDataVisualMethod("Daily");
                dispatch(setPlanShiftScreen("Daily"));
                localStorage.setItem("planShiftScreen", "Daily");
                if (startDate !== "" && endDate !== "") {
                  setFetchDaily(true);
                }
              }}
            >
              Daily
            </Mui.Button>
          </Mui.ButtonGroup>
        </Mui.Grid>
        <Mui.Grid item md={4} lg={3.5}></Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={4} lg={2}></Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
          {dataVisualMethod === "Weekly" ? (
            <Mui.Stack>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Date"
                  views={["year", "month"]}
                  size="small"
                  value={weeklyselectedDate}
                  minDate={minDate}
                  onChange={handleWeeklyDateOnChange}
                  slotProps={{ textField: { size: "small" } }}
                  renderInput={(params) => (
                    <Mui.TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Mui.Stack>
          ) : (
            <Mui.Stack style={{ marginTop: 2.5 }}>
              <Mui.Grid item xs={2}>
                <Mui.TextField
                  label="Select Week"
                  type="week"
                  value={selectedWeek}
                  size="small"
                  onChange={handleDailyDateOnChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Mui.Grid>
            </Mui.Stack>
          )}
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid sx={{ paddingTop: 2 }}>
        {dataVisualMethod === "Daily" ? (
          <Daily
            loading={loading}
            dailyShiftList={dailyShiftList}
            isEdit={isEdit}
            editingIndex={editingIndex}
            filterChanges={filterChanges}
            setFilterChanges={setFilterChanges}
            shiftData={shiftData}
            shiftName={shiftName}
            setShiftName={setShiftName}
            shiftId={shiftId}
            setshiftId={setshiftId}
            handleShiftChange={handleShiftChange}
            listOfDate={listOfDate}
            shiftChanged={shiftChanged}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            updateDailyShift={updateDailyShift}
            storeCurrentWeek={storeCurrentWeek}
            selectedWeek={selectedWeek}
          />
        ) : dataVisualMethod === "Weekly" ? (
          <Weekly
            loading={loading}
            weeklyShiftList={weeklyShiftList}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            filterChanges={filterChanges}
            setFilterChanges={setFilterChanges}
            editingIndex={editingIndex}
            shiftData={shiftData}
            shiftName={shiftName}
            setShiftName={setShiftName}
            shiftId={shiftId}
            setshiftId={setshiftId}
            handleShiftChange={handleShiftChange}
            weekNumber={weekNumber}
            selectedYear={selectedYear}
            shiftChanged={shiftChanged}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            updateWeeklyShift={updateWeeklyShift}
            setDataVisualMethod={setDataVisualMethod}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setSelectedWeek={setSelectedWeek}
            setFetchDaily={setFetchDaily}
            departmentId={departmentId}
            departmentName={departmentName}
            setDepartmentId={setDepartmentId}
            setDepartmentName={setDepartmentName}
            storeCurrentWeek={storeCurrentWeek}
            weeklyselectedDate={weeklyselectedDate}
          />
        ) : null}
      </Mui.Grid>
    </React.Fragment>
  );
};
