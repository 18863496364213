import React from "react";
import App from "./App";
import { store } from "./services/Redux/store";
import { Provider } from "react-redux";
import * as ReactDOMClient from "react-dom/client";
import { ThemeProvider } from "@emotion/react";
import { nfb_theme } from "./theme";
import { BrowserRouter as Router } from "react-router-dom";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={nfb_theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch(function (err) {
      console.error("Service Worker registration failed:", err);
    });
}
