import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { showToast } from "components/Status";
import { Loader } from "components/Loader";
import { DialogBox } from "./DialogBox";
import { CustomButton } from "components/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { setGlobalSearchValue } from "services/Redux/userToken";
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  MoreVert,
} from "@mui/icons-material";
import {
  setDepartmentId,
  setDepartmentLeadId,
  setDepartmentName,
} from "services/Redux/userManagament";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import sessionHandling from "services/utils/notificationUtils";
export const ListView = ({
  departmentList,
  isLoading,
  searchedValue,
  page,
  setPage,
  setEditDepartmentDetails,
  setOpenEditScreen,
  setDepartmentApi,
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { token, domain } = useSelector((state) => state.tokenReducer);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expendedId, setExpendedId] = useState(0);
  const [expandedRows, setExpandedRows] = useState(false);
  const [teamMemberApiLoading, setTeamMemberApiLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteUserDetails, setDeleteUserDetails] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTeamMembers, setCurrentTeamMembers] = useState([]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchTeamMembers = async (teamLeadId, teamId) => {
    try {
      setTeamMemberApiLoading(true);
      const response = await fetch(
        `${domain}get_department_members/${teamLeadId}/${teamId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setCurrentTeamMembers(res.members_data);
        setTeamMemberApiLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setTeamMemberApiLoading(false);
    }
  };

  const handleDeleteMember = async () => {
    try {
      const response = await fetch(
        `${domain}department_lead_and_member/delete/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            teamlead_id: deleteUserDetails.department_lead_id,
            user_id: deleteUserDetails.id,
            user_name: deleteUserDetails.department_member_name,
            team_id: deleteUserDetails.department_id,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        fetchTeamMembers(
          deleteUserDetails.department_lead_id,
          deleteUserDetails.department_id
        );
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
    setDeleteConfirmation(false);
  };

  const deleteDepartment = async () => {
    try {
      const response = await fetch(`${domain}delete-department/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          department_id: deleteUserDetails.department_id,
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setDepartmentApi(true);
        showToast("error", res.message);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteCancel = () => {
    setDeleteUserDetails(null);
    setDeleteConfirmation(false);
  };

  const handleClose = () => {
    setDeleteUserDetails(null);
    setOpenDialog(false);
  };

  // const editTeamDetails = () => {
  //   ToDo:this code will be under the development process i need to keep this code
  //   setCurrentEditTeamUsers([]);
  //   setViewEditDetails(true);
  //   setAnchorEl(null);
  //   setOpenEditTeam(true);
  //   setEditTeamMembers(true);
  // };

  return (
    <React.Fragment>
      {isLoading ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : departmentList.length === 0 ? (
        <Mui.Box textAlign="center" my={2}>
          <Mui.Alert severity="info">
            {searchedValue !== "" ? "No data available" : "No data available"}
          </Mui.Alert>
        </Mui.Box>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 2, width: "100%", paddingLeft: 1 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                <Mui.TableCell />
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Department Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Manager
                </Mui.TableCell>

                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Proxy
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Department Members
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Action
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {departmentList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((department) => (
                  <React.Fragment
                    key={`${department.department_lead_id}-${department.department_id}`}
                  >
                    <Mui.TableRow
                      onDoubleClick={() => {
                        dispatch(setDepartmentId(department.department_id));
                        dispatch(
                          setDepartmentLeadId(department.department_lead_id)
                        );
                        dispatch(setDepartmentName(department.department_name));
                        dispatch(setGlobalSearchValue(""));
                        history.push("/admin/department/view/members");
                      }}
                    >
                      <Mui.TableCell align="center">
                        {expendedId === department.department_id &&
                        expandedRows ? (
                          <Mui.IconButton
                            onClick={() => {
                              setExpandedRows(false);
                              setExpendedId(0);
                            }}
                          >
                            <KeyboardArrowDown
                              style={{
                                color: themes.blackColor,
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() => {
                              setCurrentTeamMembers([]);
                              setExpandedRows(
                                expendedId === department.department_id ||
                                  expendedId === 0
                                  ? !expandedRows
                                  : expandedRows
                              );
                              setExpendedId(department.department_id);
                              fetchTeamMembers(
                                department.department_lead_id,
                                department.department_id
                              );
                            }}
                          >
                            <KeyboardArrowRight
                              style={{
                                color: themes.blackColor,
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        )}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department?.department_name}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        <Mui.Stack direction="row">
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt={department?.department_lead_name?.charAt(0)}
                            src={department?.image_url}
                          />
                          <Mui.Typography
                            sx={{
                              paddingLeft: 1,
                            }}
                          >
                            {department?.department_lead_name}
                          </Mui.Typography>
                        </Mui.Stack>
                      </Mui.TableCell>
                      <Mui.TableCell>{department.proxy_name}</Mui.TableCell>
                      <Mui.TableCell>
                        <Mui.AvatarGroup
                          max={6}
                          sx={{
                            "& .MuiAvatar-root": {
                              width: 25,
                              height: 25,
                              fontSize: 15,
                            },
                            justifyContent: "left",
                          }}
                        >
                          {department?.members?.map((member) => (
                            <Mui.Avatar
                              key={member.id}
                              sx={{ width: 25, height: 25 }}
                              alt={member?.username}
                              src={member?.image_url}
                            >
                              {member.image_url ? (
                                <img
                                  src={member?.image_url}
                                  alt="User Mui.Avatar"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                member?.username?.charAt(0).toUpperCase()
                              )}
                            </Mui.Avatar>
                          ))}
                        </Mui.AvatarGroup>
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department.department_name === "General" ? null : (
                          <Mui.IconButton
                            onClick={(event) => {
                              setExpandedRows(false);
                              setExpendedId(0);
                              setEditDepartmentDetails(department);
                              setAnchorEl(event.currentTarget);
                              setDeleteUserDetails(department);
                            }}
                          >
                            <MoreVert />
                          </Mui.IconButton>
                        )}
                        {department.department_name === "General" ? null : (
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.Stack>
                              <Mui.MenuItem
                                onClick={() => setOpenEditScreen(true)}
                              >
                                Edit
                              </Mui.MenuItem>
                              <Mui.MenuItem
                                onClick={() => {
                                  handleMenuClose();
                                  setOpenDialog(true);
                                }}
                              >
                                Delete
                              </Mui.MenuItem>
                            </Mui.Stack>
                          </Mui.Menu>
                        )}
                      </Mui.TableCell>
                    </Mui.TableRow>
                    <Mui.TableRow>
                      <Mui.TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={10}
                      >
                        <Mui.Collapse
                          in={expandedRows}
                          timeout="auto"
                          unmountOnExit
                        >
                          {expendedId === department.department_id ? (
                            <Mui.Box
                              textAlign="center"
                              sx={{
                                margin: 2,
                              }}
                            >
                              {expendedId === department.department_id &&
                              currentTeamMembers.length === 0 ? (
                                teamMemberApiLoading ? (
                                  <Loader info="Loading..." />
                                ) : (
                                  <Mui.Alert severity="info">
                                    No members have been assigned under this
                                    team.
                                  </Mui.Alert>
                                )
                              ) : (
                                expendedId === department.department_id && (
                                  <Mui.Table>
                                    <Mui.TableHead
                                      sx={{
                                        backgroundColor: themes.primary,
                                      }}
                                    >
                                      <Mui.TableRow>
                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Name
                                        </Mui.TableCell>

                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          User code
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Employment Type
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Designation
                                        </Mui.TableCell>

                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Phone Number
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          System Access
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Status
                                        </Mui.TableCell>
                                      </Mui.TableRow>
                                    </Mui.TableHead>
                                    {expandedRows &&
                                      currentTeamMembers?.map(
                                        (member, index) => (
                                          <Mui.TableBody key={index}>
                                            <Mui.TableRow>
                                              <Mui.TableCell>
                                                <Mui.Stack direction="Row">
                                                  <Mui.Avatar
                                                    sx={{
                                                      width: 25,
                                                      height: 25,
                                                    }}
                                                    alt="Remy Sharp"
                                                    src={member.image_url}
                                                  />
                                                  <Mui.Typography
                                                    sx={{
                                                      paddingLeft: 1,
                                                    }}
                                                  >
                                                    {
                                                      member.department_member_name
                                                    }
                                                  </Mui.Typography>
                                                </Mui.Stack>
                                              </Mui.TableCell>
                                              <Mui.TableCell>
                                                {member.user_code}
                                              </Mui.TableCell>
                                              <Mui.TableCell>
                                                {member.employment_type
                                                  ? member.employment_type
                                                  : "N/A"}
                                              </Mui.TableCell>
                                              <Mui.TableCell>
                                                {member.designation
                                                  ? member.designation
                                                  : "N/A"}
                                              </Mui.TableCell>
                                              <Mui.TableCell>
                                                {member.phonenumber}
                                              </Mui.TableCell>
                                              <Mui.TableCell>
                                                {member.system_access
                                                  ? member.system_access
                                                  : "N/A"}
                                              </Mui.TableCell>
                                              <Mui.TableCell>
                                                {member.status}
                                              </Mui.TableCell>
                                            </Mui.TableRow>
                                          </Mui.TableBody>
                                        )
                                      )}
                                  </Mui.Table>
                                )
                              )}
                            </Mui.Box>
                          ) : null}
                        </Mui.Collapse>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </React.Fragment>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {departmentList.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={departmentList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      )}
      <DialogBox
        open={deleteConfirmation}
        handleClose={handleDeleteCancel}
        title="Remove Confirmation"
      >
        {currentTeamMembers.length !== 1 ? (
          <Mui.DialogContent>
            <Mui.DialogContentText>
              Are you sure you want to remove{" "}
              {deleteUserDetails?.team_member_name}&nbsp;from the team ?
            </Mui.DialogContentText>
          </Mui.DialogContent>
        ) : null}
        {currentTeamMembers.length !== 1 ? (
          <Mui.DialogActions>
            <Mui.Button onClick={handleDeleteCancel} color="primary">
              No
            </Mui.Button>
            <Mui.Button
              onClick={() => {
                handleDeleteMember();
              }}
              color="secondary"
            >
              Yes
            </Mui.Button>
          </Mui.DialogActions>
        ) : null}
        {currentTeamMembers.length === 1 ? (
          <Mui.DialogContent>Couldn't Remove Every Member </Mui.DialogContent>
        ) : null}
        {currentTeamMembers.length === 1 ? (
          <Mui.DialogActions>
            <Mui.Button onClick={handleDeleteCancel} color="primary">
              ok
            </Mui.Button>
          </Mui.DialogActions>
        ) : null}
      </DialogBox>

      <GlobalDialogBox
        open={openDialog}
        handleCloseDialog={handleClose}
        title=" Delete Confirmation"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>
            {" "}
            Are you sure you want to delete this department?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={() => {
              setOpenDialog(false);
              handleClose();
            }}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => {
              setOpenDialog(false);
              deleteDepartment();
            }}
            actionName="Yes"
            typeName="button"
          />
        </Mui.DialogActions>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
