import React, { useCallback, useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { Close, AddCircleTwoTone } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
let id = 0;
export const DeductionDetails = ({
  otherSalaryComponents,
  existingUserDetails,
  userDeductionDetails,
  setUserDeductionDetails,
  updateDeductionDetails,
  setUpdateDeductionDetails,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [deductionData, setDeductionData] = useState([]);
  const [addedDeduction, setAddedDeduction] = useState([]);
  const [allDeductionData, setAllDeductionData] = useState([]);
  let { result: deductionDataDetails } = useFetch(
    `${domain}deductions/?company=${existingUserDetails.company_id}`
  );

  useEffect(() => {
    if (deductionDataDetails.length !== 0) {
      setDeductionData(
        deductionDataDetails.filter((data) => {
          return data.deduction_type !== "D";
        })
      );
      setAllDeductionData(
        deductionDataDetails.filter((data) => {
          return data.deduction_type !== "D";
        })
      );
    }
  }, [deductionDataDetails]);

  const getEarningDetailsAfterUpdateEarning = useCallback(
    (data) => {
      let matchEarning = [];
      data?.earnings_detail?.forEach((element) => {
        otherSalaryComponents.forEach((earning) => {
          if (earning.name === element.name) {
            matchEarning = [...matchEarning, earning];
          }
        });
      });
      return matchEarning;
    },
    [otherSalaryComponents]
  );

  useEffect(() => {
    const updateDeductionAmountDetails = () => {
      userDeductionDetails.forEach((element) => {
        const earningData = getEarningDetailsAfterUpdateEarning(element);
        const calculateEmployeeConstributionAmount =
          calculateContributionAmount(
            earningData,
            element.employee_contribution_percentage
          );
        const calculateCompanyContributionAmount = calculateContributionAmount(
          earningData,
          element.company_contribution_percentage
        );
        element.employeeContributionAmount =
          calculateEmployeeConstributionAmount;
        element.companyContributionAmount = calculateCompanyContributionAmount;
      });
    };
    if (updateDeductionDetails && userDeductionDetails !== 0) {
      updateDeductionAmountDetails();
      setUpdateDeductionDetails(false);
    }
  }, [
    getEarningDetailsAfterUpdateEarning,
    setUpdateDeductionDetails,
    updateDeductionDetails,
    userDeductionDetails,
  ]);

  const addNewRow = () => {
    const newRow = {
      rowId: id + 1,
      amount: "",
      name: "",
    };
    id = id + 1;
    setUserDeductionDetails([...userDeductionDetails, newRow]);
  };

  const removeSalaryComponent = (item) => {
    let remaingData = userDeductionDetails.filter((data) => {
      return data.rowId !== item.rowId;
    });
    setUserDeductionDetails(remaingData);
    addDeductionData(item);
  };

  const addDeductionData = (item) => {
    if (item?.name) {
      let removeDeductionDetials = [];
      addedDeduction.forEach((element) => {
        if (element.name === item.name) {
          removeDeductionDetials = element;
        }
      });
      setDeductionData([removeDeductionDetials, ...deductionData]);
      let filterData = addedDeduction.filter((data) => {
        return data.name !== item.name;
      });
      setAddedDeduction(filterData);
    }
  };

  const handleDeductionNameChange = (data, id) => {
    if (data) {
      let updatedData = userDeductionDetails?.map((element) => {
        if (element.rowId === id) {
          const findEarningDetails = getEarningDetails(data);
          const calculateEmployeeConstributionAmount =
            calculateContributionAmount(
              findEarningDetails,
              data.employee_contribution_percentage
            );
          const calculateCompanyContributionAmount =
            calculateContributionAmount(
              findEarningDetails,
              data.company_contribution_percentage
            );
          return {
            ...element,
            ...data,
            earningDetail: findEarningDetails,
            employeeContributionAmount: calculateEmployeeConstributionAmount,
            companyContributionAmount: calculateCompanyContributionAmount,
            deduction_id: data.id,
            name: data.name,
          };
        } else {
          return element;
        }
      });
      setUserDeductionDetails(updatedData);
      setAddedDeduction([...addedDeduction, data]);
      removeAddedDeduction(updatedData);
    } else {
      let remainingData = allDeductionData;
      let currentRowData = userDeductionDetails.filter((fil_data) => {
        return fil_data.rowId === id;
      });
      userDeductionDetails.forEach((element) => {
        remainingData = remainingData.filter((deduction) => {
          return element.name !== deduction.name;
        });
      });
      setDeductionData(
        remainingData.filter((remain) => {
          return remain.name !== currentRowData[0].name;
        })
      );
    }
  };
  const removeAddedDeduction = (updatedData) => {
    let remainingData = allDeductionData;
    updatedData.forEach((element) => {
      remainingData = remainingData.filter((data) => {
        return element.name !== data.name;
      });
    });
    setDeductionData(remainingData);
  };

  const getEarningDetails = (data) => {
    let matchEarning = [];
    data?.earnings_detail?.forEach((element) => {
      otherSalaryComponents.forEach((earning) => {
        if (earning.name === element.name) {
          matchEarning = [...matchEarning, earning];
        }
      });
    });
    return matchEarning;
  };

  const calculateContributionAmount = (data, employeeContributions) => {
    let amount = 0;
    data.forEach((element) => {
      amount = amount + parseInt(element.amount);
    });
    return Math.round(
      (parseInt(amount) * parseFloat(employeeContributions)) / 100
    );
  };

  const updateFieldValue = (field, value, rowid) => {
    if (parseFloat(value) <= 100) {
      const updatedValue = userDeductionDetails.map((element) => {
        if (rowid === element.id) {
          return { ...element, [field]: value };
        }
        return element;
      });

      let updatedDataValue = updatedValue.filter((data) => {
        return data.id === rowid;
      });
      const findEarningDetails = getEarningDetails(updatedDataValue[0]);
      console.log(updatedDataValue);
      const calculateEmployeeConstributionAmount = calculateContributionAmount(
        findEarningDetails,
        updatedDataValue[0].employee_contribution_percentage
      );
      const calculateCompanyContributionAmount = calculateContributionAmount(
        findEarningDetails,
        updatedDataValue[0].company_contribution_percentage
      );
      const updatePercentageAmount = updatedValue.map((element) => {
        if (rowid === element.id) {
          return {
            ...element,
            employeeContributionAmount: calculateEmployeeConstributionAmount,
            companyContributionAmount: calculateCompanyContributionAmount,
          };
        }
        return element;
      });
      setUserDeductionDetails(updatePercentageAmount);
    } else {
      const updatedValue = userDeductionDetails.map((element) => {
        if (rowid === element.id) {
          return { ...element, [field]: value };
        }
        return element;
      });
      setUserDeductionDetails(updatedValue);
    }
  };

  return (
    <React.Fragment>
      <Mui.TableContainer sx={{ mt: 2, width: "100%" }}>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left">
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Component
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Depends on
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Employee contributions
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Company contributions
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Action
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {userDeductionDetails?.map((data, index) => (
              <Mui.TableRow key={data.rowId}>
                <>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={data.name}
                      options={deductionData?.map((obj) => ({
                        ...obj,
                        label: obj.name,
                      }))}
                      sx={{ width: "100%" }}
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option.label}</li>;
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                        },
                      }}
                      onChange={(event, value) => {
                        handleDeductionNameChange(value, data.rowId);
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          sx={{ background: themes.whiteColor }}
                          placeholder="Please select the salary component"
                          size="small"
                          value={data.name}
                        />
                      )}
                    />
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Tooltip
                      title={data?.earnings_detail?.map((earn) => (
                        <span style={{ paddingLeft: 1 }}>{earn.name}</span>
                      ))}
                    >
                      {data?.earnings_detail?.map((earn) => (
                        <span style={{ paddingLeft: 2 }}>{earn.name}</span>
                      ))}
                    </Mui.Tooltip>
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    {data.deduction_type === "S" ? (
                      <Mui.Stack direction="row">
                        <Mui.TextField
                          sx={{ width: 150 }}
                          size="small"
                          value={data.employee_contribution_percentage}
                          onChange={(event) =>
                            updateFieldValue(
                              "employee_contribution_percentage",
                              event.target.value,
                              data.id
                            )
                          }
                        />
                        &nbsp;
                        <Mui.TextField
                          size="small"
                          value={data.employeeContributionAmount}
                          disabled={true}
                          fullWidth
                        />
                      </Mui.Stack>
                    ) : data.deduction_type === "F" ? (
                      <Mui.TextField
                        size="small"
                        value={data.fixed_amount}
                        disabled={true}
                        fullWidth
                      />
                    ) : null}
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    {data.deduction_type === "S" ? (
                      <Mui.Stack direction="row">
                        <Mui.TextField
                          sx={{ width: 150 }}
                          size="small"
                          value={data.company_contribution_percentage}
                          onChange={(event) =>
                            updateFieldValue(
                              "company_contribution_percentage",
                              event.target.value,
                              data.id
                            )
                          }
                        />
                        &nbsp;
                        <Mui.TextField
                          size="small"
                          value={data.companyContributionAmount}
                          disabled={true}
                          fullWidth
                        />
                      </Mui.Stack>
                    ) : null}
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.IconButton>
                      <Close onClick={() => removeSalaryComponent(data)} />
                    </Mui.IconButton>
                  </Mui.TableCell>
                </>
              </Mui.TableRow>
            ))}
            <Mui.TableRow>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Mui.IconButton
                  size="small"
                  onClick={() => addNewRow()}
                  disabled={deductionData?.length === 0 ? true : false}
                >
                  <Mui.Tooltip title="Add Deduction">
                    <AddCircleTwoTone size="large" />
                  </Mui.Tooltip>
                </Mui.IconButton>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
    </React.Fragment>
  );
};
