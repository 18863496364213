import { configureStore } from "@reduxjs/toolkit";
import UserToken from "./userToken";
import userManagementReducer from "./userManagament";
import leaveManagementReducer from "./leaveManagement";
import themeReducer from './Themes';
import projectManagementReducer from "./projectManagement";
import hrmsManagementReducer from "./hrms";

export const store = configureStore({
  reducer: {
    tokenReducer: UserToken,
    userReducer: userManagementReducer,
    leaveReducer: leaveManagementReducer,
    themeReducer: themeReducer,
    projectReducer: projectManagementReducer,
    hrmsReducer: hrmsManagementReducer,
  },
});