import React, { useState, useEffect } from "react";
import { showToast } from "components/Status";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { MoreVert } from "@mui/icons-material";
import { CustomButton } from "components/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { setViewUserGroupDetails } from "services/Redux/userManagament";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { Loader } from "components/Loader";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import * as Mui from "@mui/material";
import { EditGroup } from "./EditGroup";
import { userGroupNameFilter } from "services/constants";
import { DeleteGroup } from "./DeleteGroup";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import sessionHandling from "services/utils/notificationUtils";

export const UserGroup = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [autocompleteVisible, setAutocompleteVisible] = useState(true);
  const { companyId } = useSelector((state) => state.userReducer);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userGroups, setUserGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [editUserGroup, setEditUserGroup] = useState(null);
  const [unAssignedUsers, setUnAssignedUsers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [createNewUserGroup, setCreateNewUserGroup] = useState(false);
  const [userGroupName, setUserGroupName] = useState("");
  const [helperTextValidation, setHelperTextValidation] = useState("");
  const [getUpdatedData, setGetUpdatedData] = useState(true);
  const [openEditGroup, setOpenEditGroup] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [masterDesignationList, setMasterDesignationList] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: unAssignedUsers,
    getOptionLabel: (option) => option.label,
    value: selectedMembers,
    onChange: (event, newValue) => {
      setSelectedMembers(newValue);
    },
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getUserGroup = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}user-group-details/?company_id=${companyId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setUserGroups(res);
          setMasterDesignationList(res);
          setAutocompleteVisible(true);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (getUpdatedData) {
      getUserGroup();
      setGetUpdatedData(false);
    }
  }, [companyId, domain, getUpdatedData, token]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch(`${domain}selected-user-group-members/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const userDetailsResponse = res.map((obj) => ({
            ...obj,
            label: obj.user_name,
          }));
          setUnAssignedUsers(userDetailsResponse);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    getUser();
  }, [companyId, domain, token]);

  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };

  const handleClosePopup = () => {
    setCreateNewUserGroup(false);
    setUserGroupName("");
    setSelectedMembers([]);
    setHelperTextValidation("");
    setButtonDisabled(false);
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const createUserGroup = debounce(async () => {
    if (userGroupName.trim() !== "" && helperTextValidation === "") {
      handleClosePopup();
      try {
        const response = await fetch(`${domain}user-group-details/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            company_id: companyId,
            name: userGroupName,
            selected_members: selectedMembers,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", "User group created successfully");
          handleClosePopup();
          setGetUpdatedData(true);
          setButtonDisabled(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      if (userGroupName.trim() === "") {
        setButtonDisabled(false);
        setHelperTextValidation("User group name is required");
      }
    }
  }, 5);

  const checkSpecialChar = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    const filteredResults = masterDesignationList.filter((item) =>
      userGroupNameFilter.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setUserGroups(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDesignationList, userGroupName]);

  const validateUserGroupName = async () => {
    if (userGroupName.trim().length >= 3) {
      try {
        const response = await fetch(
          `${domain}check/usergroup?name=${userGroupName.trim()}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            setButtonDisabled(false);
            setHelperTextValidation("Group name is already exists");
          } else {
            createUserGroup();
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      setButtonDisabled(false);
      setHelperTextValidation("Minimum 3 characters required");
    }
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ padding: 1, justifyContent: "flex-end" }}>
        <Mui.Grid item>
          <CustomButton
            actionFuntion={() => setCreateNewUserGroup(true)}
            actionName="Add User Group"
            typeName="submit"
          />
        </Mui.Grid>
      </Mui.Grid>
      {userGroups?.length !== 0 ? (
        <Mui.TableContainer sx={{ mt: 2, width: "100%", maxHeight: 810 }}>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow maxWidth="xl" align="left">
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Group Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Users
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Action
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {userGroups
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <Mui.TableRow
                    key={data.id}
                    onDoubleClick={() => {
                      localStorage.setItem("viewGroup", JSON.stringify(data));
                      dispatch(setViewUserGroupDetails(data));
                      dispatch(setGlobalSearchValue(""));
                      history.push("/admin/view/user/groups");
                    }}
                  >
                    <>
                      <Mui.TableCell align="left">
                        {data?.name ? data?.name : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.users_count ? data?.users_count : "N/A"}
                      </Mui.TableCell>

                      <Mui.TableCell align="left">
                        <Mui.IconButton
                          onClick={(event) => {
                            setAnchorElMenu(event.currentTarget);
                            setEditUserGroup(data);
                          }}
                          disabled={
                            data?.name === "UG-SYS-LEAVE APPROVALS" ||
                            data?.name === "UG-SYS-ESS FOR SYSTEM USERS" ||
                            data?.name === "UG-SYS-SUPER ADMIN"
                              ? true
                              : false
                          }
                        >
                          <MoreVert />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorElMenu}
                          open={Boolean(anchorElMenu)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                            sx={{ width: "100%" }}
                            onClick={() => {
                              setOpenEditGroup(true);
                              setAnchorElMenu(null);
                            }}
                          >
                            Edit
                          </Mui.MenuItem>
                          <Mui.MenuItem
                            sx={{ width: "100%" }}
                            onClick={() => {
                              setDeleteGroup(true);
                              setAnchorElMenu(null);
                            }}
                          >
                            Delete
                          </Mui.MenuItem>
                        </Mui.Menu>
                      </Mui.TableCell>
                    </>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      ) : (
        <Mui.Container maxWidth="xl" sx={{ marginTop: 7 }}>
          <Mui.Alert severity="info">No data available</Mui.Alert>
        </Mui.Container>
      )}
      {userGroups.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={userGroups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {createNewUserGroup ? (
        <GlobalDialogBox
          open={createNewUserGroup}
          title="Add User Group"
          handleCloseDialog={handleClosePopup}
        >
          <Mui.Stack className={classes.card}>
            <Mui.Typography sx={{ paddingBottom: 0.5 }}>
              Group Name
            </Mui.Typography>
            <Mui.TextField
              sx={{ paddingBottom: 2 }}
              placeholder="Enter Group Name"
              value={userGroupName}
              error={
                helperTextValidation === "User group name is required" ||
                helperTextValidation === "Group name is already exists" ||
                helperTextValidation === "Minimum 3 characters required"
                  ? true
                  : false
              }
              helperText={
                helperTextValidation === "User group name is required" ||
                helperTextValidation === "Group name is already exists" ||
                helperTextValidation === "Minimum 3 characters required"
                  ? helperTextValidation
                  : null
              }
              InputProps={{
                inputProps: { maxLength: 64 },
              }}
              onChange={(event) => {
                setUserGroupName(event.target.value);
                if (event.target.value.trim().length >= 3) {
                  setButtonDisabled(false);
                }
                if (helperTextValidation === "User group name is required") {
                  setHelperTextValidation("");
                }
              }}
              onKeyPress={checkSpecialChar}
              onBlur={async () => {
                if (userGroupName.replace(/\s/g, "").length >= 3) {
                  try {
                    const response = await fetch(
                      `${domain}check/usergroup?name=${userGroupName.trim()}`,
                      {
                        method: "GET",
                        headers: {
                          "content-type": "application/json",
                          Authorization: `token ${token}`,
                        },
                      }
                    );
                    const res = await response.json();
                    if (response.ok) {
                      if (res?.exists) {
                        setHelperTextValidation("Group name is already exists");
                      } else {
                        setButtonDisabled(false);
                        setHelperTextValidation("");
                      }
                    } else if (response.status === 409) {
                      sessionHandling();
                    } else {
                      throw new Error(res.error);
                    }
                  } catch (error) {
                    showToast("error", error.message);
                  }
                } else {
                  setButtonDisabled(true);
                  setHelperTextValidation("Minimum 3 characters required");
                }
              }}
            />
            <Mui.Typography sx={{ paddingBottom: 0.5 }}>Users</Mui.Typography>
            <div {...getRootProps()}>
              <InputWrapper
                sx={{
                  width: "100%",
                  minHeight: 50,
                  mt: 1,
                  mb: 1,
                  borderRadius: "15px",
                  padding: 1.5,
                }}
                ref={setAnchorEl}
                className={autocompleteVisible || focused ? "focused" : ""}
                autocompleteVisible={autocompleteVisible}
              >
                {selectedMembers.map((option, index) => (
                  <StyledTag label={option.label} {...getTagProps({ index })} />
                ))}
                <input {...getInputProps()} />
              </InputWrapper>
            </div>
            {groupedOptions.length > 0 ? (
              <Listbox
                {...getListboxProps()}
                autocompleteVisible={autocompleteVisible}
              >
                {groupedOptions.map((option, index) => (
                  <li {...getOptionProps({ option, index })}>
                    <span>{option.label}</span>
                    <CheckIcon fontSize="small" />
                  </li>
                ))}
              </Listbox>
            ) : null}
            <Mui.Grid container sx={{ padding: 1, justifyContent: "flex-end" }}>
              <Mui.Grid item>
                <CustomButton
                  actionFuntion={() => {
                    setButtonDisabled(true);
                    validateUserGroupName();
                  }}
                  actionName="Create"
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Stack>
        </GlobalDialogBox>
      ) : null}
      {openEditGroup ? (
        <EditGroup
          openDialog={openEditGroup}
          setOpenDialogBox={setOpenEditGroup}
          editUserGroup={editUserGroup}
          unAssignedUsers={unAssignedUsers}
          setGetUpdatedData={setGetUpdatedData}
        />
      ) : null}
      {deleteGroup ? (
        <DeleteGroup
          openDialog={deleteGroup}
          setOpenDialogBox={setDeleteGroup}
          editUserGroup={editUserGroup}
          setGetUpdatedData={setGetUpdatedData}
        />
      ) : null}
    </React.Fragment>
  );
};

const InputWrapper = Mui.styled("div")(
  ({ theme, autocompleteVisible }) => `
  width: 100%; 
   border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
   background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   border-radius: 4px;
   padding: 1px;
   display: flex;
   flex-wrap: wrap;
   
   &:hover {
     border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
   }

   ${
     autocompleteVisible
       ? `
       &.focused {
         border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
         box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
       }
     `
       : ""
   }

   & input {
     background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
     color: ${
       theme.palette.mode === "dark"
         ? "rgba(255,255,255,0.65)"
         : "rgba(0,0,0,.85)"
     };
    
    
     flex-grow: 1;
     border: 0;
     margin: 0;
     outline: 0;
   }
`
);

const Listbox = Mui.styled("ul")(
  ({ theme, autocompleteVisible }) => `
   width: 100%;
   margin: 2px 0 0;
   padding: 0;
   position: relative;
   list-style: none;
   background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   overflow: auto;
   max-height: 100px;
   border-radius: 4px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
   z-index: 1;
  
   ${
     autocompleteVisible
       ? `
     background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   `
       : ""
   }
  
   & li {
     padding: 5px 8px;
     display: flex;
  
     & span {
       flex-grow: 1;
     }
  
     & svg {
       color: transparent;
     }
   }
  
   & li[aria-selected='true'] {
     background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
     font-weight: 600;
  
     & svg {
       color: #1890ff;
     }
   }
  
   & li.${autocompleteClasses.focused} {
     background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
     cursor: pointer;
  
     & svg {
       color: currentColor;
     }
   }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = Mui.styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 34px;
  margin: 2px;
  line-height: 22px;
  border-radius: 25px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  border-radius: 25px;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
  },
}));
