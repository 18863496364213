import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themes, clientFilterKey } from "services/constants";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { AddClient } from "./AddClient";
import { Loader } from "components/Loader";
import { MoreVert } from "@mui/icons-material";
import { CustomButton } from "components/CustomButton";
import { BackArrow } from "components/BackArrow";
import sessionHandling from "services/utils/notificationUtils";
export const Clients = () => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const role = userDetails.role === "User" ? true : false;
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addClientScreen, setAddClientScreen] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [fetchClients, setFetchClients] = useState(true);
  const [screenName, setScreenName] = useState("Add Client");
  const [editClientDetails, setEditClientDetails] = useState(null);
  const [editDetails, setEditDetails] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(`${domain}client/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setMasterDataForSearch(res);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (fetchClients) {
      fetchClientDetails();
      setFetchClients(false);
    }
  }, [domain, fetchClients, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addNewClient = () => {
    setEditClientDetails({
      clientname: "",
      clientcode: "",
      address: "",
      gstno: "",
      email: "",
      phonenumber: "",
    });
    setScreenName("Add Client");
    setUpdateData(true);
    setAddClientScreen(true);
  };

  const EditClient = (item) => {
    setEditClientDetails({
      id: item.id,
      clientname: item.clientname,
      clientcode: item.clientid,
      address: item.address,
      gstno: item.gstnumber,
      email: item.email,
      phonenumber: item.phonenumber,
    });
    setScreenName("Edit Client");
    setUpdateData(true);
    setAddClientScreen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const filteredResults = masterDataForSearch.filter((item) =>
      clientFilterKey.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setAllClients(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1 }}>
        <Mui.Grid item xs={1} sm={1} md={1} lg={1}>
          {addClientScreen ? (
            <BackArrow actionFunction={() => setAddClientScreen(false)} />
          ) : null}
        </Mui.Grid>
        <Mui.Grid
          item
          xs={11}
          sm={11}
          md={11}
          lg={11}
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {addClientScreen === false && !role ? (
            <CustomButton
              actionFuntion={() => addNewClient()}
              actionName="Add Client"
              typeName="button"
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      <br />
      <Mui.TableContainer
        sx={{
          width: "100%",
          padding: 1,
        }}
      >
        {addClientScreen === false && allClients.length !== 0 ? (
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow
                maxWidth="xl"
                align="left"
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "10px 15px",
                    backgroundColor: themes.primary,
                    borderBottom: "1.5px solid transparent",
                  },
                }}
              >
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Client Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Client Code
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Address
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Gst No
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Email
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Phone No
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 100,
                  }}
                >
                  Actions
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {allClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Mui.TableRow
                    maxWidth="xl"
                    align="left"
                    hover={true}
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "10px 15px",
                      },
                    }}
                  >
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 100,
                      }}
                    >
                      <Mui.Typography
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.clientname}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 100,
                      }}
                    >
                      <Mui.Typography>
                        {item?.clientid ? item?.clientid : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 100,
                      }}
                    >
                      <Mui.Tooltip title={item?.address}>
                        <Mui.Typography
                          sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item?.address ? item?.address : "N/A"}
                        </Mui.Typography>
                      </Mui.Tooltip>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 100,
                      }}
                    >
                      <Mui.Typography
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.gstnumber ? item?.gstnumber : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 100,
                      }}
                    >
                      <Mui.Typography
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.email ? item?.email : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 100,
                      }}
                    >
                      <Mui.Typography
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.phonenumber ? item?.phonenumber : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 100,
                      }}
                    >
                      <Mui.IconButton
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setEditDetails(item);
                        }}
                        disabled={role}
                      >
                        <MoreVert />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setEditClientDetails(null);
                            setAnchorEl(null);
                            EditClient(editDetails);
                          }}
                        >
                          Edit Client
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        ) : allClients.length === 0 && addClientScreen === false ? (
          loading ? (
            <Mui.Grid>
              <Loader info="Loading..." />
            </Mui.Grid>
          ) : (
            <Mui.Alert sx={{ marginTop: 5 }} severity="info">
              No data available
            </Mui.Alert>
          )
        ) : null}
        {addClientScreen === true ? (
          <AddClient
            screenName={screenName}
            editClientDetails={editClientDetails}
            setAddClientScreen={setAddClientScreen}
            updateData={updateData}
            setUpdateData={setUpdateData}
            setFetchClients={setFetchClients}
          />
        ) : null}
        {allClients.length > 10 && addClientScreen === false ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={allClients.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Mui.TableContainer>
    </React.Fragment>
  );
};
