import React, { useState } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/BackArrow";
import { themes } from "services/constants";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import {
  Timeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import moment from "moment";

export const PayrunLog = ({ setOpenSidePeek, openSidePeek }) => {
  const [doAnyAct, setDoAnyAct] = useState(false);
  const [lastHistoryName, setLastHistoryName] = useState("Ashok");
  const taskHistory = [
    {
      event_type: "Initiate payrun",
      user_name: "Ashok",
      details: "initate the payrun details",
      image_url: "http://tom.organflow.co/api/get_images/5/",
    },
    {
      event_type: "Submitted payrun",
      user_name: "Prakash",
      details: "Submitted the payrun details",
      image_url: "http://tom.organflow.co/api/get_images/5/",
    },
    {
      event_type: "Rejected payrun",
      user_name: "Sadheesh",
      details: "Rejected the payrun details",
      image_url: "http://tom.organflow.co/api/get_images/5/",
    },
    {
      event_type: "ReSubmited payrun",
      user_name: "Ashok",
      details: "Resubmitted the payrun details",
      image_url: "http://tom.organflow.co/api/get_images/5/",
    },
    {
      event_type: "Approve payrun",
      user_name: "Manoj",
      details: "Approve the payrun details",
      image_url: "http://tom.organflow.co/api/get_images/5/",
    },
  ];
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Mui.Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSidePeek(open);
    if (open === false) {
      if (doAnyAct) {
        setDoAnyAct(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Mui.Drawer
        anchor="right"
        open={openSidePeek}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: {
            width: {
              xs: 300,
              sm: 360,
              md: 500,
              lg: 700,
            },
          },
        }}
      >
        <Mui.Grid
          container
          sx={{ backgroundColor: themes.primaryIndicator, padding: 1 }}
        >
          <Mui.Grid xs={1}>
            <BackArrow />
          </Mui.Grid>
          <Mui.Grid xs={11}>
            <Mui.Typography
              sx={{
                color: themes.headLine,
                textAlign: "center",
                fontSize: 22,
                paddingTop: 1,
              }}
            >
              Payrun Log
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {taskHistory.map((data) => (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    color="info"
                    style={{ width: "20px", height: "18px" }}
                  />
                  {lastHistoryName === data.event_type ? null : (
                    <TimelineConnector />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Mui.Card
                    sx={{ display: "flex", borderRadius: 3 }}
                    elevation={5}
                  >
                    <Mui.CardMedia
                      sx={{
                        width: 200,
                        backgroundColor: themes.primaryIndicator,
                        borderRadius: 3,
                        flexShrink: 0,
                      }}
                    >
                      <Mui.Stack
                        direction="row"
                        spacing={2}
                        sx={{ overflow: "hidden", m: 2, mb: 0, mt: 1 }}
                      >
                        <Mui.Avatar
                          alt={data.user_name.charAt(0)}
                          src={data.image_url}
                        />
                        <Mui.Tooltip title={data.user_name}>
                          <Mui.Typography
                            sx={{
                              pt: "8px",
                              color: themes.headLine,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {data.user_name}
                          </Mui.Typography>
                        </Mui.Tooltip>
                      </Mui.Stack>
                      <Mui.Typography
                        sx={{ m: 2, mt: 1, mb: 1, color: themes.headLine }}
                      >
                        June 5 , 2024
                        {/* {moment(data.timestamp).format("MMM DD, YY hh:mm a")} */}
                      </Mui.Typography>
                    </Mui.CardMedia>
                    <Mui.Box
                      sx={{
                        display: "flex",
                        borderRadius: 25,
                        flex: 1,
                      }}
                    >
                      <Mui.CardContent
                        sx={{ alignItems: "center", justifyContent: "center" }}
                      >
                        <Mui.Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 18,
                          }}
                        >
                          {data?.event_type}
                          <br />
                          {data?.details}
                        </Mui.Typography>
                      </Mui.CardContent>
                    </Mui.Box>
                  </Mui.Card>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Mui.Grid>
      </Mui.Drawer>
    </React.Fragment>
  );
};
