import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { PastLeaveDetails } from "./PastLeaveDetails";
import moment from "moment";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import "./ButtonGroup.css";
import { showToast } from "components/Status";
import "react-toastify/dist/ReactToastify.css";
import { leaveManagementError } from "services/constants/ErrorMessages";
import sessionHandling from "services/utils/notificationUtils";

export const MyLeaves = () => {
  const nextYear =
    parseInt(moment(new Date(), "YYYY/MM/DD").format("YYYY")) + 1;
  const previousYear =
    parseInt(moment(new Date(), "YYYY/MM/DD").format("YYYY")) - 1;
  const taxCurrentYear =
    moment(new Date(), "YYYY/MM/DD").format("M") > 3
      ? parseInt(moment(new Date(), "YYYY/MM/DD").format("YYYY"))
      : previousYear;
  const nextTaxYear =
    moment(new Date(), "YYYY/MM/DD").format("M") > 3
      ? nextYear
      : parseInt(moment(new Date(), "YYYY/MM/DD").format("YYYY"));
  const { token, domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  // const [showDetails, setShowDetails] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [getDataFromApi, setGetDataFromApi] = useState(true);
  const [taxYear, setTaxYear] = useState(
    moment(new Date(), "YYYY/MM/DD").format("M") > 3
      ? moment(new Date(), "YYYY/MM/DD").format("YYYY") + "-" + nextYear
      : previousYear +
          "-" +
          parseInt(moment(new Date(), "YYYY/MM/DD").format("YYYY"))
  );
  const [taxYearStartDate, setTaxYearStartDate] = useState(
    taxCurrentYear + "-04-01"
  );
  const [taxYearEndDate, setTaxYearEndDate] = useState(nextTaxYear + "-03-31");
  const [data, setData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reUpdatePastLeaveDetails, setReUpdatePastLeaveDetails] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${domain}leavesummary/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setData(res?.leave_count);
          setLeaveData(res?.leave_count);
          handleVisibilityToggle(res?.leave_count);
          setReUpdatePastLeaveDetails(true);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setError(leaveManagementError.dataFetchError);
        setLoading(false);
      }
    };
    if (getDataFromApi) {
      fetchData();
      setGetDataFromApi(false);
    }
  }, [domain, getDataFromApi, token, userDetails.id]);

  const handleVisibilityToggle = (user) => {
    setSelectedUser(user);
  };

  return (
    <div>
      {loading === true ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <PastLeaveDetails
          selectedUser={selectedUser}
          setReUpdatePastLeaveDetails={setReUpdatePastLeaveDetails}
          reUpdatePastLeaveDetails={reUpdatePastLeaveDetails}
          taxYear={taxYear}
          taxYearStartDate={taxYearStartDate}
          taxYearEndDate={taxYearEndDate}
          setSelectedUser={setSelectedUser}
        />
      )}
    </div>
  );
};
