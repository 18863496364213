import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/organflow-dashboard.scss?v1.2.1";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setReloadApi,
  setReloadHRMSApi,
  setAllStatus,
  setActiveStatus,
  setLeaveStatus,
  setOnBreakStatus,
} from "services/Redux/leaveManagement";
import { setCurrentUser, setCurrentLocation } from "./services/Redux/userToken";
import {
  setCurrentScreen,
  setSubMenuExpand,
} from "services/Redux/userManagament";
import { toastContainerStyle } from "./services/constants/index";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { showToast } from "components/Status";
// import { Heartbeat } from "HeartBeat";
import sessionHandling from "services/utils/notificationUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getRoutesByRole } from "routes";

const firebaseConfig = {
  apiKey: "AIzaSyDp5I141R1Be3Towg6p7eVGgPgIaRco2DE",
  authDomain: "push-notification-1c0c8.firebaseapp.com",
  projectId: "push-notification-1c0c8",
  storageBucket: "push-notification-1c0c8.appspot.com",
  messagingSenderId: "409828226876",
  appId: "1:409828226876:web:99cee85a366ad9b6761039",
  measurementId: "G-7NM3BX9XRV",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };

export default function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  let user_token = useSelector((state) => state.tokenReducer.token);
  let [redirect, setRedirect] = useState("");
  let [authenticated, setAuthenticated] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(true);
  const domainUrl = useSelector((state) => state.tokenReducer.domain);
  const token = useSelector((state) => state.tokenReducer.token);
  const date = new Date().toISOString().split("T")[0];
  const routes = getRoutesByRole(
    JSON.parse(localStorage.getItem("productAccess"))
      ? JSON.parse(localStorage.getItem("productAccess"))
      : null
  );

  const updateUserToken = useCallback(
    async (userToken, userId) => {
      try {
        const response = await fetch(`${domainUrl}fcm_token/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fcm_token: userToken, user_id: userId }),
        });

        if (!response.ok) {
          throw new Error("Failed to update the token on the server");
        }
      } catch (error) {
        showToast("error", `An error occurred: ${error.message}`);
      }
    },
    [domainUrl]
  );

  const callYourApiFunction = useCallback(async () => {
    try {
      const response = await fetch(`${domainUrl}checkinfilter/?date=${date}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      if (response.status === 409) {
        sessionHandling();
      }
      dispatch(setReloadApi(true));
      dispatch(setAllStatus(true));
      dispatch(setReloadHRMSApi(true));
      dispatch(setActiveStatus(true));
      dispatch(setLeaveStatus(true));
      dispatch(setOnBreakStatus(true));
      await response.json();
    } catch (error) {
      showToast("error", "Error fetching data:", error);
    }
  }, [date, dispatch, domainUrl, token]);

  const getRouteNames = useCallback(() => {
    if (location.pathname !== "/") {
      routes.forEach((route) => {
        if (route.collapse) {
          route.collapse.forEach((collapsedRoute) => {
            if (collapsedRoute.path === location.pathname) {
              dispatch(setCurrentLocation(route.name));
              dispatch(setCurrentScreen(collapsedRoute.name));
              dispatch(setSubMenuExpand(true));
            }
          });
        }
      });
    }
  }, [dispatch, location.pathname, routes]);

  useEffect(() => {
    const initApp = async () => {
      if (!user_token) {
        setRedirect("/auth/login");
        setAuthenticated(false);
      } else {
        const currentUserString = localStorage.getItem("currentuser");
        const currentUserArray = JSON.parse(currentUserString);
        const currentUserId = currentUserArray
          ? String(currentUserArray.id)
          : null;
        dispatch(
          setCurrentUser(JSON.parse(localStorage.getItem("currentuser")))
        );
        // dispatch(setCurrentLocation(localStorage.getItem("currentScreen")));
        // dispatch(setCurrentScreen(localStorage.getItem("currentSubMenu")));
        setRedirect(
          location.pathname === "/" || location.pathname === "/auth/login"
            ? currentUserArray.role === "Admin" ||
              currentUserArray.role === "Team Lead"
              ? "/admin/teamzone/attendance"
              : "/admin/myzone/status"
            : location.pathname
        );
        getRouteNames(
          location.pathname === "/" || location.pathname === "/auth/login"
            ? currentUserArray.role === "Admin" ||
              currentUserArray.role === "Team Lead"
              ? "/admin/teamzone/attendance"
              : "/admin/myzone/status"
            : location.pathname
        );
        setAuthenticated(true);
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          showToast("info", "Notification permission isn't granted yet!");
          return;
        }
        const existingUserId = localStorage.getItem("currentUserId");
        const existingToken = localStorage.getItem("fcmToken");
        if (permission === "granted") {
          navigator.serviceWorker.ready.then(async (registration) => {
            try {
              const token = await getToken(messaging, {
                serviceWorkerRegistration: registration,
              });
              if (token !== existingToken || currentUserId !== existingUserId) {
                await updateUserToken(token, currentUserId);
                localStorage.setItem("fcmToken", token);
                localStorage.setItem("currentUserId", currentUserId);
              }
            } catch (error) {
              showToast("error", `An error occurred: ${error.message}`);
            }
            const unsubscribe = onMessage(messaging, (payload) => {
              if (payload.data) {
                showToast("success", payload.data.body);
                if (
                  payload.data.body === "Deployment process is about to start"
                ) {
                  localStorage.clear();
                  history.push("/auth/maintenance");
                  window.location.reload();
                } 
                //INFO :I need this code to handle the move to login screens in separate server
              
                // else if (
                //   payload.data.body ===
                //   "Deployment has completed. Please refresh the page to use the latest version"
                // ) {
                //   history.push("/auth/login");
                //   localStorage.clear();
                //   window.location.reload();
                // }
              }
              if (payload.data && payload.data.triggerApi) {
                callYourApiFunction();
              }
            });

            return () => {
              unsubscribe();
            };
          });
        }
      }
    };

    initApp();
  }, [
    callYourApiFunction,
    dispatch,
    getRouteNames,
    history,
    location.pathname,
    updateUserToken,
    user_token,
  ]);

  //ToDo:we need this code to get the current screens

  useEffect(() => {
    if (defaultLocation) {
      // getRouteNames(routes);
      // dispatch(setCurrentLocation("Leave Management"));
      setDefaultLocation(false);
    }
  }, [defaultLocation, dispatch]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          {authenticated === true ? (
            <Route
              path="/admin"
              render={(props) =>
                authenticated === true ? (
                  <AdminLayout {...props} />
                ) : (
                  <Redirect to="/auth/login" />
                )
              }
            />
          ) : (
            <Route
              path="/auth"
              render={(props) =>
                authenticated === false ? (
                  <AuthLayout {...props} />
                ) : (
                  <Redirect to="/admin/myzone/calendar" />
                )
              }
            />
          )}
          <Redirect to={redirect} />
        </Switch>
      </BrowserRouter>
      <ToastContainer toastStyle={toastContainerStyle} />
    </>
  );
}
