import * as React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  styled,
} from "@mui/material";
import { themes } from "services/constants";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && title !== "Logout" ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: themes.blackColor }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const DialogBox = ({ handleClose, open, title, children }) => {
  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <FuncDialogTitle
          id="customized-dialog-title"
          align="center"
          sx={{
            minWidth: 550,
            color: themes.headLine,
            fontWeight: 1000,
            fontSize: "23px",
            background: themes.primary,
          }}
          onClose={handleClose}
          title={title}
        >
          {title}
        </FuncDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </BootstrapDialog>
    </div>
  );
};
