import React from "react";
import { NewProjectContextProvider } from "./NewProjectContext";
import NewProjects from "./NewProjectMain";

export const NewProjectMain = () => {
  return (
    <React.Fragment>
      <NewProjectContextProvider>
        <NewProjects />
      </NewProjectContextProvider>
    </React.Fragment>
  );
};
