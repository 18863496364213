import React, { useState } from "react";
import * as Mui from "@mui/material";
import { Box } from "@material-ui/core";
import { themes } from "services/constants";
import { Loader } from "components/Loader";
import moment from "moment";
export const Weekly = ({
  loading,
  weeklyShiftList,
  weekNumber,
  setDataVisualMethod,
  setStartDate,
  setEndDate,
  setSelectedWeek,
}) => {
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  return (
    <React.Fragment>
      {loading === true ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <Mui.Grid container sx={{ paddingRight: 1 }}>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{ mt: 2, width: "100%" }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Name
                  </Mui.TableCell>
                  {weekNumber?.map((week) => (
                    <Mui.TableCell
                      onClick={() => {
                        setStartDate(
                          moment(week?.startDate).format("YYYY-MM-DD")
                        );
                        setEndDate(moment(week?.endDate).format("YYYY-MM-DD"));
                        const weekValue = `${
                          week?.currentYear
                        }-W${week?.weekNumber.toString().padStart(2, "0")}`;
                        setSelectedWeek(weekValue);
                        setDataVisualMethod("Daily");
                      }}
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      Week {week?.weekNumber}
                      <Mui.Typography>
                        {`${week?.start} - ${week?.end}`}
                      </Mui.Typography>
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {weeklyShiftList?.length === 0 ? (
                  <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                    No data found
                  </Mui.Alert>
                ) : (
                  weeklyShiftList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((shiftList, index) => (
                      <React.Fragment>
                        <Mui.TableRow>
                          <Mui.TableCell>{shiftList?.user_name}</Mui.TableCell>
                          {shiftList?.shifts.map((shift, i) => (
                            <Mui.TableCell>
                              {shift?.shift_name
                                ? shift?.shift_name
                                : "Not Joined"}
                              <br></br>
                              {shift?.shift_name === null ||
                              shift?.shift_name === "Weekoff" ? (
                                ""
                              ) : shift?.shift_start_time &&
                                shift?.shift_end_time ? (
                                <div>
                                  {shift?.shift_start_time?.split(":")[0] +
                                    ":" +
                                    shift?.shift_start_time?.split(":")[1]}{" "}
                                  to{" "}
                                  {shift?.shift_end_time?.split(":")[0] +
                                    ":" +
                                    shift?.shift_end_time?.split(":")[1]}
                                </div>
                              ) : null}
                            </Mui.TableCell>
                          ))}
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {weeklyShiftList?.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={weeklyShiftList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.Grid>
      )}
    </React.Fragment>
  );
};
