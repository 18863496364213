import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomButton";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { CustomTextBox } from "components/CustomTextBox";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "components/Status";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import {
  specialCharacterRestrict,
  onlyNumberRegex,
  ifscCodePatterns,
} from "services/constants/patterns";

export const EditBank = ({
  companyId,
  setEditBankDetails,
  bankDetails,
  reCallApi,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [bankNameDetails, setBankNameDetails] = useState(bankDetails.bank_name);
  const [accountNumber, setAccountNumber] = useState(
    bankDetails.bank_account_number
  );
  const [confirmAccountNumber, setConfirmAccountNumber] = useState(
    bankDetails.bank_account_number
  );
  const [branchNameDetails, setBranchNameDetails] = useState(
    bankDetails.bank_branch
  );
  const [ifscCodeDetails, setIFSCcodeDetails] = useState(
    bankDetails.bank_ifsc_code
  );
  const [nickName, setNickName] = useState(bankDetails.nick_name);

  useEffect(() => {
    setValue("bankName", bankDetails.bank_name);
    setValue("nick_name", bankDetails.nick_name);
    setValue("bankAccountNumber", bankDetails.bank_account_number);
    setValue("confirm_account_number", bankDetails.bank_account_number);
    setValue("branchName", bankDetails.bank_branch);
    setValue("ifscCode", bankDetails.bank_ifsc_code);
  }, [
    bankDetails.bank_account_number,
    bankDetails.bank_branch,
    bankDetails.bank_ifsc_code,
    bankDetails.bank_name,
    bankDetails.nick_name,
    setValue,
  ]);

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${domain}source-bank/${bankDetails.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          bank_account_number: data.bankAccountNumber,
          bank_name: data.bankName,
          bank_branch: data.branchName,
          bank_ifsc_code: data.ifscCode,
          company: companyId,
          nick_name: data.nick_name,
        }),
      });
      if (response.ok) {
        setEditBankDetails(false);
        reset();
        reCallApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error("Account number already exists");
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateBankName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("bankName", event.target.value);
      setBankNameDetails(event.target.value);
    }
  };

  const updateNickName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("nick_name", event.target.value);
      setNickName(event.target.value);
    }
  };

  const updateBankAccountNumber = (event) => {
    if (
      event.target.value.length <= 15 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("bankAccountNumber", event.target.value);
      setAccountNumber(event.target.value);
    }
  };

  const updateConfirmAccountNumber = (event) => {
    if (
      event.target.value.length <= 15 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("confirm_account_number", event.target.value);
      setConfirmAccountNumber(event.target.value);
    }
  };

  const updateBranchName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setValue("branchName", event.target.value);
      setBranchNameDetails(event.target.value);
    }
  };

  const updateIFSCcode = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 11
    ) {
      setValue("ifscCode", event.target.value);
      setIFSCcodeDetails(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        <Mui.Grid container fullWidth sx={{ padding: 1 }}>
          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Bank Name
            </Mui.Typography>
            <CustomTextBox
              selectedValue={bankNameDetails}
              rules={{
                ...register("bankName", {
                  required: "Bank name is required",
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateBankName}
              placeHolder="Enter Bank Name"
              errorMessage={
                errors?.bankName?.message ? errors?.bankName?.message : ""
              }
              textBoxError={errors.bankName ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid xs={0.4}></Mui.Grid>
          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Nick Name
            </Mui.Typography>
            <CustomTextBox
              selectedValue={nickName}
              rules={{
                ...register("nick_name", {
                  required: "Nick name is required",
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateNickName}
              placeHolder="Enter Nick Name"
              errorMessage={
                errors?.nick_name?.message ? errors?.nick_name?.message : ""
              }
              textBoxError={errors.nick_name ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ padding: 1 }}>
          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Account Number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={accountNumber}
              rules={{
                ...register("bankAccountNumber", {
                  required: "Bank account number is required",
                  minLength: {
                    value: 8,
                    message: "Minimum 8 characters required",
                  },
                }),
              }}
              updateDataFunction={updateBankAccountNumber}
              placeHolder="Enter Bank Account Number"
              errorMessage={
                errors?.bankAccountNumber?.message
                  ? errors?.bankAccountNumber?.message
                  : ""
              }
              textBoxError={errors.bankAccountNumber ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid xs={0.4}></Mui.Grid>
          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Confirm Account Number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={confirmAccountNumber}
              rules={{
                ...register("confirm_account_number", {
                  required: "Confrim account number is required",
                  validate: {
                    minLengthNoSpaces: (value) => {
                      if (value !== getValues("bankAccountNumber")) {
                        return "Account number and confirm account number should be same";
                      } else {
                        return true;
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateConfirmAccountNumber}
              placeHolder="Enter Confirm Account Number"
              errorMessage={
                errors?.confirm_account_number?.message
                  ? errors?.confirm_account_number?.message
                  : ""
              }
              textBoxError={errors.confirm_account_number ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ padding: 1 }}>
          <Mui.Grid xs={5.8}>
            {" "}
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Branch
            </Mui.Typography>
            <CustomTextBox
              selectedValue={branchNameDetails}
              rules={{
                ...register("branchName", {
                  required: "Branch name is required",
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateBranchName}
              placeHolder="Enter Branch Name"
              errorMessage={
                errors?.branchName?.message ? errors?.branchName?.message : ""
              }
              textBoxError={errors.branchName ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid xs={0.4}></Mui.Grid>
          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              IFSC code
            </Mui.Typography>
            <CustomTextBox
              selectedValue={ifscCodeDetails}
              rules={{
                ...register("ifscCode", {
                  required: "IFSC code is required",
                  validate: {
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 11 && value !== "") {
                        return "Minimum 11 characters with no spaces";
                      } else {
                        return true;
                      }
                    },
                    checkPatterns: (value) => {
                      if (!ifscCodePatterns.test(value)) {
                        return "Please enter a valid format: ABCD0123456";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateIFSCcode}
              placeHolder="Enter IFSC Code"
              errorMessage={
                errors?.ifscCode?.message ? errors?.ifscCode?.message : ""
              }
              textBoxError={errors.ifscCode ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: {
              xs: 0,
              sm: 0,
              md: 10,
              lg: 10,
            },
            paddingBottom: 2,
          }}
        >
          <Mui.Grid
            xs={12}
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "flex-end",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setEditBankDetails(false);
              }}
              actionName="Cancel"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Update" typeName="submit" />
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
