import React, { useState, useEffect } from "react";
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
  Button,
  Autocomplete,
} from "@material-ui/core";
import { themes } from "services/constants";
import DateRangeIcon from "@mui/icons-material/DateRange";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { dateLabels } from "services/constants";
import { leaveManagamentPlaceholder } from "services/constants/PlaceHolder";

const dateFormat = "YYYY-MM-DD";
const dateMonthFormat = "DD-MM-YYYY";
const initialDate = new Date();

export const DateRangePicker = ({ handleStartDate, selectedDateRange }) => {
  const [currentLabel, setCurrentLabel] = useState("This week");
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [inputValue, setInputValue] = useState(selectedDateRange);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [tempStartDate, setTempStartDate] = useState(initialDate);
  const [tempEndDate, setTempEndDate] = useState(initialDate);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [showCustomField, setShowCustomField] = useState(false);
  const [updateDates, setUpdateDates] = useState(true);
  const minDate = new Date(2020, 0, 1);

  useEffect(() => {
    setInputValue(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    const updateDateRange = () => {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
      const mostRecentMonday = new Date(
        currentDate.setDate(currentDate.getDate() + diffToMonday)
      );
      const currentDateDetails = new Date();
      const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
      const currentWeekSunday = new Date(
        currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
      );
      setTempStartDate(new Date(mostRecentMonday.toISOString().split("T")[0]));
      setTempEndDate(new Date(currentWeekSunday.toISOString().split("T")[0]));
      setFromDate(new Date(mostRecentMonday.toISOString().split("T")[0]));
      setToDate(new Date(currentWeekSunday.toISOString().split("T")[0]));
      setInputValue(
        moment(new Date(mostRecentMonday.toISOString().split("T")[0])).format(
          "DD-MM-YYYY"
        ) +
          " - " +
          moment(
            new Date(currentWeekSunday.toISOString().split("T")[0])
          ).format("DD-MM-YYYY")
      );
    };
    if (updateDates) {
      updateDateRange();
      setUpdateDates(false);
    }
  }, [updateDates]);

  const onAdornmentClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
    setShowCustomField(true);
  };

  const onInputChange = (e) => {
    const newValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);
    setInputValue(newValue);
    setTempStartDate(fromDate);
    setTempEndDate(toDate);
  };

  const onApply = () => {
    setShowCustomField(false);
    handleStartDate(
      moment(new Date(tempStartDate)).format(dateFormat),
      moment(new Date(tempEndDate)).format(dateFormat)
    );
    setInputValue(
      `${moment(new Date(tempStartDate)).format(dateMonthFormat)}  -  ${moment(
        new Date(tempEndDate)
      ).format(dateMonthFormat)}`
    );
    setSelectedStartDate(tempStartDate);
    setSelectedEndDate(tempEndDate);
    setDisplayCalendar(false);
  };

  const onCancel = () => {
    if (selectedStartDate && selectedEndDate) {
      setTempStartDate(selectedStartDate);
      setTempEndDate(selectedEndDate);
      setInputValue(
        `${moment(selectedStartDate).format(dateMonthFormat)} - ${moment(
          selectedEndDate
        ).format(dateMonthFormat)}`
      );
    } else {
      setInputValue("");
    }
    setDisplayCalendar(false);
  };

  const onPopoverClose = () => {
    setInputValue(
      `${moment(fromDate).format(dateMonthFormat)}  -  ${moment(toDate).format(
        dateMonthFormat
      )}`
    );
    setDisplayCalendar(false);
    setAnchorEl(null);
    setTempStartDate(fromDate);
    setTempEndDate(toDate);
  };

  const onSelectDateRanges = ({ selection }) => {
    const { startDate, endDate } = selection;
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const handleCloseDatePicker = () => {
    setShowCustomField(false);
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const processInputValue = (value) => {
    const [fromDateStr, toDateStr] = value.split("-").map((elm) => elm.trim());
    const fromDate = moment(fromDateStr, dateMonthFormat, true);
    const toDate = moment(toDateStr, dateMonthFormat, true);
    const validFromDate = fromDate.isValid() ? fromDate.toDate() : undefined;
    const validToDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate: validFromDate, toDate: validToDate };
  };

  const handleManualDateInput = () => {
    let update_fromDate = "";
    let update_toDate = "";
    if (dateRangeSelection === "Custom") {
      [update_fromDate, update_toDate] = inputValue
        .split("-")
        .map((elm) => elm.trim());
    } else {
      let { fromDate, toDate } = processInputValue(inputValue);
      update_fromDate = fromDate;
      update_toDate = toDate;
    }

    if (update_fromDate && update_toDate) {
      setTempStartDate(update_fromDate);
      setTempEndDate(update_toDate);
      setSelectedStartDate(update_fromDate);
      setSelectedEndDate(update_toDate);
      setInputValue(
        `${moment(fromDate).format(dateMonthFormat)}  -  ${moment(
          toDate
        ).format(dateMonthFormat)}`
      );
    } else if (inputValue === "") {
      setTempStartDate(null);
      setTempEndDate(null);
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setInputValue("");
    }
  };

  const getDataWithDateRange = (start, end) => {
    handleStartDate(
      moment(new Date(start)).format(dateFormat),
      moment(new Date(end)).format(dateFormat)
    );
    setInputValue(
      `${moment(new Date(start)).format(dateMonthFormat)}  -  ${moment(
        new Date(end)
      ).format(dateMonthFormat)}`
    );
    setSelectedStartDate(start);
    setSelectedEndDate(end);
    setDisplayCalendar(false);
  };

  const dateRangeSelection = async (action) => {
    switch (action) {
      case "This week":
        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
        const mostRecentMonday = new Date(
          currentDate.setDate(currentDate.getDate() + diffToMonday)
        );
        const currentDateDetails = new Date();
        const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
        const currentWeekSunday = new Date(
          currentDateDetails.setDate(
            currentDateDetails.getDate() + diffToSunday
          )
        );
        setTempStartDate(
          new Date(mostRecentMonday.toISOString().split("T")[0])
        );
        setTempEndDate(new Date(currentWeekSunday.toISOString().split("T")[0]));
        getDataWithDateRange(
          new Date(mostRecentMonday.toISOString().split("T")[0]),
          new Date(currentWeekSunday.toISOString().split("T")[0])
        );
        break;
      case "Last week":
        const lastWeekCurrentDate = new Date();
        const lastWeekCurrentDay = lastWeekCurrentDate.getDay();
        const lastWeekdiffToMonday =
          (lastWeekCurrentDay === 0 ? -6 : 1) - lastWeekCurrentDay - 7;
        const lastMostRecentMonday = new Date(
          lastWeekCurrentDate.setDate(
            lastWeekCurrentDate.getDate() + lastWeekdiffToMonday
          )
        );
        const lastDateDetails = new Date();
        const LastDiffToSunday =
          (lastWeekCurrentDay === 0 ? 0 : 7) - lastWeekCurrentDay - 7;
        const lastWeekSunday = new Date(
          lastDateDetails.setDate(lastDateDetails.getDate() + LastDiffToSunday)
        );
        setTempStartDate(
          new Date(lastMostRecentMonday.toISOString().split("T")[0])
        );
        setTempEndDate(new Date(lastWeekSunday.toISOString().split("T")[0]));
        getDataWithDateRange(
          new Date(lastMostRecentMonday.toISOString().split("T")[0]),
          new Date(lastWeekSunday.toISOString().split("T")[0])
        );
        break;
      case "This month":
        let monthCurrentDate = new Date();
        let firstDay = new Date(
          monthCurrentDate.getFullYear(),
          monthCurrentDate.getMonth(),
          1
        );
        let lastDay = new Date(
          monthCurrentDate.getFullYear(),
          monthCurrentDate.getMonth() + 1,
          0
        );
        setTempStartDate(firstDay);
        setTempEndDate(lastDay);
        getDataWithDateRange(firstDay, lastDay);
        break;
      case "Last month":
        let currentMonthDate = new Date();
        let lastMonthFirstDay = new Date(
          currentMonthDate.getFullYear(),
          currentMonthDate.getMonth() - 1,
          1
        );
        let lastMonthLastDay = new Date(
          currentMonthDate.getFullYear(),
          currentMonthDate.getMonth(),
          0
        );
        setTempStartDate(lastMonthFirstDay);
        setTempEndDate(lastMonthLastDay);
        getDataWithDateRange(lastMonthFirstDay, lastMonthLastDay);
        break;
      case "Last year":
        let currentYear = moment(new Date()).year();
        let yearStartDate = `01/01/${currentYear - 1}`;
        let yearEndDate = `12/31/${currentYear - 1}`;
        setTempStartDate(new Date(yearStartDate));
        setTempEndDate(new Date(yearEndDate));
        getDataWithDateRange(new Date(yearStartDate), new Date(yearEndDate));
        break;
      case "This year":
        let currentYearStartDate = `01/01/${moment(new Date()).year()}`;
        let currentYearEndDate = `12/31/${moment(new Date()).year()}`;
        setTempStartDate(new Date(currentYearStartDate));
        setTempEndDate(new Date(currentYearEndDate));
        getDataWithDateRange(
          new Date(currentYearStartDate),
          new Date(currentYearEndDate)
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      {showCustomField === false ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          size="small"
          value={inputValue}
          options={dateLabels}
          filterOptions={(options, state) => {
            return options.filter((option) =>
              option.label
                .toLowerCase()
                .includes(state.inputValue.toLowerCase())
            );
          }}
          renderOption={(props, option) => {
            return <li {...props}>{option.label}</li>;
          }}
          ListboxProps={{
            style: {
              maxHeight: "150px",
            },
          }}
          onChange={(event, value) => {
            if (value) {
              if (value.label === "Custom") {
                setShowCustomField(true);
              }
              dateRangeSelection(value.label);
              setCurrentLabel(value.label);
            }
          }}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={currentLabel}
              value={inputValue}
              sx={{ background: themes.whiteColor }}
              placeholder={leaveManagamentPlaceholder.dateRange}
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <DateRangeIcon
                      style={{ margin: "0 8px", color: "#a6a6a6" }}
                    />
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      ) : (
        <TextField
          label={currentLabel}
          placeholder={leaveManagamentPlaceholder.dateFormat}
          defaultValue={inputValue}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleCloseDatePicker()}>
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={onAdornmentClick}>
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true, required: true }}
          onChange={onInputChange}
          onBlur={handleManualDateInput}
          autoFocus
          fullWidth
        />
      )}
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <DateRange
          ranges={[
            {
              startDate: tempStartDate,
              endDate: tempEndDate,
              key: "selection",
            },
          ]}
          onChange={onSelectDateRanges}
          scroll={{ enabled: true }}
          minDate={minDate}
          showMonthAndYearPickers={true}
          showDateDisplay={false}
        />
        <br />
        <div
          style={{
            float: "right",
          }}
        >
          <Button
            color="error"
            size="small"
            onClick={onCancel}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={onApply}
            sx={{ marginRight: 1 }}
          >
            Apply
          </Button>
        </div>
      </Popover>
    </>
  );
};
