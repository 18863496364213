import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import * as Mui from "@mui/material";
import { Box } from "@material-ui/core";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import { Cancel, Done, Edit } from "@material-ui/icons";
import moment from "moment";
export const Weekly = ({
  loading,
  weeklyShiftList,
  isEdit,
  setIsEdit,
  editingIndex,
  shiftData,
  shiftName,
  setShiftName,
  shiftId,
  setshiftId,
  handleShiftChange,
  updateWeeklyShift,
  weekNumber,
  selectedYear,
  shiftChanged,
  handleCancel,
  handleEdit,
  setDataVisualMethod,
  setStartDate,
  setEndDate,
  setSelectedWeek,
  setFetchDaily,
  filterChanges,
  setFilterChanges,
  departmentId,
  departmentName,
  setDepartmentId,
  setDepartmentName,
  storeCurrentWeek,
  weeklyselectedDate,
}) => {
  const dispatch = useDispatch();
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  useEffect(() => {
    if (filterChanges) {
      setFilterChanges(false);
      setLeavePage(0);
    }
  }, [filterChanges, setFilterChanges]);

  return (
    <React.Fragment>
      {loading === true ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{ mt: 2, width: "100%" }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Name
                  </Mui.TableCell>
                  {weekNumber?.map((week) => (
                    <Mui.TableCell
                      onClick={() => {
                        setIsEdit(false);
                        dispatch(setDepartmentName(departmentName));
                        dispatch(setDepartmentId(departmentId));
                        setStartDate(
                          moment(week?.startDate).format("YYYY-MM-DD")
                        );
                        setEndDate(moment(week?.endDate).format("YYYY-MM-DD"));
                        const weekValue = `${
                          week?.currentYear
                        }-W${week?.weekNumber.toString().padStart(2, "0")}`;
                        setSelectedWeek(weekValue);
                        setDataVisualMethod("Daily");
                        setFetchDaily(true);
                      }}
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      Week {week?.weekNumber}
                      <Mui.Typography>
                        {`${week?.start} - ${week?.end}`}
                      </Mui.Typography>
                    </Mui.TableCell>
                  ))}
                  {weeklyselectedDate?.getMonth() >= new Date().getMonth() ? (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Action
                    </Mui.TableCell>
                  ) : null}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {weeklyShiftList?.length === 0 ? (
                  <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                    No data found
                  </Mui.Alert>
                ) : (
                  weeklyShiftList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((shiftList, index) => (
                      <React.Fragment>
                        <Mui.TableRow>
                          <Mui.TableCell>{shiftList?.user_name}</Mui.TableCell>
                          {shiftList?.shifts.map((shift, i) =>
                            isEdit &&
                            editingIndex === index &&
                            weekNumber[i]?.weekNumber >= storeCurrentWeek &&
                            shift?.shift_name !== "Custom Shift" ? (
                              <Mui.TableCell>
                                <Mui.Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  value={
                                    shiftData?.find(
                                      (option) => option?.name === shiftName[i]
                                    ) || ""
                                  }
                                  options={shiftData.filter(
                                    (value) => value?.label !== "Weekoff"
                                  )}
                                  getOptionLabel={(option) =>
                                    option?.name ?? shift?.shift_name ?? ""
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.name === value.name
                                  }
                                  filterOptions={(options, state) => {
                                    return options.filter((option) =>
                                      option && option.name
                                        ? option.name
                                            .toLowerCase()
                                            .includes(
                                              state.inputValue.toLowerCase()
                                            )
                                        : false
                                    );
                                  }}
                                  renderOption={(props, option) => {
                                    return <li {...props}>{option.name}</li>;
                                  }}
                                  ListboxProps={{
                                    style: {
                                      maxHeight: "150px",
                                      overflow: "auto",
                                    },
                                  }}
                                  onChange={async (event, value) => {
                                    if (!value) {
                                      setshiftId("");
                                      setShiftName("");
                                      handleShiftChange(i, "", "");
                                    } else {
                                      handleShiftChange(
                                        i,
                                        value?.name,
                                        value?.id
                                      );
                                    }
                                  }}
                                  sx={{ width: "100%" }}
                                  renderInput={(params) => (
                                    <Mui.TextField
                                      {...params}
                                      sx={{
                                        background: themes.whiteColor,
                                        padding: 0.5,
                                      }}
                                      placeholder="Select shift"
                                      size="small"
                                    />
                                  )}
                                />
                              </Mui.TableCell>
                            ) : (
                              <Mui.TableCell sx={{ paddingLeft: 10 }}>
                                {shift?.shift_name
                                  ? shift?.shift_name
                                  : "Not Joined"}
                                <br></br>
                                {shift?.shift_name === null ||
                                shift?.shift_name === "Weekoff" ? (
                                  ""
                                ) : shift?.shift_start_time &&
                                  shift?.shift_end_time ? (
                                  <div>
                                    {shift?.shift_start_time?.split(":")[0] +
                                      ":" +
                                      shift?.shift_start_time?.split(
                                        ":"
                                      )[1]}{" "}
                                    to{" "}
                                    {shift?.shift_end_time?.split(":")[0] +
                                      ":" +
                                      shift?.shift_end_time?.split(":")[1]}
                                  </div>
                                ) : null}
                              </Mui.TableCell>
                            )
                          )}
                          {weeklyselectedDate?.getMonth() >=
                          new Date().getMonth() ? (
                            <Mui.TableCell>
                              {isEdit && editingIndex === index ? (
                                <React.Fragment>
                                  <Mui.IconButton
                                    onClick={(event) => {
                                      const week_assignments = shiftList?.shifts
                                        .map((value, i) => ({
                                          week_number:
                                            weekNumber[i]?.weekNumber,
                                          shift_id:
                                            shiftId[i] ?? value?.shift_id,
                                        }))
                                        .filter(
                                          (value, i) =>
                                            shiftChanged[i] && value?.shift_id
                                        );
                                      updateWeeklyShift(
                                        shiftList?.user_id,
                                        selectedYear,
                                        week_assignments
                                      );
                                    }}
                                  >
                                    <Done
                                      sx={{
                                        color: themes.primaryIndicator,
                                        strokeWidth: 2,
                                      }}
                                    />
                                  </Mui.IconButton>
                                  <Mui.IconButton onClick={handleCancel}>
                                    <Cancel
                                      sx={{
                                        color: themes.redColor,
                                        strokeWidth: 2,
                                      }}
                                    />
                                  </Mui.IconButton>
                                </React.Fragment>
                              ) : (
                                <Mui.IconButton
                                  onClick={(event) => {
                                    handleEdit(index);
                                  }}
                                >
                                  <Edit
                                    sx={{
                                      color: themes.primaryIndicator,
                                      strokeWidth: 2,
                                    }}
                                  />
                                </Mui.IconButton>
                              )}
                            </Mui.TableCell>
                          ) : null}
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {weeklyShiftList?.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={weeklyShiftList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
