import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/BackArrow";
import { themes } from "services/constants";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { specialCharacterRestrict } from "services/constants/patterns";
import { CustomTextBox } from "components/CustomTextBox";
import { useForm, Controller } from "react-hook-form";
import { CustomButton } from "components/CustomButton";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
import { useFetch } from "services/hooks/useFetch";

export const NewPaySchedule = ({
  companyId,
  companyName,
  setCreateNewPaySchedule,
  reCallApi,
}) => {
  const {
    control,
    register,
    // reset,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [payrunName, setPayrunName] = useState("");
  const [selectedSalaryGroup, setSelectedSalaryGroup] = useState([]);
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const minDate = new Date("2020-01-01");
  const { result: members } = useFetch(
    companyId !== 0 ? `${domain}salary-group/?company=${companyId}` : null
  );
  useEffect(() => {
    setValue("PayFrequencyKey", "M");
    setValue("payfrequency_details", "Monthly");
  }, [setValue]);

  const validatePayDate = (value) => {
    const selectedDate = new Date(value);
    if (isNaN(selectedDate.getTime())) {
      return "Invalid date";
    }
    if (selectedDate < minDate) {
      return "Pay date cannot be before 01/01/2020, 2020";
    }
    return true;
  };

  const updateUserName = (event) => {
    if (event.target.value.length <= 32) {
      setValue("payrunname", event.target.value);
      setPayrunName(event.target.value);
    }
  };

  const handlePayrunStartDate = (event) => {
    setValue("start_payrun_period", event.target.value);
  };

  const handlePayrunEndDate = (event) => {
    setValue("end_payrun_period", event.target.value);
  };

  const handlePayDate = (event) => {
    setValue("payDate", event.target.value);
  };

  const onSubmit = async (data) => {
    let salaryGroup = [];
    data.salary_group.forEach((element) => {
      salaryGroup.push(element.id);
    });
    try {
      const response = await fetch(`${domain}payschedule/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          name: data.payrunname,
          start_date: data.start_payrun_period,
          end_date: data.end_payrun_period,
          run_date: data.payDate,
          company: companyId,
          salary_group: salaryGroup,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        setCreateNewPaySchedule(false);
        reCallApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1.5 }}>
        <BackArrow actionFunction={() => setCreateNewPaySchedule(false)} />
        &nbsp;
        <Mui.Typography
          sx={{
            fontSize: 25,
            fontWeight: "bold",
            color: themes.blackColor,
            paddingTop: 1,
          }}
        >
          Add Pay Schedule
        </Mui.Typography>
      </Mui.Grid>

      <Mui.Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "50vh" }}
        elavation={5}
      >
        <Mui.Paper elevation={5} sx={{ width: 950, height: 660 }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ paddingTop: 10, padding: 20 }}
          >
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Typography sx={{ fontSize: 30, textAlign: "center" }}>
                Pay schedule for {companyName}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Payrun Name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={payrunName}
                rules={{
                  ...register("payrunname", {
                    required: "payrunname is required",
                    maxLength: {
                      value: 32,
                      message: "Maximum 32 characters are required",
                    },
                    validate: {
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateUserName}
                placeHolder="Enter the payrun name"
                errorMessage={
                  errors?.payrunname?.message ? errors?.payrunname?.message : ""
                }
                textBoxError={errors.payrunname ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Grid container>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 1,
                    fontWeight: "bold",
                  }}
                >
                  Payrun period
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.Grid xs={6}>
                  <CustomTextBox
                    type="date"
                    rules={{
                      ...register("start_payrun_period", {
                        required: "Payrun start date is required",
                        validate: {
                          minLengthNoSpaces: (value) => {
                            const startDate = new Date(value);
                            const minDate = new Date("2020-01-01");
                            const endDate = new Date(
                              getValues("end_payrun_period")
                            );
                            if (startDate < minDate) {
                              return "Start date must be 01/01/2020 or later";
                            }
                            if (startDate <= endDate) {
                              return true;
                            } else {
                              return "Start date is lesser then end date";
                            }
                          },
                        },
                      }),
                    }}
                    updateDataFunction={handlePayrunStartDate}
                    errorMessage={
                      errors?.start_payrun_period?.message
                        ? errors?.start_payrun_period?.message
                        : ""
                    }
                    textBoxError={errors.start_payrun_period ? true : false}
                  />
                </Mui.Grid>
                <Mui.Grid xs={6} sx={{ paddingLeft: 2 }}>
                  <CustomTextBox
                    type="date"
                    rules={{
                      ...register("end_payrun_period", {
                        required: "Payrun end date is required",
                        validate: {
                          minLengthNoSpaces: (value) => {
                            const endDate = new Date(value);
                            const minDate = new Date("2020-01-01");
                            const startDate = new Date(
                              getValues("start_payrun_period")
                            );
                            if (endDate < minDate) {
                              return "End date must be 01/01/2020 or later";
                            } else if (endDate >= startDate) {
                              return true;
                            } else {
                              return "End date is greater then start date";
                            }
                          },
                        },
                      }),
                    }}
                    updateDataFunction={handlePayrunEndDate}
                    errorMessage={
                      errors?.end_payrun_period?.message
                        ? errors?.end_payrun_period?.message
                        : ""
                    }
                    textBoxError={errors.end_payrun_period ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Paydate
              </Mui.Typography>
              <CustomTextBox
                type="date"
                rules={{
                  ...register("payDate", {
                    required: "Pay date is required",
                    validate: validatePayDate,
                  }),
                }}
                updateDataFunction={handlePayDate}
                errorMessage={
                  errors?.payDate?.message ? errors?.payDate?.message : ""
                }
                textBoxError={errors.payDate ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Salary Group
              </Mui.Typography>
              <Controller
                name="salary_group"
                control={control}
                defaultValue={selectedSalaryGroup}
                rules={{
                  required: "Please select at least one salary_group",
                }}
                render={({ field }) => (
                  <Mui.Autocomplete
                    {...field}
                    size="small"
                    fullWidth
                    multiline
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    options={members}
                    getOptionLabel={(option) =>
                      option?.name ? option?.name : ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                    onChange={(event, value) => {
                      if (value) {
                        setSelectedSalaryGroup(value[0]?.name);
                        field?.onChange(value);
                      } else {
                        setSelectedSalaryGroup([]);
                        field.onChange([]);
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Mui.Chip
                          variant="outlined"
                          label={option?.name}
                          {...getTagProps({ index })}
                          fullWidth
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props} style={{ width: 800 }}>
                          <Mui.Checkbox
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={<CheckBox />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          &nbsp;{option.name}
                        </li>
                      );
                    }}
                    componentsProps={{
                      popper: { style: { width: "fit-content" } },
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select leave type"
                        error={!!errors.salary_group}
                        helperText={
                          errors.salary_group ? errors.salary_group.message : ""
                        }
                      />
                    )}
                  />
                )}
              />
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 10,
              }}
            >
              <CustomButton
                actionFuntion={() => setCreateNewPaySchedule(false)}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;
              <CustomButton actionName="Add" typeName="submit" />
            </Mui.Grid>
          </form>
        </Mui.Paper>
      </Mui.Grid>
    </React.Fragment>
  );
};
