import React from "react";
import { ShiftGroups } from "./ShiftGroups";

export const ShiftGroupIndex = () => {
  return (
    <React.Fragment>
      <ShiftGroups />
    </React.Fragment>
  );
};
