import React, { useState } from "react";
import * as Mui from "@mui/material";
// import { AccountBalance } from "@mui/icons-material";
import { themes } from "services/constants";
export const Loans = () => {
  const [overTimeScreen, setOverTimeScreen] = useState("Approve");

  const handleChange = (event, newAlignment) => {
    setOverTimeScreen(newAlignment);
  };

  const renderComponent = () => {
    switch (overTimeScreen) {
      case "approved":
        return (
          <></>
          //  <Approved
          //    setReloadApprovedData={setReloadApprovedData}
          //    reloadApprovedData={reloadApprovedData}
          //  />
        );
      case "rejected":
        return (
          <></>
          //  <HrmsPermission
          //    setReloadRejectedData={setReloadRejectedData}
          //    reloadRejectedData={reloadRejectedData}
          //  />
        );
      case "pending":
        return (
          <></>
          //  <Pending
          //    data={pendingdata}
          //    loading={loading}
          //    onApproveClick={handleApproveClick}
          //    setUpdateLeaveCard={setUpdateLeaveCard}
          //    setReloadData={setReloadData}
          //  />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 5, paddingLeft: 3 }}>
        <Mui.Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: 500,
            height: 55,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Mui.ToggleButtonGroup
            value={overTimeScreen}
            exclusive
            onChange={handleChange}
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: "30px",
              backgroundColor: themes.lightBrown,
            }}
          >
            <Mui.ToggleButton
              value="Approve"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                borderRadius: "30px",
                fontWeight: 600,
                border: "none",
                color:
                  overTimeScreen === "Approve"
                    ? themes.whiteColor
                    : themes.blackColor,
                backgroundColor:
                  overTimeScreen === "Approve"
                    ? themes.blackColor
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.blackColor,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    overTimeScreen === "Approve"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    overTimeScreen === "Approve"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
              }}
              onClick={() => {
                // setUpdateLeaveCard(true);
                // setSelectedComponent("pending");
                // setReloadData(true);
              }}
            >
              Approve
            </Mui.ToggleButton>
            <Mui.ToggleButton
              value="History"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                fontWeight: 600,
                border: "none",
                color:
                  overTimeScreen === "History"
                    ? themes.whiteColor
                    : themes.blackColor,
                backgroundColor:
                  overTimeScreen === "History"
                    ? themes.blackColor
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.blackColor,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    overTimeScreen === "History"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    overTimeScreen === "History"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
              }}
              onClick={() => {
                // setSelectedComponent("approved");
                // setReloadApprovedData(true);
                // setReloadData(true);
              }}
            >
              History
            </Mui.ToggleButton>
            <Mui.ToggleButton
              value="Apply Leave"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                borderRadius: "30px",
                fontWeight: 600,
                border: "none",
                color:
                  overTimeScreen === "Apply Leave"
                    ? themes.whiteColor
                    : themes.blackColor,
                backgroundColor:
                  overTimeScreen === "Apply Leave"
                    ? themes.blackColor
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.blackColor,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    overTimeScreen === "Apply Leave"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    overTimeScreen === "Apply Leave"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
                "&:focus": {
                  backgroundColor:
                    overTimeScreen === "Apply Leave"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    overTimeScreen === "Apply Leave"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
              }}
              onClick={() => {
                // setReloadRejectedData(true);
                // setSelectedComponent("rejected");
              }}
            >
              Apply
            </Mui.ToggleButton>
          </Mui.ToggleButtonGroup>
        </Mui.Box>
        {/* <OverTimeTable /> */}
        <Mui.Grid container>{renderComponent()}</Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
