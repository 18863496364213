import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Sidebar } from "components/Sidebar";
import { useSelector } from "react-redux";
import { getRoutesByRole } from "routes";

function Admin() {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const { productAccess } = useSelector((state) => state.tokenReducer);
  const routes = getRoutesByRole(productAccess);


  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const toggleSidenav = () => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setOpen((current) => !current);
  };

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        open={open}
        logo={{
          innerLink: "/",
          imgSrc: require("assets/img/brand/nfblogo.png"),
          imgAlt: "...",
        }}
      />
      <div
        className="main-content"
        ref={mainContentRef}
        style={open ? { marginLeft: 250 } : { marginLeft: 65 }}
      >
        <Switch>
          {getRoutes()}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
      </div>
    </>
  );
}

export default Admin;
