import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "components/Status";

export const useFetch = (url) => {
  const token = useSelector((state) => state.tokenReducer.token);
  const [result, setResult] = useState([]);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setResult(res);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoad(false);
      }
    };
    if (token && url && load) {
      fetchData();
    }
  }, [load, token, url]);

  return {
    result,
    setLoad,
    loading,
  };
};
