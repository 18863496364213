import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { Daily } from "./Daily";
import { Weekly } from "./Weekly";
import { showToast } from "components/Status";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachWeekOfInterval,
  getWeek,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import moment from "moment";
import sessionHandling from "services/utils/notificationUtils";
export const MyShift = () => {
  const [dataVisualMethod, setDataVisualMethod] = useState("Weekly");
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [weekNumber, setWeekNumber] = useState([]);
  const [weeklyselectedDate, setWeeklySelectedDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(new Date(), "yyyy-MM");
  const [loading, setIsLoading] = useState(false);
  const [fetchWeekly, setFetchWeekly] = useState(false);
  const [fetchDaily, setFetchDaily] = useState(false);
  const [weeklyShiftList, setWeeklyShiftList] = useState([]);
  const [dailyShiftList, setDailyShiftList] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [shiftCatagoryName, setShiftCatagoryName] = useState("");

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days =
      Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)) +
      startDate.getDay();
    const weekNumber = Math.ceil(days / 7);
    const weekValue = `${currentDate.getFullYear()}-W${weekNumber
      .toString()
      .padStart(2, "0")}`;
    return weekValue;
  };
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek);
  const handleDailyDateOnChange = (event) => {
    setSelectedWeek(event.target.value);
  };
  const handleWeeklyDateOnChange = (date) => {
    setWeeklySelectedDate(date);
  };
  useEffect(() => {
    if (selectedWeek && dataVisualMethod === "Daily") {
      const startDate = moment(selectedWeek).startOf("isoWeek").toDate();
      const endDate = moment(selectedWeek).endOf("isoWeek").toDate();
      setStartDate(moment(startDate).format("YYYY-MM-DD"));
      setEndDate(moment(endDate).format("YYYY-MM-DD"));
      if (startDate !== "" && endDate !== "") {
        setFetchDaily(true);
      }
    }
  }, [dataVisualMethod, selectedWeek]);

  useEffect(() => {
    const getWeekNumbersOfMonth = () => {
      const start = startOfMonth(weeklyselectedDate);
      const end = endOfMonth(weeklyselectedDate);
      const weeks = eachWeekOfInterval({ start, end }, { weekStartsOn: 1 });
      const maxWeeks = 52;
      const currentMonth = weeklyselectedDate.getMonth();
      const nextYear = weeklyselectedDate.getFullYear() + 1;
      const currentYear = weeklyselectedDate.getFullYear();
      return weeks
        .map((week) => {
          const weekNumber = getWeek(week, { weekStartsOn: 1 });
          const weekStart = startOfWeek(week, { weekStartsOn: 1 });
          const weekEnd = endOfWeek(week, { weekStartsOn: 1 });

          if (
            (currentMonth === 11 && weekNumber === 1) ||
            (currentMonth !== 11 &&
              weekNumber === 1 &&
              weeks[0]?.getFullYear() === nextYear) ||
            weekNumber > maxWeeks
          ) {
            return null;
          }

          return {
            weekNumber: weekNumber,
            start: format(weekStart, "MMM d"),
            end: format(weekEnd, "MMM d"),
            startDate: weekStart,
            endDate: weekEnd,
            currentYear: currentYear,
          };
        })
        .filter((week) => week !== null);
    };
    if (weekNumber) {
      const getWeekNumber = getWeekNumbersOfMonth();
      setWeekNumber([]);
      setWeekNumber(getWeekNumber);
    }
  }, [weekNumber, weeklyselectedDate]);

  useEffect(() => {
    const formatDate = () => {
      if (weeklyselectedDate) {
        const formattedDate =
          weeklyselectedDate?.getFullYear() +
          "-" +
          ("0" + (weeklyselectedDate?.getMonth() + 1)).slice(-2);
        setFormattedDate("");
        setFormattedDate(formattedDate);
      }
    };
    if (formattedDate && dataVisualMethod === "Weekly") {
      formatDate();
      setFetchWeekly(true);
    }
  }, [dataVisualMethod, formattedDate, weeklyselectedDate]);

  useEffect(() => {
    const fetchWeeklyShiftList = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${domain}weekly_user_shift_assignments/?month=${formattedDate}&user_id=${userDetails?.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setWeeklyShiftList([]);
          setShiftCatagoryName("");
          setMasterDataForSearch([]);
          setWeeklyShiftList(res?.data);
          setShiftCatagoryName(res?.shift_category);
          setMasterDataForSearch(res?.data);
          setIsLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setIsLoading(false);
      }
    };
    if (
      fetchWeekly === true &&
      formattedDate !== "" &&
      userDetails?.id !== ""
    ) {
      fetchWeeklyShiftList();
      setFetchWeekly(false);
    }
  }, [domain, fetchWeekly, formattedDate, token, userDetails?.id]);
  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setWeeklyShiftList(FilterDockets());
  }, [globalSearchValue, masterDataForSearch]);

  useEffect(() => {
    const fetchDailyShiftList = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${domain}daily_user_shift_assignments/?user_id=${userDetails?.id}&from_date=${startDate}&to_date=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDailyShiftList([]);
          setShiftCatagoryName("");
          setMasterDataForSearch([]);
          setDailyShiftList(res?.data);
          setShiftCatagoryName(res?.shift_category);
          setMasterDataForSearch(res?.data);
          setIsLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setIsLoading(false);
      }
    };
    if (
      fetchDaily === true &&
      startDate !== "" &&
      endDate !== "" &&
      userDetails?.id !== ""
    ) {
      fetchDailyShiftList();
      setFetchDaily(false);
    }
  }, [domain, endDate, fetchDaily, startDate, token, userDetails?.id]);

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setDailyShiftList(FilterDockets());
  }, [globalSearchValue, masterDataForSearch]);

  return (
    <React.Fragment>
      <Mui.Grid sx={{ paddingTop: 2 }}>
        <Mui.ButtonGroup size="large" aria-label="Large button group">
          <Mui.Button
            variant={dataVisualMethod === "Weekly" ? "contained" : "outlined"}
            onClick={() => {
              setDataVisualMethod("Weekly");
              setFetchWeekly(true);
            }}
            sx={{
              background:
                dataVisualMethod === "Weekly"
                  ? themes.primaryButton
                  : "outlined",
              textTransform: "capitalize",
              border: "none",
              "&:hover": {
                border: "none",
                backgroundColor:
                  dataVisualMethod === "Weekly"
                    ? themes.primaryButton
                    : "outlined",
              },
              color: themes.headLine,
            }}
          >
            Weekly
          </Mui.Button>
          <Mui.Button
            variant={dataVisualMethod === "Daily" ? "contained" : "outlined"}
            onClick={() => {
              setDataVisualMethod("Daily");
              setSelectedWeek(getCurrentWeek);
              if (startDate !== "" && endDate !== "") {
                setFetchDaily(true);
              }
            }}
            sx={{
              background:
                dataVisualMethod === "Daily"
                  ? themes.primaryButton
                  : "outlined",
              textTransform: "capitalize",
              border: "none",
              "&:hover": {
                border: "none",
                backgroundColor:
                  dataVisualMethod === "Daily"
                    ? themes.primaryButton
                    : "outlined",
              },
              color: themes.headLine,
            }}
          >
            Daily
          </Mui.Button>
        </Mui.ButtonGroup>
        <br />

        <Mui.Stack
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: 10,
            alignSelf: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {dataVisualMethod === "Weekly" ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  size="small"
                  label="Select Date"
                  views={["year", "month"]}
                  value={weeklyselectedDate}
                  onChange={handleWeeklyDateOnChange}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    width: 250,
                  }}
                  renderInput={(params) => (
                    <Mui.TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </>
          ) : (
            <>
              <Mui.TextField
                label="Select Week"
                size="small"
                type="week"
                value={selectedWeek}
                onChange={handleDailyDateOnChange}
                style={{
                  width: 250,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          )}
          <Mui.TextField
            label="Shift Catagory"
            type="text"
            value={shiftCatagoryName ?? "N/A"}
            readOnly={true}
            onChange={() => null}
            style={{
              width: 250,
              marginLeft: 5,
            }}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Mui.Stack>
        {dataVisualMethod === "Daily" ? (
          <Daily loading={loading} dailyShiftList={dailyShiftList} />
        ) : (
          <Weekly
            loading={loading}
            weeklyShiftList={weeklyShiftList}
            weekNumber={weekNumber}
            setDataVisualMethod={setDataVisualMethod}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setSelectedWeek={setSelectedWeek}
          />
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
