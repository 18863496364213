import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import * as Icons from "@mui/icons-material";
import * as Mui from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import Person4Icon from "@mui/icons-material/Person4";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import { BackArrow } from "components/BackArrow";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";

export const EditDepartment = ({
  setOpenEditScreen,
  editDepartmentDetails,
  setDepartmentApi,
}) => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [shiftCategoryName, setShiftCategoryName] = useState(
    editDepartmentDetails.shift_category_name
  );
  const [departmentLeadName, setDepartmentLeadName] = useState(
    editDepartmentDetails.department_lead_name
  );
  const [departmentProxyName, setDepartmentProxyName] = useState(
    editDepartmentDetails.proxy_name
  );
  const [departmentLeadList, setDepartmentLeadList] = useState([]);
  const [departmentProxyList, setDepartmentProxyList] = useState([]);
  const [shiftCategoryList, setShiftCategroyList] = useState([]);
  const {
    setValue,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("department", editDepartmentDetails.department_name);
    setValue("shiftCategory", editDepartmentDetails.shift_category_name);
    setValue("departmentLead", editDepartmentDetails.department_lead_name);
    setValue("proxyName", editDepartmentDetails.proxy_name);
    setValue("departmentLeadId", editDepartmentDetails.department_lead_id);
    setValue("departmentProxy", editDepartmentDetails.proxy_id);
    setValue("shiftCategoryId", editDepartmentDetails.shift_category_id);
  }, [
    editDepartmentDetails.department_lead_id,
    editDepartmentDetails.department_lead_name,
    editDepartmentDetails.department_name,
    editDepartmentDetails.proxy_id,
    editDepartmentDetails.proxy_name,
    editDepartmentDetails.shift_category_id,
    editDepartmentDetails.shift_category_name,
    setValue,
  ]);

  useEffect(() => {
    const fetchDepartmentLead = async () => {
      try {
        const response = await fetch(`${domain}departmentleadfilter/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const userDetailsResponse = res.map((obj) => ({
            ...obj,
            label: obj.user.auth_user.username,
          }));
          setDepartmentLeadList(userDetailsResponse);
          setDepartmentProxyList(userDetailsResponse);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentLead();
  }, [domain, token]);

  useEffect(() => {
    const fetchShift = async () => {
      try {
        const response = await fetch(`${domain}shift_category/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const userDetailsResponse = res.map((obj) => ({
            ...obj,
            label: obj.name,
          }));
          setShiftCategroyList(userDetailsResponse);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchShift();
  }, [domain, token]);

  const editDepartmentOnSubmit = async (data) => {
    try {
      const response = await fetch(`${domain}edit_department/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          department_lead_id: data.departmentLeadId,
          shift_category: shiftCategoryName,
          departmentname: data.department,
          department_id: editDepartmentDetails.department_id,
          proxy_id: data.departmentProxy,
          approved_by: userDetails.username,
          selectedMembers: null,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        reset();
        setOpenEditScreen(false);
        setDepartmentApi(true);
        showToast("success", res.message);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const checkSpecialChar = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const getDepartmentProxyList = (leadName) => {
    if (leadName !== "") {
      setDepartmentProxyList(departmentLeadList);
    } else {
      setDepartmentProxyList([]);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(editDepartmentOnSubmit)}>
        <Mui.Grid container>
          <Mui.Grid xs={12} sm={12} md={3} lg={3}>
            <Mui.Stack direction="row">
              <BackArrow actionFunction={() => setOpenEditScreen(false)} />
              &nbsp;
              <Mui.Typography
                sx={{
                  paddingLeft: 2,
                  fontSize: 22,
                  fontWeight: "bold",
                  paddingTop: 1,
                }}
              >
                Edit Department
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              justifyContent: "center",
              direction: "column",
              paddingTop: { xs: 3, sm: 3, md: 7, lg: 10 },
            }}
          >
            <Mui.Typography sx={{ fontSize: 22, fontWeight: "medium" }}>
              Department name
            </Mui.Typography>
            <Mui.TextField
              size="medium"
              placeholder="Enter department name"
              sx={{
                borderWidth: "10px solid black",
                paddingBottom: 2,
              }}
              {...register("department", {
                required: "Department is required",
                maxLength: {
                  value: 30,
                  message: "Maximum length is 30 characters",
                },
                minLength: {
                  value: 3,
                  message: "Minimum length is 3 characters",
                },
                validate: {
                  noDigitsOrSpecial: (fieldValue) => {
                    if (!/[A-Za-z]/.test(fieldValue)) {
                      return "Department Name must contain at least one alphabet character.";
                    }
                    if (/^\d+$/.test(fieldValue)) {
                      return "Department Name should not contain only digits.";
                    }
                    if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                      return "DepartmentClient Name should only contain alphabets, spaces, -,numbers and & symbol.";
                    }
                    return true;
                  },
                  checkUnique: async (fieldValue) => {
                    if (
                      fieldValue !== "" &&
                      fieldValue !== editDepartmentDetails.department_name
                    ) {
                      const response = await fetch(
                        `${domain}check_department_name/?department_name=${fieldValue}`,
                        {
                          method: "GET",
                          headers: {
                            "content-type": "application/json",
                            Authorization: `token ${token}`,
                          },
                        }
                      );
                      const resp = await response.json();
                      if (resp.exists) {
                        return "The department name has been already taken.Use a unique department name to proceed further";
                      }
                    }
                  },
                },
              })}
              error={Boolean(errors.department)}
              helperText={errors.department?.message || ""}
              onKeyPress={checkSpecialChar}
              InputProps={{
                startAdornment: (
                  <Mui.InputAdornment>
                    <Mui.IconButton>
                      <Icons.AddBusiness
                        style={{
                          fontSize: 25,
                          color: themes.blackColor,
                          fontWeight: "bold",
                        }}
                      />
                    </Mui.IconButton>
                  </Mui.InputAdornment>
                ),
              }}
              fullWidth
            />
            <Mui.Typography sx={{ fontSize: 22, fontWeight: "medium" }}>
              Default Shift Category
            </Mui.Typography>
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={shiftCategoryName}
              options={shiftCategoryList}
              sx={{ width: "100%", paddingBottom: 2 }}
              ListboxProps={{ style: { maxHeight: "150px" } }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.name
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.name}</li>;
              }}
              onChange={(event, value) => {
                if (value) {
                  setShiftCategoryName(value.name);
                  setValue("shiftCategoryId", value.id);
                } else {
                  setValue("shiftCategoryId", null);
                  setShiftCategoryName("");
                }
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  value={shiftCategoryName}
                  {...register("shiftCategory", {
                    required: "Shift category is required",
                  })}
                  sx={{
                    background: themes.whiteColor,
                    maxHeight: 120,
                  }}
                  placeholder="Select shift category"
                  size="medium"
                  error={Boolean(errors.shiftCategory)}
                  helperText={errors.shiftCategory?.message || ""}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <GroupsIcon
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <Mui.Typography sx={{ fontSize: 22, fontWeight: "medium" }}>
              Department Lead
            </Mui.Typography>
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={departmentLeadName}
              options={departmentLeadList}
              sx={{ width: "100%" }}
              ListboxProps={{ style: { maxHeight: "150px" } }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.user.auth_user.username}</li>;
              }}
              onChange={(event, value) => {
                if (value) {
                  setDepartmentLeadName(value.user.auth_user.username);
                  setValue("departmentLeadId", value.user.id);
                  getDepartmentProxyList(value.user.auth_user.username);
                } else {
                  setValue("departmentLeadId", null);
                  setDepartmentLeadName("");
                  getDepartmentProxyList("");
                }
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  value={departmentLeadName}
                  {...register("departmentLead", {
                    required: "Department lead is required",
                  })}
                  sx={{
                    background: themes.whiteColor,
                    maxHeight: 120,
                    paddingBottom: 2,
                  }}
                  placeholder="Select department lead"
                  size="medium"
                  error={Boolean(errors.departmentLead)}
                  helperText={errors.departmentLead?.message || ""}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <Person4Icon
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  fullWidth
                />
              )}
            />
            <Mui.Typography sx={{ fontSize: 22, fontWeight: "medium" }}>
              Proxy
            </Mui.Typography>
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={departmentProxyName}
              options={departmentProxyList}
              sx={{ width: "100%", paddingBottom: 2 }}
              ListboxProps={{ style: { maxHeight: "150px" } }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.user.auth_user.username}</li>;
              }}
              onChange={(event, value) => {
                if (value) {
                  setDepartmentProxyName(value.user.auth_user.username);
                  setValue("departmentProxy", value.user.id);
                } else {
                  setValue("departmentProxy", null);
                  setDepartmentProxyName("");
                }
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  value={departmentProxyName}
                  {...register("proxyName", {
                    required: "Proxy name is required",
                  })}
                  sx={{
                    background: themes.whiteColor,
                    maxHeight: 120,
                  }}
                  placeholder="Select proxy name"
                  size="medium"
                  error={Boolean(errors.proxyName)}
                  helperText={errors.proxyName?.message || ""}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <Person4Icon
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  fullWidth
                />
              )}
            />
            <Mui.Grid
              container
              sx={{ justifyContent: "right", paddingBottom: 2 }}
            >
              <CustomButton actionName="Update" typeName="submit" />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={12} md={3} lg={3}></Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
