import React, { useEffect, useState } from "react";
import {
  DownloadForOffline,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import { getColorWorkingHours } from "components/Status";
import { DateRangePicker } from "./DateRangePicker";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import moment from "moment";
import * as Mui from "@mui/material";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { leaveSuccess } from "services/constants/SuccessMessages";
import sessionHandling from "services/utils/notificationUtils";

export const IndividualWorkingHours = () => {
  const { domain, token, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [breakInTime, setBreakInTime] = useState(null);
  const [breakOutTime, setBreakOutTime] = useState(null);
  const [breakInError, setBreakInError] = useState(null);
  const [breakOutError, setBreakOutError] = useState(null);
  const [breakPopup, setBreakPopup] = useState(false);
  const [breakDayDetails, setBreakDayDetails] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [workingHoursDetails, setWorkingHoursDetails] = useState([]);
  const [handleLoading, setHandleLoading] = useState(false);
  const [expandRow, setExpandRow] = useState(null);
  const [userWeeklyReport, setUserWeeklyReport] = useState([]);
  const [ediBreakErrors, setEditBreakErrors] = useState("");
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [showBreakDetails, setShowBreakDetails] = useState(true);

  useEffect(() => {
    const getWorkingHours = async () => {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
      const mostRecentMonday = new Date(
        currentDate.setDate(currentDate.getDate() + diffToMonday)
      );
      const currentDateDetails = new Date();
      const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
      const currentWeekSunday = new Date(
        currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
      );
      let searchStartDate = moment(new Date(mostRecentMonday))
        .format("DD/MM/YYYY")
        .split("/")
        .reverse()
        .join("-");
      let searchEndDate = moment(new Date(currentWeekSunday))
        .format("DD/MM/YYYY")
        .split("/")
        .reverse()
        .join("-");
      setHandleLoading(true);
      try {
        const response = await fetch(
          `${domain}individual_details_working_hours/?user_id=${userDetails.id}&start_date=${searchStartDate}&end_date=${searchEndDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setUserWeeklyReport(res);
          setMasterDataForSearch(
            res?.checkin_details ? res?.checkin_details : []
          );
          setWorkingHoursDetails(
            res?.checkin_details ? res?.checkin_details : []
          );
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
        setHandleLoading(false);
        setShowBreakDetails(false);
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (showBreakDetails) {
      getWorkingHours();
    }
  }, [domain, setShowBreakDetails, showBreakDetails, token, userDetails.id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
    const mostRecentMonday = new Date(
      currentDate.setDate(currentDate.getDate() + diffToMonday)
    );
    const currentDateDetails = new Date();
    const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
    const currentWeekSunday = new Date(
      currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
    );
    setSelectedDateRange(
      `${moment(new Date(mostRecentMonday.toISOString().split("T")[0])).format(
        "DD/MM/YYYY"
      )} - ${moment(
        new Date(currentWeekSunday.toISOString().split("T")[0])
      ).format("DD/MM/YYYY")}`
    );
  }, []);

  const handleStartDate = async (startDate, endDate) => {
    setSelectedDateRange(`${startDate} - ${endDate}`);
    let searchStartDate = startDate.split("/").reverse().join("-");
    let searchEndDate = endDate.split("/").reverse().join("-");
    setHandleLoading(true);
    try {
      const response = await fetch(
        `${domain}individual_details_working_hours/?user_id=${userDetails.id}&start_date=${searchStartDate}&end_date=${searchEndDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setUserWeeklyReport(res);
        setMasterDataForSearch(res.checkin_details);
        setWorkingHoursDetails(res.checkin_details);
        setHandleLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
      setHandleLoading(false);
      setShowBreakDetails(false);
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleTableClick = (date) => {
    setExpandRow(date);
  };

  const DownloadData = () => {
    let dataPreparation = [];
    workingHoursDetails?.forEach((element) => {
      let overAllData = {
        date: element.date,
        checkin: element.check_in_time_formatted,
        checkout: element.check_out_time_formatted,
        break: element.break_hours,
        workingHours: element.working_hours,
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const username = ["UserName"];
    const DateRange = ["DateRange"];
    const WorkingHours = ["WorkingHours"];
    const Permission = ["Permissions"];
    const Late = ["Late"];
    const Break = ["Break"];
    const Leave = ["Leave"];
    const Absent = ["Absent"];
    Permission.push(userWeeklyReport?.total_permission_days);
    Late.push(userWeeklyReport?.total_late_days);
    Leave.push(userWeeklyReport?.personal_leave);
    Break.push(userWeeklyReport?.total_break_hours);
    Absent.push(userWeeklyReport?.absent_days);
    username.push(userDetails?.username);
    DateRange.push(selectedDateRange);
    WorkingHours.push(userWeeklyReport.weekly_working_hours);
    const items = dataPreparation;
    const TableHead = ["Date"];
    TableHead.push("CheckInTime");
    TableHead.push("CheckOutTime");
    TableHead.push("BreakHours");
    TableHead.push("WorkingHours");
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter(
      (field) =>
        field !== "break_details" &&
        field !== "permission_details" &&
        field !== "id" &&
        field !== "total_permission_taken" &&
        field !== "total_permission_taken" &&
        field !== "Late_days" &&
        field !== "type"
    );
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(Absent.join(","));
    csv.unshift(Break.join(","));
    csv.unshift(Late.join(","));
    csv.unshift(Leave.join(","));
    csv.unshift(Permission.join(","));
    csv.unshift(WorkingHours.join(","));
    csv.unshift(DateRange.join(","));
    csv.unshift(username.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "WorkingHours.csv";
    document.body.appendChild(element);
    element.click();
  };
  const handleCloseDialog = () => {
    setBreakDayDetails(null);
    setBreakPopup(false);
    setBreakOutError(null);
    setBreakInError(null);
    setBreakInTime(null);
    setBreakOutTime(null);
  };

  const createNewBreaks = async () => {
    const breakInData = getTwentyFourHourTime(breakInTime);
    const breakOutData = getTwentyFourHourTime(breakOutTime);
    const checkBreakOutTime =
      parseInt(breakOutData.split(":")[0]) * 60 +
      parseInt(breakOutData.split(":")[1]);
    const checkBreakInTime =
      parseInt(breakInData.split(":")[0]) * 60 +
      parseInt(breakInData.split(":")[1]);
    if (breakInTime && breakOutTime) {
      if (checkBreakInTime > checkBreakOutTime) {
        try {
          const response = await fetch(`${domain}break-user/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              break_in: getTwentyFourHourTime(breakInTime),
              break_out: getTwentyFourHourTime(breakOutTime),
              user: userDetails.id,
              date: breakDayDetails?.date.split("-").reverse().join("-"),
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setShowBreakDetails(true);
            handleCloseDialog();
            showToast("success", leaveSuccess.breakInOutTime);
            setEditBreakErrors("");
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      } else {
        setEditBreakErrors(leaveManagementError.breakError);
      }
    } else {
      if (breakInTime === null) {
        setBreakInError(leaveManagementError.breakInError);
      } else {
        setBreakOutError(leaveManagementError.breakOutError);
      }
    }
  };

  const getTwentyFourHourTime = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    }
  };
  const validate = () => {
    if (breakInTime === null) {
      setBreakInError(leaveManagementError.breakInError);
    } else {
      setBreakInError("");
    }
    if (breakOutTime === null) {
      setBreakOutError(leaveManagementError.breakOutError);
    } else {
      setBreakOutError("");
    }
  };

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        (masterDataForSearch ? masterDataForSearch[0] : null) &&
        Object.keys(masterDataForSearch ? masterDataForSearch[0] : null);
      return masterDataForSearch?.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toString().toLowerCase()) > -1
            : null
        )
      );
    };
    setWorkingHoursDetails(FilterDockets());
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2.5 }}>
        <Mui.Grid xs={2}>
          <Mui.Grid conatiner>
            <Mui.Stack
              direction="Row"
              alignItems="center"
              justifyContent="center"
            >
              <Mui.Avatar
                sx={{
                  width: 100,
                  height: 100,
                  bgcolor: "#90c6f0",
                }}
                alt={userDetails?.username?.charAt(0).toUpperCase()}
                src={userDetails?.image_url}
              />
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid xs={2}>
          <Mui.Stack>
            <Mui.Typography sx={{ fontWeight: "bold", fontSize: 30 }}>
              {userDetails?.username}
            </Mui.Typography>
            <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
              {userDetails?.role}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid xs={5}></Mui.Grid>
        <Mui.Grid xs={2.5}>
          <DateRangePicker
            handleStartDate={handleStartDate}
            selectedDateRange={selectedDateRange}
          />
        </Mui.Grid>
        <Mui.Grid xs={0.5}></Mui.Grid>
      </Mui.Grid>
      {handleLoading === false && workingHoursDetails?.length === 0 ? (
        <Mui.Grid conatiner sx={{ paddingTop: 5, width: "100%" }}>
          <Mui.Alert severity="info" sx={{ width: "100%" }}>
            There is no data available
          </Mui.Alert>
        </Mui.Grid>
      ) : null}
      {workingHoursDetails?.length !== 0 ? (
        <>
          <Mui.Box
            sx={{
              marginTop: 2,
              boxShadow: 3,
              ml: 15,
              mr: 15,
              p: 2,
              maxWidth: "93%",
              minWidth: "85%",
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#101010" : "#fff",
              color: (theme) =>
                theme.palette.mode === "dark" ? "grey.300" : "grey.800",
              border: "1px solid",
              borderColor: (theme) =>
                theme.palette.mode === "dark" ? "grey.800" : "grey.300",
              borderRadius: 2,
              fontSize: "0.875rem",
              fontWeight: "700",
              justifyContent: "center",
            }}
          >
            <Mui.Grid container spacing={1}>
              <Mui.Grid
                item
                xs={2}
                sx={{ borderRight: "3px solid black", textAlign: "center" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: themes.permissionTap,
                      fontWeight: "bold",
                      fontSize: 19,
                    }}
                  >
                    Working Days
                  </span>
                  <span
                    style={{
                      color: themes.permissionTap,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;{userWeeklyReport.working_days}
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.4}
                sx={{ borderRight: "3px solid black", textAlign: "center" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: themes.lateTap,
                      fontWeight: "bold",
                      fontSize: 19,
                    }}
                  >
                    Late
                  </span>
                  <span
                    style={{
                      color: themes.lateTap,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;{userWeeklyReport.total_late_days} days
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.6}
                sx={{ borderRight: "3px solid black", textAlign: "center" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: themes.breakTap,
                      fontWeight: "bold",
                      fontSize: 19,
                    }}
                  >
                    Break
                  </span>
                  <span
                    style={{
                      color: themes.breakTap,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;{userWeeklyReport.total_break_hours}hrs
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.7}
                sx={{ borderRight: "3px solid black", textAlign: "center" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: themes.absentTap,
                      fontWeight: "bold",
                      fontSize: 19,
                    }}
                  >
                    Absent
                  </span>
                  <span
                    style={{
                      color: themes.absentTap,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;{userWeeklyReport.absent_days} days
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.6}
                sx={{ borderRight: "3px solid black", textAlign: "center" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: themes.absentTap,
                      fontWeight: "bold",
                      fontSize: 19,
                    }}
                  >
                    Over Time
                  </span>
                  <span
                    style={{
                      color: themes.absentTap,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;{userWeeklyReport.total_over_time}
                  </span>
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                item
                xs={1.5}
                sx={{ borderRight: "3px solid black", textAlign: "center" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: themes.permissionTap,
                      fontWeight: "bold",
                      fontSize: 19,
                    }}
                  >
                    Permission
                  </span>
                  <span
                    style={{
                      color: themes.permissionTap,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;{userWeeklyReport.total_permission_days}
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.7}
                sx={{ borderRight: "3px solid black", textAlign: "center" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: themes.workingHours,
                      fontWeight: "bold",
                      fontSize: 19,
                    }}
                  >
                    Total Hours
                  </span>
                  <span
                    style={{
                      color: themes.workingHours,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;{userWeeklyReport.weekly_working_hours}
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.5}>
                <Mui.IconButton size="small" onClick={() => DownloadData()}>
                  <DownloadForOffline size="small" />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Box>
          <Mui.TableContainer
            sx={{
              marginTop: 2,
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "90%",
            }}
            className="w-96 border  rounded "
          >
            <Mui.Table className="border">
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}></Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>Date</Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>Shift</Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Check-In
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Check-Out
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>Break</Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Over Time
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Working Hours
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Leave Credit Hours
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>Status</Mui.Typography>
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {workingHoursDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <>
                      <Mui.TableRow>
                        <Mui.TableCell>
                          {expandRow === user.date ? (
                            <Mui.IconButton
                              onClick={() => handleTableClick(null)}
                            >
                              <KeyboardArrowDown />
                            </Mui.IconButton>
                          ) : user.status === "A" || user.status === "L" ? (
                            ""
                          ) : (
                            <Mui.IconButton
                              onClick={() => handleTableClick(user.date)}
                            >
                              <KeyboardArrowRight />
                            </Mui.IconButton>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell>{user.date}</Mui.TableCell>
                        <Mui.TableCell>{user.shift}</Mui.TableCell>
                        <Mui.TableCell>
                          {user.check_in_time_formatted}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {user.check_out_time_formatted}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {user.status === "A" ||
                          user.status === "L" ||
                          user.status === "W" ? null : (
                            <Mui.Typography>
                              {user.break_hours === "" ||
                              user.break_hours === "00:00"
                                ? "00:00"
                                : user.break_hours}
                            </Mui.Typography>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {user.status === "A" ||
                          user.status === "L" ||
                          user.status === "W" ? null : (
                            <Mui.Typography>{user.over_time}</Mui.Typography>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {user.status === "A" ||
                          user.status === "L" ||
                          user.status === "W" ? null : (
                            <Mui.Typography>
                              {user.working_hours === "" ||
                              user.working_hours === "00:00"
                                ? "00:00"
                                : user.working_hours}
                            </Mui.Typography>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {user.status === "L" || user.status === "H" ? (
                            <Mui.Typography>
                              {user.Leave_credit_hours}
                            </Mui.Typography>
                          ) : user.status === "HL" ||
                            user.status === "PL" ||
                            user.status === "LP" ? (
                            <Mui.Typography>
                              {user.Leave_credit_hours}
                            </Mui.Typography>
                          ) : null}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.Typography
                            sx={{
                              display: "inline-block",
                              width: "28px",
                              color:
                                user.status === "W"
                                  ? themes.blackColor
                                  : themes.whiteColor,
                              border:
                                user.status === "W"
                                  ? "1px solid black"
                                  : "none",
                              height: "28px",
                              textAlign: "center",
                              lineHeight: "29px",
                              backgroundColor: getColorWorkingHours(
                                user.status
                              ),
                            }}
                          >
                            {user.status === "HL" ||
                            user.status === "PL" ||
                            user.status === "LP" ? (
                              <Mui.Grid container>
                                {user.status === "HL" && (
                                  <>
                                    <Mui.Typography
                                      sx={{
                                        width: "14px",
                                        height: "28px",
                                        textAlign: "center",
                                        lineHeight: "29px",
                                        backgroundColor:
                                          user.half_day_leave_period === "2nd"
                                            ? themes.greenColor
                                            : themes.yellowColor,
                                      }}
                                    >
                                      H
                                    </Mui.Typography>
                                    <Mui.Typography
                                      sx={{
                                        width: "14px",
                                        height: "28px",
                                        textAlign: "center",
                                        lineHeight: "29px",
                                        backgroundColor:
                                          user.half_day_leave_period === "2nd"
                                            ? themes.yellowColor
                                            : themes.greenColor,
                                      }}
                                    >
                                      L
                                    </Mui.Typography>
                                  </>
                                )}
                                {user.status === "PL" && (
                                  <>
                                    <Mui.Typography
                                      sx={{
                                        width: "14px",
                                        height: "28px",
                                        textAlign: "center",
                                        lineHeight: "29px",
                                        backgroundColor: themes.greenColor,
                                      }}
                                    >
                                      P
                                    </Mui.Typography>
                                    <Mui.Typography
                                      sx={{
                                        width: "14px",
                                        height: "28px",
                                        textAlign: "center",
                                        lineHeight: "29px",
                                        backgroundColor: themes.yellowColor,
                                      }}
                                    >
                                      L
                                    </Mui.Typography>
                                  </>
                                )}
                                {user.status === "LP" && (
                                  <>
                                    <Mui.Typography
                                      sx={{
                                        width: "14px",
                                        height: "28px",
                                        textAlign: "center",
                                        lineHeight: "29px",
                                        backgroundColor: themes.yellowColor,
                                      }}
                                    >
                                      L
                                    </Mui.Typography>
                                    <Mui.Typography
                                      sx={{
                                        width: "14px",
                                        height: "28px",
                                        textAlign: "center",
                                        lineHeight: "29px",
                                        backgroundColor: themes.greenColor,
                                      }}
                                    >
                                      P
                                    </Mui.Typography>
                                  </>
                                )}
                              </Mui.Grid>
                            ) : (
                              user.status
                            )}
                          </Mui.Typography>
                        </Mui.TableCell>
                      </Mui.TableRow>
                      {expandRow === user.date && (
                        <Mui.TableRow>
                          <Mui.TableCell colSpan={10}>
                            <Mui.TableContainer
                              sx={{
                                width: "100%",
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              className="w-96 border rounded"
                            >
                              {user.break_details.length !== 0 ? (
                                <Mui.Table className="border">
                                  <Mui.TableHead
                                    sx={{
                                      backgroundColor: themes.primary,
                                    }}
                                  >
                                    <Mui.TableRow>
                                      <Mui.TableCell></Mui.TableCell>
                                      <Mui.TableCell></Mui.TableCell>
                                      <Mui.TableCell>
                                        <Mui.Typography fontWeight={"bold"}>
                                          Break Start
                                        </Mui.Typography>
                                      </Mui.TableCell>
                                      <Mui.TableCell>
                                        <Mui.Typography fontWeight={"bold"}>
                                          Break End
                                        </Mui.Typography>
                                      </Mui.TableCell>
                                      <Mui.TableCell>
                                        <Mui.Typography fontWeight={"bold"}>
                                          Overall Break Time
                                        </Mui.Typography>
                                      </Mui.TableCell>
                                    </Mui.TableRow>
                                  </Mui.TableHead>
                                  <Mui.TableBody>
                                    {user.break_details.map(
                                      (breakDetail, index) => (
                                        <Mui.TableRow key={index}>
                                          <Mui.TableCell></Mui.TableCell>
                                          <Mui.TableCell></Mui.TableCell>
                                          <Mui.TableCell>
                                            {breakDetail.break_out &&
                                            breakDetail.break_out !== "null"
                                              ? new Date(
                                                  `2000-01-01T${breakDetail.break_out}`
                                                ).toLocaleString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                  hour12: true,
                                                })
                                              : "00:00"}
                                          </Mui.TableCell>
                                          <Mui.TableCell>
                                            {breakDetail.break_in &&
                                            breakDetail.break_in !== "null"
                                              ? new Date(
                                                  `2000-01-01T${breakDetail.break_in}`
                                                ).toLocaleString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                  hour12: true,
                                                })
                                              : "00:00"}
                                          </Mui.TableCell>
                                          <Mui.TableCell>
                                            {breakDetail.individual_break_time}
                                          </Mui.TableCell>
                                        </Mui.TableRow>
                                      )
                                    )}
                                  </Mui.TableBody>
                                </Mui.Table>
                              ) : (
                                <Mui.Stack sx={{ padding: 0.5 }} fullWidth>
                                  <Mui.Alert severity="info">
                                    There is a no available breaks
                                  </Mui.Alert>
                                </Mui.Stack>
                              )}
                            </Mui.TableContainer>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      )}
                    </>
                  ))}
              </Mui.TableBody>
              <Mui.TableBody>
                <Mui.TableRow>
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography>Total</Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    {" "}
                    {userWeeklyReport.total_over_time}
                  </Mui.TableCell>
                  <Mui.TableCell>
                    {userWeeklyReport.total_working_hours}
                  </Mui.TableCell>
                  <Mui.TableCell>
                    {" "}
                    {userWeeklyReport.total_leave_credit_hours}
                  </Mui.TableCell>
                  <Mui.TableCell></Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <br></br>
          {workingHoursDetails?.length > 10 && (
            <Mui.TablePagination
              sx={{ marginRight: 5 }}
              className="custom-pagination"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={workingHoursDetails?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      ) : handleLoading ? (
        <Mui.Grid container>
          <Mui.Grid xs={5}></Mui.Grid>
          <Mui.Grid xs={2}>
            <Loader info="Loading..." />
          </Mui.Grid>
          <Mui.Grid xs={5}></Mui.Grid>
        </Mui.Grid>
      ) : null}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialog}
        open={breakPopup}
        title="Add Breaks"
      >
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Date</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingLeft: 2 }}>
            <Mui.Typography>:</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>
              {breakDayDetails ? breakDayDetails?.date : null}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Break Start Time</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1}></Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Break End Time</Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={5.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Break Start"
                  onChange={(newValue) => {
                    setBreakOutTime(moment(newValue["$d"]).format("hh:mm A"));
                    validate();
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {breakOutError === "Please enter breakout time" ? (
              <Mui.Typography sx={{ color: themes.redColor, fontSize: 12 }}>
                {breakOutError}
              </Mui.Typography>
            ) : null}
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 3, paddingLeft: 2 }}>
            <Mui.Typography> To</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Break End"
                  onChange={(newValue) => {
                    setBreakInTime(moment(newValue["$d"]).format("hh:mm A"));
                    validate();
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {breakInError === "Please enter breakin time" ? (
              <Mui.Typography sx={{ color: "red" }}>
                {breakInError}
              </Mui.Typography>
            ) : null}
          </Mui.Grid>
          <Mui.Typography sx={{ color: themes.redColor, fontSize: 12 }}>
            {ediBreakErrors === "Breakout time is lesser then breakin time"
              ? ediBreakErrors
              : null}{" "}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid item xs={10}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <Mui.Button
              sx={{
                backgroundColor: themes.button,
                color: themes.whiteColor,
                "&:hover": {
                  backgroundColor: themes.button,
                },
              }}
              onClick={() => createNewBreaks()}
            >
              Create
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
