import React, { useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import EmailDialog from "components/DialogBox/SystemAccess";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { Loader } from "components/Loader";
export const ListView = ({
  openRevokeDialog,
  setOpenRevokeDialog,
  openEmail,
  setOpenEmail,
  checkExistingUser,
  inviteSystemAccess,
  revokeSystemAccess,
  isLoading,
  allUsers,
  dropDown,
  updateMenuItem,
  setEditUserDetails,
  editUser,
  UpdateUserDetails,
  anchorEl,
  setAnchorEl,
  filterChanges,
  setFilterChanges,
  searchedValue,
}) => {
  const userDetails = useSelector((state) => state.tokenReducer.userDetails);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const handleDialog = () => {
      setOpenRevokeDialog(false);
      setOpenEmail(false);
    };
    handleDialog();
  }, [filterChanges, setFilterChanges, setOpenEmail, setOpenRevokeDialog]);

  useEffect(() => {
    if (filterChanges) {
      setFilterChanges(false);
      setPage(0);
    }
  }, [filterChanges, setFilterChanges]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialogBox = () => {
    setOpenRevokeDialog(false);
  };

  return (
    <React.Fragment>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openRevokeDialog}
        title="Revoke System Access"
      >
        <Mui.Grid container>
          <Mui.Grid item xs={12}>
            <Mui.Typography fontSize={22}>
              Are you sure you want to revoke system access for{" "}
              <b style={{ color: themes.redColor }}>{editUser?.user_name}</b>
              &nbsp;user?
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={8}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={handleCloseDialogBox}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={() =>
                revokeSystemAccess(editUser?.id, editUser?.email)
              }
              actionName="Yes"
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      <EmailDialog
        openDialog={openEmail}
        setOpenDialog={setOpenEmail}
        checkExistingUser={checkExistingUser}
        inviteSystemAccess={inviteSystemAccess}
        editUser={editUser}
      />
      {isLoading ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : allUsers?.length === 0 ? (
        <Mui.Box textAlign="center" my={2}>
          <Mui.Alert severity="info">
            {searchedValue !== "" ? "No data available" : "No data available"}
          </Mui.Alert>
        </Mui.Box>
      ) : (
        <Mui.Grid container sx={{ paddingRight: 1 }}>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{ mt: 2, width: "100%", maxHeight: 810 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow maxWidth="xl" align="left">
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    User Code
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Designation
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Employment Type
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Department
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Department Lead
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Company Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    System Access
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Status
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {allUsers
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <Mui.TableRow key={data.id}>
                      <>
                        <Mui.TableCell align="left">
                          <Mui.Stack direction="Row">
                            <Mui.Avatar
                              sx={{ width: 25, height: 25 }}
                              alt="Remy Sharp"
                              src={data.image_url}
                            />
                            <Mui.Typography
                              sx={{
                                paddingLeft: 1,
                              }}
                            >
                              {data.user_name ? data.user_name : "N/A"}
                            </Mui.Typography>
                          </Mui.Stack>
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          {data?.user_code ? data?.user_code : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          {data?.designation ? data?.designation : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          {data?.employment_type
                            ? data?.employment_type
                            : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          {data?.department ? data?.department : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          {data?.department_lead_name
                            ? data?.department_lead_name
                            : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          {data.company_name ? data.company_name : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          {data.system_access ? data.system_access : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          {data.status ? data.status : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell align="left">
                          <Mui.IconButton
                            onClick={(e) => {
                              if (data.role !== "Admin") {
                                setEditUserDetails(data);
                                updateMenuItem(
                                  data.status,
                                  data.role,
                                  data.system_access
                                );
                                handleMenuOpen(e, index);
                              } else if (userDetails.role === "Admin") {
                                setEditUserDetails(data);
                                updateMenuItem(
                                  data.status,
                                  data.role,
                                  data.system_access
                                );
                                handleMenuOpen(e, index);
                              }
                            }}
                          >
                            <MoreVertIcon
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {dropDown.map((option) =>
                              option.dropMenu !== "Make TeamLead" ? (
                                <Mui.Stack>
                                  <Mui.MenuItem
                                    key={option.dropMenu}
                                    onClick={() => {
                                      UpdateUserDetails(option.dropMenu);
                                      handleMenuClose();
                                    }}
                                  >
                                    {option.dropMenu === "Inactive"
                                      ? "Inactivate"
                                      : option.dropMenu === "Active"
                                      ? "Activate"
                                      : option.dropMenu}
                                  </Mui.MenuItem>
                                </Mui.Stack>
                              ) : editUser.role === "User" ||
                                editUser.role === "Admin" ? (
                                <Mui.Stack>
                                  <Mui.MenuItem
                                    key={option.dropMenu}
                                    onClick={() =>
                                      UpdateUserDetails(option.dropMenu)
                                    }
                                  >
                                    {option.dropMenu}
                                  </Mui.MenuItem>
                                </Mui.Stack>
                              ) : null
                            )}
                          </Mui.Menu>
                        </Mui.TableCell>
                      </>
                    </Mui.TableRow>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
            {allUsers.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[10, 20, 50, 100]}
                count={allUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.TableContainer>
        </Mui.Grid>
      )}
    </React.Fragment>
  );
};
