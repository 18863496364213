import * as React from "react";
import { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { CalendarMonth } from "@mui/icons-material";
import { showToast } from "components/Status";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { format, parse } from "date-fns";
import { leaveSuccess } from "services/constants/SuccessMessages";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import sessionHandling from "services/utils/notificationUtils";
import moment from "moment";

export const Permission = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState,
    trigger,
  } = useForm({
    mode: "onBlur",
    start_time: null,
    end_time: null,
  });
  const { errors } = formState;
  const { token, domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [startTimeSelected, setStartTimeSelected] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [leaveStartDate, setLeaveStartDate] = useState("");
  const [leaveEndDate, setLeaveEndDate] = useState("");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");

  const onSubmit = async (data) => {
    const formatTime = (time) => {
      return `${String(time.$H).padStart(2, "0")}:${String(time.$m).padStart(
        2,
        "0"
      )}:${String(time.$s).padStart(2, "0")}`;
    };

    const startTime = data.time_range[0]
      ? formatTime(data.time_range[0])
      : null;
    const endTime = data.time_range[1] ? formatTime(data.time_range[1]) : null;
    try {
      const response = await fetch(`${domain}create_permission/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          date: data.from_date,
          description: data.description,
          user_id: userDetails.id,
          applied_date: new Date().toISOString().split("T")[0],
          start_time: startTime,
          end_time: endTime,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", leaveSuccess.leavePermission);
        if (
          new Date(leaveStartDate).getFullYear() === new Date().getFullYear()
        ) {
          const today = dayjs().format("YYYY-MM-DD");
          setStartDate(today);
        } else {
          setStartDate(leaveStartDate);
        }
        reset();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const validateTimeRange = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return "Both start and end times are required.";
    }

    const start = dayjs(startTime);
    const end = dayjs(endTime);

    if (start.isAfter(end)) {
      return "End time cannot be before start time.";
    }

    const durationHours = end.diff(start, "hour", true);
    if (durationHours > 2) {
      return "Duration cannot be more than 2 hours.";
    }

    return true;
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
  };

  const resetForm = () => {
    reset();
    const today = dayjs().format("YYYY-MM-DD");
    setStartDate(today);
    setValue("from_date", today);
    setValue("to_date", today);
  };
  const handleClearButtonClick = () => {
    resetForm();
  };

  const handlePermissionSubmit = () => {
    onClose();
  };

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const today = dayjs().format("YYYY-MM-DD");
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setLeaveStartDate(sDate);
          setLeaveEndDate(eDate);
          if (
            new Date(
              res[0]?.start_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear() ||
            new Date(
              res[0]?.end_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear()
          ) {
            setStartDate(today);
          } else {
            setStartDate(sDate);
          }
          setValue("from_date", today);
          setValue("to_date", today);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, setValue, token]);

  const handleChangeLeavePeriod = (event, value) => {
    const today = dayjs().format("YYYY-MM-DD");
    if (value && value.filteredValue) {
      setFilteredLabel(value.label);
      setFilteredValue(value.filteredValue);
      setStartDate(formatDate(value.start_date));
      setLeaveStartDate(formatDate(value.start_date));
      setLeaveEndDate(formatDate(value.end_date));
      if (
        new Date(value.start_date).getFullYear() === new Date().getFullYear() ||
        new Date(
          value.end_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear()
      ) {
        let startDateConvertions = moment(new Date()).format("DD-MM-YYYY");
        setStartDate(formatDate(startDateConvertions));
        setValue("from_date", formatDate(startDateConvertions));
        setValue("to_date", formatDate(startDateConvertions));
      } else {
        setStartDate(formatDate(value.start_date));

        setValue("from_date", formatDate(value.start_date));
        setValue("to_date", formatDate(value.end_date));
      }
    } else {
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate("");
      setLeaveEndDate("");
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate(today);
      setLeaveEndDate(today);
      setStartDate(today);
    }
  };

  return (
    <>
      <Mui.Card
        content="center"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          boxShadow: 5,
          borderRadius: 5,
          width: 950,
        }}
      >
        <Mui.CardContent sx={{ marginTop: 2 }}>
          <Mui.Grid container spacing={2} justifyContent={"space-between"}>
            <Mui.ButtonGroup size="large" aria-label="Large button group">
              <Mui.Button
                variant="outlined"
                onClick={handlePermissionSubmit}
                sx={{
                  background: "outlined",
                  textTransform: "capitalize",
                  border: "none",
                  "&:hover": {
                    border: "none",
                  },
                  color: themes.headLine,
                }}
              >
                Leave
              </Mui.Button>
              <Mui.Button
                variant="contained"
                sx={{
                  background: themes.primaryButton,
                  textTransform: "capitalize",
                  border: "none",
                  "&:hover": {
                    border: "none",
                    background: themes.primaryButton,
                  },
                  color: themes.headLine,
                }}
              >
                Permission
              </Mui.Button>
            </Mui.ButtonGroup>
            <Mui.Grid
              container
              xs={6}
              sx={{
                justifyContent: "flex-end",
                paddingTop: 1,
                marginRight: 18,
              }}
            >
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={filteredValue}
                options={leaveDate}
                style={{
                  width: 305,
                }}
                getOptionLabel={(option) =>
                  option?.filteredValue ?? filteredValue ?? ""
                }
                isOptionEqualToValue={(option, value) =>
                  option?.filteredValue === value?.filteredValue
                }
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.filteredValue
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.filteredValue}</li>;
                }}
                ListboxProps={{
                  style: {
                    maxHeight: 150,
                  },
                }}
                onChange={handleChangeLeavePeriod}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    sx={{ background: themes.whiteColor }}
                    placeholder="Select leave period"
                    label={filteredLabel ? filteredLabel : "Leave Period"}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <CalendarMonth
                            style={{
                              margin: "0 8px",
                              color: "#a6a6a6",
                            }}
                          />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Typography
            sx={{
              marginTop: 3,
              marginLeft: 3,
              fontSize: 20,
            }}
          >
            APPLY PERMISSION
          </Mui.Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Mui.Grid container spacing={2} sx={{ paddingTop: 2 }}>
              <Mui.Grid item xs={6}>
                <Mui.Typography
                  sx={{ marginTop: 3, marginLeft: 17 }}
                  htmlFor="from_date"
                  style={{
                    fontSize: 20,
                  }}
                >
                  Date
                </Mui.Typography>

                <Mui.TextField
                  sx={{
                    marginTop: 1,
                    width: 650,
                    marginLeft: 17,
                  }}
                  size="medium"
                  type="date"
                  id="from_date"
                  value={startDate}
                  inputProps={{
                    min: leaveStartDate,
                    max: leaveEndDate,
                  }}
                  {...register("from_date", {
                    required: "Date is required.",
                    validate: validateStartDate,
                  })}
                  error={!!errors.from_date}
                  helperText={errors.from_date && errors.from_date.message}
                  onChange={(event) => {
                    setStartDate(event.target.value);
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container spacing={2}>
              <Mui.Grid item xs={6}>
                <Mui.Typography
                  sx={{ marginTop: 3, marginLeft: 17 }}
                  style={{
                    fontSize: 20,
                  }}
                >
                  Start Time
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.5}></Mui.Grid>
              <Mui.Grid item xs={5}>
                <Mui.Typography
                  sx={{ marginTop: 3 }}
                  style={{
                    fontSize: 20,
                  }}
                >
                  End Time
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid xs={2}></Mui.Grid>
            <Mui.Grid container>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="time_range"
                  rules={{
                    validate: (value) => {
                      if (!value || value[0] === null || value[1] === null) {
                        return "Both start and end times are required.";
                      }
                      const startTime = dayjs(value[0]);
                      const endTime = dayjs(value[1]);
                      return validateTimeRange(startTime, endTime);
                    },
                  }}
                  control={control}
                  defaultValue={[null, null]}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <Mui.Grid
                        xs={7}
                        sx={{ paddingLeft: 17, paddingTop: 0.5 }}
                      >
                        <TimePicker
                          sx={{ width: 280 }}
                          label="Start Time"
                          value={value[0]}
                          onChange={(newValue) => {
                            onChange([newValue, value[1]]);
                            setStartTimeSelected(true);
                          }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          TextFieldComponent={Mui.TextField}
                          onBlur={() => {
                            trigger("time_range");
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                        {error && (
                          <Mui.Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: 13, paddingLeft: 1.5 }}
                          >
                            {error.message}
                          </Mui.Typography>
                        )}
                      </Mui.Grid>
                      <Mui.Grid
                        xs={5}
                        sx={{ marginLeft: -3.5, paddingTop: 0.5 }}
                      >
                        <TimePicker
                          sx={{ width: 280 }}
                          label="End Time"
                          value={value[1]}
                          onChange={(newValue) =>
                            onChange([value[0], newValue])
                          }
                          error={!!error}
                          helperText={error ? error.message : null}
                          TextFieldComponent={Mui.TextField}
                          onBlur={() => {
                            trigger("time_range");
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disabled={!startTimeSelected}
                        />
                        {error && (
                          <Mui.Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: 13, paddingLeft: 1.5 }}
                          >
                            {error.message}
                          </Mui.Typography>
                        )}
                      </Mui.Grid>
                    </>
                  )}
                />
              </LocalizationProvider>
            </Mui.Grid>

            <br />

            <Mui.Typography
              sx={{ marginTop: 0.5, marginLeft: 17 }}
              htmlFor="description"
              style={{
                fontSize: 20,
              }}
            >
              Reason for Permission
            </Mui.Typography>
            <Mui.TextField
              sx={{
                marginTop: 1,
                width: "71.5%",
                marginLeft: 17,
                marginRight: "auto",
              }}
              id="description"
              {...register("description", {
                required: "Description is required",
              })}
              label="Provide a reason for your permission"
              multiline
              rows={3}
              error={!!errors.description}
              helperText={errors.description && errors.description.message}
              onBlur={() => {
                trigger("description");
              }}
            />

            <br />
            <br />
            <Mui.Grid container spacing={3}>
              <Mui.Grid item xs={7}></Mui.Grid>
              <Mui.Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={handleClearButtonClick}
                  actionName="Clear"
                  typeName="button"
                />
                &nbsp;&nbsp;
                <Mui.Button
                  sx={{
                    width: "100px",
                    height: "36px",
                    display: "block",
                    backgroundColor: themes.primaryButton,
                    "&:hover": {
                      backgroundColor: themes.primaryButton,
                    },
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  type="submit"
                  variant="contained"
                >
                  Apply
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </form>
        </Mui.CardContent>
      </Mui.Card>
    </>
  );
};
