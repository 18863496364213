import { createSlice } from "@reduxjs/toolkit";

export const projectManagementReducer = createSlice({
  name: "token",
  initialState: {
    editProject: null,
    ToDo: false,
    Pending: false,
    InProgress: false,
    OnHold: false,
    Review: false,
    Testing: false,
    Completed: false,
    sprintBack: "Back To Project",
    newSprintId: 0,
    reloadTask: false,
    newSprintName: "Backlog",
    editProjectScreenData: null,
    taskScreen: "ListView",
    taskNameFilter: false,
    assigneeFilter: false,
    taskownerFilter: false,
    startDateFilter: false,
    endDateFilter: false,
    priorityFilter: false,
    taskTypeFilter: false,
    labelNameFilter: false,
  },
  reducers: {
    setEditProject: (state, action) => {
      state.editProject = action.payload;
    },
    hideToDo: (state, action) => {
      state.ToDo = action.payload;
    },
    hidePending: (state, action) => {
      state.Pending = action.payload;
    },
    hideInProgress: (state, action) => {
      state.InProgress = action.payload;
    },
    hideOnHold: (state, action) => {
      state.OnHold = action.payload;
    },
    hideReview: (state, action) => {
      state.Review = action.payload;
    },
    hideTesting: (state, action) => {
      state.Testing = action.payload;
    },
    hideCompleted: (state, action) => {
      state.Completed = action.payload;
    },
    setSprintBack: (state, action) => {
      state.sprintBack = action.payload;
    },
    setNewSprintId: (state, action) => {
      state.newSprintId = action.payload;
    },
    setReloadTask: (state, action) => {
      state.reloadTask = action.payload;
    },
    setNewSprintName: (state, action) => {
      state.newSprintName = action.payload;
    },
    setEditProjectScreenData: (state, action) => {
      state.editProjectScreenData = action.payload;
    },
    setTaskScreen: (state, action) => {
      state.taskScreen = action.payload;
    },
    setTaskNameFilter: (state, action) => {
      state.taskNameFilter = action.payload;
    },
    setAssigneeFilter: (state, action) => {
      state.assigneeFilter = action.payload;
    },
    setTaskownerFilter: (state, action) => {
      state.taskownerFilter = action.payload;
    },
    setStartDateFilter: (state, action) => {
      state.startDateFilter = action.payload;
    },
    setEndDateFilter: (state, action) => {
      state.endDateFilter = action.payload;
    },
    setPriorityFilter: (state, action) => {
      state.priorityFilter = action.payload;
    },
    setTasktypeFilter: (state, action) => {
      state.taskTypeFilter = action.payload;
    },
    setLabelnameFilter: (state, action) => {
      state.labelNameFilter = action.payload;
    },
  },
});

export const {
  setEditProject,
  hideToDo,
  hidePending,
  hideInProgress,
  hideOnHold,
  hideReview,
  hideTesting,
  hideCompleted,
  setSprintBack,
  setNewSprintId,
  setReloadTask,
  setNewSprintName,
  setEditProjectScreenData,
  setTaskScreen,
  setTaskNameFilter,
  setAssigneeFilter,
  setTaskownerFilter,
  setStartDateFilter,
  setEndDateFilter,
  setPriorityFilter,
  setTasktypeFilter,
  setLabelnameFilter,
} = projectManagementReducer.actions;

export default projectManagementReducer.reducer;
