import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { Box } from "@material-ui/core";
import { Cancel, Done, Edit } from "@material-ui/icons";
import { themes } from "services/constants";
import { Loader } from "components/Loader";
export const Daily = ({
  loading,
  dailyShiftList,
  isEdit,
  editingIndex,
  shiftData,
  shiftName,
  setShiftName,
  shiftId,
  setshiftId,
  handleShiftChange,
  updateDailyShift,
  listOfDate,
  shiftChanged,
  handleCancel,
  handleEdit,
  filterChanges,
  setFilterChanges,
  storeCurrentWeek,
  selectedWeek,
}) => {
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(10);
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  useEffect(() => {
    if (filterChanges) {
      setFilterChanges(false);
      setLeavePage(0);
    }
  }, [filterChanges, setFilterChanges]);
  const selectedWeekNo = selectedWeek?.split("-W")[1];
  return (
    <React.Fragment>
      {loading === true ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{ mt: 2, width: "100%" }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Name
                  </Mui.TableCell>
                  {days?.map((value) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {value}
                    </Mui.TableCell>
                  ))}
                  {selectedWeekNo < storeCurrentWeek ? null : (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Action
                    </Mui.TableCell>
                  )}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {dailyShiftList?.length === 0 ? (
                  <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                    No data found
                  </Mui.Alert>
                ) : (
                  dailyShiftList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((shiftList, index) => (
                      <React.Fragment>
                        <Mui.TableRow>
                          <Mui.TableCell>{shiftList?.user_name}</Mui.TableCell>
                          {shiftList?.shifts.map((shift, i) =>
                            isEdit &&
                            editingIndex === index &&
                            shift?.shift_name !== "Custom Shift" &&
                            shift?.shift_name !== null &&
                            shift?.shift_name !== "" ? (
                              <Mui.TableCell>
                                <Mui.Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  value={
                                    shiftData?.find(
                                      (option) => option?.label === shiftName[i]
                                    ) || ""
                                  }
                                  options={
                                    shift?.shift_name === "Weekoff"
                                      ? shiftData.filter(
                                          (value) => value?.label !== "Weekoff"
                                        )
                                      : shiftData
                                  }
                                  getOptionLabel={(option) =>
                                    option?.label ?? shift?.shift_name ?? ""
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option?.label === value?.label
                                  }
                                  filterOptions={(options, state) => {
                                    return options?.filter((option) =>
                                      option?.label
                                        .toLowerCase()
                                        .includes(
                                          state.inputValue.toLowerCase()
                                        )
                                    );
                                  }}
                                  renderOption={(props, option) => {
                                    return <li {...props}>{option?.label}</li>;
                                  }}
                                  ListboxProps={{
                                    style: {
                                      maxHeight: "150px",
                                      overflow: "auto",
                                    },
                                  }}
                                  onChange={async (event, value) => {
                                    if (!value?.label) {
                                      setshiftId("");
                                      setShiftName("");
                                      handleShiftChange(i, "", "");
                                    } else {
                                      handleShiftChange(
                                        i,
                                        value?.label,
                                        value?.id
                                      );
                                    }
                                  }}
                                  sx={{ width: "100%" }}
                                  renderInput={(params) => (
                                    <Mui.TextField
                                      {...params}
                                      sx={{
                                        background: themes.whiteColor,
                                        padding: 0.5,
                                      }}
                                      placeholder="Select shift"
                                      size="small"
                                    />
                                  )}
                                />
                              </Mui.TableCell>
                            ) : (
                              <Mui.TableCell>
                                {shift?.shift_name
                                  ? shift?.shift_name
                                  : "Not Joined"}
                                <br></br>
                                {shift?.shift_name === null ||
                                shift?.shift_name === "Weekoff" ? (
                                  ""
                                ) : shift?.shift_start_time &&
                                  shift?.shift_end_time ? (
                                  <div>
                                    {shift?.shift_start_time?.split(":")[0] +
                                      ":" +
                                      shift?.shift_start_time?.split(
                                        ":"
                                      )[1]}{" "}
                                    to{" "}
                                    {shift?.shift_end_time?.split(":")[0] +
                                      ":" +
                                      shift?.shift_end_time?.split(":")[1]}
                                  </div>
                                ) : null}
                              </Mui.TableCell>
                            )
                          )}
                          {selectedWeekNo < storeCurrentWeek ? null : (
                            <Mui.TableCell>
                              {isEdit && editingIndex === index ? (
                                <React.Fragment>
                                  <Mui.IconButton
                                    onClick={(event) => {
                                      const daily_assignments =
                                        shiftList?.shifts
                                          .map((value, i) => ({
                                            shift_id:
                                              shiftId[i] ?? value?.shift_id,
                                            date: listOfDate[i],
                                            is_week_off:
                                              shiftName[i] === "Weekoff"
                                                ? true
                                                : false,
                                          }))
                                          .filter(
                                            (value, i) =>
                                              shiftChanged[i] && value?.shift_id
                                          );
                                      updateDailyShift(
                                        shiftList?.user_id,
                                        daily_assignments
                                      );
                                    }}
                                  >
                                    <Done
                                      sx={{
                                        color: themes.primaryIndicator,
                                        strokeWidth: 2,
                                      }}
                                    />
                                  </Mui.IconButton>
                                  <Mui.IconButton onClick={handleCancel}>
                                    <Cancel
                                      sx={{
                                        color: themes.redColor,
                                        strokeWidth: 2,
                                      }}
                                    />
                                  </Mui.IconButton>
                                </React.Fragment>
                              ) : (
                                <Mui.IconButton
                                  onClick={(event) => {
                                    handleEdit(index);
                                  }}
                                >
                                  <Edit
                                    sx={{
                                      color: themes.primaryIndicator,
                                      strokeWidth: 2,
                                    }}
                                  />
                                </Mui.IconButton>
                              )}
                            </Mui.TableCell>
                          )}
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {dailyShiftList.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={dailyShiftList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
