import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { showToast } from "components/Status";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { CustomTextBox } from "components/CustomTextBox";
import { specialCharacterRestrict } from "services/constants/patterns";
import { useForm } from "react-hook-form";
import { onlyNumberRegex } from "services/constants/patterns";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomButton";
import { atleastOneCharacterWithSpace,ifscCodePatterns} from "services/constants/patterns";

export const BankDetailsForm = ({
  setPageNo,
  existingUserDetails,
  setExistingUserDetails,
  openDialogBox,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [bankNameDetails, setBankNameDetails] = useState(
    existingUserDetails.bank_name ? existingUserDetails.bank_name : ""
  );
  const [accountNameDetails, setAccountNameDetails] = useState(
    existingUserDetails.account_holder_name
      ? existingUserDetails.account_holder_name
      : ""
  );
  const [accountNumber, setAccountNumber] = useState(
    existingUserDetails.account_number ? existingUserDetails.account_number : ""
  );
  const [branchNameDetails, setBranchNameDetails] = useState(
    existingUserDetails.branch_name ? existingUserDetails.branch_name : ""
  );
  const [ifscCodeDetails, setIFSCcodeDetails] = useState(
    existingUserDetails.ifsc ? existingUserDetails.ifsc : ""
  );
  const [saveAndExit, setSaveAndExit] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue(
      "bankName",
      existingUserDetails.bank_name ? existingUserDetails.bank_name : ""
    );
    setValue(
      "bankAccountNumber",
      existingUserDetails.account_number
        ? existingUserDetails.account_number
        : ""
    );
    setValue(
      "accountName",
      existingUserDetails.account_holder_name
        ? existingUserDetails.account_holder_name
        : ""
    );
    setValue(
      "branchName",
      existingUserDetails.branch_name ? existingUserDetails.branch_name : ""
    );
    setValue(
      "ifscCode",
      existingUserDetails.ifsc ? existingUserDetails.ifsc : ""
    );
  }, [
    existingUserDetails.account_holder_name,
    existingUserDetails.account_number,
    existingUserDetails.bank_name,
    existingUserDetails.branch_name,
    existingUserDetails.ifsc,
    setValue,
  ]);

  const onSubmit = async (data) => {
    setExistingUserDetails({
      ...existingUserDetails,
      bank_name: data.bankName,
      account_holder_name: data.accountName,
      account_number: data.bankAccountNumber,
      branch_name: data.branchName,
      ifsc: data.ifscCode,
    });
    if (saveAndExit) {
      try {
        const response = await fetch(`${domain}employee-onboard`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            user_name: existingUserDetails.user_name,
            email: existingUserDetails.email_id,
            company_id: existingUserDetails.company_id,
            portal_access: existingUserDetails.portal_access,
            employee_id: existingUserDetails.employee_id,
            date_joined: existingUserDetails.date_of_joined
              ? existingUserDetails.date_of_joined
                  ?.split("-")
                  .reverse()
                  .join("-")
              : null,
            leave_group_id: existingUserDetails?.leave_group_id,
            department_id: existingUserDetails.department_id,
            designation_id: existingUserDetails.designation_id,
            employment_type_id: existingUserDetails.employment_type_id,
            parent_name: existingUserDetails.parent_name,
            parent_mobile_number: existingUserDetails.parent_mobile_number,
            bankname: data.bankName,
            account_holder_name: data.accountName,
            accountno: data.bankAccountNumber,
            ifsc: data.ifscCode,
            branch_name: data.branchName,
            pan: existingUserDetails.pan_number,
            aadhar: existingUserDetails.aadhar_number,
            address: existingUserDetails.address,
            permanent_address: existingUserDetails?.permanent_address,
            pf: existingUserDetails.pf_number,
            uan: existingUserDetails.uan_number,
            esi: existingUserDetails.esi_number,
            dob: existingUserDetails.date_of_birth
              ? existingUserDetails.date_of_birth.split("-").reverse().join("-")
              : null,
            emergency_contacts: existingUserDetails.emergency_contacts,
            transport_route_no: existingUserDetails?.transport_route_no,
            transport_type: existingUserDetails?.transport_type,
            block_name: existingUserDetails?.block_name,
            room_no: existingUserDetails?.room_no,
            phone_number: existingUserDetails.phone_number,
            salary_group: existingUserDetails?.salary_group,
            earnings: existingUserDetails?.earnings,
            deductions: existingUserDetails?.deductions,
            salary_type: existingUserDetails?.salary_type,
            amount: existingUserDetails?.amount,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          openDialogBox();
          reset();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      setPageNo(5);
      reset();
    }
  };

  const updateBankName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("bankName", event.target.value);
      setBankNameDetails(event.target.value);
    }
  };

  const updateAccountName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("accountName", event.target.value);
      setAccountNameDetails(event.target.value);
    }
  };

  const updateBankAccountNumber = (event) => {
    if (
      event.target.value.length <= 17 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("bankAccountNumber", event.target.value);
      setAccountNumber(event.target.value);
    }
  };

  const updateBranchName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setValue("branchName", event.target.value);
      setBranchNameDetails(event.target.value);
    }
  };

  const updateIFSCcode = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 11
    ) {
      setValue("ifscCode", event.target.value);
      setIFSCcodeDetails(event.target.value);
    }
  };

  const updateUserEnterData = () => {
    // setExistingUserDetails({
    //   ...existingUserDetails,
    //   bank_name: getValues("bankName"),
    //   account_holder_name: getValues("accountName"),
    //   accountno: getValues("bankAccountNumber"),
    //   ifsc: getValues("ifscCode"),
    //   branch_name: getValues("branchName"),
    // });
    setPageNo(3);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 10 }}>
        <Mui.Grid container fullWidth sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 15,
              color: themes.colorShadow,
              paddingBottom: 0.5,
            }}
          >
            Account Holder Name
          </Mui.Typography>
          <CustomTextBox
            selectedValue={accountNameDetails}
            rules={{
              ...register("accountName", {
                required: false,
                validate: {
                  minLengthNoSpaces: (value) => {
                    if (value?.trim().length < 3 && value !== "") {
                      return minimum3Characters;
                    } else {
                      return true;
                    }
                  },
                  atleastOneCharacterWithSpaceReg: (value) => {
                    if (
                      atleastOneCharacterWithSpace.test(value) ||
                      value === ""
                    ) {
                      return true;
                    } else {
                      return "Atleast one alphabet required";
                    }
                  },
                },
              }),
            }}
            updateDataFunction={updateAccountName}
            placeHolder="Enter Account Name"
            errorMessage={
              errors?.accountName?.message ? errors?.accountName?.message : ""
            }
            textBoxError={errors.accountName ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 15,
              color: themes.colorShadow,
              paddingBottom: 0.5,
            }}
          >
            Bank Name
          </Mui.Typography>
          <CustomTextBox
            selectedValue={bankNameDetails}
            rules={{
              ...register("bankName", {
                required: false,
                validate: {
                  minLengthNoSpaces: (value) => {
                    if (value?.trim().length < 3 && value !== "") {
                      return minimum3Characters;
                    } else {
                      return true;
                    }
                  },
                  atleastOneCharacterWithSpaceReg: (value) => {
                    if (
                      atleastOneCharacterWithSpace.test(value) ||
                      value === ""
                    ) {
                      return true;
                    } else {
                      return "Atleast one alphabet required";
                    }
                  },
                },
              }),
            }}
            updateDataFunction={updateBankName}
            placeHolder="Enter Bank Name"
            errorMessage={
              errors?.bankName?.message ? errors?.bankName?.message : ""
            }
            textBoxError={errors.bankName ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 15,
              color: themes.colorShadow,
              paddingBottom: 0.5,
            }}
          >
            Bank Account Number
          </Mui.Typography>
          <CustomTextBox
            selectedValue={accountNumber}
            rules={{
              ...register("bankAccountNumber", {
                required: false,
              }),
            }}
            updateDataFunction={updateBankAccountNumber}
            placeHolder="Enter Bank Account Number"
            errorMessage={
              errors?.bankAccountNumber?.message
                ? errors?.bankAccountNumber?.message
                : ""
            }
            textBoxError={errors.bankAccountNumber ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 15,
              color: themes.colorShadow,
              paddingBottom: 0.5,
            }}
          >
            Branch Name
          </Mui.Typography>
          <CustomTextBox
            selectedValue={branchNameDetails}
            rules={{
              ...register("branchName", {
                required: false,
                validate: {
                  minLengthNoSpaces: (value) => {
                    if (value?.trim().length < 3 && value !== "") {
                      return minimum3Characters;
                    } else {
                      return true;
                    }
                  },
                  atleastOneCharacterWithSpaceReg: (value) => {
                    if (
                      atleastOneCharacterWithSpace.test(value) ||
                      value === ""
                    ) {
                      return true;
                    } else {
                      return "Atleast one alphabet required";
                    }
                  },
                },
              }),
            }}
            updateDataFunction={updateBranchName}
            placeHolder="Enter Branch Name"
            errorMessage={
              errors?.branchName?.message ? errors?.branchName?.message : ""
            }
            textBoxError={errors.branchName ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 15,
              color: themes.colorShadow,
              paddingBottom: 0.5,
            }}
          >
            IFSC code
          </Mui.Typography>
          <CustomTextBox
            selectedValue={ifscCodeDetails}
            rules={{
              ...register("ifscCode", {
                required: false,
                validate: {
                  minLengthNoSpaces: (value) => {
                    const trimmedValue = value?.trim();
                    if (!trimmedValue) {
                      return true;
                    }
                    if (trimmedValue.length < 11) {
                      return "Minimum 11 characters required";
                    }
                    if (!ifscCodePatterns.test(trimmedValue)) {
                      return "Please enter a valid format: ABCD0123456";
                    }

                    return true;
                  },
                },
              }),
            }}
            updateDataFunction={updateIFSCcode}
            placeHolder="Enter IFSC Code"
            errorMessage={
              errors?.ifscCode?.message ? errors?.ifscCode?.message : ""
            }
            textBoxError={errors.ifscCode ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: {
              xs: 0,
              sm: 0,
              md: 10,
              lg: 10,
            },
            paddingBottom: 2,
          }}
        >
          <Mui.Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "flex-start",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => updateUserEnterData()}
              actionName="Previous"
              typeName="submit"
            />
          </Mui.Grid>

          <Mui.Grid
            xs={8}
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "flex-end",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setSaveAndExit(true);
              }}
              actionName="Save & Exit"
              typeName="submit"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionName="Next"
              typeName="submit"
              actionFuntion={() => {
                setSaveAndExit(false);
              }}
            />
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
