import React, { useEffect, useState } from "react";
import "./Status.css";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Tooltip,
  Stack,
  Alert,
  IconButton,
} from "@mui/material";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import HouseIcon from "@mui/icons-material/House";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { themes } from "services/constants";
import { getStatusColor } from "components/Status";
import { setAllStatus } from "services/Redux/leaveManagement";
import { useDispatch } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";

export const AllStatus = ({ date, departmentid }) => {
  const dispatch = useDispatch();
  const [expandedCards, setExpandedCards] = useState({});
  const [showIcons, setShowIcons] = useState(true);
  const [intervalTime, setIntervalTime] = useState(200);
  const { token, domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  let allStatus = useSelector((state) => state.leaveReducer.allStatus);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}checkinfilter/?date=${date}&department_id=${departmentid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.user_status_details) {
            setData(res?.user_status_details);
            setMasterDataForSearch(res?.user_status_details);
            dispatch(setAllStatus(false));
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (allStatus) {
      fetchData();
    }
  }, [token, date, domain, departmentid, dispatch, allStatus]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}checkinfilter/?date=${date}&department_id=${departmentid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.user_status_details) {
            setData(res?.user_status_details);
            setMasterDataForSearch(res?.user_status_details);
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token, date, domain, departmentid, dispatch, allStatus]);

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setData(FilterDockets());
  }, [globalSearchValue, masterDataForSearch]);

  const handleCardClick = (cardId) => {
    setExpandedCards((prevState) => ({
      [cardId]: !prevState[cardId],
    }));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setShowIcons(!showIcons);
      setIntervalTime(200);
    }, intervalTime);
    return () => {
      clearInterval(interval);
    };
  }, [intervalTime, showIcons]);

  const statusBasedIcons = (status, locationStatus) => {
    switch (status) {
      case "Home":
        return (
          <Tooltip title="Home">
            <IconButton
              onMouseOver={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(100000000);
                  setShowIcons(false);
                }
              }}
              onMouseOut={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(2000);
                }
              }}
            >
              <HouseIcon
                style={{
                  fontSize: "large",
                  color:
                    locationStatus === "Pending" || locationStatus === "Hold"
                      ? "red"
                      : themes.activeTap,
                }}
              />
            </IconButton>
          </Tooltip>
        );
      case "Office":
        return (
          <Tooltip title="Office">
            <IconButton
              size="small"
              onMouseOver={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(100000000);
                  setShowIcons(false);
                }
              }}
              onMouseOut={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(500);
                }
              }}
            >
              <ImportantDevicesIcon
                style={{
                  fontSize: "large",
                  color:
                    locationStatus === "Pending" || locationStatus === "Hold"
                      ? "red"
                      : themes.activeTap,
                }}
              />
            </IconButton>
          </Tooltip>
        );
      case "Site":
        return (
          <Tooltip title="Site">
            <IconButton
              size="small"
              onMouseOver={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(100000000);
                  setShowIcons(false);
                }
              }}
              onMouseOut={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(500);
                }
              }}
            >
              <SmartphoneIcon
                style={{
                  fontSize: "large",
                  color:
                    locationStatus === "Pending" || locationStatus === "Hold"
                      ? "red"
                      : themes.activeTap,
                }}
              />
            </IconButton>
          </Tooltip>
        );
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader info="Loading..." />
      ) : data.length === 0 ? (
        <Alert severity="info">No data available</Alert>
      ) : (
        <Grid container spacing={0} sx={{ justifyContent: "center" }}>
          {data.length > 0 ? (
            <>
              {data.map(
                (item, index) =>
                  item.user_id && (
                    <Grid item key={item.id}>
                      <Card
                        className=" max-w-fit mt-5 w-96 flex space-x-1 shadow-lg  "
                        variant="outlined"
                        sx={{
                          borderRadius: 2,
                          marginLeft: 3,
                          marginTop: 5,
                          backgroundColor:
                            item.location_status === "Pending" ||
                            item.location_status === "Hold"
                              ? themes.backgroundShadow
                              : themes.backgroundWhite,
                        }}
                        onClick={() => handleCardClick(item.id)}
                      >
                        <CardContent>
                          <Mui.Grid container>
                            <Mui.Grid item xs={2.5}>
                              <Mui.Avatar
                                sx={{
                                  width: 40,
                                  height: 40,
                                  backgroundColor: "#ff4d88",
                                }}
                                alt={item.user_name?.charAt(0).toUpperCase()}
                                src={item.image_url}
                              />
                            </Mui.Grid>
                            <Mui.Grid
                              item
                              xs={9.5}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <Mui.Stack>
                                <Mui.Typography
                                  fontWeight={"bold"}
                                  sx={{
                                    fontSize:
                                      item.user_name.length < 15
                                        ? "18px"
                                        : "15px",
                                  }}
                                >
                                  {item.user_name
                                    ? item.user_name.length >= 15
                                      ? item.user_name.substr(0, 15) + "..."
                                      : item.user_name
                                    : "N/A"}
                                </Mui.Typography>
                                <Mui.Grid container>
                                  <Mui.Grid item xs={10}>
                                    <Mui.Typography>
                                      {item?.user_code
                                        ? item?.user_code
                                        : "N/A"}
                                    </Mui.Typography>
                                  </Mui.Grid>
                                  <Mui.Grid item xs={2}>
                                    <Mui.Box
                                      sx={{
                                        width: 80,
                                        height: 15,
                                        backgroundColor: getStatusColor(
                                          item.type
                                        ),
                                        borderRadius: "16px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: themes.backgroundWhite,
                                        p: 2,
                                        fontSize: 13,
                                      }}
                                    >
                                      {" "}
                                      {item.type}
                                    </Mui.Box>
                                  </Mui.Grid>
                                </Mui.Grid>
                              </Mui.Stack>
                            </Mui.Grid>
                            <Mui.Grid item xs={3}></Mui.Grid>
                            <Mui.Grid item xs={0.5}></Mui.Grid>
                          </Mui.Grid>
                          <div className="mt-2 ">
                            <Grid item xs={6} sx={{ marginTop: 1 }}>
                              <Typography
                                sx={{ color: themes.primaryIndicator }}
                                fontSize={18}
                              >
                                Check-In
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sx={{ marginTop: -3.5, marginLeft: 20 }}
                            >
                              <Typography
                                sx={{ color: themes.primaryIndicator }}
                                fontSize={18}
                              >
                                Check-Out
                              </Typography>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography sx={{ mt: 0 }} fontSize={16}>
                                  {item.check_in_time &&
                                  item.check_in_time !== "null"
                                    ? new Date(
                                        `2000-01-01T${item.check_in_time}`
                                      ).toLocaleString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })
                                    : "N/A"}{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography sx={{ mt: 0, ml: 4 }} fontSize={16}>
                                  {item.check_out_time &&
                                  item.check_out_time !== "null"
                                    ? new Date(
                                        `2000-01-01T${item.check_out_time}`
                                      ).toLocaleString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })
                                    : "N/A"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                          {expandedCards[item.id] && (
                            <>
                              <Stack direction="row">
                                <Grid container>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <Typography
                                      sx={{ color: themes.primaryIndicator }}
                                      fontSize={15}
                                    >
                                      Break Start
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-center",
                                      alignItems: "flex-center",
                                      paddingLeft: 0.5,
                                    }}
                                  >
                                    <Typography
                                      sx={{ color: themes.primaryIndicator }}
                                      fontSize={15}
                                    >
                                      Break End
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <Typography
                                      sx={{ color: themes.primaryIndicator }}
                                      fontSize={15}
                                    >
                                      Total-Time
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Stack>
                              {item.break_details.length === 0 ? (
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  sx={{ paddingLeft: 0, paddingTop: -2 }}
                                >
                                  <Grid item xs={4}>
                                    <Typography fontSize={15}>N/A</Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography fontSize={15}>N/A</Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography fontSize={15}>N/A</Typography>
                                  </Grid>
                                </Stack>
                              ) : null}
                              <div style={{ maxHeight: 60, overflowY: "auto" }}>
                                {item.break_details.map(
                                  (breakDetail, index) => (
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      sx={{ paddingLeft: 0 }}
                                    >
                                      <Grid item xs={4}>
                                        <Typography sx={{ fontSize: 14 }}>
                                          {breakDetail.break_out &&
                                          breakDetail.break_out !== "null"
                                            ? new Date(
                                                `2000-01-01T${breakDetail.break_out}`
                                              ).toLocaleString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true,
                                              })
                                            : "N/A"}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography
                                          sx={{
                                            fontSize: 14,
                                            paddingLeft: 0.4,
                                          }}
                                        >
                                          {breakDetail.break_in &&
                                          breakDetail.break_in !== "null"
                                            ? new Date(
                                                `2000-01-01T${breakDetail.break_in}`
                                              ).toLocaleString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true,
                                              })
                                            : "N/A"}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography
                                          sx={{
                                            fontSize: 14,
                                            paddingLeft: 1.5,
                                          }}
                                        >
                                          {breakDetail.individual_break_time &&
                                          breakDetail.individual_break_time !==
                                            "null"
                                            ? breakDetail.individual_break_time
                                            : "N/A"}
                                        </Typography>
                                      </Grid>
                                    </Stack>
                                  )
                                )}
                              </div>
                              <Stack direction="row" sx={{ paddingTop: 1 }}>
                                <Grid xs={9}>
                                  <Typography
                                    sx={{ color: themes.primaryIndicator }}
                                    fontSize={18}
                                  >
                                    Total Break Time
                                  </Typography>
                                </Grid>
                                <Grid xs={1}>:</Grid>
                                <Grid xs={2}>
                                  <Typography>
                                    {item.total_break_time === "" ||
                                    item.total_break_time === "00:00"
                                      ? "N/A"
                                      : item.total_break_time}
                                  </Typography>
                                </Grid>
                              </Stack>
                            </>
                          )}
                          <Stack direction="row" sx={{ paddingTop: 0.5 }}>
                            <Grid xs={9}>
                              <Typography
                                sx={{ color: themes.primaryIndicator }}
                                fontSize={18}
                              >
                                Total Working Hours
                              </Typography>
                            </Grid>
                            <Grid xs={1}>:</Grid>
                            <Grid xs={2}>
                              <Typography>
                                {item.total_work_time === "" ||
                                item.total_work_time === "00:00"
                                  ? "N/A"
                                  : item.total_work_time}
                              </Typography>
                            </Grid>
                          </Stack>
                          <div style={{ paddingTop: 15 }}>
                            <Mui.Grid container>
                              <Mui.Grid item xs={6}>
                                <Mui.Typography
                                  fontSize={15}
                                  fontWeight={"bold"}
                                  sx={{
                                    paddingBottom: 1.5,
                                    color: themes.primaryIndicator,
                                  }}
                                >
                                  Shift Name
                                </Mui.Typography>
                                <Mui.Tooltip title={item.shift_name}>
                                  <Mui.Typography>
                                    {item.shift_name
                                      ? item.shift_name.length >= 15
                                        ? item.shift_name.substr(0, 15) + "..."
                                        : item.shift_name
                                      : "N/A"}
                                  </Mui.Typography>
                                </Mui.Tooltip>
                              </Mui.Grid>
                              <Mui.Grid
                                item
                                xs={6}
                                sx={{
                                  marginTop: 0,
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    justifyContent: "flex-end",
                                    display:
                                      item.location_status === "Pending" ||
                                      item.location_status === "Hold"
                                        ? showIcons
                                          ? "none"
                                          : "flex"
                                        : "flex",
                                  }}
                                >
                                  {statusBasedIcons(
                                    item.location_tag,
                                    item.location_status
                                  )}
                                </div>
                                <Mui.Tooltip title={item.location_name}>
                                  <Mui.Typography
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                      alignItems: "flex-end",
                                      paddingTop: 1,
                                    }}
                                  >
                                    {item.location_name
                                      ? item.location_name.length >= 10
                                        ? item.location_name.substr(0, 10) +
                                          "..."
                                        : item.location_name
                                      : "N/A"}
                                  </Mui.Typography>
                                </Mui.Tooltip>
                              </Mui.Grid>
                            </Mui.Grid>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
              )}
            </>
          ) : (
            <Alert sx={{ marginTop: 5 }} severity="info">
              No data available
            </Alert>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
};
