import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { Info } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
export const LeaveTypeForm = ({
  popupTitle,
  setFormPage,
  setFirstFormPageData,
  firstFormPageData,
  setLoadData,
  loadData,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [colorValue, setColorValue] = useState(themes.primaryIndicator);
  const [currentLeaveBalance, setCurrentLeaveBalance] = useState(
    firstFormPageData.beyondCurrentLeaveBalance
  );
  const carriedForward = [{ label: "Yes" }, { label: "No" }];

  useEffect(() => {
    if (loadData) {
      setValue("leavename", firstFormPageData.leavename);
      setValue("labelColor", firstFormPageData.labelColor);
      setColorValue(firstFormPageData.labelColor);
      setValue("leaveCount", firstFormPageData.leaveCount);
      setValue(
        "beyondCurrentLeaveBalance",
        firstFormPageData.beyondCurrentLeaveBalance
      );
      setCurrentLeaveBalance(firstFormPageData.beyondCurrentLeaveBalance);
      setLoadData(false);
    }
  }, [
    firstFormPageData.beyondCurrentLeaveBalance,
    firstFormPageData.labelColor,
    firstFormPageData.leaveCount,
    firstFormPageData.leavename,
    loadData,
    setLoadData,
    setValue,
  ]);

  const changeForm = (data) => {
    setFirstFormPageData(data);
    setFormPage(2);
    reset();
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if ((charCode < 48 || charCode > 57) && charCode !== 46) {
      e.preventDefault();
    }
  };

  const checkSpeacialCharaterWithSpace = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(changeForm)}>
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
            <Mui.Stack direction="Row">
              <Mui.Typography>Leave Name</Mui.Typography> &nbsp;
              <Mui.Tooltip title="This is the type of leave name that can be applied by an employee for the current leave period. Example: Sick leave,Casual leave, other leaves...">
                <Info />
              </Mui.Tooltip>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={6}>
            <Mui.TextField
              placeholder="Enter the leave Name"
              {...register("leavename", {
                required: "Leave name is required",
                minLength: {
                  value: 3,
                  message: "Minimum 3 characters are required",
                },
                validate: {
                  leaveNameAvailable: async (fieldValue) => {
                    if (popupTitle !== "Edit LeaveType") {
                      if (fieldValue.trim() === "") {
                        return "Minimum 3 characters are required";
                      } else {
                        try {
                          const response = await fetch(
                            `${domain}leave-types-namecheck/?leave_type=${fieldValue.trim()}`,
                            {
                              method: "GET",
                              headers: {
                                "content-type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const res = await response.json();
                          if (response.ok) {
                            if (res.exists) {
                              return "Leave name is already exists";
                            } else {
                              return true;
                            }
                          } else if (response.status === 409) {
                            sessionHandling();
                          } else {
                            throw new Error(res.error);
                          }
                        } catch (error) {
                          showToast("error", error.message);
                        }
                      }
                    }
                  },
                  editLeaveName: async (fieldValue) => {
                    if (
                      popupTitle === "Edit LeaveType" &&
                      firstFormPageData.leavename !== getValues("leavename")
                    ) {
                      if (fieldValue.trim() === "") {
                        return "Minimum 3 characters are required";
                      } else {
                        try {
                          const response = await fetch(
                            `${domain}leave-types-namecheck/?leave_type=${fieldValue.trim()}`,
                            {
                              method: "GET",
                              headers: {
                                "content-type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const res = await response.json();
                          if (response.ok) {
                            if (res.exists) {
                              return "Designation name is already exists";
                            } else {
                              return true;
                            }
                          } else if (response.status === 409) {
                            sessionHandling();
                          } else {
                            throw new Error(res.error);
                          }
                        } catch (error) {
                          showToast("error", error.message);
                        }
                      }
                    }
                  },
                },
              })}
              size="small"
              InputProps={{
                inputProps: { maxLength: 32 },
              }}
              error={Boolean(errors.leavename)}
              helperText={errors.leavename?.message || ""}
              onKeyPress={checkSpeacialCharaterWithSpace}
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid xs={5} sx={{ paddingTop: 1 }}>
            <Mui.Stack direction="Row">
              <Mui.Typography>Leave Color</Mui.Typography> &nbsp;
              <Mui.Tooltip title="This is the colour that appears in the calendar if this leave type is applied. ">
                <Info />
              </Mui.Tooltip>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={0.5}></Mui.Grid>
          <Mui.Grid xs={6.5} sx={{ paddingLeft: 1.8 }}>
            <Mui.TextField
              type="color"
              value={colorValue}
              {...register("labelColor", {
                required: false,
              })}
              onChange={(event) => {
                setValue("labelColor", event.target.value);
                setColorValue(event.target.value);
              }}
              sx={{
                "& fieldset": { border: "none" },
                width: 360,
              }}
              size="small"
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
            <Mui.Stack direction="Row">
              <Mui.Typography>Leave Per Leave period</Mui.Typography> &nbsp;
              <Mui.Tooltip title="This is the number of leave days that can be applied by an employee per year (or the current leave period). If the leave period is less than a Year this is the number of leaves for the leave period.">
                <Info />
              </Mui.Tooltip>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={6}>
            <Mui.TextField
              placeholder="Enter the leave Count"
              {...register("leaveCount", {
                required: "Leave count is required",
              })}
              size="small"
              InputProps={{
                inputProps: { maxLength: 5 },
              }}
              error={Boolean(errors.leaveCount)}
              helperText={errors.leaveCount?.message || ""}
              onKeyPress={handleKeyPress}
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid xs={6}>
            <Mui.Stack direction="Row">
              <Mui.Typography>
                Employee can apply beyond the current leave balance this leave
                type
              </Mui.Typography>
              <div style={{ paddingTop: 5 }}>
                <Mui.Tooltip title="If [Yes], employees can apply for this leave type even if they don't have enough leave balance. This can be useful for medical or sick leave. ">
                  <Info />
                </Mui.Tooltip>
              </div>{" "}
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={6}>
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={currentLeaveBalance}
              options={carriedForward}
              sx={{ width: "100%" }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option?.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option?.label}</li>;
              }}
              onChange={(event, value) => {
                setCurrentLeaveBalance(value?.label);
              }}
              onBlur={() => trigger("beyondCurrentLeaveBalance")}
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  sx={{ background: themes.whiteColor }}
                  {...register("beyondCurrentLeaveBalance", {
                    required: "beyond the current leave balance is required",
                  })}
                  size="small"
                  error={Boolean(errors.beyondCurrentLeaveBalance)}
                  helperText={errors.beyondCurrentLeaveBalance?.message || ""}
                />
              )}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingBottom: 5,
          }}
        >
          <CustomButton actionName="Next" typeName="submit" />
        </Mui.Stack>
      </form>
    </React.Fragment>
  );
};
