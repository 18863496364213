import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
const otReportsKeys = [
  {
    key: "username",
    name: "User Name",
  },
  {
    key: "attendant_shift_count",
    name: "No of shift",
  },
  {
    key: "weekly_working_hours",
    name: "Shift Hours",
  },
  {
    key: "attendant_ot_count",
    name: "No of OT",
  },
  {
    key: "ot_hours",
    name: "OT Hours",
  },
];
export const OtReports = ({
  workingHoursDetails,
  exportOTime,
  exportOverTimeReport,
  departmentName,
  shiftCatagoryName,
  shiftName,
  startDate,
  endDate,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listViewPage, setListViewPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  useEffect(() => {
    const exportOTTimeDetails = () => {
      let dataPreparation = [];
      workingHoursDetails.forEach((element) => {
        let overAllData = {
          username: element.username,
          no_shift: element.attendant_shift_count,
          workinghours: element.weekly_working_hours,
          no_of_ot: element.attendant_ot_count,
          othours: element.ot_hours,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const department = ["Department Name"];
      const shiftCategory = ["ShiftCategory Name"];
      const shift = ["Shift Name"];
      const date = ["Date"];
      department.push(departmentName);
      shiftCategory.push(shiftCatagoryName);
      shift.push(shiftName);
      date.push(`${startDate} to ${endDate}`);
      const items = dataPreparation;
      const TableHead = ["Username"];
      TableHead.push("No Of Shifts");
      TableHead.push("Shift Hours");
      TableHead.push("No of OT");
      TableHead.push("OT Hours");
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(",");
      csv.unshift(date.join(","));
      csv.unshift(shift.join(","));
      csv.unshift(shiftCategory.join(","));
      csv.unshift(department.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "OverTimeReport.csv";
      document.body.appendChild(element);
      element.click();
    };
    if (exportOTime) {
      exportOTTimeDetails();
      exportOverTimeReport(false);
    }
  }, [
    departmentName,
    endDate,
    exportOTime,
    exportOverTimeReport,
    shiftCatagoryName,
    shiftName,
    startDate,
    workingHoursDetails,
  ]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", paddingRight: 2 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                {otReportsKeys.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {workingHoursDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((department) => (
                  <Mui.TableRow>
                    {otReportsKeys.map((header) => (
                      <Mui.TableCell sx={{ color: themes.blackColor }}>
                        {department[header.key]
                          ? department[header.key]
                          : "N/A"}
                      </Mui.TableCell>
                    ))}
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {workingHoursDetails.length === 0 ? (
            <Mui.Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
              No data available
            </Mui.Alert>
          ) : null}

          {workingHoursDetails.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={workingHoursDetails.length}
              rowsPerPage={rowsPerPage}
              page={listViewPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      </Mui.Grid>
    </React.Fragment>
  );
};
