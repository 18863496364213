import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const domainUrl =
  window.location.hostname === "localhost"
    ? `https://dev.organflow.co/api/`
    : `https://${window.location.hostname}/api/`;

export const fetchDepartment = createAsyncThunk(
  "fetchDepartmentDetails",
  async () => {
    const response = await fetch(`${domainUrl}department/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    });
    if (response.ok) {
      const res = await response.json();
      const allDepartments = res.map((obj) => ({
        ...obj,
        label: obj.department,
      }));
      return allDepartments;
    }
  }
);

export const userManagementReducer = createSlice({
  name: "token",
  initialState: {
    currentScreen: "Employee",
    dataViewFormat: "CardView",
    tabIndicator: 0,
    editUserId: 0,
    editUserDetailsApi: false,
    profileEdit: false,
    sidebarExpand: true,
    subMenuExpand: false,
    department: [],
    loading: false,
    shift_category_id: 99999,
    departmentId: localStorage.getItem("approveLeaveDepartmentId")
      ? JSON.parse(localStorage.getItem("approveLeaveDepartmentId"))
      : 99999,

    departmentLeadId: localStorage.getItem("approveLeaveDepartmentLeadId")
      ? JSON.parse(localStorage.getItem("approveLeaveDepartmentLeadId"))
      : 99999,
    departmentName: localStorage.getItem("approveLeaveDepartmentName")
      ? localStorage.getItem("approveLeaveDepartmentName")
      : "All",
    attendanceScreenView: "CardView",
    companyId: JSON.parse(localStorage.getItem("companyId"))
      ? JSON.parse(localStorage.getItem("companyId"))
      : 99999,
    companyName: localStorage.getItem("companyName")
      ? localStorage.getItem("companyName")
      : "",
    backToUserGroup: false,
    viewUserGroupDetails: JSON.parse(localStorage.getItem("viewGroup"))
      ? JSON.parse(localStorage.getItem("viewGroup"))
      : null,
  },
  reducers: {
    setDataViewFormat: (state, action) => {
      state.dataViewFormat = action.payload;
    },
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload;
    },
    setTapIndicator: (state, action) => {
      state.tabIndicator = action.payload;
    },
    setEditUserId: (state, action) => {
      state.editUserId = action.payload;
    },
    setEditUserDetailsApi: (state, action) => {
      state.editUserDetailsApi = action.payload;
    },
    setProfileEditBack: (state, action) => {
      state.profileEdit = action.payload;
    },
    setSidebarExpand: (state, action) => {
      state.sidebarExpand = action.payload;
    },
    setSubMenuExpand: (state, action) => {
      state.subMenuExpand = action.payload;
    },
    setDepartmentId: (state, action) => {
      state.departmentId = action.payload;
    },
    setDepartmentLeadId: (state, action) => {
      state.departmentLeadId = action.payload;
    },
    setDepartmentName: (state, action) => {
      state.departmentName = action.payload;
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
    setBackToUserGroup: (state, action) => {
      state.backToUserGroup = action.payload;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setViewUserGroupDetails: (state, action) => {
      state.viewUserGroupDetails = action.payload;
    },
    setLeaveGroupDetails: (state, action) => {
      state.viewUserGroupDetails = action.payload;
    },
    setAttendanceScreenView: (state, action) => {
      state.attendanceScreenView = action.payload;
    },
    setShiftCategoryId: (state, action) => {
      state.shift_category_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.department = action.payload;
      })
      .addCase(fetchDepartment.rejected, (state, action) => {
        state.loading = true;
      });
  },
});

export const {
  setCurrentScreen,
  setTapIndicator,
  setEditUserId,
  setEditUserDetailsApi,
  setSidebarExpand,
  setProfileEditBack,
  setSubMenuExpand,
  setDataViewFormat,
  setDepartmentId,
  setDepartmentLeadId,
  setDepartmentName,
  setCompanyId,
  setBackToUserGroup,
  setCompanyName,
  setViewUserGroupDetails,
  setLeaveGroupDetails,
  setAttendanceScreenView,
  setShiftCategoryId,
} = userManagementReducer.actions;

export default userManagementReducer.reducer;
