import React from "react";
import { Grid, Paper } from "@mui/material";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
export const SettingsMenu = ({
  setCurrentScreen,
  currentScreen,
  leaveCount,
  permissionCount,
  setReloadData,
}) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid conatiner spacing={2} sx={{ marginTop: 5 }}>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Leaves"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Leaves" ? themes.formBackground : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Leaves");
              setCurrentScreen("Leaves");
              setReloadData(true);
              dispatch(setGlobalSearchValue(""));
            }}
          >
            Leaves
            {leaveCount !== 0 && (
              <div
                style={{
                  marginLeft: "auto",
                  width: 34,
                  height: 34,
                  borderRadius: "50%",
                  backgroundColor: themes.blackColor,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    color: themes.lightGreen,
                  }}
                >
                  {leaveCount}
                </span>
              </div>
            )}
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Permissions"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Permissions" ? themes.formBackground : null,
            }}
            onClick={() => {
              dispatch(setDepartmentId(99999));
              dispatch(setDepartmentName("All"));
              localStorage.setItem("approveSettingScreens", "Permissions");
              setCurrentScreen("Permissions");
              setReloadData(true);
              dispatch(setGlobalSearchValue(""));
            }}
          >
            Permissions
            {permissionCount !== 0 && (
              <div
                style={{
                  marginLeft: "auto",
                  width: 34,
                  height: 34,
                  borderRadius: "50%",
                  backgroundColor: themes.blackColor,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    color: themes.lightGreen,
                  }}
                >
                  {permissionCount}
                </span>
              </div>
            )}
          </Paper>
        </Grid>
        {/* <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Over time"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Over time" ? themes.formBackground : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Over time");
              setCurrentScreen("Over time");
            }}
          >
            Over time
            <div
              style={{
                marginLeft: "auto",
                width: 34,
                height: 34,
                borderRadius: "50%",
                backgroundColor: themes.blackColor,
              }}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Advance Salary"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Advance Salary"
                  ? themes.formBackground
                  : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Advance Salary");
              setCurrentScreen("Advance Salary");
            }}
          >
            Advance Salary
            <div
              style={{
                marginLeft: "auto",
                width: 34,
                height: 34,
                borderRadius: "50%",
                backgroundColor: themes.blackColor,
              }}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Loans"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Loans" ? themes.formBackground : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Loans");
              setCurrentScreen("Loans");
            }}
          >
            Loans
            <div
              style={{
                marginLeft: "auto",
                width: 34,
                height: 34,
                borderRadius: "50%",
                backgroundColor: themes.blackColor,
              }}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Expense"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Expense" ? themes.formBackground : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Expense");
              setCurrentScreen("Expense");
            }}
          >
            Expense
            <div
              style={{
                marginLeft: "auto",
                width: 34,
                height: 34,
                borderRadius: "50%",
                backgroundColor: themes.blackColor,
              }}
            />
          </Paper>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};
