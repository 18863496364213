import React from "react";
import * as Mui from "@mui/material";
export const CustomTextBox = ({
  type,
  rules,
  selectedValue,
  label,
  placeHolder,
  textBoxError,
  errorMessage,
  updateDataFunction,
  disabledValue,
}) => {
  return (
    <Mui.TextField
      type={type}
      multiline={type === "multiline" ? true : false}
      rows={type === "multiline" ? 2 : 1}
      label={label}
      placeholder={placeHolder}
      value={selectedValue}
      rules={rules}
      size="small"
      onChange={(event) => {
        updateDataFunction(event);
      }}
      InputLabelProps={{ shrink: true }}
      error={textBoxError}
      helperText={errorMessage}
      disabled={disabledValue}
      fullWidth
    />
  );
};
