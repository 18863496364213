import { showToast } from "components/Status";
export const getNotificationOptions = (type, message) => ({
  paddingTop: 500,
  place: "tr",
  message: (
    <div className="alert-text">
      {message === "Please enter atleast one bill" ? (
        ""
      ) : (
        <span className="alert-title" data-notify="title">
          Notification
        </span>
      )}
      <span data-notify="message">{message}</span>
    </div>
  ),
  type: type,
  icon: "ni ni-bell-55",
  autoDismiss: 15,
});

export default function sessionHandling() {
  localStorage.clear();
  showToast(
    "error",
    "You have logged in from another device, so this session will be signed out.",
    {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );

  // Redirect to login after a delay
  setTimeout(() => {
    window.location.href = "/auth/login"; // Adjust the path as per your routing setup
  }, 2000);
}
