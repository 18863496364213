import React from "react";
import { SprDashboard } from "./Dashboard";
import { SprintDashboardContextProvider } from "../DashboardContext/sprintindex";

export const SprintDashboard = () => {
  return (
    <React.Fragment>
      <SprintDashboardContextProvider>
        <SprDashboard />
      </SprintDashboardContextProvider>
    </React.Fragment>
  );
};
