import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { NewPaySchedule } from "./NewPaySchedule";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import { CustomButton } from "components/CustomButton";
import { themes } from "services/constants";
import { PayScheduleTable } from "./PayScheduleTable";
import { EditPaySchedule } from "./EditPaySchedule";
import { Loader } from "components/Loader";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
const keys = [
  "name",
  "start_date",
  "end_date",
  "run_date",
  "num_of_employees",
  "status",
  "total_net_pay",
];

export const PaySchedule = () => {
  const { domain, globalSearchValue, token } = useSelector(
    (state) => state.tokenReducer
  );
  const { result: companyList } = useFetch(
    `${domain}get-active-company/`
  );
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [createNewPaySchedule, setCreateNewPaySchedule] = useState(false);
  const [editPayScheduleDetails, setEditPayScheduleDetails] = useState({});
  const [editPaySchedule, setEditPaySchedule] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [paySheduleData, setPaySheduleData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [listViewPage, setListViewPage] = useState(0);
  const {
    result: payScheduleDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}payschedule/?company=${companyId}` : null
  );

  useEffect(() => {
    if (companyList.length !== 0) {
      const data = companyList.filter((company) => {
        return company.isprimary === true;
      });
      setCompanyId(data[0].id);
      setCompanyName(data[0].companyname);
      setTimeout(function () {
        reCallApi(true);
      }, 300);
    }
  }, [companyList, reCallApi]);

  const handleCompanyName = (event, data) => {
    if (data) {
      setCompanyName(data.companyname);
      setCompanyId(data.id);
      setTimeout(function () {
        reCallApi(true);
      }, 300);
    }
  };
  useEffect(() => {
    if (updateData && payScheduleDetails?.length !== 0 && payScheduleDetails) {
      setPaySheduleData(payScheduleDetails);
      setUpdateData(false);
    }
  }, [payScheduleDetails, updateData]);

  useEffect(() => {
    if (payScheduleDetails?.length !== 0 || payScheduleDetails?.length === 0) {
      const filteredResults = payScheduleDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setPaySheduleData(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payScheduleDetails]);

  const updateDeletePayrun = async () => {
    try {
      const response = await fetch(
        `${domain}payschedule/${editPayScheduleDetails?.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({ isdeleted: true }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Deleted successfully");
        reCallApi(true);
        setOpenDialog(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      {!createNewPaySchedule && !editPaySchedule ? (
        <React.Fragment>
          <Mui.Grid container sx={{ paddingTop: 2, paddingBottom: 2 }}>
            <Mui.Grid xs={9}></Mui.Grid>
            <Mui.Grid
              xs={3}
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <Mui.Typography>Company Name</Mui.Typography>
              <CustomAutoComplete
                dataList={companyList.map((obj) => ({
                  ...obj,
                  label: obj.companyname,
                }))}
                selectedValue={companyName}
                updateDataFunction={handleCompanyName}
                placeHolder="Please select the company name"
                fullWith
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={6}>
              <Mui.Typography sx={{ fontSize: 23, color: themes.headLine }}>
                Upcoming Payrolls
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setCreateNewPaySchedule(true);
                }}
                actionName="Add Pay Schedule"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
          {loading ? (
            <Loader />
          ) : (
            <PayScheduleTable
              listViewPage={listViewPage}
              setListViewPage={setListViewPage}
              payrunDetails={paySheduleData}
              setEditPaySchedule={setEditPaySchedule}
              setEditPayScheduleDetails={setEditPayScheduleDetails}
              editPayScheduleDetails={editPayScheduleDetails}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              updateDeletePayrun={updateDeletePayrun}
            />
          )}
        </React.Fragment>
      ) : null}

      {createNewPaySchedule ? (
        <NewPaySchedule
          companyId={companyId}
          companyName={companyName}
          setCreateNewPaySchedule={setCreateNewPaySchedule}
          setEditPaySchedule={setEditPaySchedule}
          setEditPayScheduleDetails={setEditPayScheduleDetails}
          reCallApi={reCallApi}
        />
      ) : null}
      {editPaySchedule ? (
        <EditPaySchedule
          setEditPaySchedule={setEditPaySchedule}
          setCreateNewPaySchedule={setCreateNewPaySchedule}
          setEditPayScheduleDetails={setEditPayScheduleDetails}
          editPayScheduleDetails={editPayScheduleDetails}
          reCallApi={reCallApi}
          companyName={companyName}
          companyId={companyId}
        />
      ) : null}
    </React.Fragment>
  );
};
