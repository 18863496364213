import React, { useState } from "react";
import { TaskContextProvider } from "./TaskContext";
import { ListView } from "./ListView";
import { TaskTopBar } from "./TaskTopBar";
import KanbanBoard from "./KanbanBoard";
import { TaskMaterial } from "./TaskMaterial";

export const Tasks = () => {
  const [activeTap, setActiveTap] = useState("ListView");

  const displayComponent = () => {
    switch (activeTap) {
      case "ListView":
        return <ListView setActiveTap={setActiveTap} />;
      case "KanbanView":
        return <KanbanBoard />;
      case "ShowMaterialScreen":
        return <TaskMaterial setActiveTap={setActiveTap} />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div>
        <TaskContextProvider>
          <TaskTopBar activeTap={activeTap} setActiveTap={setActiveTap} />
          {displayComponent()}
        </TaskContextProvider>
      </div>
    </React.Fragment>
  );
};
