import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomButton";
import MuiDrawer from "@mui/material/Drawer";
import * as Mui from "@mui/material";
import {
  Collapse,
  Box,
  List,
  CssBaseline,
  IconButton,
  ListItem,
  Card,
  CardContent,
  DialogContent,
  ListItemIcon,
  ListItemButton,
  Alert,
  InputBase,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { getCardBackgroundColor } from "components/Status";
import SearchIcon from "@mui/icons-material/Search";
import Rectangle_10126 from "../../assets/img/brand/1_2.png";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { themes } from "services/constants";
import { Grid, Tooltip, Button, Typography, ListItemText } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import {
  setCurrentScreen,
  setSubMenuExpand,
} from "services/Redux/userManagament";
import {
  // setReloadApi,
  setEditProject,
  setProjectStatus,
  setReloadHRMSApi,
  // setAllStatus,
} from "services/Redux/leaveManagement";
import { setCurrentLocation } from "services/Redux/userToken";
import { Link, useHistory } from "react-router-dom";
import { DropdownMenu } from "../DropdownMenu";
import packageJson from "../../../package.json";
import { showToast } from "components/Status";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import sessionHandling from "services/utils/notificationUtils";
const drawerWidth = 240;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 20,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.common.white),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
//ToDo:I need this code for Header
// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   backgroundColor: themes.primary,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     backgroundColor: themes.primary,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const Sidebar = ({ routes, toggleSidenav, open }) => {
  const currentScreen = useSelector((state) => state.userReducer.currentScreen);
  const sitebarExpand = useSelector((state) => state.userReducer.sidebarExpand);
  const subMenuExpand = useSelector((state) => state.userReducer.subMenuExpand);
  const { token, domain } = useSelector((state) => state.tokenReducer);
  const [deviceDetails, setDeviceDetails] = useState(null);

  const globalSearchValue = useSelector(
    (state) => state.tokenReducer?.globalSearchValue
  );
  const currentLocation = useSelector(
    (state) => state?.tokenReducer?.currentLocation
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const history = useHistory();
  const [expandOpen, setExpandOpen] = useState(sitebarExpand);
  const dispatch = useDispatch();
  const firmName = useSelector(
    (state) => state.tokenReducer?.userDetails?.firmName
  );
  const [currentRoute, setCurrentRoute] = React.useState(routes[0]);

  const handleDrawerOpen = () => {
    toggleSidenav();
  };

  const handleItemClick = useCallback(
    (item) => {
      dispatch(setGlobalSearchValue(""));
      if (item.name !== currentLocation) {
        dispatch(setCurrentLocation(item.name));
        localStorage.setItem("currentScreen", item.name);
        if (item.name === "Project") {
          dispatch(setCurrentScreen("Projects"));
          localStorage.setItem("currentSubMenu", "Projects");
        } else if (item.name === "Administration") {
          dispatch(setCurrentScreen("Users"));
          localStorage.setItem("currentSubMenu", "Users");
        } else if (item.name === "Team Zone") {
          dispatch(setCurrentScreen("Calendar"));
          localStorage.setItem("currentSubMenu", "Calendar");
        } else if (item.name === "Settings") {
          dispatch(setCurrentScreen(""));
        } else if (item.name === "Invoice") {
          dispatch(setCurrentScreen(""));
        } else if (item.name === "My Zone") {
          dispatch(setCurrentScreen("Calendar"));
          localStorage.setItem("currentSubMenu", "Calendar");
        }
        history.push(`${item.layout}${item.uri || item.path}`);
        dispatch(setCurrentLocation(item.name));
        setCurrentRoute(item);
      }
    },
    [currentLocation, dispatch, history]
  );

  const seticons = useCallback(
    (icon, name) => {
      const Icon = icon;
      return (
        <Icon
          style={{
            color:
              name === currentLocation ? themes.blackColor : themes.blackColor,
          }}
        />
      );
    },
    [currentLocation]
  );

  const seticonsSubmenu = useCallback(
    (icon, name) => {
      const Icon = icon;
      return (
        <Icon
          size="small"
          style={{
            color:
              name === currentScreen ? themes.blackColor : themes.blackColor,
          }}
        />
      );
    },
    [currentScreen]
  );

  const logoutCurrentUser = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleSubMenu = useCallback(
    (subMenu, item) => {
      if (subMenu.name === "Tasks") {
        dispatch(setEditProject(null));
        dispatch(
          setProjectStatus([
            "ToDo",
            "InProgress",
            "Review",
            "Testing",
            "Completed",
          ])
        );
        dispatch(setReloadHRMSApi(true));
      }
      history.push(subMenu.path);
      dispatch(setGlobalSearchValue(""));
      dispatch(setCurrentScreen(subMenu.name));
      localStorage.setItem("currentSubMenu", subMenu.name);
    },
    [dispatch, history]
  );

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpenDialog1(false);
  };
  const getname = useCallback(
    (item) => {
      return (
        <Grid container>
          <Grid xs={11.7}>{item.name}</Grid>
          <Grid xs={0.3}>
            {item?.collapse ? (
              expandOpen && currentLocation === item.name ? (
                <div>
                  <ExpandCircleDownIcon fontSize="10" />
                </div>
              ) : (
                <div></div>
              )
            ) : null}
          </Grid>
        </Grid>
      );
    },
    [currentLocation, expandOpen]
  );

  const returnMenuItems = useCallback(() => {
    return (
      <List aria-labelledby="nested-list-subheader">
        {routes.map((item) => {
          if (!item.hideInSidebar) {
            return (
              <ListItem
                key={item.name}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    spacing: 2,
                    backgroundColor:
                      item?.name === currentLocation
                        ? themes.primaryIndicator
                        : "none",
                    color:
                      item?.name === currentLocation
                        ? themes.blackColor
                        : "none",
                    "&:hover": {
                      backgroundColor:
                        item?.name === currentLocation
                          ? themes.primaryIndicator
                          : "none",
                    },
                  }}
                  onClick={() => {
                    if (item?.collapse) {
                      setExpandOpen(
                        item?.name === currentLocation ? !expandOpen : true
                      );
                    }
                    handleItemClick(item);
                  }}
                >
                  <Link to={item.uri || item.path} />
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {seticons(item.icon, item?.name)}
                  </ListItemIcon>
                  <ListItemText
                    primary={getname(item)}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                {currentLocation === item.name && (
                  <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <div style={{ paddingTop: 2 }}></div>
                      {item?.collapse?.map((submenu) => (
                        <ListItemButton
                          sx={{
                            pl: open ? 6 : 4,
                            backgroundColor:
                              submenu.name === currentScreen
                                ? themes.primaryIndicator
                                : "none",
                            color:
                              submenu.name === currentScreen
                                ? themes.blackColor
                                : "none",
                            "&:hover": {
                              backgroundColor:
                                submenu.name === currentScreen
                                  ? themes.primaryIndicator
                                  : "none",
                            },
                          }}
                          onClick={() => handleSubMenu(submenu, item)}
                        >
                          <ListItemIcon sx={{ minWidth: "35px" }}>
                            {seticonsSubmenu(submenu.icon, submenu.name)}
                          </ListItemIcon>
                          {open ? (
                            <ListItemText
                              primary={submenu.name}
                              primaryTypographyProps={{
                                fontSize: "15px",
                                lineHeight: 1,
                              }}
                            />
                          ) : null}
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </ListItem>
            );
          }
          return null;
        })}
      </List>
    );
  }, [
    currentLocation,
    currentScreen,
    expandOpen,
    getname,
    handleItemClick,
    handleSubMenu,
    open,
    routes,
    seticons,
    seticonsSubmenu,
  ]);

  useEffect(() => {
    if (subMenuExpand) {
      returnMenuItems();
      dispatch(setSubMenuExpand(false));
    }
  }, [dispatch, returnMenuItems, subMenuExpand]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${domain}bio_metric/device_status`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setDeviceDetails(res);
        setOpenDialog1(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <Mui.Grid container sx={{ paddingLeft: 3, paddingBottom: 2 }}>
          <Mui.Grid
            item
            xs={2}
            sx={{
              paddingTop: 1.5,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Mui.Grid>
          {open ? (
            <Mui.Grid item xs={9} sx={{ paddingTop: 1, height: 10 }}>
              {/* Product Name: I need this code for Breadcrumbs  */}
              {/* <Mui.Typography
                sx={{ fontSize: 22, color: themes.primaryIndicator }}
              >
                OrganFlow
              </Mui.Typography> */}
              <img
                src={Rectangle_10126}
                alt=""
                style={{ height: "50px", width: "170px" }}
              />
            </Mui.Grid>
          ) : null}
        </Mui.Grid>
        {returnMenuItems()}
        <ListItem
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            left: 0,
            textAlign: "center",
            display: open ? null : "none",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: themes.primary,
            }}
          >
            <Tooltip title="Bio Metric" placement="bottom">
              <Button
                sx={{
                  backgroundColor: themes.primaryIndicator,
                  padding: 1,
                }}
                onClick={() => fetchData()}
              >
                <TouchAppIcon
                  variant="outlined"
                  fontSize="medium"
                  sx={{
                    color: themes.headLine,
                  }}
                />
              </Button>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title={packageJson.version} placement="bottom">
              <Button
                sx={{ backgroundColor: themes.primaryIndicator, padding: 1 }}
              >
                <InfoSharpIcon
                  variant="outlined"
                  size="large"
                  sx={{
                    color: themes.headLine,
                  }}
                />
              </Button>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title="logout" placement="bottom">
              <Button
                sx={{
                  backgroundColor: themes.primaryIndicator,
                  padding: 1,
                }}
                onClick={() => setOpenDialog(true)}
              >
                <ExitToAppIcon
                  variant="outlined"
                  size="large"
                  sx={{
                    color: themes.headLine,
                  }}
                />
              </Button>
            </Tooltip>
          </Typography>
        </ListItem>
      </Drawer>
      <Mui.AppBar
        sx={{
          backgroundColor: themes.whiteColor,
          boxShadow: "none",
          paddingButtom: 1,
        }}
        position="fixed"
      >
        <Mui.Grid
          container
          sx={{
            paddingLeft: 1,
            paddingTop: 1,
            paddingBottom: 0.1,
            borderBottom: `1px solid ${themes.sidebarDivider}`,
            position: "sticky",
            zIndex: 10,
          }}
        >
          <Mui.Grid Item xs={9} sx={{ paddingLeft: open ? 30 : 8 }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                marginTop: 1,
                marginRight: 5,
                display: { xs: "none", md: "flex" },
                color: themes.headLine,
              }}
            >
              {firmName}
            </Typography>
            {/* ToDo: I need this code for Breadcrumbs  */}
            {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {location} {currentScreen === "" ? "" : "/"} {currentScreen}
          </Typography> */}
            <Box sx={{ flexGrow: 1 }} />
          </Mui.Grid>
          <Mui.Grid Item xs={2}>
            <Search sx={{ backgroundColor: "white" }}>
              <SearchIconWrapper>
                <SearchIcon style={{ color: themes.blackColor }} />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  color: themes.blackColor,
                  border: `3px solid ${themes.formBackground}`,
                  borderRadius: 3,
                }}
                placeholder="Search…"
                value={globalSearchValue}
                inputProps={{ "aria-label": "search" }}
                onChange={(event) => {
                  dispatch(setGlobalSearchValue(event.target.value));
                }}
                fullWidth
              />
            </Search>
          </Mui.Grid>
          <Mui.Grid Item xs={1}>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <DropdownMenu />
            </Box>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.AppBar>
      <Mui.Divider />
      <Mui.Grid sx={{ paddingTop: 6 }}></Mui.Grid>
      <Box component="main">{currentRoute.component}</Box>
      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog1}
        title="Device Status"
        minWidth={700}
      >
        {deviceDetails && (
          <Grid xs={12}>
            <div>
              <DialogContent>
                <Card
                  style={getCardBackgroundColor(deviceDetails.status_value)}
                >
                  <CardContent>
                    <Typography>
                      <h3>Overall Status : {deviceDetails.overall_status}</h3>
                    </Typography>
                    <Typography>
                      <h3> Status Value : {deviceDetails.status_value}</h3>
                    </Typography>
                  </CardContent>
                </Card>
              </DialogContent>
              {deviceDetails.devices.length > 0 ? (
                <Mui.Grid style={{ height: "110px" }}>
                  <Mui.Typography sx={{ fontWeight: 800, paddingLeft: 2 }}>
                    Devices:
                  </Mui.Typography>
                  <ul>
                    {deviceDetails.devices.map((device, index) => (
                      <li key={index}>
                        <Mui.Grid>
                          <Mui.Typography>
                            <b>Device Name : </b>
                            {device.device_name}
                          </Mui.Typography>
                          <Mui.Typography>
                            <b>Serial Number : </b>
                            {device.device_serial_number}
                          </Mui.Typography>{" "}
                          <Mui.Typography>
                            <b>Location : </b>
                            {device.device_location}
                          </Mui.Typography>
                          <Mui.Typography>
                            <b>Status : </b>
                            {device.device_status}
                          </Mui.Typography>
                        </Mui.Grid>
                      </li>
                    ))}
                  </ul>
                </Mui.Grid>
              ) : (
                <Alert sx={{ marginTop: 3 }} severity="info">
                  No devices found
                </Alert>
              )}
            </div>
          </Grid>
        )}
      </GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Logout"
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography fontSize={24}>
              Are you sure you want to log out?
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8}></Grid>
          <Grid item xs={2}>
            <CustomButton
              actionFuntion={handleCloseDialogBox}
              actionName="No"
              typeName="button"
            />
          </Grid>
          <Grid item xs={2}>
            <CustomButton
              actionFuntion={logoutCurrentUser}
              actionName="Yes"
              typeName="button"
            />
          </Grid>
        </Grid>
      </GlobalDialogBox>
    </Box>
  );
};
