import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import HouseIcon from "@mui/icons-material/House";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { themes } from "services/constants";
import { getStatusColor } from "components/Status";
import { Loader } from "components/Loader";

export const CardView = ({
  departmentMembers,
  loading,
  filterDepartMemberList,
  expand,
  setExpand,
  page,
  setPage,
}) => {
  const [onClickCard, setOnClickCard] = useState(0);
  const [showIcons, setShowIcons] = useState(true);
  const [intervalTime, setIntervalTime] = useState(200);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(10);

  useEffect(() => {
    if (expand) {
      setOnClickCard(0);
      setExpand(false);
    }
  }, [expand, setExpand]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowIcons(!showIcons);
      setIntervalTime(200);
    }, intervalTime);
    return () => {
      clearInterval(interval);
    };
  }, [intervalTime, showIcons]);

  const handleChangeLeaveRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setLeaveRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangeLeavePage = (event, newPage) => {
    setPage(newPage);
  };

  if (
    (!departmentMembers ||
      departmentMembers?.user_status_details?.length === 0) &&
    !loading
  ) {
    return (
      <Mui.Alert severity="info" sx={{ marginTop: 5 }}>
        No data available
      </Mui.Alert>
    );
  }

  const statusBasedIcons = (status, locationStatus) => {
    switch (status) {
      case "Home":
        return (
          <Mui.Tooltip title="Home">
            <Mui.IconButton
              onMouseOver={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(100000000);
                  setShowIcons(false);
                }
              }}
              onMouseOut={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(2000);
                }
              }}
            >
              <HouseIcon
                style={{
                  fontSize: "large",
                  color:
                    locationStatus === "Pending" || locationStatus === "Hold"
                      ? "red"
                      : themes.activeTap,
                }}
              />
            </Mui.IconButton>
          </Mui.Tooltip>
        );
      case "Office":
        return (
          <Mui.Tooltip title="Office">
            <Mui.IconButton
              onMouseOver={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(100000000);
                  setShowIcons(false);
                }
              }}
              onMouseOut={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(2000);
                }
              }}
            >
              <ImportantDevicesIcon
                style={{
                  fontSize: "large",
                  color:
                    locationStatus === "Pending" || locationStatus === "Hold"
                      ? "red"
                      : themes.activeTap,
                }}
              />
            </Mui.IconButton>
          </Mui.Tooltip>
        );
      case "Site":
        return (
          <Mui.Tooltip title="Site">
            <Mui.IconButton
              onMouseOver={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(100000000);
                  setShowIcons(false);
                }
              }}
              onMouseOut={() => {
                if (locationStatus === "Pending" || locationStatus === "Hold") {
                  setIntervalTime(2000);
                }
              }}
            >
              <SmartphoneIcon
                style={{
                  fontSize: "large",
                  color:
                    locationStatus === "Pending" || locationStatus === "Hold"
                      ? "red"
                      : themes.activeTap,
                }}
              />
            </Mui.IconButton>
          </Mui.Tooltip>
        );
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container spacing={0} sx={{ justifyContent: "center" }}>
        {loading === true ? <Loader info="Loading..." /> : null}
        {filterDepartMemberList
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item) => (
            <Mui.Grid item key={item.id}>
              <Mui.Card
                className=" min-h-full max-w-fit mt-5 w-96 flex space-x-1 shadow-lg "
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  marginLeft: 5,
                  backgroundColor:
                    item.location_status === "Pending" ||
                    item.location_status === "Hold"
                      ? themes.backgroundShadow
                      : themes.backgroundWhite,
                }}
                onClick={() => setOnClickCard(onClickCard !== 0 ? 0 : item.id)}
              >
                <Mui.CardContent>
                  <Mui.Grid container>
                    <Mui.Grid item xs={2.5}>
                      <Mui.Avatar
                        sx={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#ff4d88",
                        }}
                        alt={item.user_name?.charAt(0).toUpperCase()}
                        src={item.image_url}
                      />
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      xs={9.5}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Mui.Stack>
                        <Mui.Typography
                          fontWeight={"bold"}
                          sx={{
                            fontSize:
                              item.user_name.length < 15 ? "18px" : "15px",
                          }}
                        >
                          {item.user_name
                            ? item.user_name.length >= 15
                              ? item.user_name.substr(0, 15) + "..."
                              : item.user_name
                            : "N/A"}
                        </Mui.Typography>
                        <Mui.Grid container>
                          <Mui.Grid item xs={10}>
                            <Mui.Typography>
                              {item?.user_code ? item?.user_code : "N/A"}
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={2}>
                            <Mui.Box
                              sx={{
                                width: 80,
                                height: 15,
                                backgroundColor: getStatusColor(item.type),
                                borderRadius: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: themes.backgroundWhite,
                                p: 2,
                                fontSize: 13,
                              }}
                            >
                              {" "}
                              {item.type}
                            </Mui.Box>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Stack>
                    </Mui.Grid>
                    <Mui.Grid item xs={3}></Mui.Grid>
                    <Mui.Grid item xs={0.5}></Mui.Grid>
                  </Mui.Grid>
                  <div className="mt-2 ">
                    <Mui.Grid item xs={6} sx={{ marginTop: 1 }}>
                      <Mui.Typography
                        sx={{ color: themes.primaryIndicator }}
                        fontSize={18}
                      >
                        Check-In
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      xs={6}
                      sx={{ marginTop: -3.5, marginLeft: 20 }}
                    >
                      <Mui.Typography
                        sx={{ color: themes.primaryIndicator }}
                        fontSize={18}
                      >
                        Check-Out
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={6}>
                        <Mui.Typography sx={{ mt: 0, ml: 0 }} fontSize={16}>
                          {item?.check_in_time_formatted
                            ? item?.check_in_time_formatted
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={6}>
                        <Mui.Typography sx={{ mt: 0, ml: 4 }} fontSize={16}>
                          {item?.check_out_time_formatted
                            ? item?.check_out_time_formatted
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  </div>
                  {onClickCard === item.id && (
                    <>
                      <Mui.Stack direction="row">
                        <Mui.Grid container>
                          <Mui.Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <Mui.Typography
                              sx={{ color: themes.primaryIndicator }}
                              fontSize={15}
                            >
                              Break Start
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-center",
                              alignItems: "flex-center",
                              paddingLeft: 0.5,
                            }}
                          >
                            <Mui.Typography
                              sx={{ color: themes.primaryIndicator }}
                              fontSize={15}
                            >
                              Break End
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Mui.Typography
                              sx={{ color: themes.primaryIndicator }}
                              fontSize={15}
                            >
                              Total-Time
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Stack>
                      {item.break_details.length === 0 ? (
                        <Mui.Stack
                          direction="row"
                          spacing={2}
                          sx={{ paddingLeft: 0 }}
                        >
                          <Mui.Grid item xs={4}>
                            <Mui.Typography fontSize={15}>N/A</Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.Typography fontSize={15}>N/A</Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.Typography fontSize={15}>N/A</Mui.Typography>
                          </Mui.Grid>
                        </Mui.Stack>
                      ) : null}
                      {item.break_details.map((breakDetail, index) => (
                        <Mui.Stack
                          direction="row"
                          spacing={2}
                          sx={{ paddingLeft: 0 }}
                        >
                          <Mui.Grid item xs={4}>
                            <Mui.Typography sx={{ fontSize: 14 }}>
                              {breakDetail?.break_out_formatted
                                ? breakDetail?.break_out_formatted
                                : "N/A"}
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.Typography sx={{ fontSize: 14 }}>
                              {breakDetail?.break_in_formatted
                                ? breakDetail?.break_in_formatted
                                : "N/A"}
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.Typography sx={{ fontSize: 14 }}>
                              {breakDetail.individual_break_time &&
                              breakDetail.individual_break_time !== "null"
                                ? breakDetail.individual_break_time
                                : "N/A"}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Stack>
                      ))}

                      <Mui.Stack direction="row" sx={{ paddingTop: 1 }}>
                        <Mui.Grid xs={9}>
                          <Mui.Typography
                            sx={{ color: themes.primaryIndicator }}
                            fontSize={18}
                          >
                            Total Break Time
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={1}>:</Mui.Grid>
                        <Mui.Grid xs={2}>
                          <Mui.Typography>
                            {item?.total_break_time
                              ? item?.total_break_time
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Stack>
                    </>
                  )}
                  <Mui.Stack direction="row" sx={{ paddingTop: 0.5 }}>
                    <Mui.Grid xs={9}>
                      <Mui.Typography
                        sx={{ color: themes.primaryIndicator }}
                        fontSize={18}
                      >
                        Total Working Hours
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={1}>:</Mui.Grid>
                    <Mui.Grid xs={2}>
                      <Mui.Typography>
                        {item?.total_work_time ? item?.total_work_time : "N/A"}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Stack>
                  <div style={{ paddingTop: 15 }}>
                    <Mui.Grid container>
                      <Mui.Grid item xs={6}>
                        <Mui.Typography
                          fontSize={15}
                          fontWeight={"bold"}
                          sx={{
                            paddingBottom: 1.5,
                            color: themes.primaryIndicator,
                          }}
                        >
                          Shift Name
                        </Mui.Typography>
                        <Mui.Tooltip title={item.shift_name}>
                          <Mui.Typography>
                            {item.shift_name
                              ? item.shift_name.length >= 15
                                ? item.shift_name.substr(0, 15) + "..."
                                : item.shift_name
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Tooltip>
                      </Mui.Grid>
                      <Mui.Grid
                        item
                        xs={6}
                        sx={{ marginTop: 0, justifyContent: "flex-end" }}
                      >
                        <div
                          style={{
                            justifyContent: "flex-end",
                            display:
                              item.location_status === "Pending" ||
                              item.location_status === "Hold"
                                ? showIcons
                                  ? "none"
                                  : "flex"
                                : "flex",
                          }}
                        >
                          {statusBasedIcons(
                            item.location_tag,
                            item.location_status
                          )}
                        </div>
                        <Mui.Tooltip title={item.location_name}>
                          <Mui.Typography
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            {item.location_name
                              ? item.location_name.length >= 10
                                ? item.location_name.substr(0, 10) + "..."
                                : item.location_name
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Tooltip>
                      </Mui.Grid>
                    </Mui.Grid>
                  </div>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
          ))}
      </Mui.Grid>
      {filterDepartMemberList.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={filterDepartMemberList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangeLeavePage}
          onRowsPerPageChange={handleChangeLeaveRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
