import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomButton";
import { useSelector } from "react-redux";
export default function EmailDialog({
  openDialog,
  setOpenDialog,
  checkExistingUser,
  inviteSystemAccess,
  editUser,
}) {
  const { domain } = useSelector((state) => state.tokenReducer);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    const checkMail = () => {
      checkExistingUser(editUser?.id).then((value) => {
        if (value !== "") {
          reset();
          setValue("email", value);
          setReadOnly(true);
        } else {
          reset();
          setValue("email", "");
          setReadOnly(false);
        }
      });
    };
    if (openDialog === true) {
      checkMail();
    }
  }, [checkExistingUser, editUser?.id, openDialog, reset, setValue]);

  const handleClose = () => {
    setOpenDialog(false);
    setReadOnly(false);
    setValue("email", "");
    reset();
  };

  const onSubmit = (data) => {
    if (data) {
      inviteSystemAccess(editUser?.id, data.email);
      setReadOnly(false);
      setOpenDialog(false);
      reset();
      setValue("email", "");
    } else {
      handleClose();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog}
        title="Invite System Access"
      >
        <Mui.Grid container>
          <Mui.Grid item xs={11.5}>
            <Mui.TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              defaultValue=""
              style={{
                margin: 10,
              }}
              onChange={(event) => setValue("email", event.target.value)}
              {...register("email", {
                required: true,
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "A valid email is required",
                },
                validate:
                  readOnly !== true
                    ? {
                        emailAvailable: async (fieldValue) => {
                          if (fieldValue !== "") {
                            const response = await fetch(
                              `${domain}email_verfication/?email=${fieldValue}`
                            );
                            const resp = await response.json();
                            if (resp.exists) {
                              return "Email already exists";
                            }
                          }
                        },
                      }
                    : null,
              })}
              inputProps={{ readOnly: readOnly === true ? true : false }}
              error={Boolean(errors.email)}
              helperText={errors?.email && errors?.email?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={8}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={handleClose}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={handleSubmit(onSubmit)}
              actionName="Invite"
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </form>
  );
}
