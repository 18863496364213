import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomButton";
import { useFetch } from "services/hooks/useFetch";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import { overTimeKeyPair } from "pages/PayRoll/PayRollHeaders";
// import { CustomTextBox } from "components/CustomTextBox";
import {
  // Cancel,
  // Done,
  // Edit,
  KeyboardArrowDown,
  ChevronRight,
} from "@material-ui/icons";
// import { showToast } from "components/Status";
// import sessionHandling from "services/utils/notificationUtils";
// import { rgxWithDot } from "services/constants/patterns";
// import { onlyNumberRegex } from "services/constants/patterns";  token,

const keys = [
  "user_name",
  "employee_id",
  "paid_days",
  "gross_pay",
  "total_deduction",
  "net_pay",
  "per_day_salary",
];

export const OverTime = ({
  viewPayrunDetails,
  downloadReportDetails,
  summaryName,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listViewPage, setListViewPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  // const [editRowId, setEditRowId] = useState(null);
  // const [newTotalLeave, setNewTotalLeave] = useState("");
  const [overTimeDetailsState, setOverTimeDetailsState] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [showShiftCategaryDetails, setShowShiftCategaryDetails] = useState(0);
  // const [updateData, setUpdateData] = useState(true);

  const { result: payrunData, loading } = useFetch(
    `${domain}overtime-report/?pay_schedule_id=${viewPayrunDetails.id}`
  );
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  // const handleSave = async (userId, newTotalLeave) => {
  //   const totalLeave = parseFloat(newTotalLeave);
  //   try {
  //     const response = await fetch(`${domain}update-leave-count/`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `token ${token}`,
  //       },
  //       body: JSON.stringify({
  //         // leave_type_id: shiftId,
  //         user_id: userId,
  //         total_leave: totalLeave,
  //       }),
  //     });
  //     const res = await response.json();
  //     if (response.ok) {
  //       showToast("success", res.message);
  //       // setUpdateData(true);
  //     } else if (response.status === 409) {
  //       sessionHandling();
  //     } else {
  //       throw new Error(res.error);
  //     }
  //   } catch (error) {
  //     showToast("error", error.message);
  //   }
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  // const handleCancel = () => {
  //   setEditRowId(null);
  // };

  // const updateRatePerHour = (event) => {
  //   if (onlyNumberRegex.test(event.target.value)) {
  //     setNewTotalLeave(event.target.value);
  //   }
  // };

  console.log(payrunData);

  useEffect(() => {
    if (
      updateData &&
      payrunData?.payroll_run_details?.length !== 0 &&
      payrunData
    ) {
      setOverTimeDetailsState(payrunData?.payroll_run_details);
      setUpdateData(false);
    }
  }, [payrunData, updateData]);

  useEffect(() => {
    if (payrunData?.length !== 0 || payrunData?.length === 0) {
      const filteredResults = payrunData?.payroll_run_details?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setOverTimeDetailsState(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payrunData]);

  useEffect(() => {
    const downloadReports = () => {};
    if (downloadReportDetails && summaryName === "Cost Structure") {
      downloadReports();
    }
    // if()
  }, [downloadReportDetails, summaryName]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      {overTimeDetailsState?.length === 0 ? (
        <Mui.Alert severity="info" sx={{ width: "100%", marginTop: 2 }}>
          No data available
        </Mui.Alert>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {overTimeKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {overTimeDetailsState
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((payschedule) => (
                  <>
                    <Mui.TableRow
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        // setViewPayRunDetails(payschedule);
                        // setViewPayRunScreen(true);
                      }}
                    >
                      <Mui.TableCell>
                        {payschedule?.id === showShiftCategaryDetails ? (
                          <Mui.IconButton
                            onClick={() => setShowShiftCategaryDetails(0)}
                          >
                            <KeyboardArrowDown />
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() =>
                              setShowShiftCategaryDetails(payschedule?.id)
                            }
                            style={{
                              padding: "0px",
                            }}
                          >
                            <ChevronRight />
                          </Mui.IconButton>
                        )}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        <Mui.Stack direction="Row">
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt="Remy Sharp"
                            src={payschedule.image_url}
                          />
                          <Mui.Typography
                            sx={{
                              paddingLeft: 1,
                            }}
                          >
                            {payschedule.user_name}
                          </Mui.Typography>
                        </Mui.Stack>
                      </Mui.TableCell>
                      <Mui.TableCell>{payschedule?.employee_id}</Mui.TableCell>
                      <Mui.TableCell>{payschedule?.paid_days}</Mui.TableCell>
                      <Mui.TableCell>{payschedule?.gross_pay}</Mui.TableCell>
                      <Mui.TableCell>
                        {payschedule?.total_deduction}
                      </Mui.TableCell>
                    </Mui.TableRow>
                    {showShiftCategaryDetails === payschedule?.id && (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={7}>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%px",
                            }}
                            className="w-96 border rounded"
                          >
                            {payschedule?.shifts?.length !== 0 ? (
                              <Mui.Table className="border">
                                <Mui.TableHead
                                  sx={{
                                    backgroundColor: themes.primary,
                                  }}
                                >
                                  <Mui.TableRow>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Date
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Check-In Time
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Check-Out time
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Shift Hours
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Over Time Hours
                                    </Mui.TableCell>

                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Approver Name
                                    </Mui.TableCell>
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Approved at
                                    </Mui.TableCell>
                                  </Mui.TableRow>
                                </Mui.TableHead>
                                <Mui.TableBody>
                                  {/* {payschedule?.shifts?.map((shift, index) => ( */}
                                  <Mui.TableRow>
                                    {/* key={index} */}
                                    <Mui.TableCell>21/3/2024</Mui.TableCell>
                                    <Mui.TableCell>
                                      9:50 PM
                                      {/* {shift.name} */}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      06:30PM
                                      {/* {shift.start_time} */}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      08:00
                                      {/* {shift.min_start_time} */}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      6:00
                                      {/* {shift.min_start_time} */}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      Ashok
                                      {/* {shift.min_start_time} */}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      20/05/2024
                                      {/* {shift.min_start_time} */}
                                    </Mui.TableCell>
                                  </Mui.TableRow>
                                  {/* ))} */}
                                </Mui.TableBody>
                              </Mui.Table>
                            ) : (
                              <Mui.Stack
                                sx={{ padding: 0.5 }}
                                spacing={2}
                                fullWidth
                              >
                                <Mui.Alert severity="info">
                                  There is a no available shifts
                                </Mui.Alert>
                              </Mui.Stack>
                            )}
                          </Mui.TableContainer>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {overTimeDetailsState?.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={overTimeDetailsState?.length}
            rowsPerPage={rowsPerPage}
            page={listViewPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>

      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
