import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { CustomTextBox } from "components/CustomTextBox";
import { Delete, AddCircleOutline } from "@mui/icons-material";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { PaymentMode } from "pages/PayRoll/PayRollHeaders";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "components/Status";
let rowId = 0;
export const AddNewSalaryGroup = ({
  companyId,
  setAddNewSalaryGroup,
  setCompanyFilter,
  companyFilter,
  reCallApi,
  employeeDetails,
  reCallEmployeeApi,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [salaryGroupName, setSalaryGroupName] = useState("");
  const [bankName, setBankName] = useState("");
  const [paymentModeDetails, setPaymentModeDetails] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAdd, setIsAdd] = useState(false);
  const { result: bankDetailsData, setLoad: reCallSourceBankApi } = useFetch(
    companyId !== 0 ? `${domain}source-bank/?company=${companyId}` : null
  );

  useEffect(() => {
    if (companyFilter) {
      reCallSourceBankApi(true);
      setCompanyFilter(false);
    }
  }, [companyFilter, reCallSourceBankApi, setCompanyFilter]);

  const updateSalaryGroupName = (event) => {
    if (event.target.value.length < 32) {
      setValue("salry_group_name", event.target.value);
      setSalaryGroupName(event.target.value);
    }
  };

  const handleBankName = (event, data) => {
    if (data) {
      setValue("banck_name", data?.label);
      setBankName(data?.label);
      setValue("bank_id", data.id);
    }
  };

  const handlePaymentMode = (event, data) => {
    if (data) {
      setValue("payment_mode", data?.label);
      setPaymentModeDetails(data?.label);
    }
  };

  const addNewUser = isAdd
    ? () => null
    : () => {
        if (!isAdd) {
          let shouldSetIsAdd = false;
          for (const item of userDetails) {
            if (item?.username === "") {
              shouldSetIsAdd = true;
              break;
            }
          }
          if (shouldSetIsAdd) {
            setIsAdd(true);
            return;
          }
          const newRow = {
            rowId: rowId + 1,
            username: "",
            employee_id: "",
          };
          rowId = rowId + 1;
          setUserDetails([...userDetails, newRow]);
        }
      };

  const handleCompanyName = (details) => (event, data) => {
    if (data) {
      let userDetailsWithNewMember = userDetails?.map((element) => {
        if (element?.id === details?.id) {
          return {
            ...element,
            employee_id: data?.employee_id,
            username: data?.username,
            id: data?.id,
          };
        }
        return element;
      });
      setUserDetails(userDetailsWithNewMember);
      setIsAdd(false);
    }
  };

  const createSalaryGroup = async (data) => {
    let userArray = [];
    userDetails?.forEach((element) => {
      if (element.username !== "") {
        userArray.push(element?.id);
      }
    });
    try {
      const response = await fetch(`${domain}salary-group/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          name: data.salry_group_name,
          company: companyId,
          users: userArray,
          payment_cycle: "Weekly", //optional for now
          mode_of_payment: data.payment_mode,
          source_bank_detail: data.bank_id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setAddNewSalaryGroup(false);
        reCallApi(true);
        reset();
        showToast("success", "Salary group added successfully");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const removeUser = async (members) => {
    let removedData = userDetails?.filter((data) => {
      return data?.id !== members?.id;
    });
    setUserDetails(removedData);
    setIsAdd(false);
  };

  useEffect(() => {
    const filterEmployeeData = () => {
      const employeeDetailsData = employeeDetails?.map((obj) => ({
        ...obj,
        label: obj.username,
      }));
      let empDetails = [];
      employeeDetailsData.forEach((element) => {
        let findEmp = userDetails?.filter((data) => {
          return data.username === element.label;
        });
        if (findEmp.length === 0) {
          empDetails = [...empDetails, element];
        }
      });
      if (empDetails?.length === 0) {
        setIsAdd(true);
      } else {
        setIsAdd(false);
        setFilterEmployee(empDetails);
      }
    };

    if (userDetails?.length !== 0) {
      filterEmployeeData();
    }
  }, [employeeDetails, filterEmployee, userDetails]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <form onSubmit={handleSubmit(createSalaryGroup)}>
      <React.Fragment>
        <Mui.Grid container sx={{ padding: 5 }}>
          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Salary Group Name
            </Mui.Typography>
            <CustomTextBox
              selectedValue={salaryGroupName}
              rules={{
                ...register("salry_group_name", {
                  required: "Salary group name is required",
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateSalaryGroupName}
              placeHolder="Enter Salary Group Name"
              errorMessage={
                errors?.salry_group_name?.message
                  ? errors?.salry_group_name?.message
                  : ""
              }
              textBoxError={errors.salry_group_name ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid xs={0.4}></Mui.Grid>
          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Payment Bank
            </Mui.Typography>
            <CustomAutoComplete
              dataList={bankDetailsData.map((obj) => ({
                ...obj,
                label: obj.bank_name,
              }))}
              selectedValue={bankName}
              rules={{
                ...register("banck_name", {
                  required: "Source bank is required",
                }),
              }}
              updateDataFunction={handleBankName}
              placeHolder="Select payment bank"
              errorMessage={
                errors?.banck_name?.message ? errors?.banck_name?.message : ""
              }
              textBoxError={errors?.banck_name ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={5.8}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 0.5,
                }}
              >
                Payment Mode
              </Mui.Typography>
              <CustomAutoComplete
                dataList={PaymentMode}
                selectedValue={paymentModeDetails}
                rules={{
                  ...register("payment_mode", {
                    required: "Payment mode is required",
                  }),
                }}
                updateDataFunction={handlePaymentMode}
                placeHolder="Select payment mode"
                errorMessage={
                  errors?.payment_mode?.message
                    ? errors?.payment_mode?.message
                    : ""
                }
                textBoxError={errors?.payment_mode ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Typography sx={{ paddingTop: 2 }}>Users List</Mui.Typography>
          <Mui.TableContainer sx={{ mt: 2, width: "100%", maxHeight: 500 }}>
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow maxWidth="xl" align="left">
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Employee name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Employee id
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {userDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((members) => (
                    <React.Fragment>
                      <Mui.TableRow maxWidth="xl" align="left">
                        <Mui.TableCell>
                          <CustomAutoComplete
                            dataList={filterEmployee}
                            selectedValue={members?.username}
                            updateDataFunction={handleCompanyName(members)}
                            placeHolder="Please select the user"
                            fullWith
                          />
                        </Mui.TableCell>
                        <Mui.TableCell>{members?.employee_id}</Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.IconButton onClick={() => removeUser(members)}>
                            <Delete />
                          </Mui.IconButton>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    </React.Fragment>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignSelf: "flex-end",
            }}
          >
            {userDetails?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[10, 20, 50, 100]}
                count={userDetails?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
          <Mui.IconButton onClick={() => addNewUser()}>
            <AddCircleOutline />
          </Mui.IconButton>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 3,
            paddingBottom: 2,
          }}
        >
          <CustomButton
            actionFuntion={() => setAddNewSalaryGroup(false)}
            actionName="Cancel"
            typeName="button"
          />
          &nbsp; &nbsp;
          <CustomButton actionName="Add" typeName="submit" />
        </Mui.Grid>
      </React.Fragment>
    </form>
  );
};
