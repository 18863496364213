export const earningHeaders = [
  {
    header: "Name",
    key: "name",
  },
  {
    header: "Action",
    key: "action",
  },
];

export const deductionHeaders = [
  { header: "Name", key: "name" },
  { header: "Deduction Type", key: "deduction_type_display" },
  { header: "Action", key: "action" },
];

export const ComponentTypeDetails = [
  {
    label: "Earnings",
  },
  {
    label: "Deduction",
  },
  {
    label: "Expense",
  },
];

export const overTime = [
  {
    label: "Hour",
  },
  {
    label: "Day",
  },
];

// export const overTimeKeyPair = [
//   {
//     name: "Employee Name",
//   },
//   {
//     name: "Overtime Worked Date",
//   },
//   {
//     name: "In-Out Time",
//   },
//   {
//     name: "Regular Hours",
//   },
//   {
//     name: "Rate Per Hours",
//   },
//   {
//     name: "Total Rate",
//   },
//   {
//     name: "Approve",
//   },
//   {
//     name: "Action",
//   },
// ];

export const PayRollEmployeeHeader = [
  {
    header: "Employee Name",
    key: "employee name",
  },
  {
    header: "Employee Id",
    key: "employeeid",
  },
  {
    header: "Cost to company (per year)",
    key: "",
  },
  {
    header: "Salary Group",
    key: "salary_group",
  },
  {
    header: "Status",
    key: "status",
  },
  {
    header: "Action",
    key: "",
  },
];

export const PaymentMode = [
  {
    label: "NEFT",
  },
  {
    label: "Cash",
  },
  {
    label: "Cheque",
  },
];

export const summaryDetails = [
  {
    label: "Cost Structure",
  },
  {
    label: "Attendance Timeline",
  },
  // {
  //   label: "Over Time",
  // },
  // {
  //   label: "Salary Revision",
  // },
];

export const salaryRevisionKeyPair = [
  {
    name: "Employee Name",
  },
  {
    name: "Employee Id",
  },
  {
    name: "Old CTC",
  },
  {
    name: "Revised CTC",
  },
  {
    name: "Revision Month",
  },
];

export const overTimeKeyPair = [
  {
    name: "Action",
  },
  {
    name: "Employee Name",
  },
  {
    name: "Employee Id",
  },
  {
    name: "Overtime Hours",
  },
  {
    name: "Per Hour Rate",
  },
  {
    name: "Total Amount",
  },
];

export const salaryStatementReportPart1 = [
  {
    name: "Employee Name",
    key: "user_name",
  },
  {
    name: "Employee ID",
    key: "employee_id",
  },
  {
    name: "Monthly CTC",
    key: "monthly_ctc",
  },
  {
    name: "Mode of payment",
    key: "mode_of_payment",
  },
  {
    name: "Per day salary",
    key: "per_day_salary",
  },
  {
    name: "Payable Days",
    key: "paid_days",
  },
  {
    name: "LOP",
    key: "lop",
  },
  {
    name: "LOP Amount",
    key: "lop_amount",
  },
  {
    name: "Gross",
    key: "gross_pay",
  },
];

export const salaryStatementReportPart2 = [
  {
    name: "Total Deductions",
    key: "total_deductions",
  },
  {
    name: "Net Pay",
    key: "net_pay",
  },
  {
    name: "Department",
    key: "department",
  },
  {
    name: "Bank",
    key: "bank",
  },
];

export const ESIReportKeys = [
  {
    name: "Employee Name",
    key: "user_name",
  },
  {
    name: "Employee ID",
    key: "employee_id",
  },
  {
    name: "ESI No",
    key: "esi_no",
  },
  {
    name: "Gross salaray",
    key: "gross_pay",
  },
  {
    name: "Per day salary",
    key: "per_day_salary",
  },
  {
    name: "Number of days in ESI",
    key: "paid_days",
  },
  {
    name: "Employee contribution",
    key: "employee_esi",
  },
  {
    name: "Company contribution",
    key: "company_esi",
  },
  {
    name: "Total esi amount",
    key: "total_esi",
  },
];

export const EPFReportKeys = [
  {
    name: "Employee Name",
    key: "user_name",
  },
  {
    name: "Employee ID",
    key: "employee_id",
  },
  {
    name: "PF No",
    key: "pf_no",
  },
  {
    name: "Employee Contribution",
    key: "employee_pf",
  },
  {
    name: "Company Contribution",
    key: "employer_pf",
  },
  {
    name: "Total Contribution",
    key: "total_contribution",
  },
];
