import React, { useState, useContext } from "react";
import { MyProjectContext } from "./MyProjectContext";
import { ProjectTopBar } from "./ProjectTopBar";
import { CardView } from "./CardView";
import { ListView } from "./ListView";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setEditProjectScreenData } from "services/Redux/projectManagement";
import { showToast } from "components/Status";
import { projectSuccess } from "services/constants/SuccessMessages";
import { setEditProject } from "services/Redux/leaveManagement";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { setGlobalSearchValue } from "services/Redux/userToken";
import * as Mui from "@mui/material";
import { themes } from "services/constants";

const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: themes.blackColor }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ProjectHead = () => {
  const domainUrl = useSelector((state) => state.tokenReducer.domain);
  const token = useSelector((state) => state.tokenReducer.token);
  const { setGetClientDetails } = useContext(MyProjectContext);
  const [dropDown, setDropDown] = useState(["Edit"]);
  const [open, setOpen] = useState(false);
  const [projctId, setProjectId] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const dataViewFormat = useSelector(
    (state) => state.userReducer.dataViewFormat
  );

  const handleMoveToEditScreen = async (option, editProject) => {
    switch (option) {
      case "Edit":
        dispatch(setEditProjectScreenData(editProject));
        localStorage.setItem("editProject", true);
        dispatch(setGlobalSearchValue(""));
        history.push("/admin/edit/project");
        break;
      case "Dashboard":
        dispatch(setEditProject(editProject));
        dispatch(setEditProjectScreenData(editProject));
        localStorage.setItem("proDashboard", true);
        localStorage.setItem("projectData", JSON.stringify(editProject));
        dispatch(setGlobalSearchValue(""));
        history.push("/admin/project/dashboard");
        break;
      case "Clone":
        setProjectId(editProject?.id);
        setOpen(true);
        break;
      case "Delete":
        try {
          const response = await fetch(`${domainUrl}delete_project`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              project_id: editProject.id,
              isdeleted: true,
            }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error);
          } else {
            showToast("success", projectSuccess.editProject);
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/project-management");
          }
        } catch (error) {
          showToast("error", error.message);
        }
        break;
      case "Activate":
      case "Onhold":
      case "Complete":
      case "Inactive":
        try {
          const response = await fetch(`${domainUrl}update_project_status/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              project_id: editProject.id,
              project_status:
                option === "Complete"
                  ? "Completed"
                  : option === "Activate"
                  ? "Active"
                  : option,
            }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error);
          } else {
            showToast("success", projectSuccess.editProject);
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/project-management");
          }
        } catch (error) {
          showToast("error", error.message);
        }
        break;
      default:
        break;
    }
  };

  const handleCloneProject = async () => {
    try {
      const response = await fetch(`${domainUrl}clone-project/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          project_id: projctId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      } else {
        showToast("success", projectSuccess.cloneProject);
        setOpen(false);
        setProjectId(null);
        setGetClientDetails(true);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateMenuDetails = (project) => {
    switch (project.status.status) {
      case "Active":
        setDropDown([
          "Edit",
          "Dashboard",
          "Clone",
          "Onhold",
          "Complete",
          "Inactive",
        ]);
        break;
      case "Inactive":
        setDropDown(["Edit", "Dashboard", "Activate", "Onhold", "Delete"]);
        break;
      case "Onhold":
        setDropDown([
          "Edit",
          "Dashboard",
          "Clone",
          "Activate",
          "Complete",
          "Inactive",
        ]);
        break;
      case "Completed":
        setDropDown(["Edit", "Dashboard", "Clone"]);
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <ProjectTopBar />
      {dataViewFormat === "CardView" ? (
        <CardView
          handleMoveToEditScreen={handleMoveToEditScreen}
          updateMenuDetails={updateMenuDetails}
          dropDown={dropDown}
        />
      ) : (
        <ListView
          handleMoveToEditScreen={handleMoveToEditScreen}
          updateMenuDetails={updateMenuDetails}
          dropDown={dropDown}
        />
      )}
      <div>
        <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
          <FuncDialogTitle
            id="customized-dialog-title"
            align="center"
            sx={{
              minWidth: 580,
              color: themes.headLine,
              fontWeight: 1000,
              fontSize: "23px",
              background: themes.primary,
            }}
            onClose={() => {
              setProjectId(null);
              setOpen(false);
            }}
            title={"Clone Project"}
          >
            {"Clone Project"}
          </FuncDialogTitle>
          <Mui.DialogContent dividers>
            <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
              <Mui.Typography sx={{ fontSize: 24 }}>
                Do you want to clone this project?
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid xs={5}></Mui.Grid>
              <Mui.Grid xs={3}>
                <Mui.Button
                  variant="contained"
                  size="large"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: themes.primaryIndicator,
                    "&:hover": {
                      border: "none",
                      backgroundColor: themes.primaryIndicator,
                    },
                    color: themes.whiteColor,
                    float: "right",
                    width: 150,
                  }}
                  onClick={() => {
                    setProjectId(null);
                    setOpen(false);
                  }}
                >
                  No
                </Mui.Button>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Button
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: themes.primaryIndicator,
                    "&:hover": {
                      border: "none",
                      backgroundColor: themes.primaryIndicator,
                    },
                    color: themes.whiteColor,
                    float: "right",
                    width: 150,
                  }}
                  onClick={handleCloneProject}
                >
                  Yes
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.DialogContent>
        </BootstrapDialog>
      </div>
    </React.Fragment>
  );
};
