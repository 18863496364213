import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { CustomButton } from "components/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import { setGlobalSearchValue } from "services/Redux/userToken";
import dayjs from "dayjs";
import {
  themes,
  checkInPolicyDetails,
  checkOutPolicyDetails,
} from "services/constants";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DialogBoxShift } from "./DialogBoxShift";
import moment from "moment";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { BackArrow } from "components/BackArrow";
import sessionHandling from "services/utils/notificationUtils";
import { useDispatch } from "react-redux";

export const AddShiftCategory = () => {
  const dispatch = useDispatch();
  const { token, domain } = useSelector((state) => state.tokenReducer);
  const history = useHistory();
  const [idNumber, setIdNumber] = useState(2);
  const [material, setMaterial] = useState([
    {
      id: 1,
      name: "",
      min_start_time: null,
      start_time: null,
      max_start_time: null,
      min_end_time: null,
      end_time: null,
      max_end_time: null,
      break_allowed: false,
      ot_allowed: false,
      break_time: null,
    },
  ]);

  const [openDialog, setOpenDialog] = useState(false);
  const [shiftCategoryName, setShiftCategoryName] = useState("");
  const [errorHandling, setErrorHandling] = useState("");
  const [handleTimeError, setHandleTimeError] = useState("");
  const [handleEndTimeError, setHandleEndTimeError] = useState("");
  const [checkInPolicy, setCheckInPolicy] = useState("");
  const [checkOutPolicy, setCheckOutPolicy] = useState("");
  const [checkInPolicyText, setCheckInPolicyText] = useState("");
  const [checkOutPolicyText, setCheckOutPolicyText] = useState("");
  const [removeDataId, setRemoveDataId] = useState(0);
  const [maxStartTime, setMaxStartTime] = useState(null);
  const [stateChange, setStateChange] = useState(false);
  const [additionalinfo, setAdditionalinfo] = useState(false);
  const [hidePolicy, setHidePolicy] = useState(false);
  const [sameShiftNameError, setSameShiftNameError] = useState("");
  const [shiftCategoryError, setShiftCatagoryError] = useState("");
  const [shiftError, setShiftError] = useState("");
  const [overlabRowId, setOverlabRowId] = useState(0);
  const [shiftErrorRowId, setShiftErrorRowId] = useState("");

  const [workingDays, setWorkingDays] = useState({
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
  });
  const handleCheckboxChange = (event) => {
    setWorkingDays({
      ...workingDays,
      [event.target.name]: event.target.checked,
    });
  };

  // const handleCheckboxChanged = (event, data) => {
  //   const isChecked = event.target.checked;
  //   updateFieldValue("break_allowed", isChecked, data.id);
  //   if (!isChecked) {
  //     updateFieldValue("break_time", null, data.id);
  //   }
  // };

  const handleStartTime = (newValue, id) => {
    if (newValue && newValue.format) {
      const time = newValue.format("HH:mm");
      const timeWithDate = newValue ? newValue.toDate() : new Date();

      if (time) {
        let minTime = moment.utc(timeWithDate);
        minTime.subtract(15, "minutes");

        let maxTime = moment.utc(timeWithDate);
        maxTime.add(15, "minutes");

        const startTimeUpdatedValue = material.map((element) => {
          if (id === element.id) {
            return {
              ...element,
              start_time: moment.utc(timeWithDate),
              min_start_time: dayjs()
                .set("hour", minTime._d.getHours())
                .set("minute", minTime._d.getMinutes())
                .set("second", minTime._d.getSeconds()),
              max_start_time: dayjs()
                .set("hour", maxTime._d.getHours())
                .set("minute", maxTime._d.getMinutes())
                .set("second", maxTime._d.getSeconds()),
            };
          }
          return element;
        });
        setMaterial(startTimeUpdatedValue);
        validateStartTime(startTimeUpdatedValue, id);
        setMaxStartTime(maxTime);
      }
      if (
        errorHandling === "Use different time " ||
        errorHandling === "Use different time"
      ) {
        validateFields();
      }
    }
  };

  const validateStartTime = (material, id) => {
    const element = material.find((item) => item.id === id);
    if (!element) return;

    const { start_time, min_start_time, max_start_time } = element;
    let errors = [];

    if (start_time && min_start_time && min_start_time.isAfter(start_time)) {
      setOverlabRowId(id);
      errors.push("Min start time must be before the start time.");
    }

    if (start_time && max_start_time && max_start_time.isBefore(start_time)) {
      setOverlabRowId(id);
      errors.push("Max start time must be after the start time.");
    }

    if (errors.length > 0) {
      setHandleTimeError(errors.join(" "));
    } else {
      setHandleTimeError("");
    }
  };

  const handleMinStartTimeChange = (newValue, id) => {
    const updatedMaterial = material.map((element) => {
      if (id === element.id) {
        return {
          ...element,
          min_start_time: newValue,
        };
      }
      return element;
    });
    setMaterial(updatedMaterial);
    validateStartTime(updatedMaterial, id);
  };

  const handleTimeChange = (newValue, id) => {
    const updatedMaterial = material.map((element) => {
      if (id === element.id) {
        return {
          ...element,
          break_time: newValue,
        };
      }
      return element;
    });
    setMaterial(updatedMaterial);
  };

  const handleMaxStartTimeChange = (newValue, id) => {
    const updatedMaterial = material.map((element) => {
      if (id === element.id) {
        return {
          ...element,
          max_start_time: newValue,
        };
      }
      return element;
    });
    setMaterial(updatedMaterial);
    validateStartTime(updatedMaterial, id);
  };

  const validateEndTime = (material, id) => {
    const element = material.find((item) => item.id === id);
    if (!element) return;

    const { end_time, min_end_time, max_end_time } = element;
    let errors = [];

    if (end_time && min_end_time && min_end_time.isAfter(end_time)) {
      setOverlabRowId(id);
      errors.push("Min end time must be before the end time.");
    }

    if (end_time && max_end_time && max_end_time.isBefore(end_time)) {
      setOverlabRowId(id);
      errors.push("Max end time must be after the end time.");
    }

    if (errors.length > 0) {
      setHandleEndTimeError(errors.join(" "));
    } else {
      setHandleEndTimeError("");
    }
  };

  const handleEndTime = (newValue, id) => {
    if (newValue && newValue.format) {
      const time = newValue.format("HH:mm");
      const timeWithDate = newValue ? newValue.toDate() : new Date();
      if (time) {
        let minTime = moment.utc(timeWithDate);
        minTime.subtract(15, "minutes");

        let maxTime = moment.utc(timeWithDate);
        maxTime.add(15, "minutes");

        const endTimeUpdatedValue = material.map((element) => {
          if (id === element.id) {
            return {
              ...element,
              end_time: moment.utc(timeWithDate),
              min_end_time: dayjs()
                .set("hour", minTime._d.getHours())
                .set("minute", minTime._d.getMinutes())
                .set("second", minTime._d.getSeconds()),
              max_end_time: dayjs()
                .set("hour", maxTime._d.getHours())
                .set("minute", maxTime._d.getMinutes())
                .set("second", maxTime._d.getSeconds()),
            };
          }
          return element;
        });
        setMaterial(endTimeUpdatedValue);
        validateEndTime(endTimeUpdatedValue, id);
        if (
          errorHandling === "Use different time " ||
          errorHandling === "Use different time"
        ) {
          validateFields();
          setStateChange(!stateChange);
        }
      }
    }
  };

  const handleMinEndTimeChange = (newValue, id) => {
    const updatedMaterial = material.map((element) => {
      if (id === element.id) {
        return {
          ...element,
          min_end_time: newValue,
        };
      }
      return element;
    });
    setMaterial(updatedMaterial);
    validateEndTime(updatedMaterial, id);
  };

  const handleMaxEndTimeChange = (newValue, id) => {
    const updatedMaterial = material.map((element) => {
      if (id === element.id) {
        return {
          ...element,
          max_end_time: newValue,
        };
      }
      return element;
    });
    setMaterial(updatedMaterial);
    validateEndTime(updatedMaterial, id);
  };

  const addNewRow = () => {
    if (validateFields()) {
      let newRow = {
        id: idNumber,
        name: "",
        min_start_time: null,
        start_time: null,
        max_start_time: null,
        min_end_time: null,
        end_time: null,
        max_end_time: null,
        break_allowed: false,
        ot_allowed: false,
        break_time: null,
      };
      setMaterial([...material, newRow]);
      setIdNumber(idNumber + 1);
    }
  };

  const deleteRow = (id) => {
    const data = material.filter((shift) => {
      return shift.id !== id;
    });
    setMaterial(data);
    setOpenDialog(false);
    const name = findDuplicate(data);
    setSameShiftNameError(name ? name[0] : "");
    if (
      (handleTimeError === "Min start time must be before the start time." &&
        id === overlabRowId) ||
      (handleTimeError === "Max start time must be after the start time." &&
        id === overlabRowId) ||
      (handleEndTimeError === "Min end time must be before the end time." &&
        id === overlabRowId) ||
      (handleEndTimeError === "Max end time must be after the end time." &&
        id === overlabRowId)
    ) {
      setOverlabRowId(0);
      setHandleTimeError("");
      setHandleEndTimeError("");
    }
    validateFields();
  };

  const findDuplicate = (data) => {
    const names = data.map((item) => item.name);
    const uniqueNames = new Set(names);
    if (names.length !== uniqueNames.size) {
      const duplicateNames = names.filter(
        (name, index) => names.indexOf(name) !== index
      );
      return duplicateNames;
    }
    return null;
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const handleKeyDown = (e) => {
    const allowedKeys = /^[A-Za-z\s0-9]/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };

  const updateFieldValue = (field, value, rowid) => {
    const updatedValue = material.map((element) => {
      if (rowid === element.id) {
        if (field === "break_allowed" && !value) {
          return { ...element, [field]: value, break_time: null };
        } else {
          return { ...element, [field]: value };
        }
      }
      return element;
    });
    setMaterial(updatedValue);
  };

  const handlePreventCopyPaste = (event) => {
    event.preventDefault();
  };

  const validateFields = () => {
    if (shiftCategoryError !== "Shift category name already exists") {
      let emptyValue = [];
      if (
        (shiftCategoryName !== "" && shiftCategoryName.length >= 3) ||
        errorHandling !== ""
      ) {
        emptyValue = material.filter((data) => {
          return (
            data.name === null ||
            data.name === "" ||
            data.min_start_time === null ||
            data.min_start_time === "Invalid date" ||
            data.min_start_time === "NaN:NaN" ||
            data.max_start_time === null ||
            data.max_start_time === "Invalid date" ||
            data.max_start_time === "NaN:NaN" ||
            data.min_end_time === null ||
            data.min_end_time === "Invalid date" ||
            data.min_end_time === "NaN:NaN" ||
            data.end_time === null ||
            data.end_time === "Invalid date" ||
            data.end_time === "NaN:NaN" ||
            data.max_end_time === null ||
            data.max_end_time === "Invalid date" ||
            data.max_end_time === "NaN:NaN" ||
            data.check_in_policy === "" ||
            data.check_out_policy === "" ||
            (data.break_allowed && data.break_time === null) ||
            (data.break_allowed && data.break_time === "") ||
            (data.break_allowed &&
              data.break_time?.toString() === "Invalid Date")
          );
        });
        if (emptyValue?.length > 0) {
          // emptyValue[0]
          setShiftError(
            emptyValue[0].name === "" ? "Please enter shift name" : ""
          );
          setShiftErrorRowId(emptyValue[0].name === "" ? emptyValue[0].id : 0);
          setErrorHandling("Please enter time");
          return false;
        } else {
          setErrorHandling("");
          return true;
        }
      } else {
        setShiftCatagoryError(
          shiftCategoryName === ""
            ? "Please enter shiftcategory name"
            : "shift category minimum 3 characters"
        );
        return false;
      }
    } else {
      return false;
    }
  };

  const checkTime = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };

  const createShiftCategory = async () => {
    if (validateFields()) {
      const updatedValues = material.map((element) => {
        return {
          ...element,
          min_start_time:
            checkTime(new Date(element.min_start_time).getHours()) +
            ":" +
            checkTime(new Date(element.min_start_time).getMinutes()),
          start_time:
            checkTime(new Date(element.start_time).getHours()) +
            ":" +
            checkTime(new Date(element.start_time).getMinutes()),
          max_start_time:
            checkTime(new Date(element.max_start_time).getHours()) +
            ":" +
            checkTime(new Date(element.max_start_time).getMinutes()),
          min_end_time:
            checkTime(new Date(element.min_end_time).getHours()) +
            ":" +
            checkTime(new Date(element.min_end_time).getMinutes()),
          end_time:
            checkTime(new Date(element.end_time).getHours()) +
            ":" +
            checkTime(new Date(element.end_time).getMinutes()),
          max_end_time:
            checkTime(new Date(element.max_end_time).getHours()) +
            ":" +
            checkTime(new Date(element.max_end_time).getMinutes()),
          name: element.name,
          break_time: element.break_time
            ? element.break_time["$H"] +
              ":" +
              element.break_time["$m"] +
              ":" +
              element.break_time["$s"]
            : null,
          // break_time:
          //   checkTime(new Date(element.break_time).getHours()) +
          //   ":" +
          //   checkTime(new Date(element.break_time).getMinutes()),
        };
      });

      try {
        const response = await fetch(`${domain}create_shift/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            shift_category_name: shiftCategoryName,
            department: null,
            check_in_policy: checkInPolicyText,
            check_out_policy: checkOutPolicyText,
            shift_timings: updatedValues,
            working_days: workingDays,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", "Shift category created successfully");
          dispatch(setGlobalSearchValue(""));
          history.push("/admin/shiftcategory");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const hasDuplicateNames = () => {
    const names = material.map((item) => item.name);
    const uniqueNames = new Set(names);
    if (names.length !== uniqueNames.size) {
      const duplicateNames = names.filter(
        (name, index) => names.indexOf(name) !== index
      );
      return duplicateNames;
    }
    return null;
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Grid item xs={12}>
          <Mui.Stack direction="Row">
            <BackArrow
              actionFunction={() => {
                dispatch(setGlobalSearchValue(""));
                history.push("/admin/shiftcategory");
              }}
            />
            &nbsp;
            <Mui.Typography
              sx={{ paddingTop: 1, fontSize: 22, fontWeight: "bold" }}
            >
              Add shift category
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Container fixed>
        <Mui.Grid container spacing={3}>
          <Mui.Grid xs={0.5}></Mui.Grid>
          <Mui.Grid item xs={2.5}>
            <Mui.Typography sx={{ paddingTop: 2 }}>
              Shift Category Name &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;:
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={6.5}>
            <Mui.TextField
              sx={{ width: "518px" }}
              placeholder="Please enter shift Category name"
              onKeyPress={handleKeyDown}
              onPaste={handlePreventCopyPaste}
              onChange={(event) => {
                setShiftCategoryName(event.target.value);
                setShiftCatagoryError("");
              }}
              error={
                shiftCategoryError === "Please enter shiftcategory name" ||
                shiftCategoryError === "shift category minimum 3 characters" ||
                shiftCategoryError === "Shift category name already exists"
                  ? true
                  : false
              }
              helperText={
                shiftCategoryError === "Please enter shiftcategory name" ||
                shiftCategoryError === "Shift category name already exists" ||
                shiftCategoryError === "shift category minimum 3 characters" ? (
                  <Mui.Typography
                    variant="body2"
                    color="error"
                    sx={{ fontSize: 13, paddingLeft: 0.5 }}
                  >
                    {shiftCategoryError}
                  </Mui.Typography>
                ) : null
              }
              InputProps={{
                inputProps: { maxLength: 32 },
              }}
              onBlur={async () => {
                if (shiftCategoryName !== "")
                  try {
                    const response = await fetch(
                      `${domain}check_shift_category_name/?shift_category_name=${shiftCategoryName}`,
                      {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `token ${token}`,
                        },
                      }
                    );
                    const data = await response.json();
                    if (data.exists) {
                      setShiftCatagoryError(
                        "Shift category name already exists"
                      );
                    }
                  } catch (error) {
                    showToast("error", error.message);
                  }
              }}
            />
          </Mui.Grid>
          <Mui.Grid xs={2.5}></Mui.Grid>
          <Mui.Grid xs={0.5}></Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 5 }}>
            <Mui.Grid xs={0.75}></Mui.Grid>
            <Mui.Grid xs={2.5} sx={{ paddingTop: 3 }}>
              <Mui.Typography>
                Working Days &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container xs={6.5}>
              <Card
                item
                fullWidth
                elevation={5}
                sx={{
                  height: 75,
                  borderRadius: 2,
                }}
              >
                <Mui.Grid>
                  <Mui.FormControl component="fieldset">
                    <Mui.FormGroup aria-label="position" row>
                      <Mui.Grid Item>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={workingDays.sunday}
                              onChange={handleCheckboxChange}
                              name="sunday"
                              color="error"
                            />
                          }
                          label="Sun"
                          labelPlacement="bottom"
                        />
                      </Mui.Grid>
                      <Mui.Grid Item>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={workingDays.monday}
                              onChange={handleCheckboxChange}
                              name="monday"
                              color="error"
                            />
                          }
                          label="Mon"
                          labelPlacement="bottom"
                        />
                      </Mui.Grid>
                      <Mui.Grid Item>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={workingDays.tuesday}
                              onChange={handleCheckboxChange}
                              name="tuesday"
                              color="error"
                            />
                          }
                          label="Tue"
                          labelPlacement="bottom"
                        />
                      </Mui.Grid>
                      <Mui.Grid Item>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={workingDays.wednesday}
                              onChange={handleCheckboxChange}
                              name="wednesday"
                              color="error"
                            />
                          }
                          label="Wed"
                          labelPlacement="bottom"
                        />
                      </Mui.Grid>
                      <Mui.Grid Item>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={workingDays.thursday}
                              onChange={handleCheckboxChange}
                              name="thursday"
                              color="error"
                            />
                          }
                          label="Thu"
                          labelPlacement="bottom"
                        />
                      </Mui.Grid>
                      <Mui.Grid Item>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={workingDays.friday}
                              onChange={handleCheckboxChange}
                              name="friday"
                              color="error"
                            />
                          }
                          label="Fri"
                          labelPlacement="bottom"
                        />
                      </Mui.Grid>
                      <Mui.Grid Item>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={workingDays.saturday}
                              onChange={handleCheckboxChange}
                              name="saturday"
                              color="error"
                            />
                          }
                          label="Sat"
                          labelPlacement="bottom"
                        />
                      </Mui.Grid>
                    </Mui.FormGroup>
                  </Mui.FormControl>
                </Mui.Grid>
              </Card>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>

        {material.map((data) => (
          <>
            <Mui.Grid sx={{ paddingTop: 5 }}>
              <Card
                sx={{ padding: 5, borderRadius: 4, height: 415 }}
                elevation={8}
              >
                <Mui.Grid container spacing={6}>
                  <Mui.Grid item xs={6} sx={{ marginTop: 0.9 }}>
                    <Mui.Grid>
                      <label>Shift Name </label>
                    </Mui.Grid>

                    <Mui.TextField
                      fullWidth
                      placeholder="Shift Name"
                      onKeyPress={handleKeyDown}
                      onPaste={handlePreventCopyPaste}
                      sx={{ paddingTop: 0.2 }}
                      onChange={(event) => {
                        updateFieldValue("name", event.target.value, data.id);
                        if (data.id === shiftErrorRowId) {
                          setShiftError("");
                          setShiftErrorRowId(0);
                        }
                      }}
                      error={shiftErrorRowId === data.id ? true : false}
                      helperText={shiftErrorRowId === data.id ? shiftError : ""}
                      InputProps={{
                        inputProps: { maxLength: 32 },
                      }}
                      onBlur={async () => {
                        const name = hasDuplicateNames();
                        setSameShiftNameError(name ? name[0] : "");
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid
                    container
                    xs={6}
                    sx={{ paddingTop: 7, paddingLeft: 8 }}
                  >
                    <Mui.Grid item xs={6}>
                      <Mui.Typography sx={{ fontSize: 17.2 }}>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={data.break_allowed}
                              // onChange={handleCheckboxChanged}
                              onChange={(event) =>
                                updateFieldValue(
                                  "break_allowed",
                                  event.target.checked,
                                  data.id
                                )
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              color="error"
                            />
                          }
                        />
                        <label>Is break applicable</label>
                      </Mui.Typography>
                      <Mui.Typography sx={{ fontSize: 17.3 }}>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Checkbox
                              checked={data.ot_allowed}
                              onChange={(event) =>
                                updateFieldValue(
                                  "ot_allowed",
                                  event.target.checked,
                                  data.id
                                )
                              }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                              }}
                              color="error"
                            />
                          }
                        />
                        <label>Is overtime applicable</label>
                      </Mui.Typography>
                    </Mui.Grid>
                    {data.break_allowed ? (
                      <Mui.Grid item sx={{ paddingBottom: 5 }}>
                        {data.id !== 99999 ? (
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={[
                                  "TimeField",
                                  "TimeField",
                                  "TimeField",
                                ]}
                              >
                                <TimeField
                                  sx={{
                                    height: "45px",
                                    width: "150px",
                                    "& input": {
                                      padding: "10px",
                                    },
                                  }}
                                  defaultValue={data.break_time}
                                  format="HH:mm:ss"
                                  onChange={(newValue) => {
                                    handleTimeChange(newValue, data.id);
                                  }}
                                  onBlur={() => {
                                    if (errorHandling === "Please enter time") {
                                      validateFields();
                                    }
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {(data.break_time === null &&
                              errorHandling === "Please enter time") ||
                            data?.break_time?.toString() === "Invalid Date" ? (
                              <h5 style={{ color: themes.helperTextColor }}>
                                Please enter break hours
                              </h5>
                            ) : null}
                          </>
                        ) : null}
                      </Mui.Grid>
                    ) : null}
                  </Mui.Grid>
                </Mui.Grid>

                <Mui.Grid container sx={{ paddingTop: 5 }}>
                  <Mui.Grid>
                    <Mui.Typography>Start Time </Mui.Typography>
                    <Card
                      elevation={8}
                      sx={{
                        width: 513,
                        height: 120,
                        padding: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Mui.Grid container>
                        <Mui.Grid container spacing={3}>
                          <Mui.Grid item xs={4}>
                            {data.id !== 99999 ? (
                              <>
                                <Mui.Typography>Start Time</Mui.Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <div sx={{ width: 4 }}>
                                      <TimePicker
                                        ampm={false}
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        value={data.start_time || null}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            width: 15,
                                          },
                                        }}
                                        onChange={(newValue) => {
                                          handleStartTime(newValue, data.id);
                                          if (
                                            errorHandling ===
                                              "Use different time " ||
                                            errorHandling ===
                                              "Use different time"
                                          ) {
                                            validateFields();
                                          }
                                        }}
                                      />
                                    </div>
                                  </DemoContainer>
                                </LocalizationProvider>
                                {errorHandling === "Please enter time" &&
                                data.start_time === null ? (
                                  <h5 style={{ color: themes.helperTextColor }}>
                                    {errorHandling}
                                  </h5>
                                ) : null}
                                {errorHandling === "Use different time " ? (
                                  <h5 style={{ color: themes.helperTextColor }}>
                                    {errorHandling}
                                  </h5>
                                ) : null}
                              </>
                            ) : null}
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            {data.id !== 99999 ? (
                              <>
                                <Mui.Typography>Min Start Time</Mui.Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <div sx={{ width: 15 }}>
                                      <TimePicker
                                        ampm={false}
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        value={data.min_start_time || null}
                                        onChange={(newValue) => {
                                          handleMinStartTimeChange(
                                            newValue,
                                            data.id
                                          );
                                        }}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            width: 15,
                                          },
                                        }}
                                      />
                                    </div>
                                  </DemoContainer>
                                </LocalizationProvider>
                                {errorHandling === "Please enter time" &&
                                (data.min_start_time === null ||
                                  data.min_start_time === "Invalid date") ? (
                                  <h5
                                    style={{
                                      color: themes.helperTextColor,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errorHandling}
                                  </h5>
                                ) : null}
                                {handleTimeError ===
                                  "Min start time must be before the start time." &&
                                data.id === overlabRowId ? (
                                  <h5
                                    style={{
                                      color: themes.helperTextColor,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {handleTimeError}
                                  </h5>
                                ) : null}
                              </>
                            ) : null}
                          </Mui.Grid>

                          <Mui.Grid item xs={4}>
                            {data.id !== 99999 ? (
                              <>
                                <Mui.Typography>Max Start Time</Mui.Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <div sx={{ width: 15 }}>
                                      <TimePicker
                                        ampm={false}
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        value={data.max_start_time || null}
                                        onChange={(newValue) =>
                                          handleMaxStartTimeChange(
                                            newValue,
                                            data.id
                                          )
                                        }
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            width: 15,
                                          },
                                        }}
                                      />
                                    </div>
                                  </DemoContainer>
                                </LocalizationProvider>
                                {errorHandling === "Please enter time" &&
                                (data.max_start_time === null ||
                                  data.max_start_time === "Invalid date") ? (
                                  <h5
                                    style={{
                                      color: themes.helperTextColor,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errorHandling}
                                  </h5>
                                ) : null}
                                {handleTimeError ===
                                  "Max start time must be after the start time." &&
                                data.id === overlabRowId ? (
                                  <h5
                                    style={{
                                      color: themes.helperTextColor,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {handleTimeError}
                                  </h5>
                                ) : null}
                              </>
                            ) : null}
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Card>
                  </Mui.Grid>

                  <Mui.Grid xs={0.5}></Mui.Grid>
                  <Mui.Grid>
                    <Mui.Typography>End Time </Mui.Typography>
                    <Card
                      elevation={8}
                      sx={{
                        width: 513,
                        height: 120,
                        padding: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Mui.Grid container>
                        <Mui.Grid container spacing={3}>
                          <Mui.Grid item xs={4}>
                            {data.id !== 99999 ? (
                              <>
                                <Mui.Typography>End Time</Mui.Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <div sx={{ width: 4 }}>
                                      <TimePicker
                                        ampm={false}
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        value={data.end_time || null}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            width: 10,
                                          },
                                        }}
                                        onChange={(newValue) => {
                                          handleEndTime(newValue, data.id);
                                          if (
                                            errorHandling ===
                                              "Use different time " ||
                                            errorHandling ===
                                              "Use different time"
                                          ) {
                                            validateFields();
                                          }
                                        }}
                                      />
                                    </div>
                                  </DemoContainer>
                                </LocalizationProvider>
                                {errorHandling === "Please enter time" &&
                                (data.end_time === null ||
                                  data.end_time === "Invalid date") ? (
                                  <h5 style={{ color: themes.helperTextColor }}>
                                    {errorHandling}
                                  </h5>
                                ) : null}
                                {/* && modifiedRowId === data.id */}
                                {errorHandling === "Use different time" ? (
                                  <h5 style={{ color: themes.helperTextColor }}>
                                    {errorHandling}
                                  </h5>
                                ) : null}
                              </>
                            ) : null}
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            {data.id !== 99999 ? (
                              <>
                                <Mui.Typography>Min End Time</Mui.Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <div sx={{ width: 15 }}>
                                      <TimePicker
                                        ampm={false}
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        value={data.min_end_time || null}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            width: 10,
                                          },
                                        }}
                                        onChange={(newValue) => {
                                          handleMinEndTimeChange(
                                            newValue,
                                            data.id
                                          );
                                        }}
                                      />
                                    </div>
                                  </DemoContainer>
                                </LocalizationProvider>
                                {errorHandling === "Please enter time" &&
                                (data.min_end_time === null ||
                                  data.min_end_time === "Invalid date") ? (
                                  <h6
                                    style={{
                                      color: themes.helperTextColor,
                                      fontSize: 12,
                                    }}
                                  >
                                    {errorHandling}
                                  </h6>
                                ) : null}
                                {handleEndTimeError ===
                                  "Min end time must be before the end time." &&
                                data.id === overlabRowId ? (
                                  <h5
                                    style={{
                                      color: themes.helperTextColor,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {handleEndTimeError}
                                  </h5>
                                ) : null}
                              </>
                            ) : null}
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            {data.id !== 99999 ? (
                              <>
                                <Mui.Typography>Max End Time</Mui.Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <div sx={{ width: 15 }}>
                                      <TimePicker
                                        ampm={false}
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        value={data.max_end_time || null}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            width: 10,
                                          },
                                        }}
                                        onChange={(newValue) =>
                                          handleMaxEndTimeChange(
                                            newValue,
                                            data.id
                                          )
                                        }
                                      />
                                    </div>
                                  </DemoContainer>
                                </LocalizationProvider>
                                {errorHandling === "Please enter time" &&
                                (data.max_end_time === null ||
                                  data.max_end_time === "Invalid date") ? (
                                  <h6
                                    style={{
                                      color: themes.helperTextColor,
                                      fontSize: 12,
                                    }}
                                  >
                                    {errorHandling}
                                  </h6>
                                ) : null}
                                {handleEndTimeError ===
                                  "Max end time must be after the end time." &&
                                data.id === overlabRowId ? (
                                  <h5
                                    style={{
                                      color: themes.helperTextColor,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {handleEndTimeError}
                                  </h5>
                                ) : null}
                              </>
                            ) : null}
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Card>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid sx={{ paddingTop: 3 }}>
                  <Mui.Button
                    sx={{
                      textTransform: "capitalize",
                      float: "right",
                      backgroundColor: themes.redColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.redColor,
                      },
                    }}
                    onClick={() => {
                      setRemoveDataId(data?.id);
                      setOpenDialog(true);
                    }}
                  >
                    Remove
                  </Mui.Button>
                </Mui.Grid>
              </Card>
            </Mui.Grid>
          </>
        ))}
        <Mui.Grid sx={{ paddingTop: 3 }}>
          <Mui.Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: themes.lightGray,
              color: themes.whiteColor,
              "&:hover": {
                backgroundColor: themes.lightGray,
              },
            }}
            disabled={
              handleTimeError !== "" || handleEndTimeError !== "" ? true : false
            }
            onClick={() => addNewRow()}
          >
            <AddIcon fontSize="small" /> Add Shift
          </Mui.Button>
        </Mui.Grid>
        {hidePolicy ? null : (
          <>
            <Mui.Grid sx={{ paddingTop: 3 }}>
              <Mui.Grid>
                <Mui.Grid>
                  <Mui.Typography sx={{ fontSize: 24, fontWeight: 800 }}>
                    Policy :
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid>
                  {additionalinfo ? null : (
                    <Mui.Typography sx={{ fontSize: 24, fontWeight: 800 }}>
                      Is dual sensors installed in the building ?
                    </Mui.Typography>
                  )}
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container spacing={3} sx={{ paddingTop: 3 }}>
                <Mui.Grid item>
                  {additionalinfo ? null : (
                    <Mui.Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: themes.lightGray,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.lightGray,
                        },
                      }}
                      onClick={() => setAdditionalinfo(true)}
                    >
                      yes
                    </Mui.Button>
                  )}
                </Mui.Grid>

                <Mui.Grid item>
                  {additionalinfo ? null : (
                    <Mui.Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: themes.lightGray,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.lightGray,
                        },
                      }}
                      onClick={() => setHidePolicy(true)}
                    >
                      no
                    </Mui.Button>
                  )}
                </Mui.Grid>
              </Mui.Grid>
              {additionalinfo ? (
                <Mui.Grid sx={{ paddingTop: 3 }}>
                  <Card
                    sx={{ padding: 3, borderRadius: 4, width: "100%" }}
                    elevation={8}
                  >
                    <Mui.Grid container>
                      <Mui.Grid item xs={3.5}>
                        <Mui.Typography sx={{ paddingLeft: 0.5 }}>
                          Check-in{" "}
                        </Mui.Typography>
                        <Mui.Autocomplete
                          id="combo-box-demo"
                          value={checkInPolicy}
                          options={checkInPolicyDetails}
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          }}
                          renderOption={(props, option) => {
                            return <li {...props}>{option.label}</li>;
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                            },
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              setCheckInPolicy(value.label);
                              setCheckInPolicyText(value.policy);
                            }
                          }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              value={checkInPolicy}
                              sx={{ background: themes.whiteColor, padding: 1 }}
                              placeholder="Please select checkin policy"
                            />
                          )}
                        />
                      </Mui.Grid>
                      <Mui.Grid item xs={3.5}>
                        <Mui.Typography sx={{ paddingLeft: 1 }}>
                          Check-out{" "}
                        </Mui.Typography>
                        <Mui.Autocomplete
                          id="combo-box-demo"
                          value={checkOutPolicy}
                          options={checkOutPolicyDetails}
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          }}
                          renderOption={(props, option) => {
                            return <li {...props}>{option.label}</li>;
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                            },
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              setCheckOutPolicy(value.label);
                              setCheckOutPolicyText(value.policy);
                            }
                          }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              value={checkOutPolicy}
                              sx={{ background: themes.whiteColor, padding: 1 }}
                              placeholder="Please select checkin policy"
                            />
                          )}
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </Card>
                  <Mui.Grid sx={{ paddingTop: 2 }}>
                    <Mui.Button
                      sx={{
                        textTransform: "capitalize",
                        float: "right",
                        backgroundColor: themes.redColor,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.redColor,
                        },
                      }}
                      onClick={() => setAdditionalinfo(false)}
                    >
                      cancel
                    </Mui.Button>
                  </Mui.Grid>
                </Mui.Grid>
              ) : null}
            </Mui.Grid>
          </>
        )}

        <Mui.Grid container sx={{ paddingTop: 5 }}>
          <Mui.Grid item xs={10}></Mui.Grid>
          <Mui.Grid
            item
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                dispatch(setGlobalSearchValue(""));
                history.push("/admin/shiftcategory");
              }}
              actionName="Cancel"
              typeName="button"
            />
            &nbsp;
            <Mui.Button
              style={{
                height: "40px",
                textTransform: "none",
                backgroundColor:
                  sameShiftNameError === ""
                    ? errorHandling === "Use different time"
                      ? themes.lightGray
                      : themes.primaryButton
                    : themes.lightGray,
                color: themes.headLine,
                float: "right",
                "&:hover": {
                  backgroundColor:
                    sameShiftNameError === "" ||
                    errorHandling === "Use different time"
                      ? themes.primaryButton
                      : themes.lightGray,
                },
              }}
              onClick={() => createShiftCategory()}
              disabled={
                sameShiftNameError === ""
                  ? errorHandling === "Use different time" ||
                    errorHandling === "Use different time " ||
                    handleTimeError !== "" ||
                    handleEndTimeError !== ""
                    ? true
                    : false
                  : true
              }
            >
              <Mui.Typography
                sx={{ fontSize: { xs: 0, sm: 0, md: 12, lg: 14 } }}
              >
                Add
              </Mui.Typography>
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Container>
      <Mui.Grid sx={{ paddingTop: 3 }}></Mui.Grid>
      {openDialog ? (
        <DialogBoxShift
          handleCloseDialog={handleCloseDialogBox}
          open={openDialog}
          title="Remove Shift"
        >
          <Mui.Grid container>
            <Mui.Grid item xs={12}>
              <Mui.Typography fontSize={24}>
                Do you want to remove shift ?
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={handleCloseDialogBox}
              actionName="No"
              typeName="submit"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => {
                console.log(removeDataId);
                deleteRow(removeDataId);
              }}
              actionName="Yes"
              typeName="submit"
            />
          </Mui.Grid>
        </DialogBoxShift>
      ) : null}
      <Mui.Grid sx={{ display: "none" }}>
        <Mui.TextField value={maxStartTime} />
      </Mui.Grid>
    </React.Fragment>
  );
};
