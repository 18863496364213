import React, { useState, useEffect } from "react";
import Pending from "./Pending";
import Approved from "./Approved";
import { ApplyLeave } from "./ApplyLeave";
import LeavesCard from "./LeaveRequest";
import { parse, format } from "date-fns";
import { showToast } from "components/Status";
import { themes, defaultAllSelection } from "services/constants";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import sessionHandling from "services/utils/notificationUtils";

export const Leaves = ({ setReloadData }) => {
  const dispatch = useDispatch();
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedComponent, setSelectedComponent] = React.useState("pending");
  const [pendingdata, setPendingData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [updateLeaveCard, setUpdateLeaveCard] = useState(true);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [reloadApprovedData, setReloadApprovedData] = useState(false);
  const [reloadRejectedData, setReloadRejectedData] = useState(false);
  const [alignment, setAlignment] = useState("Approve");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userName, setUserName] = useState("All");
  const [userId, setUserId] = useState(defaultAllSelection);
  const { result: membersData, setLoad: reCallMemberApi } = useFetch(
    `${domain}get-department-based-user/?department_id=${departmentId}&user_id=${userDetails.id}`
  );

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const allDepartment = {
            id: defaultAllSelection,
            department_name: "All",
          };
          setDepartmentData([allDepartment, ...res]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const leaveApi = `${domain}leave-request/?user_id=${userDetails.id}&status=Pending&department_id=${departmentId}`;
        const response1 = await fetch(leaveApi, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });

        const res1 = await response1.json();
        if (response1.ok) {
          const totalData = res1;
          totalData?.sort(
            (a, b) =>
              new Date(b?.from_date.split("-").reverse().join("-")) -
              new Date(a?.from_date.split("-").reverse().join("-"))
          );
          setPendingData(totalData);
          setMasterDataForSearch(totalData);
        } else {
          if (!response1.ok) {
            if (response1.status === 409) {
              sessionHandling();
            } else {
              throw new Error(res1.error);
            }
          }
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (selectedComponent === "pending") {
      fetchData();
    }
  }, [
    departmentId,
    domain,
    selectedComponent,
    token,
    userDetails.id,
    userDetails.role,
  ]);

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setStartDate(sDate);
          setEndDate(eDate);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, token]);

  const handleApproveClick = (item) => {
    const { id } = item;
    setPendingData((prevData) => {
      const updatedData = prevData.filter((item) => item.id !== id);
      return updatedData;
    });
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "approved":
        return (
          <Approved
            setReloadApprovedData={setReloadApprovedData}
            reloadApprovedData={reloadApprovedData}
            userId={userId}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case "rejected":
        return (
          <ApplyLeave
            setReloadRejectedData={setReloadRejectedData}
            reloadRejectedData={reloadRejectedData}
          />
        );
      case "pending":
        return (
          <Pending
            data={pendingdata}
            loading={loading}
            onApproveClick={handleApproveClick}
            setUpdateLeaveCard={setUpdateLeaveCard}
            setReloadData={setReloadData}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch?.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toString().toLowerCase()) > -1
            : null
        )
      );
    };
    setPendingData(FilterDockets());
    // setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  const updateUserName = (event, data) => {
    if (data) {
      setUserName(data.label);
      setUserId(data.user_id);
      reCallMemberApi(true);
      if (selectedComponent === "approved") {
        setReloadApprovedData(true);
      } else if (selectedComponent === "rejected") {
        setReloadRejectedData(true);
      } else if (selectedComponent === "pending") {
        setUpdateLeaveCard(true);
      }
    }
  };

  const updateDepartmentName = (event, data) => {
    if (data) {
      dispatch(setDepartmentName(data.label));
      dispatch(setDepartmentId(data.id));
      localStorage.setItem("approveLeaveDepartmentId", data.id);
      localStorage.setItem("approveLeaveDepartmentName", data.label);
      setUserName("All");
      setUserId(defaultAllSelection);
      reCallMemberApi(true);
      if (selectedComponent === "approved") {
        setReloadApprovedData(true);
      } else if (selectedComponent === "rejected") {
        setReloadRejectedData(true);
      } else if (selectedComponent === "pending") {
        setUpdateLeaveCard(true);
      }
      setReloadData(true);
    }
  };

  const updateLeavePeriod = (event, data) => {
    if (data && data.filteredValue) {
      setFilteredLabel(data.label);
      setFilteredValue(data.filteredValue);
      setStartDate(formatDate(data.start_date));
      setEndDate(formatDate(data.end_date));
      reCallMemberApi(true);
      if (selectedComponent === "approved") {
        setReloadApprovedData(true);
      } else if (selectedComponent === "rejected") {
        setReloadRejectedData(true);
      } else if (selectedComponent === "pending") {
        setUpdateLeaveCard(true);
      }
      setReloadData(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <Mui.Grid container sx={{ paddingTop: 5, paddingLeft: 3 }}>
        <Mui.Grid xs={4.4}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
              height: 55,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "30px",
                backgroundColor: themes.lightBrown,
              }}
            >
              <ToggleButton
                value="Approve"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    alignment === "Approve"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    alignment === "Approve"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      alignment === "Approve"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      alignment === "Approve"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
                onClick={() => {
                  setUpdateLeaveCard(true);
                  setSelectedComponent("pending");
                  setReloadData(true);
                }}
              >
                Approve
              </ToggleButton>
              <ToggleButton
                value="History"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  border: "none",
                  color:
                    alignment === "History"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    alignment === "History"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      alignment === "History"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      alignment === "History"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
                onClick={() => {
                  reCallMemberApi(true);
                  setSelectedComponent("approved");
                  setReloadApprovedData(true);
                  setReloadData(true);
                }}
              >
                History
              </ToggleButton>
              <ToggleButton
                value="Apply Leave"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    alignment === "Apply Leave"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    alignment === "Apply Leave"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      alignment === "Apply Leave"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      alignment === "Apply Leave"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                  "&:focus": {
                    backgroundColor:
                      alignment === "Apply Leave"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      alignment === "Apply Leave"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
                onClick={() => {
                  setReloadRejectedData(true);
                  setSelectedComponent("rejected");
                }}
              >
                Apply
              </ToggleButton>
            </ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>
        <Mui.Grid xs={0.2}></Mui.Grid>
        <Mui.Grid item xs={2.4} sx={{ padding: 1 }}>
          {selectedComponent === "pending" ||
          selectedComponent === "approved" ? (
            <CustomAutoComplete
              dataList={departmentData?.map((obj) => ({
                ...obj,
                label: obj.department_name,
              }))}
              selectedValue={departmentName}
              updateDataFunction={updateDepartmentName}
              placeHolder="Select department name"
              label="Select Department Name"
            />
          ) : null}
        </Mui.Grid>
        <Mui.Grid item xs={2.4} sx={{ padding: 1 }}>
          {selectedComponent === "approved" ? (
            <CustomAutoComplete
              dataList={membersData?.map((obj) => ({
                ...obj,
                label: obj?.user_name,
              }))}
              selectedValue={userName}
              updateDataFunction={updateUserName}
              placeHolder="Select employee name"
              label="Select User Name"
            />
          ) : null}
        </Mui.Grid>
        <Mui.Grid item xs={2.5} sx={{ padding: 1 }}>
          {selectedComponent === "approved" ? (
            <CustomAutoComplete
              dataList={leaveDate}
              selectedValue={filteredValue}
              updateDataFunction={updateLeavePeriod}
              placeHolder="Select leave period"
              label={filteredLabel ? filteredLabel : "Leave Period"}
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Grid
          xs={selectedComponent === "pending" ? 8.8 : 12}
          sx={{ justifyContent: "center" }}
        >
          {renderComponent()}
        </Mui.Grid>
        <Mui.Grid
          xs={selectedComponent === "pending" ? 0.2 : 0}
          sx={{ borderRight: "4px solid black" }}
        ></Mui.Grid>
        <Mui.Grid xs={selectedComponent === "pending" ? 3 : 0}>
          {selectedComponent === "pending" ? (
            <LeavesCard
              updateLeaveCard={updateLeaveCard}
              setUpdateLeaveCard={setUpdateLeaveCard}
              departmentId={departmentId}
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
    </div>
  );
};
