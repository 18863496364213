import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { CustomButton } from "components/CustomButton";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
import { getContrastingTextColor } from "services/utils/colorChecker";
const attendanceSummaryHeader = [
  { name: "P", key: "Present" },
  { name: "L", key: "Leave" },
  { name: "A", key: "Absent" },
  { name: "HL", key: "Half Day Leave" },
  { name: "PR", key: "Permission" },
  // { name: "LOP", key: "" },
  // { name: "OT", key: "" },
  // { name: "EXPIRED", key: "" },
  // { name: "EXCEEDED", key: "" },
];
export const PayrunPeriodDateRange = ({
  attendanceDetails,
  payScheduleId,
  reCallPayScheduleApi,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [leavePage, setLeavePage] = useState(0);
  const [leaverowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [editUserPresentDetails, setEditUserPrensentDetails] = useState(null);
  const [editedDay, setEditedDay] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [checkInTime, setCheckInTime] = useState(null);
  const [handleCheckInError, setHandleCheckInError] = useState("");
  console.log(attendanceDetails);
  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 25));
    setLeavePage(0);
  };

  const updateCheckInTime = (newValue) => {
    if (newValue && newValue["$d"].toString() !== "Invalid Date") {
      setCheckInTime(dayjs(newValue["$d"]));
      setHandleCheckInError("");
    } else {
      setHandleCheckInError("Invalid check-in format");
    }
  };

  const updateCheckOutTime = (newValue) => {
    if (newValue && newValue["$d"].toString() !== "Invalid Date") {
      setCheckOutTime(dayjs(newValue["$d"]));
      setHandleCheckInError("");
    } else {
      setHandleCheckInError("Invalid check-out format");
    }
  };

  const getTwentyFourHourTime = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    }
  };

  const updateTheModifiedDay = async () => {
    if (checkInTime && checkOutTime) {
      let validation = false;
      if (
        (handleCheckInError === "" ||
          handleCheckInError ===
            "Checkin time is greater then check out time" ||
          handleCheckInError ===
            "Check-in time to be earlier than check-out time.") &&
        checkInTime["$d"]
      ) {
        const checkInData = getTwentyFourHourTime(
          moment(checkInTime["$d"]).format("hh:mm A")
        );
        const checkOutData = getTwentyFourHourTime(
          moment(checkOutTime["$d"]).format("hh:mm A")
        );
        const checkTotalCheckOutTime =
          parseInt(checkOutData?.split(":")[0]) * 60 +
          parseInt(checkOutData?.split(":")[1]);
        const checkTotalCheckInTime =
          parseInt(checkInData?.split(":")[0]) * 60 +
          parseInt(checkInData?.split(":")[1]);
        if (checkTotalCheckInTime < checkTotalCheckOutTime) {
          validation = true;
        } else {
          validation = false;
          setHandleCheckInError(leaveManagementError.checkInError);
        }
      } else {
        if (checkOutTime?._d) {
          validation = false;
        } else if (
          handleCheckInError === "Invalid check-out format" ||
          handleCheckInError === "Invalid check-out format"
        ) {
          validation = false;
        } else {
          validation = true;
        }
      }
      if (validation) {
        try {
          const response = await fetch(`${domain}payrun/attendance-timeline/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              check_in_time: checkInTime["$d"]
                ? getTwentyFourHourTime(
                    moment(checkInTime["$d"]).format("hh:mm A")
                  )
                : null,
              check_out_time: checkOutTime["$d"]
                ? getTwentyFourHourTime(
                    moment(checkOutTime["$d"]).format("hh:mm A")
                  )
                : null,
              date: editedDay?.date,
              user_id: editUserPresentDetails.user_id,
              pay_schedule_id: payScheduleId,
              start_date: editedDay?.date,
              end_date: editedDay?.date,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setCheckInTime(null);
            setCheckOutTime(null);
            setOpenDialog(false);
            reCallPayScheduleApi(true);
            showToast("success", res.message);
            setHandleCheckInError("");
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    } else {
      if (!checkInTime) {
        setHandleCheckInError("Invalid check-in format");
      }
      if (checkInTime && !checkOutTime) {
        setHandleCheckInError("Invalid check-out format");
      }
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid item xs={1}>
          <Mui.Stack direction="row">
            <div
              style={{
                display: "inline-block",
                width: "25px",
                height: "20px",
                backgroundColor: "#3A8D4C",
                justifyContent: "center",
                cursor: "pointer",
              }}
            />{" "}
            &nbsp;
            <Mui.Typography>PRESENT</Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid item xs={1}>
          <Mui.Stack direction="row">
            <div
              style={{
                display: "inline-block",
                width: "25px",
                height: "20px",
                backgroundColor: "#171D1A",
                justifyContent: "center",
                cursor: "pointer",
              }}
            />{" "}
            &nbsp;
            <Mui.Typography>PERMISSION</Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid item xs={1}>
          <Mui.Stack direction="row">
            <div
              style={{
                display: "inline-block",
                width: "25px",
                height: "20px",
                backgroundColor: "#6B3E3E",
                justifyContent: "center",
                cursor: "pointer",
              }}
            />{" "}
            &nbsp;
            <Mui.Typography>HALF DAY</Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid item xs={1}>
          <Mui.Stack direction="row">
            <div
              style={{
                display: "inline-block",
                width: "25px",
                height: "20px",
                backgroundColor: "#3A4C8D",
                justifyContent: "center",
                cursor: "pointer",
              }}
            />{" "}
            &nbsp;
            <Mui.Typography>HOLIDAY</Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid item xs={1}>
          <Mui.Stack direction="row">
            <div
              style={{
                display: "inline-block",
                width: "25px",
                height: "20px",
                backgroundColor: "#D3A500",
                justifyContent: "center",
                cursor: "pointer",
              }}
            />{" "}
            &nbsp;
            <Mui.Typography>LEAVE</Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid item xs={1}>
          <Mui.Stack direction="row">
            <div
              style={{
                display: "inline-block",
                width: "25px",
                height: "20px",
                backgroundColor: "#DE4646",
                justifyContent: "center",
                cursor: "pointer",
              }}
            />{" "}
            &nbsp;
            <Mui.Typography>ABSEND</Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid item xs={1}>
          <Mui.Stack direction="row">
            <div
              style={{
                display: "inline-block",
                width: "25px",
                height: "20px",
                backgroundColor: "#FFFFFF",
                justifyContent: "center",
                cursor: "pointer",
                border: "2px solid black",
              }}
            />{" "}
            &nbsp;
            <Mui.Typography>WEEK OFF</Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.TableContainer sx={{ paddingTop: 2 }}>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow
              sx={{
                "& .MuiTableCell-root": {
                  height: "15px",
                  padding: "1px 1px",
                  width: "-1px",
                },
              }}
            >
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Name
              </Mui.TableCell>
              {attendanceDetails?.headers?.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  <Mui.Stack>
                    <Mui.Typography textAlign="center" sx={{ fontSize: 10 }}>
                      {header.day_number}
                    </Mui.Typography>
                    <Mui.Typography textAlign="center" sx={{ fontSize: 10 }}>
                      {header.day}
                    </Mui.Typography>
                  </Mui.Stack>
                </Mui.TableCell>
              ))}
              {attendanceSummaryHeader?.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  <Mui.Stack>
                    <Mui.Typography textAlign="center" sx={{ fontSize: 10 }}>
                      {header.name}
                    </Mui.Typography>
                  </Mui.Stack>
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {attendanceDetails?.attendance_data
              ?.slice(
                leavePage * leaverowsPerPage,
                leavePage * leaverowsPerPage + leaverowsPerPage
              )
              .map((attendancedata) => (
                <Mui.TableRow
                  key={attendancedata.user_id}
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "15px",
                      padding: "8px 1px",
                      width: "-1px",
                    },
                  }}
                >
                  <Mui.TableCell className="column-cell" align="center">
                    {attendancedata?.user}
                  </Mui.TableCell>
                  {attendancedata?.attendance?.map((dayDetails) => (
                    <Mui.TableCell
                      // className="column-cell"
                      align="center"
                      onClick={() => {
                        if (dayDetails.legend === "A") {
                          setHandleCheckInError("");
                          setCheckOutTime(null);
                          setCheckInTime(null);
                          setOpenDialog(true);
                          setEditUserPrensentDetails(attendancedata);
                          setEditedDay(dayDetails);
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          width: "25px",
                          height: "20px",
                          backgroundColor: dayDetails.colour_code,
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Mui.Typography
                          sx={{
                            fontSize: 15,
                            color: getContrastingTextColor(
                              dayDetails?.colour_code
                                ? dayDetails?.colour_code
                                : null
                            ),
                          }}
                        >
                          {dayDetails.legend}
                        </Mui.Typography>
                      </div>
                    </Mui.TableCell>
                  ))}
                  {attendanceSummaryHeader?.map((header) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.Stack>
                        <Mui.Typography
                          textAlign="center"
                          sx={{ fontSize: 10 }}
                        >
                          {attendancedata?.status_counts[header.key] ||
                          attendancedata?.status_counts[header.key] === 0
                            ? attendancedata?.status_counts[header.key]
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              ))}
          </Mui.TableBody>
        </Mui.Table>
        {attendanceDetails?.attendance_data?.length > 25 ? (
          <Mui.TablePagination
            className="custom-pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={attendanceDetails?.attendance_data?.length}
            rowsPerPage={leaverowsPerPage}
            page={leavePage}
            onPageChange={handleChangeLeavePage}
            onRowsPerPageChange={handleChangeLeaveRowsPerPage}
          />
        ) : null}
      </Mui.TableContainer>
      {openDialog ? (
        <GlobalDialogBox
          handleCloseDialog={() => {
            setOpenDialog(false);
            setCheckInTime(null);
            setCheckOutTime(null);
            setEditUserPrensentDetails(null);
            setEditedDay(null);
            setHandleCheckInError("");
          }}
          open={openDialog}
          title="Edit Attendance Details"
        >
          <Mui.Stack>
            <Mui.Typography>Name</Mui.Typography>
            <Mui.TextField
              defaultValue={editUserPresentDetails.user}
              size="small"
              disabled={true}
              fullWidth
            />
            <Mui.Typography sx={{ paddingTop: 0.5 }}>Date</Mui.Typography>
            <Mui.TextField
              value={editedDay.date}
              size="small"
              type="date"
              disabled={true}
              fullWidth
            />
          </Mui.Stack>
          <Mui.Typography sx={{ paddingTop: 0.5 }}>
            Check-In Time
          </Mui.Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                // label="check-in"
                size="small"
                value={checkInTime}
                onChange={updateCheckInTime}
                slotProps={{
                  textField: {
                    size: "small",
                    style: {
                      width: "100%",
                    },
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Mui.Typography
            sx={{
              color: themes.redColor,
              fontSize: 12,
            }}
          >
            {handleCheckInError ===
            "Checkin time is greater then check out time"
              ? "Checkin time is greater then check out time"
              : null}
            {handleCheckInError === "Invalid check-in format"
              ? "Invalid check-in format"
              : null}
          </Mui.Typography>
          <Mui.Typography sx={{ paddingTop: 0.5 }}>
            Check-Out Time
          </Mui.Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                // label="check-out"
                value={checkOutTime}
                onChange={(newValue) => updateCheckOutTime(newValue)}
                sx={{ width: 25 }}
                slotProps={{
                  textField: {
                    size: "small",
                    style: {
                      width: "100%",
                    },
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Mui.Typography
            sx={{
              color: themes.redColor,
              fontSize: 12,
            }}
          >
            {handleCheckInError === "Invalid check-out format"
              ? "Invalid check-out format"
              : null}
            {handleCheckInError ===
            "Check-in time to be earlier than check-out time."
              ? "Check-in time to be earlier than check-out time."
              : null}
          </Mui.Typography>
          <Mui.Grid
            container
            sx={{
              paddingTop: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="Cancel"
              typeName="submit"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={updateTheModifiedDay}
              actionName="Update"
              typeName="submit"
            />
          </Mui.Grid>
        </GlobalDialogBox>
      ) : null}
    </React.Fragment>
  );
};
