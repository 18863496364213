import React, { useEffect, useState, useContext } from "react";
import { NewProjectContext } from "./NewProjectContext";
import { showToast } from "components/Status";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { useHistory } from "react-router-dom";
import { AskProjectWorkFlow } from "./AskProjectWorkFlow";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import PropTypes from "prop-types";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import {
  setEditProject,
  setProjectStatus,
} from "services/Redux/leaveManagement";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { CreateClient } from "./CreateClient";
import {
  Person,
  Check,
  Close,
  AccountTree,
  CorporateFare,
} from "@mui/icons-material";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/BackArrow";

const NewProjects = () => {
  const {
    allClients,
    clientId,
    setClientId,
    clientName,
    setClientName,
    allClientsId,
  } = useContext(NewProjectContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [autocompleteVisible, setAutocompleteVisible] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [estimateAmount, setEstimateAmount] = useState(0);
  const [clientUniqueId, setClientUniqueId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [workflowId, setWorkflowId] = useState(0);
  const [members, setMembers] = useState([]);
  const [projectLead, setProjectLead] = useState(userDetails.username);
  const [projectLeadId, setProjectLeadId] = useState(userDetails.id);
  const [projectName, setProjectName] = useState("");
  const [page, setPage] = useState("Create Project");
  const [createProject, setCreateProject] = useState(false);
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: members,
    getOptionLabel: (option) => option.username,
    value: selectedMembers,
    onChange: (event, newValue) => {
      setSelectedMembers(newValue);
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("projectLead", userDetails.username);
  }, [setValue, userDetails.username]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(`${domain}company/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const companyDetails = res.map((obj) => ({
            ...obj,
            label: obj.companyname,
          }));
          setAllCompanies(companyDetails);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchCompanyData();
  }, [domain, token]);

  useEffect(() => {
    const getMembers = async () => {
      try {
        const response = await fetch(`${domain}selected_members/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const memberList = res.map((obj) => ({
            ...obj,
            label: obj.username,
            username: obj.username,
          }));
          setMembers(memberList);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    getMembers();
  }, [domain, token]);

  const goToNextPage = (data) => {
    setPage("AskProjectWorkFlow");
    setProjectName(data.projectname);
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const handleCloseDialogBox = () => {
    setOpenCreateClient(false);
  };

  const getComponent = (page) => {
    switch (page) {
      case "AskProjectWorkFlow":
        return (
          <AskProjectWorkFlow
            setPage={setPage}
            setWorkflowId={setWorkflowId}
            workflowId={workflowId}
            setCreateProject={setCreateProject}
          />
        );
      default:
        return <Mui.Grid></Mui.Grid>;
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = /^[A-Z 0-9 a-z\s]$/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const createNewProject = async () => {
      try {
        const response = await fetch(`${domain}create_project/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            project_name: projectName,
            project_lead: projectLeadId,
            client_id: clientUniqueId,
            estimation_amount: estimateAmount,
            selected_members: selectedMembers,
            autocomplete_visible: true,
            company_id: companyId,
            workflow_id: workflowId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          reset();
          let status = [];
          res?.data?.workflow?.statuses.forEach((element) => {
            status.push(element.name);
          });
          dispatch(setProjectStatus(status));
          localStorage.setItem("currentEditProject", JSON.stringify(res.data));
          dispatch(setEditProject(res.data));
          dispatch(setGlobalSearchValue(""));
          history.push("/admin/task");
          showToast("success", res.message);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setCreateProject(false);
      }
    };
    if (createProject) {
      createNewProject();
    }
  }, [
    clientUniqueId,
    companyId,
    createProject,
    dispatch,
    domain,
    estimateAmount,
    history,
    projectLead,
    projectLeadId,
    projectName,
    reset,
    selectedMembers,
    token,
    workflowId,
  ]);

  return (
    <React.Fragment>
      {page === "Create Project" ? (
        <>
          <Mui.Grid
            container
            spacing={2}
            sx={{
              padding: 5,
              paddingBottom: 0,
              paddingTop: 4,
              paddingLeft: 1,
            }}
            item
            direction="row"
            rowGap={1}
          >
            <Mui.Grid xs={0.5} sm={0.2} md={0.2} lg={0.2}></Mui.Grid>
            <Mui.Grid
              xs={12}
              sm={0.8}
              md={0.8}
              lg={0.8}
              sx={{ paddingTop: { xs: 1, sm: 0.8, md: 1, lg: 1 } }}
            >
              <BackArrow
                actionFunction={() => {
                  dispatch(setGlobalSearchValue(""));
                  history.push("/admin/project-management");
                }}
              />
              &nbsp;
            </Mui.Grid>
            <Mui.Grid xs={10} sm={12} md={8} lg={6}>
              <Mui.Typography
                color={themes.headLine}
                sx={{
                  fontWeight: "bold",
                  paddingBottom: 3,
                  fontFamily: "Popins",
                  fontSize: { xs: 20, sm: 30, md: 42, lg: 45 },
                  paddingLeft: { xs: 3, sm: 3 },
                }}
              >
                Create new project
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={1} sm={0} md={3} lg={5}></Mui.Grid>
          </Mui.Grid>

          <Mui.Grid
            container
            spacing={2}
            sx={{
              paddingBottom: 5,
              paddingLeft: 3,
            }}
            item
            direction="row"
            rowGap={1}
          >
            <Mui.Grid xs={0} sm={1} md={2} lg={3}></Mui.Grid>
            <Mui.Grid xs={12} sm={10} md={8} lg={6}>
              <Mui.Card
                className={classes.card}
                sx={{
                  borderRadius: 5,
                  boxShadow: 0,
                }}
              >
                <Mui.CardContent sx={{ margin: 5 }}>
                  <form onSubmit={handleSubmit(goToNextPage)}>
                    <Mui.TextField
                      label={
                        <>
                          Project Name
                          <span
                            style={{ color: themes.redColor, fontSize: 18 }}
                          >
                            *
                          </span>
                        </>
                      }
                      InputLabelProps={{
                        shrink: true,
                        sx: { fontSize: 17 },
                      }}
                      fullWidth
                      {...register("projectname", {
                        required: "Project name is required",
                        maxLength: {
                          value: 30,
                          message: "Maximum length is 30 characters",
                        },
                        minLength: {
                          value: 3,
                          message: "Minium length is 3 characters",
                        },
                        validate: {
                          noDigitsOrSpecial: (fieldValue) => {
                            if (!/[A-Za-z]/.test(fieldValue)) {
                              return "Project name must contain at least one alphabet character.";
                            }
                            if (/^\d+$/.test(fieldValue)) {
                              return "Project name should not contain only digits.";
                            }
                            if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                              return "Project name should only contain alphabets, spaces, -,numbers and & symbol.";
                            }
                            return true;
                          },
                          projectAvailable: async (fieldValue) => {
                            if (fieldValue !== "") {
                              try {
                                const response = await fetch(
                                  `${domain}check_project_name/?project_name=${fieldValue}`,
                                  {
                                    method: "GET",
                                    headers: {
                                      "content-type": "application/json",
                                      Authorization: `token ${token}`,
                                    },
                                  }
                                );
                                const res = await response.json();
                                if (response.ok) {
                                  if (res?.exists) {
                                    return "Project name already taken.Choose a unique name.";
                                  } else {
                                    return true;
                                  }
                                } else if (response.status === 409) {
                                  sessionHandling();
                                } else {
                                  throw new Error(res.error);
                                }
                              } catch (error) {
                                showToast("error", error.message);
                              }
                            }
                          },
                        },
                      })}
                      name="projectname"
                      error={errors.name ? true : false}
                      sx={{ background: themes.whiteColor }}
                      placeholder={projectPlaceholder.projectName}
                      onBlur={() => {
                        setValue(
                          "projectname",
                          getValues("projectname").replace(/S[^a-zA-Z]+/g, "")
                        );
                      }}
                      onKeyPress={handleKeyDown}
                      InputProps={
                        ({
                          startAdornment: (
                            <>
                              <AccountTree
                                style={{
                                  margin: "0 8px",
                                  color: themes.allIconColor,
                                }}
                              />
                            </>
                          ),
                        },
                        {
                          inputProps: { maxLength: 30 },
                        })
                      }
                    />
                    {errors.projectname && (
                      <Mui.Typography
                        sx={{
                          color: themes.redColor,
                          fontSize: 12,
                        }}
                      >
                        {errors.projectname.message}
                      </Mui.Typography>
                    )}
                    <Mui.Grid
                      container
                      direction="row"
                      sx={{ paddingBottom: 0.5, paddingTop: 2 }}
                    ></Mui.Grid>
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={projectLead}
                      options={members}
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.username
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option.username}</li>;
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                        },
                      }}
                      onChange={(event, value) => {
                        if (value) {
                          setProjectLead(value.username);
                          setProjectLeadId(value.user_id);
                          setValue("projectLead", value.username);
                          trigger("projectLead");
                        } else {
                          setProjectLead("");
                          setProjectLeadId(null);
                          setValue("projectLead", null);
                        }
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <Mui.TextField
                          label={
                            <>
                              Project Lead
                              <span
                                style={{ color: themes.redColor, fontSize: 18 }}
                              >
                                *
                              </span>
                            </>
                          }
                          InputLabelProps={{
                            shrink: true,
                            sx: { fontSize: 17 },
                          }}
                          {...params}
                          {...register("projectLead", {
                            required: "Project lead is required",
                          })}
                          value={projectLead}
                          sx={{ background: themes.whiteColor }}
                          placeholder={projectPlaceholder.projectLead}
                          size="medium"
                          error={errors.projectLead ? true : false}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <>
                                <Person
                                  style={{
                                    margin: "0 8px",
                                    color: themes.allIconColor,
                                  }}
                                />{" "}
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors.projectLead && (
                      <Mui.Typography
                        sx={{
                          color: themes.redColor,
                          fontSize: 12,
                        }}
                      >
                        {errors.projectLead.message}
                      </Mui.Typography>
                    )}
                    <Mui.Grid
                      container
                      direction="row"
                      sx={{ paddingBottom: 0.5, paddingTop: 2 }}
                    ></Mui.Grid>

                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={companyName}
                      options={allCompanies}
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.companyname
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option.companyname}</li>;
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                        },
                      }}
                      onChange={(event, value) => {
                        if (value) {
                          setCompanyId(value.id);
                          setCompanyName(value.companyname);
                          setValue("company", value.companyname);
                          trigger("company");
                        } else {
                          setCompanyId(null);
                          setCompanyName("");
                          setValue("company", "");
                        }
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <Mui.TextField
                          label={
                            <>
                              Company
                              <span
                                style={{ color: themes.redColor, fontSize: 18 }}
                              >
                                *
                              </span>
                            </>
                          }
                          InputLabelProps={{
                            shrink: true,
                            sx: { fontSize: 17 },
                          }}
                          {...params}
                          {...register("company", {
                            required: "Company is required",
                          })}
                          value={companyName}
                          sx={{ background: themes.whiteColor }}
                          placeholder={projectPlaceholder.selectCompany}
                          size="medium"
                          error={errors.company ? true : false}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <>
                                <CorporateFare
                                  style={{
                                    margin: "0 8px",
                                    color: themes.allIconColor,
                                  }}
                                />{" "}
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors.company ? (
                      <Mui.Typography
                        sx={{ color: themes.redColor, fontSize: 12 }}
                      >
                        {errors.company?.message}
                      </Mui.Typography>
                    ) : null}
                    <Mui.Grid
                      container
                      direction="row"
                      sx={{ paddingBottom: 0.5, paddingTop: 2 }}
                    ></Mui.Grid>
                    <Mui.Grid container direction="row">
                      <Mui.Grid xs={12} sm={12} md={12} lg={6} sx={{ pr: 0.5 }}>
                        <Mui.Autocomplete
                          disablePortal
                          fullWidth
                          id="combo-box-demo"
                          value={clientId}
                          options={allClientsId}
                          renderOption={(props, option) => {
                            if (option.clientname === "Create Client") {
                              return (
                                <li style={{ width: "100%" }} {...props}>
                                  <Mui.Button
                                    fullWidth={true}
                                    sx={{
                                      textAlign: "center",
                                      width: "100%",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={() => setOpenCreateClient(true)}
                                  >
                                    Add Client
                                  </Mui.Button>
                                </li>
                              );
                            } else {
                              return <li {...props}>{option.label}</li>;
                            }
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "120px",
                            },
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              setClientUniqueId(value.id);
                              setClientId(value.clientid);
                              setValue("clientid", value.clientid);
                              setClientName(value.clientname);
                              setValue("clientname", value.clientname);
                            } else {
                              setClientUniqueId(null);
                              setClientName("");
                              setValue("clientname", "");
                              setClientId(0);
                              setValue("clientid", 0);
                            }
                          }}
                          sx={{ background: themes.whiteColor }}
                          renderInput={(params) => (
                            <Mui.TextField
                              label="Client Id"
                              InputLabelProps={{
                                shrink: true,
                                sx: { fontSize: 17 },
                              }}
                              {...params}
                              {...register("clientid", {
                                required: false,
                              })}
                              value={clientId}
                              sx={{ background: themes.whiteColor }}
                              placeholder={projectPlaceholder.selectClientId}
                              error={errors.clientid ? true : false}
                              helperText={errors.clientid?.message}
                              onKeyPress={handleKeyPress}
                            />
                          )}
                        />
                      </Mui.Grid>
                      <Mui.Grid xs={12} sm={12} md={12} lg={6} sx={{ pl: 0.5 }}>
                        <Mui.Autocomplete
                          disablePortal
                          fullWidth
                          id="combo-box-demo"
                          value={clientName}
                          options={allClients}
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option.clientname
                                ?.toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          }}
                          renderOption={(props, option) => {
                            if (option.clientname === "Create Client") {
                              return (
                                <div style={{ justifyContent: "center" }}>
                                  <Mui.Button
                                    variant="text"
                                    onClick={() => setOpenCreateClient(true)}
                                    size="small"
                                    sx={{
                                      textTransform: "capitalize",
                                      paddingLeft: 2,
                                    }}
                                  >
                                    Add Client
                                  </Mui.Button>
                                </div>
                              );
                            } else {
                              return <li {...props}>{option.clientname}</li>;
                            }
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "120px",
                            },
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              setClientUniqueId(value.id);
                              setClientName(value.clientname);
                              setValue("clientname", null);
                              setClientId(value.clientid);
                              setValue("clientid", value.clientid);
                            } else {
                              setClientUniqueId(null);
                              setClientName("");
                              setValue("clientname", "");
                              setClientId(0);
                              setValue("clientid", 0);
                            }
                          }}
                          sx={{ background: themes.whiteColor }}
                          renderInput={(params) => (
                            <Mui.TextField
                              label="Client Name"
                              InputLabelProps={{
                                shrink: true,
                                sx: { fontSize: 17 },
                              }}
                              {...params}
                              {...register("clientname", {
                                required: false,
                              })}
                              value={clientName}
                              sx={{ background: themes.whiteColor }}
                              placeholder={projectPlaceholder.selectClientName}
                              error={errors.clientname ? true : false}
                              helperText={errors.clientname?.message}
                            />
                          )}
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid
                      container
                      direction="row"
                      sx={{ paddingBottom: 0.5, paddingTop: 2 }}
                    ></Mui.Grid>
                    <Mui.Grid container direction="row">
                      <Mui.Grid xs={12} sm={12} md={12} lg={12}>
                        <Mui.TextField
                          label="Estimated Amount"
                          InputLabelProps={{
                            shrink: true,
                            sx: { fontSize: 17 },
                          }}
                          fullWidth
                          sx={{ background: themes.whiteColor }}
                          {...register("estimateAmount", {
                            required: "Atleast one digits required",
                          })}
                          value={estimateAmount}
                          InputProps={{
                            inputProps: { maxLength: 10 },
                          }}
                          error={errors.estimateAmount ? true : false}
                          helperText={errors.estimateAmount?.message}
                          onKeyPress={handleKeyPress}
                          onChange={(e) =>
                            setEstimateAmount(
                              parseInt(
                                e.target.value === "" ? 0 : e.target.value
                              )
                            )
                          }
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid
                      container
                      direction="row"
                      sx={{ paddingBottom: 0.5, paddingTop: 2 }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 14, pl: 1.5, fontWeight: "bold" }}
                      >
                        Visibility
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.FormControlLabel
                      control={
                        <Mui.Switch
                          onChange={() =>
                            setAutocompleteVisible(!autocompleteVisible)
                          }
                          sx={{
                            width: 70,
                            height: 50,

                            "& .MuiSwitch-thumb": {
                              width: 32,
                              height: 32,
                              color: autocompleteVisible
                                ? themes.primaryIndicator
                                : "none",
                            },
                            "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                              borderRadius: 100 / 2,
                              backgroundColor: autocompleteVisible
                                ? themes.primaryIndicator
                                : "none",
                            },
                          }}
                          checked={autocompleteVisible}
                        />
                      }
                      label={
                        autocompleteVisible ? (
                          <Mui.Typography
                            sx={{
                              fontSize: 18,
                              fontWeight: "bold",
                            }}
                          >
                            Visible To Members
                          </Mui.Typography>
                        ) : (
                          <Mui.Typography
                            sx={{
                              fontSize: 18,
                              fontWeight: "bold",
                            }}
                          >
                            Access to All
                          </Mui.Typography>
                        )
                      }
                    />
                    {autocompleteVisible && (
                      <>
                        <Mui.Grid
                          container
                          direction="row"
                          sx={{ paddingBottom: 0.5 }}
                        >
                          <Mui.Typography
                            sx={{
                              fontSize: 15,
                            }}
                          >
                            Add Members (Optional)
                          </Mui.Typography>
                        </Mui.Grid>

                        <div {...getRootProps()}>
                          <InputWrapper
                            sx={{
                              width: "100%",
                              minHeight: 50,
                              mt: 1,
                              mb: 1,
                              padding: 1.5,
                              background: themes.whiteColor,
                            }}
                            ref={setAnchorEl}
                          >
                            {selectedMembers.map((option, index) => (
                              <StyledTag
                                label={option.username}
                                {...getTagProps({ index })}
                              />
                            ))}
                            <input
                              {...getInputProps()}
                              style={{ background: themes.whiteColor }}
                            />
                          </InputWrapper>
                        </div>
                        {groupedOptions.length > 0 ? (
                          <Listbox
                            {...getListboxProps()}
                            autocompleteVisible={autocompleteVisible}
                            sx={{ background: themes.whiteColor }}
                          >
                            {groupedOptions.map((option, index) => (
                              <li {...getOptionProps({ option, index })}>
                                <span>{option.username}</span>
                                <Check fontSize="small" />
                              </li>
                            ))}
                          </Listbox>
                        ) : null}
                      </>
                    )}
                    <Mui.Grid container spacing={2} sx={{ paddingTop: 2 }}>
                      <Mui.Grid item xs={12} sx={{ marginTop: 1 }}>
                        <Mui.Button
                          sx={{
                            borderRadius: 2,
                            width: "100%",
                            backgroundColor: themes.primaryIndicator,
                            padding: "14px 0px",
                          }}
                          variant="contained"
                          type="submit"
                        >
                          Next
                        </Mui.Button>
                      </Mui.Grid>
                    </Mui.Grid>
                  </form>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
            <Mui.Grid xs={0} sm={1} md={2} lg={3}></Mui.Grid>
          </Mui.Grid>
          <CreateClient
            handleCloseDialogBox={handleCloseDialogBox}
            openCreateClient={openCreateClient}
          />
        </>
      ) : (
        getComponent(page)
      )}
    </React.Fragment>
  );
};

export default NewProjects;

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
  },
}));

const InputWrapper = Mui.styled("div")(
  ({ theme, autocompleteVisible }) => `
  width: 100%; 
   border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
   background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   border-radius: 4px;
   padding: 1px;
   display: flex;
   flex-wrap: wrap;
   
   &:hover {
     border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
   }

   ${
     autocompleteVisible
       ? `
       &.focused {
         border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
         box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
       }
     `
       : ""
   }

   & input {
     background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
     color: ${
       theme.palette.mode === "dark"
         ? "rgba(255,255,255,0.65)"
         : "rgba(0,0,0,.85)"
     };
    
    
     flex-grow: 1;
     border: 0;
     margin: 0;
     outline: 0;
   }
`
);

const Listbox = Mui.styled("ul")(
  ({ theme, autocompleteVisible }) => `
   width: 350px;
   margin: 2px 0 0;
   padding: 0;
   position: absolute;
   list-style: none;
   background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   overflow: auto;
   max-height: 150px;
   border-radius: 4px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
   z-index: 1;
  
   ${
     autocompleteVisible
       ? `
     background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   `
       : ""
   }
  
   & li {
     padding: 5px 12px;
     display: flex;
  
     & span {
       flex-grow: 1;
     }
  
     & svg {
       color: transparent;
     }
   }
  
   & li[aria-selected='true'] {
     background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
     font-weight: 600;
  
     & svg {
       color: #1890ff;
     }
   }
  
   & li.${Mui.autocompleteClasses.focused} {
     background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
     cursor: pointer;
  
     & svg {
       color: currentColor;
     }
   }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <Close onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = Mui.styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 34px;
  margin: 2px;
  line-height: 22px;
  border-radius: 25px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  border-radius: 25px;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);
