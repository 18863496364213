import { themes } from "services/constants";
import { toast } from "react-toastify";

export const getStatusColor = (status) => {
  switch (status) {
    case "Active":
      return themes.activeTap;
    case "Halfday":
    case "Leave":
      return themes.onleaveTap;
    case "Late":
      return themes.lateTap;
    case "Absent":
      return themes.absentTap;
    case "Break":
      return themes.breakTap;
    case "Out":
      return themes.outTap;
    case "Permission":
      return themes.permissionTap;
    case "Missing":
      return themes.lightOrange;
    default:
      return "black";
  }
};

export const getColorWorkingHours = (status) => {
  switch (status) {
    case "P":
      return themes.greenColor;
    case "L":
      return themes.yellowColor;
    case "A":
      return themes.redColor;
    case "W":
      return themes.whiteColor;
    case "M":
      return themes.lightOrange;
    case "H":
      return themes.blueColor;
    case "PR":
      return themes.blackColor;
    case "HL":
      return "";
    default:
      return "black";
  }
};

export const getCardBackgroundColor = (statusValue) => {
  if (statusValue === "Good") {
    return { backgroundColor: themes.lightGreen };
  } else if (statusValue === "Poor") {
    return { backgroundColor: themes.lightRedColor };
  } else if (statusValue === "Moderate") {
    return { backgroundColor: themes.lightYellowColor };
  } else {
    return true;
  }
};

export const showToast = (type, msg) => {
  switch (type) {
    case "success":
      toast.success(msg, { autoClose: 3000 });
      break;
    case "error":
      toast.error(msg, { autoClose: 3000 });
      break;
    case "info":
      toast.info(msg, { autoClose: 3000 });
      break;
    default:
      toast(msg, { autoClose: 3000 });
  }
};
